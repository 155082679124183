import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { getAllDeliveryBoys } from '../../Services/DeliveryBoy';

function DBLocation() {

    const [deliveryBoyArr, setDeliveryBoyArr] = useState([]);

    const [filteredArr, setFilteredArr] = useState([]);


    const sortIcon = <ArrowDownward />;
    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Phone',
            selector: row => row.mobile,
            sortable: true,
        },

    ];

    const handleSearch = (val) => {
        setFilteredArr([...deliveryBoyArr.filter(el => el.name.toLowerCase().includes(val.toLowerCase()) || el.mobile.includes(val)  || el.email.toLowerCase().includes(val.toLowerCase()) )])
    }
    const getDeliveryBoys = async () => {
        try {
            const { data: res, status: statusCode } = await getAllDeliveryBoys();
            if (statusCode == 200 || statusCode == 304) {
                setDeliveryBoyArr(res.data)
                setFilteredArr(res.data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        getDeliveryBoys()
    }, [])



    return (
        <main className="main_content">
            <div className="searchBar">
                <input type="text" className="form-control" placeholder="Search Delivery Boy" onChange={(e) => handleSearch(e.target.value)} />
                <i className="fa fa-search"></i>
            </div>
            <div className="infoOrdertable mb-4">
                <div className="headingTitle"><h3> Delivery Boy Location </h3></div>
                <div className="card">
                    <DataTable
                        title="Delivery Boys"
                        columns={columns}
                        data={filteredArr}
                        sortIcon={sortIcon}
                        keyField='_id'
                        pagination
                        expandableRows
                        expandableRowsComponent={({ data }) => {
                            return (
                                <div style={{ paddingLeft: 50 }}>

                                    <DataTable
                                        columns={[
                                            {
                                                name: 'S.no',
                                                selector: row => row.sno,
                                                sortable: true
                                            },
                                            {
                                                name: 'Location',
                                                selector: row => row.location,
                                                sortable: true
                                            }, {
                                                name: 'Date',
                                                selector: row => `${new Date(row.createdAt).toDateString()},${new Date(row.createdAt).getHours()}:${new Date(row.createdAt).getMinutes()}`,
                                                sortable: true
                                            }
                                        ]}
                                        data={data.deliveryBoyLocations.map((el, index) => {
                                            let obj = {
                                                sno: index + 1,
                                                ...el
                                            }
                                            return obj
                                        })}
                                    />
                                </div>

                            )
                        }}
                    />

                </div>
            </div>
        </main>
    )
}

export default DBLocation;