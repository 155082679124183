import React from 'react';


function InfoNotDelivered() {
    return (
        <main className="main_content">
            <div className="filterSearchBar">
                <form>
                    <div className="row mb-4">
                        <div className="col-12 col-md-3">
                            <label>Start Date</label>
                            <input type="date" className="form-control" />
                        </div>
                        <div className="col-12 col-md-3">
                            <label>End Date</label>
                            <input type="date" className="form-control" />
                        </div>
                        <div className="col-12 col-md-2">
                            <label>&nbsp;</label>
                            <button type="button" className="btn searchBttn"> Search </button>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12 col-md-3">
                            <label>User</label>
                            <input type="text" className="form-control" placeholder="Search Delivery Name" />
                        </div>
                        <div className="col-12 col-md-3">
                            <label>Status</label>
                            <select className="form-control">
                                <option>All ( default )</option>
                                <option>Active</option>
                                <option>Completed</option>
                                <option>Top UP</option>
                            </select>
                        </div>
                        <div className="col-12 col-md-2">
                            <label>&nbsp;</label>
                            <button type="button" className="btn searchBttn"> Search </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-3">
                            <label>Delivery Boy</label>
                            <input type="text" className="form-control" placeholder="Search Delivery Boy" />
                        </div>
                        <div className="col-12 col-md-2">
                            <label>Type</label>
                            <select className="form-control">
                                <option>All ( default )</option>
                                <option>Active</option>
                                <option>Completed</option>
                                <option>Top UP</option>
                            </select>
                        </div>
                        <div className="col-12 col-md-2">
                            <label>City</label>
                            <input type="text" className="form-control" placeholder="Search City Name" />
                        </div>
                        <div className="col-12 col-md-2">
                            <label>Area</label>
                            <input type="text" className="form-control" placeholder="Search Area Name" />
                        </div>
                        <div className="col-12 col-md-2">
                            <label>&nbsp;</label>
                            <button type="button" className="btn searchBttn"> Search </button>
                        </div>
                    </div>
                </form>
            </div>
            <br />
            <br />
            <div className="userTabsDesign mb-4">
                <div className="headingTitle"><h3> Order Not Delivered </h3></div>
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-responsive-sm">
                                <thead>
                                    <tr>
                                        <th>User Name</th>
                                        <th>Happy CODE</th>
                                        <th>Pickup Address</th>
                                        <th> Delivery address </th>
                                        <th> Area </th>
                                        <th> KM ( range ) </th>
                                        <th> Not Delivered </th>
                                        <th> Pickup Time </th>
                                        <th> Delivered time </th>
                                        <th> Cash Collection </th>
                                        <th> Amount </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th> 01 </th>
                                        <td>1010</td>
                                        <td>Gurgaon Phase2</td>
                                        <td> Gurgaon Phase1 </td>
                                        <td> DLF (1) </td>
                                        <td> 4 </td>
                                        <td> Reason </td>
                                        <td> 11.10 am </td>
                                        <td> 11.30 am </td>
                                        <td> yes </td>
                                        <td> 500 </td>
                                    </tr>
                                    <tr>
                                        <th> 02 </th>
                                        <td>1010</td>
                                        <td>Gurgaon Phase2</td>
                                        <td> Gurgaon Phase1 </td>
                                        <td> DLF (1) </td>
                                        <td> 4 </td>
                                        <td> Reason </td>
                                        <td> 11.10 am </td>
                                        <td> 11.30 am </td>
                                        <td> No </td>
                                        <td>  </td>
                                    </tr>
                                    <tr>
                                        <th> 03 </th>
                                        <td>1010</td>
                                        <td>Gurgaon Phase2</td>
                                        <td> Gurgaon Phase1 </td>
                                        <td> DLF (1) </td>
                                        <td> 4 </td>
                                        <td> Reason </td>
                                        <td> 11.10 am </td>
                                        <td> 11.30 am </td>
                                        <td> yes </td>
                                        <td> 500 </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default InfoNotDelivered;