
import axios from 'axios';
import { url } from "./Url"


const serverUrl = `${url}/deliveryBoy`


export const getAllDeliveryBoys = async() => {
    try {
        const res = await axios.get(`${serverUrl}/deliveryBoyLocations`);
        return res
    } catch (error) {
        console.error(error)
        throw(error)
    }
}

export const getMonthlyProductivityById=async(id)=>{
    try {
        const res = await axios.get(`${serverUrl}/getMonthlyProductivityById/${id}`);
        return res
    } catch (error) {
        console.error(error)
        throw(error)
    }
}

export const getSpecificDeliveryBoy=async(id)=>{
    try {
        return await axios.get(`${serverUrl}/findByDeliveryBoyId/${id}`);
    } catch (error) {
        console.error(error)
        throw(error)
    }
}
export const updateDeliveryBoyPincode=async(id,obj)=>{
    try {
        return await axios.patch(`${serverUrl}/addDeliveryPincodes/${id}`,obj);
    } catch (error) {
        console.error(error)
        throw(error)
    }
}

export const updateDeliveryBoySalaryById=async(id,obj)=>{
    try {
        return await axios.patch(`${serverUrl}/updateDeliveryBoySalaryById/${id}`,obj);
    } catch (error) {
        console.error(error)
        throw(error)
    }
}