import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { url } from '../../Services/Url'
import { ToastContainer, toast } from 'react-toastify';
import MaterialTable from 'material-table';


function AddShop() {
    // vehicle

    const [toggle, setToggle] = useState();


    const [name, setName] = useState("");

    const [shopTypeArr, setShopTypeArr] = useState();

    const handleSubmit = async () => {
        try {
            if (name != "") {
                let obj = {
                    name: name,
                }
                let { data: res } = await axios.post(`${url}/shopType/`, obj)
                if (res.success) {
                    toast.success(res.message)
                    getShopType()
                    // alert(res.message)
                }
            }
            else {
                alert("Name cannot be empty")
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }

    }



    const getShopType = async () => {
        try {
            let { data: res } = await axios.get(`${url}/shopType/`)
            console.log(res.data)
            if (res.success) {
                setShopTypeArr(res.data);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }

    useEffect(() => {
        getShopType()
    }, [])
    return (
        <main className="main_content">
            {
                toggle ?
                    <>
                        <div className="headingTitle" style={{ marginBottom: 20 }}><h1> Shop Types </h1> <button onClick={() => setToggle(false)} className="btn addModifiBttn">Add Shop Types</button></div>
                        <div className="card mt-3">
                            {
                                shopTypeArr && shopTypeArr.length > 0 &&
                                <MaterialTable
                                    columns={[
                                        {
                                            title: 'Shop Type Name', field: "name",
                                            cellStyle: {
                                                minWidth: 150,
                                                textAlign: "left"
                                            },
                                        },
                                    ]}
                                    data={shopTypeArr}
                                    title=""

                                    // actions={[
                                    //     {
                                    //         icon: 'delete',
                                    //         tooltip: 'Delete Entry',
                                    //         onClick: (event, rowData) => {
                                    //             // Do save operation
                                    //         }
                                    //     }
                                    // ]}
                                    options={{
                                        headerStyle: {
                                            backgroundColor: 'rgba(0,0,0,0.7)',
                                            color: '#FFF',
                                            fontSize: 12,
                                            fontWeight: "bold",
                                        },
                                        actionsColumnIndex: -1
                                    }}

                                />

                            }
                        </div>
                    </>
                    :
                    <>

                        <div className="headingTitle" style={{ marginBottom: 20 }}><h1>Add Shop Type </h1> <button  className="btn addModifiBttn"><a style={{color:'white'}} href="/category/shop-type">View Shop Types</a></button></div>
                        <div className="card">
                            <div className="card-body">
                                <div className="AddModiForm w-100">
                                    <form>
                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <div className="field-group">
                                                    <label> Add Shop Type </label>
                                                    <input type="text" onChange={(e) => setName(e.target.value)} className="form-control" placeholder="Enter Shop Type" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-4 d-flex justify-content-start">
                                            <div className="col-12 col-md-3">
                                                <div className="field-group">
                                                    <button type="button" onClick={() => handleSubmit()} className="btn searchBttn"> Save </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </>
            }
        </main>
    )
}

export default AddShop;
