import React from 'react';


function DeliveriesDetails() {
  return (
    <main className="main_content">
      <div className="myInfo_boxes mb-4">
        <div className="row">
          <div className="col-xl-3 col-lg-3 col-sm-6 col-12">
            <a href="javascript:;">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Deliveries  </h5>
                </div>
                <div className="card-body">
                  <div className="boysCount">
                    <p><i className="fa fa-male"></i> 500 </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-3 col-lg-3 col-sm-6 col-12">
            <a href="/package/total-sold">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title"> Completed </h5>
                </div>
                <div className="card-body">
                  <div className="boysCount">
                    <p><i className="fa fa-male"></i> 500 </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-3 col-lg-3 col-sm-6 col-12">
            <a href="/package/top-up">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Pending</h5>
                </div>
                <div className="card-body">
                  <div className="boysCount">
                    <p><i className="fa fa-first-order"></i> 150 </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="userTabsDesign mb-4">
        <div className="headingTitle"><h3> Order Deliveries Details </h3></div>
        <div className="card">

          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-responsive-sm">
                <thead>
                  <tr>
                    <th>Order No.</th>
                    <th>Happy CODE</th>
                    <th>Remarks</th>
                    <th>Delivery boy</th>
                    <th>Pickup Address</th>
                    <th>Delivery address</th>
                    <th>Area </th>
                    <th>KM ( range )</th>
                    <th>Order Status</th>
                    <th>Pickup Time</th>
                    <th>Delivered time</th>
                    <th>Cash Collection</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> 6/23/2020  </td>
                    <td>ABC traders</td>
                    <td>Delhi</td>
                    <td> Janak Puri  </td>
                    <td>Chemist</td>
                    <td>One Plan</td>
                    <td> 1299  </td>
                    <td>1299</td>
                    <td>bank</td>
                    <td> Mr Dhiraj  </td>
                    <td>99999999</td>
                    <td>active</td>
                    <td>40</td>
                  </tr>
                  <tr>
                    <td> 6/23/2020  </td>
                    <td>ABC traders</td>
                    <td>Delhi</td>
                    <td> Janak Puri  </td>
                    <td>Chemist</td>
                    <td>One Plan</td>
                    <td> 1299  </td>
                    <td>1299</td>
                    <td>bank</td>
                    <td> Mr Dhiraj  </td>
                    <td>99999999</td>
                    <td>active</td>
                    <td>40</td>
                  </tr>
                  <tr>
                    <td> 6/23/2020  </td>
                    <td>ABC traders</td>
                    <td>Delhi</td>
                    <td> Janak Puri  </td>
                    <td>Chemist</td>
                    <td>One Plan</td>
                    <td> 1299  </td>
                    <td>1299</td>
                    <td>bank</td>
                    <td> Mr Dhiraj  </td>
                    <td>99999999</td>
                    <td>active</td>
                    <td>40</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default DeliveriesDetails;