import axios from 'axios';
import { url } from "./Url"
import { decodeJwt } from './users';


const serverUrl = `${url}/AdminNotifications`


export const sendNotification=async(formData)=>{
    try {
        let tempObj=await decodeJwt()
        formData.userId=tempObj.userId;
        const res=await axios.post(`${serverUrl}/`,formData);
        return res
    } catch (error) {
        console.error(error)
        throw(error)
    }
}

export const getNotifications=async()=>{
    let tempObj=await decodeJwt()
    return await axios.get(`${serverUrl}/getAdminNotification/${tempObj.userId}`)
}
export const removeNotifications=async(id)=>{
    return await axios.delete(`${serverUrl}/deleteById/${id}`)
}