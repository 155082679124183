import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { url } from '../../Services/Url'
import { ToastContainer, toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
function VehicleTypes() {
    const sortIcon = <ArrowDownward />;
    const [VehiclesArr, setVehiclesArr] = useState();
    const [vehicleNamesArr, setVehicleNamesArr] = useState();
    const [vehichletypeSelected, setVehichletypeSelected] = useState("");


    const [vehicleIdToBeUpdated, setVehicleIdToBeUpdated] = useState();
    const [vehicleName, setVehicleName] = useState();



    const filterVehicleType = () => {
        if (vehichletypeSelected == "") {
            getVehicles()
        }
        else {
            let filteredVehicleArr = vehicleNamesArr.filter(el => el._id == vehichletypeSelected);
            setVehiclesArr(filteredVehicleArr)
        }
    }





    const getVehicles = async () => {
        try {
            let { data: res } = await axios.get(`${url}/vehicle/`)
            if (res.success) {
                setVehiclesArr(res.data);
                setVehicleNamesArr(res.data)
                console.log(res.data)
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }

    const updateVehicleById = async () => {
        try {
            let obj = {
                name: vehicleName
            }
            let { data: res } = await axios.patch(`${url}/vehicle/updateVehicleById/${vehicleIdToBeUpdated}`, obj)
            if (res.success) {
                getVehicles()
                console.log(res.data)
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }

    const ShopTypeColumns = [
        {
            name: 'S.No',
            selector: (row, index) => <span> {index + 1} </span>,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => <span>{row.name}</span>,
            sortable: true,
        },
        {
            name: 'Carrying Capacity',
            selector: row => <span>{row.carryingcapacity}</span>,
            sortable: true,
        },
        {
            name: 'Documents required',
            selector: row => <span>{row.documentRequired ? "Yes" : "No"}</span>,
            sortable: true,
        },
      
        {
            name: 'Vehicle Count',
            selector: row => row?.vehicleCount,
            sortable: true,
        },
        {
            name: 'Actions',
            selector: rowData =><><a href={`/vehicle-type-details/${rowData._id}`} type="button" className="btn searchBttn width-auto"> Details </a> <button type="button" onClick={() => setVehicleIdToBeUpdated(rowData._id)} className="btn addModifiBttn ml-2" data-toggle="modal" data-target="#ModifyShopType"> Modify </button></>,
            sortable: true,
        }
    ];
    useEffect(() => {
        getVehicles()
    }, [])

    return (
        <main className="main_content">
            <div className="filterSearchBar mb-4">
                <form>
                    <div className="row">
                        {/* <div className="col-12 col-md-12">
                            <div className="searchBar">
                                <input type="text" className="form-control" placeholder="Search Shop Type " />
                                <i className="fa fa-search"></i>
                            </div>
                        </div> */}
                    </div>
                    <div className="row">

                        <div className="col-12 col-md-2">
                            <label>Type</label>
                            <select onChange={(e) => setVehichletypeSelected(e.target.value)} className="form-control">
                                <option value="">All ( default )</option>
                                {
                                    vehicleNamesArr && vehicleNamesArr.length > 0 && vehicleNamesArr.map(el => {
                                        return (
                                            <option key={el._id} value={el._id}>{el.name}</option>

                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-12 col-md-2">
                            <label>&nbsp;</label>
                            <button type="button" onClick={() => filterVehicleType()} className="btn searchBttn"> Search </button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="infoOrdertable mb-4">
                <div className="headingTitle">
                    {/* <h3> Vehicle Type </h3> */}
                </div>
                <div className="card">
                    <div className="row">
                        <div className="col-12 ">
                            {/* {
                                VehiclesArr && VehiclesArr.length > 0 &&
                                <MaterialTable
                                    style={{ boxShadow: "none" }}
                                    columns={[
                                        {
                                            title: 'Vehicle Type', field: "name",
                                            cellStyle: {
                                                minWidth: 150,
                                                textAlign: "left"
                                            },
                                        },
                                        {
                                            title: 'Vehicle Count', field: "vehicleCount",
                                            cellStyle: {
                                                minWidth: 150,
                                                textAlign: "left"
                                            },
                                        },
                                        {
                                            title: 'Action', field: "vehicleCount",
                                            cellStyle: {
                                                minWidth: 150,
                                                textAlign: "left"
                                            },


                                            render: rowData => <><a href={`/vehicle-type-details/${rowData._id}`} type="button" className="btn searchBttn width-auto"> Details </a> <button type="button" onClick={() => setVehicleIdToBeUpdated(rowData._id)} className="btn addModifiBttn ml-2" data-toggle="modal" data-target="#ModifyShopType"> Modify </button></>
                                        },

                                    ]}
                                    data={VehiclesArr}
                                    title=""
                                    options={{
                                        headerStyle: {
                                            color: '#000',
                                            fontSize: 12,
                                            fontWeight: "bold",
                                        },
                                        actionsColumnIndex: -1
                                    }}
                                />
                            } */}
                            <DataTable
                                title="Vehicle Types"
                                columns={ShopTypeColumns}
                                data={VehiclesArr}
                                sortIcon={sortIcon}
                                keyField='_id'
                                pagination
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* <a href="/shop-type-details" type="button" className="btn searchBttn width-auto"> Details </a> */}

            {/*  */}
            <div id="ModifyShopType" className="modal fade" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title text-center"> Modify Vehicle Type </h4>
                        </div>
                        <div className="modal-body">
                            <div className="AddModiForm">
                                <form>
                                    <div className="row">
                                        <div className="col-12 col-md-12">
                                            <div className="field-group">
                                                <label> Enter New Vehicle Type Name </label>
                                                <input onChange={(e) => setVehicleName(e.target.value)} type="text" className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-4 d-flex justify-content-center">
                                        <div className="col-12 col-md-3">
                                            <div className="field-group">
                                                <button type="button" onClick={() => updateVehicleById()} className="btn searchBttn"> Save </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </main>
    )
}

export default VehicleTypes;
