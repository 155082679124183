import React from 'react';


function BalanceSheet() {
    return (
        <main className="main_content">
            <div className="filterSearchBar mb-4">
                <form>
                    <div className="row mb-4">
                        <div className="col-12 col-md-3">
                            <label>Start Date</label>
                            <input type="date" className="form-control" />
                        </div>
                        <div className="col-12 col-md-3">
                            <label>End Date</label>
                            <input type="date" className="form-control" />
                        </div>
                        <div className="col-12 col-md-2">
                            <label>&nbsp;</label>
                            <button type="button" className="btn searchBttn"> Search </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-3">
                            <label>Boy Name</label>
                            <input type="text" className="form-control" placeholder="Enter Delivery Boy Name" />
                        </div>
                        <div className="col-12 col-md-2">
                            <label>&nbsp;</label>
                            <button type="button" className="btn searchBttn"> Search </button>
                        </div>
                    </div>
                </form>
            </div>
            <br />
            <div className="infoOrdertable mb-4">
                <div className="headingTitle"><h3> Total Boys Balance Sheet in a Day </h3></div>
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-responsive-sm">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Earnings</th>
                                        <th>Expenses</th>
                                        <th>Profit/Loss Result</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td> 10/11/2019  </td>
                                        <td>500</td>
                                        <td>350</td>
                                        <td> +150  </td>
                                    </tr>
                                    <tr>
                                        <td> 10/12/2019  </td>
                                        <td>500</td>
                                        <td>350</td>
                                        <td> +150  </td>
                                    </tr>
                                    <tr>
                                        <td> 11/12/2019  </td>
                                        <td>500</td>
                                        <td>350</td>
                                        <td> +150  </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="infoOrdertable mb-4">
                <div className="headingTitle"><h3> Total Boys Balance Sheet in a Week </h3></div>
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-responsive-sm">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Earnings</th>
                                        <th>Expenses</th>
                                        <th>Profit/Loss Result</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td> 10/11/2019  </td>
                                        <td>500</td>
                                        <td>350</td>
                                        <td> +150  </td>
                                    </tr>
                                    <tr>
                                        <td> 10/12/2019  </td>
                                        <td>500</td>
                                        <td>350</td>
                                        <td> +150  </td>
                                    </tr>
                                    <tr>
                                        <td> 11/12/2019  </td>
                                        <td>500</td>
                                        <td>350</td>
                                        <td> +150  </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className="userTabsDesign mb-4">
                <div className="headingTitle"><h3> Delivery Man Balance Sheet In a Day </h3></div>
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-responsive-sm">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Salary</th>
                                        <th>Incentive</th>
                                        <th>Total</th>
                                        <th>Average ( Day )</th>
                                        <th>Deliveries made</th>
                                        <th>Average Earnings</th>
                                        <th>Total earnings</th>
                                        <th>Profit / Loss</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td> viahul Singh  </td>
                                        <td>12000</td>
                                        <td>3500</td>
                                        <td> 15500 </td>
                                        <td>750</td>
                                        <td>One Plan</td>
                                        <td> 1299  </td>
                                        <td>1299</td>
                                        <td>Profit</td>
                                    </tr>
                                    <tr>
                                        <td> viahul Singh  </td>
                                        <td>12000</td>
                                        <td>3500</td>
                                        <td> 15500 </td>
                                        <td>750</td>
                                        <td>One Plan</td>
                                        <td> 1299  </td>
                                        <td>1299</td>
                                        <td>Profit</td>
                                    </tr>
                                    <tr>
                                        <td> viahul Singh  </td>
                                        <td>12000</td>
                                        <td>3500</td>
                                        <td> 15500 </td>
                                        <td>750</td>
                                        <td>One Plan</td>
                                        <td> 1299  </td>
                                        <td>1299</td>
                                        <td>Profit</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="userTabsDesign mb-4">
                <div className="headingTitle"><h3> Delivery Man Balance Sheet In a Week </h3></div>
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-responsive-sm">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Name</th>
                                        <th>Salary</th>
                                        <th>Incentive</th>
                                        <th>Total</th>
                                        <th>Average ( Day )</th>
                                        <th>Deliveries made</th>
                                        <th>Average Earnings</th>
                                        <th>Total earnings</th>
                                        <th>Profit / Loss</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>10/11/2019</td>
                                        <td> viahul Singh  </td>
                                        <td>12000</td>
                                        <td>3500</td>
                                        <td> 15500 </td>
                                        <td>750</td>
                                        <td>One Plan</td>
                                        <td> 1299  </td>
                                        <td>1299</td>
                                        <td>Profit</td>
                                    </tr>
                                    <tr>
                                        <td>10/11/2019</td>
                                        <td> viahul Singh  </td>
                                        <td>12000</td>
                                        <td>3500</td>
                                        <td> 15500 </td>
                                        <td>750</td>
                                        <td>One Plan</td>
                                        <td> 1299  </td>
                                        <td>1299</td>
                                        <td>Profit</td>
                                    </tr>
                                    <tr>
                                        <td>10/11/2019</td>
                                        <td> viahul Singh  </td>
                                        <td>12000</td>
                                        <td>3500</td>
                                        <td> 15500 </td>
                                        <td>750</td>
                                        <td>One Plan</td>
                                        <td> 1299  </td>
                                        <td>1299</td>
                                        <td>Profit</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className="userTabsDesign">
                <div className="headingTitle"><h3> Total Earnings Vs Expenses Missing  </h3></div>
                <div className="row">
                    <div className="col-md-6 col-12 mb-4">
                        <div className="card dashboardCardd">
                            <div className="card-header">
                                <h5 className="card-title"> Total Earnings </h5>
                                <p>₹ 120</p>
                            </div>
                            <h4>Total Earnings in a</h4>
                            <div className="card-body">
                                <div className="boysCase">
                                    <label>Day</label>
                                    <p> 23 </p>
                                </div>
                                <div className="boysCase">
                                    <label>Week</label>
                                    <p> 3 </p>
                                </div>
                                <div className="boysCase">
                                    <label>Month</label>
                                    <p> 1 </p>
                                </div>
                                <div className="boysCase">
                                    <label>Year</label>
                                    <p> 1 </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="card dashboardCardd">
                            <div className="card-header">
                                <h5 className="card-title"> Expenses Missing </h5>
                                <p>₹ 120</p>
                            </div>
                            <h4>Expenses Missing in a</h4>
                            <div className="card-body">
                                <div className="boysCase">
                                    <label>Day</label>
                                    <p> 23 </p>
                                </div>
                                <div className="boysCase">
                                    <label>Week</label>
                                    <p> 3 </p>
                                </div>
                                <div className="boysCase">
                                    <label>Month</label>
                                    <p> 1 </p>
                                </div>
                                <div className="boysCase">
                                    <label>Year</label>
                                    <p> 1 </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </main>
    )
}

export default BalanceSheet;