import React from 'react'

export default function privacy() {
    return (
        <div className="container mt-4">
            <h3 className="text-center mt-4"><b>Privacy Policy</b></h3>

            <h4><b>Privacy Policy &  and Personal Data Collection Policy</b></h4>.
            In order to deliver our service to the customers of our platform, it is essential for us to treasure, stockpile, use, process, part and revise the individual data of all users. This Privacy Policy and Personal Data Collection Policy (Privacy Policy) is specified here to permit the users of our platform to comprehend our parameters for the gathering and use of users’ personal data.
            Delivery Ladka app desires all users to prudently evaluate our Privacy Policy and make sure that you cognize it. We also inspire all users to reexamine our Privacy Policy frequently on our website as it may be revised and altered from time to time.
            BY USING OUR SERVICE, IT SHALL BE BELIEVED THAT YOU HAVE ACCEPTED AND AGREED TO OUR PRIVACY POLICY AND HAVE FULLY PERMITTED TO THE GATHERING, STORING, USE, TREATING, DISTRIBUTING AND REVISING OF YOUR PERSONAL DATA FOR THE PURPOSEFULNESS OF OFFERING OUR SERVICE.

            <h4><b> Whose private records do we amass?</b> </h4>

            We gather individual data only from those who use our application to get and take delivery service and those who offer delivery service through our platform.
            What type of individual data do we treasure?
            We gather data that may be necessary for us to benefit the users of our platform (both : user who places delivery request or the user who provides delivery service ) to attain or offer delivery service. The records largely include, but may not be limited to addresses, phone numbers, emails, physical locations, pictures, and any other info that may be valuable in the course of offering our services.

            <h5> <b>
                In what way do we gather individual data?
            </b>
            </h5>


            Individual data is gathered from our website and mobile application once users register for an account and specify added knowledge in the course of using our application for a delivery. We also provide  support thru whatsapp. Our employee / agent  may help users by phone or other ways and gather individual data in order to support smooth running of our services such as the collection of unfinished, omitted, or extraordinary information concerning a delivery. We may also use cookies to develop and improve the user know-how and the delivery of our service.
            We may also observe, record, collect and use any telephone, email or other communiqué with you in order to draft any directions given to us, for teaching purposes, for compliance purposes, and to progress the value of our client service.
            <h5>
                <b>
                Why do we gather individual data?
                </b>
            </h5>

            Individual data is gathered so that we may offer our service in the paramount promising way and also to monogram and advance your understanding with our service. Individual data that users provide to us will be distributed for the purposefulness of carrying out and finishing deliveries, gist is that individual data of users placing delivery desires will be distributed with users who are offering deliveries and vice versa.
            We will also use your individual data for: arithmetic analysis; to grow and expand our software and services; to revise your archives; to categorize which of our, or others’, products might concern you; and for marketplace research.
            <h5>
                <b>
                Do we unveil individual data to any third party?
                </b>
            </h5>

            We do not circulate, vend, or purposely unveil individual data to third parties barring under the trailing situations:
            To develop and continue the function of our platform and service, we may part data with our trustworthy cohorts who have contracted to keep the privacy of the individual data;
            To increase the user know-how on our platform and to provide advertisings to users by cooperating with reliable partners who have decided to keep the confidentiality of the individual data;
            To fulfill with laws and regulations, an official order, court order, or order by any government specialist which Delivery
            Ladka  believes to have a right to issue such order.

            <h5>
                <b>
                Amendment in our Privacy Policy?
                </b>
            </h5>
            Deliver Ladka  may revise its Privacy Policy from time to time as seen fitting or as mandatory by law. Any modifications to our Privacy Policy shall take instant effect and can be seen on our website.

            <h5><b>Your Picks, Your Privileges</b></h5>
            Users have the right to approach, correct and erase individual information gathered. To use your rights, you can communicate with us via email
            What can you do if you have any questions on the Privacy Policy?
            We urge users to interact with us via email per any queries concerning our Privacy Policy.
            @2021 Delivery Ladka . All rights reserved.


        </div>
    )
}
