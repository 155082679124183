import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { url } from '../../Services/Url'
import { ToastContainer, toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
function ShopType() {
    const sortIcon = <ArrowDownward />;

    const [shopTypeArr, setShopTypeArr] = useState();
    const [shoptypeFilteredArr, setShoptypeFilteredArr] = useState();
    const [selectedShopType, setSelectedShopType] = useState();



    const [shopTypeName, setShopTypeName] = useState();
    const [selectedShopTypeForupdate, setSelectedShopTypeForupdate] = useState();
    const getShopType = async () => {
        try {
            let { data: res } = await axios.get(`${url}/shopType/`)
            if (res.success) {
                setShopTypeArr(res.data);
                setShoptypeFilteredArr(res.data);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }


    const filterShopTypes = () => {
        if (selectedShopType == "") {
            getShopType()
        }
        else {
            let tempShopTypesArr = shopTypeArr.filter(el => el._id == selectedShopType)
            setShoptypeFilteredArr(tempShopTypesArr);
        }
    }
    const handleShopTypeFilter=(val)=>{
        setShoptypeFilteredArr([...shopTypeArr.filter(el=>el.name.toLowerCase().includes(val.toLowerCase()))])
    }



    const updateShopType = async () => {
        try {
            let obj = {
                name: shopTypeName
            }
            let { data: res } = await axios.patch(`${url}/shopType/updateById/${selectedShopTypeForupdate._id}`, obj)
            if (res.success) {
                alert(res.message)
                getShopType()
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }


    const ShopTypeColumns = [
        {
            name: 'S.No',
            selector: (row, index) => <span> {index + 1} </span>,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => <span>{row.name}</span>,
            sortable: true,
        },

        {
            name: 'Shop Count',
            selector: row => row?.userCount,
            sortable: true,
        },
        {
            name: 'Actions',
            selector: rowData => <>
                <button type="button" onClick={() => setSelectedShopTypeForupdate(rowData)} className="btn addModifiBttn ml-2" data-toggle="modal" data-target="#ModifyShopType"> Modify </button>
                {/* <button type="button" onClick={() => setSelectedShopTypeForupdate(rowData)} className="btn addModifiBttn ml-2" data-toggle="modal" data-target="#ModifyShopType"> Modify </button> */}
            </>,
            sortable: true,
        }
    ];


    useEffect(() => {
        getShopType()
    }, [])
    return (
        <main className="main_content">
            <div className="filterSearchBar mb-4">
                <form>

                    <div className="row">
                        <div className="col-12 col-md-2">
                            <label>Search Shop Types</label>
                            <input className="form-control" placeholder="Search By Type" onChange={(val)=>handleShopTypeFilter(val.target.value)} />
                        </div>

                    </div>
                </form>
            </div>
            <div className="infoOrdertable mb-4">
                <div className="headingTitle">
                    {/* <h3> Shop Type </h3> */}
                </div>
                <div className="card">
                    <DataTable
                        title="Shop Types"
                        columns={ShopTypeColumns}
                        data={shoptypeFilteredArr}
                        sortIcon={sortIcon}
                        keyField='_id'
                        pagination
                    />
                </div>
            </div>

            <div id="ModifyShopType" className="modal fade" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title text-center"> Modify Shop Type </h4>
                        </div>
                        <div className="modal-body">
                            <div className="AddModiForm">
                                <form>
                                    <div className="row">
                                        <div className="col-12 col-md-12">
                                            <div className="field-group">
                                                <label> Update Shop Type Name </label>
                                                <input onChange={(e) => setShopTypeName(e.target.value)} type="text" className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-4 d-flex justify-content-center">
                                        <div className="col-12 col-md-3">
                                            <div className="field-group">
                                                <button onClick={() => updateShopType()} type="button" className="btn searchBttn"> Save </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </main>
    )
}

export default ShopType;
