import { useHistory, useParams } from 'react-router';
import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { url } from '../../Services/Url'
import { ToastContainer, toast } from 'react-toastify';
import MaterialTable from 'material-table';


function VehicleTypeDetails() {
    let { id } = useParams();
    let history = useHistory()


    const [VehiclesObj, setVehiclesObj] = useState();
    const [vehicleNamesArr, setVehicleNamesArr] = useState();
    const [vehichletypeSelected, setVehichletypeSelected] = useState("");


    const [vehicleIdToBeUpdated, setVehicleIdToBeUpdated] = useState();
    const [vehicleName, setVehicleName] = useState();








    const getVehicles = async () => {
        try {
            let { data: res } = await axios.get(`${url}/vehicle/getDeliveryBoysByVehicleId/${id}`)
            // console.log(res.data)
            if (res.success) {
                setVehiclesObj(res.data.deliveryBoysArr);
                setVehicleNamesArr(res.data)
                console.log(res.data)
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }


    useEffect(() => {
        getVehicles()
    }, [])

    return (
        <main className="main_content">
            <div className="infoOrdertable mb-4">
                <div className="headingTitle"><h3> Delivery Boys </h3></div>
                <div className="card">
                    {
                        VehiclesObj && VehiclesObj.length > 0 &&
                        <MaterialTable
                            style={{ boxShadow: "none" }}
                            columns={[
                                {
                                    title: 'Delivery Boy Name', field: "name",
                                    cellStyle: {
                                        minWidth: 150,
                                        textAlign: "left"
                                    },
                                },
                                {
                                    title: 'State', field: "deliveryBoyState",
                                    cellStyle: {
                                        minWidth: 150,
                                        textAlign: "left"
                                    },
                                },
                                {
                                    title: 'City', field: "deliveryBoyCity",
                                    cellStyle: {
                                        minWidth: 150,
                                        textAlign: "left"
                                    },
                                },
                                {
                                    title: 'Area', field: "deliveryBoyArea",
                                    cellStyle: {
                                        minWidth: 150,
                                        textAlign: "left"
                                    },
                                },
                                {
                                    title: 'PinCode', field: "pinCode",
                                    cellStyle: {
                                        minWidth: 150,
                                        textAlign: "left"
                                    },
                                },
                            ]}
                            data={VehiclesObj}
                            title=""

                            // actions={[
                            //     {
                            //         icon: 'delete',
                            //         tooltip: 'Delete Entry',
                            //         onClick: (event, rowData) => {
                            //             // Do save operation
                            //         }
                            //     }
                            // ]}
                            options={{
                                headerStyle: {
                                    // backgroundColor: 'rgba(0,0,0,0.7)',
                                    color: '#000',
                                    fontSize: 12,
                                    fontWeight: "bold",
                                },
                                actionsColumnIndex: -1
                            }}

                        />

                    }
                </div>
            </div>
        </main>
    )
}

export default VehicleTypeDetails;
