import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useHistory,
    useLocation,
} from "react-router-dom";
function Topbar(props) {
    // console.log(props)
    const history = useHistory()
    const heading = useLocation()
    console.log(heading)
    return (
        <>
            {heading.pathname != "/" &&
                <button style={{ position: 'absolute', left: 300, top: 30, zIndex: 1000 }} onClick={() => history.goBack()} className="btn btn-primary">Go Back</button>
            }
            <h2 style={{ position: 'absolute', right: 50, top: 0, zIndex: 1000 }}>{heading?.pathname.split('/').length>3 ?heading?.pathname.split('/')[2] : heading?.pathname.split('/')[1] }</h2>
        </>

    );
}

export default Topbar;