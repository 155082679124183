import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { url } from "../../Services/Url";
import MaterialTable from 'material-table'
import { div } from "prelude-ls";
import { getDeliveryBoyAttendance } from "../../Services/Attendance";
import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { filterArrayByDateRange } from '../../utils/util';
import { Pie, Bar } from 'react-chartjs-2';

export function AttendanceDetails() {
    const sortIcon = <ArrowDownward />;

    ///////////array
    const [attendanceArr, setAttendanceArr] = useState([]);
    const [filteredAttendanceArr, setFilteredAttendanceArr] = useState();

   
    
    
    

    /////date
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    const [barGraphData, setBarGraphData] = useState({});


    let { id } = useParams()
    const getAttendance = async () => {
        try {

            let res = await getDeliveryBoyAttendance(id)
            if (res.status == 200 || res.status == 304) {
                setAttendanceArr(res.data.data)
                setFilteredAttendanceArr(res.data.data)
                handleBarGraph(res.data.data)

            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.log(err?.response?.data?.message)
                alert(err?.response?.data?.message)
            }
            else {
                console.log(err)
                alert(err)
            }
        }
    }
    const barGraphConfig = {
        type: 'bar',
        data: barGraphData,
        options: {
            scales: {
                y: {
                    beginAtZero: true
                }
            }
        },
    }
    const FilterAttendanceByDate = () => {
        let filteredArr = attendanceArr.filter(el => filterArrayByDateRange(startDate, endDate, el.dateString))
        setFilteredAttendanceArr(filteredArr)
    }

    const handleBarGraph = (arr) => {
        const labels = arr?.map(el => el.dateString);
        const barGraphData = {
            labels: labels,
            datasets: [
                {
                    label: 'Hours Worked',
                    data: arr?.map(el => el.hours),
                    backgroundColor: [
                        'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                        'rgb(255, 159, 64)',
                    ],
                    borderWidth: 1
                }
            ]

        };
        setBarGraphData({ ...barGraphData })
    }


    const AttendanceColumns = [
        {
            name: 'Date',
            selector: row => row.dateString,
            sortable: true,
        },
        {
            name: 'Hours',
            selector: row => row.hours,
            cell: row => `${row.hours.toFixed()} hrs`,
            sortable: true,
        },
    ];

    useEffect(() => {
        getAttendance();
    }, [])

    return (
        <>
            <main className="main_content">
                <div className="filterSearchBar" style={{ marginTop: 20, marginBottom: 20 }}>
                    <form>
                        <div className="row">

                            <div className="col-12 col-md-2" style={{ marginTop: 15 }}>
                                <label>Start Date</label>
                                <input type="date" className="form-control" onChange={(e) => setStartDate(e.target.value)} />
                            </div>
                            <div className="col-12 col-md-2" style={{ marginTop: 15 }}>
                                <label>End Date</label>
                                <input type="date" className="form-control" onChange={(e) => setEndDate(e.target.value)} />
                            </div>
                            <div className="col-12 col-md-2" style={{ marginTop: 15 }}>
                                <label>&nbsp;</label>
                                <button type="button" className="btn searchBttn" onClick={() => FilterAttendanceByDate()}> Search </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="infoOrdertable mb-4">
                    <div className="headingTitle addModifyHeading">
                        <div className="addModifyInner">
                            <a href="/attendence" type="button" className="btn addModifiBttn width-auto mr-2"> Go Back </a>
                        </div>
                    </div>
                    <div className="card">
                        <div className="row">
                            <div className="col-lg-10">
                                <Bar data={barGraphData} options={barGraphConfig} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 ">
                                <DataTable
                                    title="Attendance"
                                    columns={AttendanceColumns}
                                    data={filteredAttendanceArr}
                                    sortIcon={sortIcon}
                                    keyField='_id'
                                    pagination
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </main>
        </>
    );
}