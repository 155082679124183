import axios from 'axios';
import { url } from "./Url"


const serverUrl = `${url}/packages`





export const getAllPackages = async (formData) => {
    try {
        const res = await axios.get(`${serverUrl}/`, formData);
        return res
    } catch (error) {
        console.error(error)
        throw (error)
    }
}



export const updatePackageById = async (formData) => {
    try {
        const res = await axios.patch(`${serverUrl}/updateById`, formData);
        return res
    } catch (error) {
        console.error(error)
        throw (error)
    }
}

export const deletePackageById = async (id) => {
    try {
        const res = await axios.delete(`${serverUrl}/deleteById/${id}`);
        return res
    } catch (error) {
        console.error(error)
        throw (error)
    }
}




