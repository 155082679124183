import axios from 'axios';
import { url } from "./Url"


const serverUrl = `${url}/deliveryBoyAttendence`


export const getDeliveryBoyAttendance = async (id) => {
    try {
        const res = await axios.get(`${serverUrl}/getDeliveryBoyRecord/${id}`,);
        return res
    } catch (error) {
        console.error(error)
        throw (error)
    }
}