import axios from 'axios';
import { url } from "./Url"


const serverUrl = `${url}/expense`


export const createExpense = async (formData) => {
    try {
        const res = await axios.post(`${serverUrl}/`, formData);
        return res
    } catch (error) {
        console.error(error)
        throw (error)
    }
}


export const getAllExpenses = async (formData) => {
    try {
        const res = await axios.get(`${serverUrl}/`, formData);
        return res
    } catch (error) {
        console.error(error)
        throw (error)
    }
}

export const UpdateExpenseById = async (id, formData) => {
    try {
        const res = await axios.patch(`${serverUrl}/updateExpense/${id}`, formData);
        return res
    } catch (error) {
        console.error(error)
        throw (error)
    }
}
export const DeleteExpenseById = async (id) => {
    try {
        const res = await axios.delete(`${serverUrl}/deleteExpense/${id}`);
        return res
    } catch (error) {
        console.error(error)
        throw (error)
    }
}