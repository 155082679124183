export const monthArr = ["January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"]

export const getMonthByIndex = (monthIndex) => {
    return monthArr[monthIndex]
}

export const generateYearArr = () => {
    const currentYear = new Date().getFullYear();
    const yearArr = [];
    for (let i = currentYear; i <= currentYear + 10; i++) {
        yearArr.push(i)
    }
    return yearArr
}




