import axios from 'axios';
import { url } from "./Url"


const serverUrl = `${url}/city`


export const uploadCityData=async(formData)=>{
    try {
        const res=await axios.post(`${serverUrl}/cityCheck`,formData);
        return res
    } catch (error) {
        console.error(error)
        throw(error)
    }
}