import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { url } from '../../Services/Url'
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import MaterialTable from 'material-table';

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { uploadStateData } from '../../Services/state';
import { uploadCityData } from '../../Services/city';
import Select from 'react-select';

import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { acceptDeliveryBoyApplications, getAllApplications, uploadImageDeliveryBoyApplications } from '../../Services/DeliveryBoyApplications';
import { Dialog } from '@material-ui/core';
import { getAreaByPincode } from '../../Services/area';
const MySwal = withReactContent(Swal)

export default function DeliveryBoyRequest() {
    const [applicationsArr, setApplicationsArr] = useState([]);

    const [selectedApplication, setSelectedApplication] = useState({});

    const [vehicleArr, setVehicleArr] = useState([]);
    const [modalActive, setModalActive] = useState(false);

    const [rcNumber, setRcNumber] = useState('');
    const [chassisNumber, setChassisNumber] = useState('');
    const [aadhaarNumber, setAadhaarNumber] = useState('');
    const [licenseNumber, setLicenseNumber] = useState('');
    const [aadhaarImage, setAadhaarImage] = useState();
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [licenseImage, setLicenseImage] = useState('');
    const [rcImage, setRcImage] = useState('');
    const [selectedVehicleObj, setSelectedVehicleObj] = useState({});

    const [selectedAreaObj, setSelectedAreaObj] = useState([]);
    const sortIcon = <ArrowDownward />;
    const [areaArr, setAreaArr] = useState([]);

    const [usableSelectedVehicleObj, setUsableSelectedVehicleObj] = useState({});
    const [mainVehicleArr, setMainVehicleArr] = useState([]);

    const [permanentAdress, setPermanentAdress] = useState('');
    const [permanentAdressPincode, setPermanentAdressPincode] = useState('');

    const [currentAddress, setCurrentAddress] = useState('');
    const [currentAddressPincode, setCurrentAddressPincode] = useState('');
    const getApplications = async () => {
        try {
            let { data: res } = await getAllApplications()
            if (res.success) {

                setApplicationsArr(res.data);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }

    const getVehicles = async () => {
        try {
            let { data: res } = await axios.get(`${url}/vehicle/`)
            if (res.success) {
                setMainVehicleArr(res.data)
                setVehicleArr([...res.data.map(el => {
                    let obj = {
                        value: el._id,
                        label: el.name
                    }
                    return obj
                })])
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }

    const rejectApplication = async () => {
        try {

        } catch (error) {
            console.error(error)
        }
    }


    const getArea = async (pincode) => {
        try {
            const { data: res, status: statusCode } = await getAreaByPincode(pincode);
            if (statusCode == 200 || statusCode == 304) {
                setAreaArr([...res.data.map(el => {
                    let obj = {
                        value: el._id,
                        label: el.name,
                        stateId: el.stateId,
                        cityId: el.cityId
                    }
                    return obj
                })])
            }
        } catch (error) {
            console.error(error)
        }
    }


    const columns = [
        {
            name: 'S.no',
            selector: row => row.sno,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => `${row.name}`,
            sortable: true,
        },
        {
            name: 'Mobile',
            selector: row => `${row.mobile}`,
            sortable: true,
        },
        {
            name: 'Pincode',
            selector: row => row.pincode,
            sortable: true,
        },

        {
            name: 'Recieved At',
            selector: row => row.createdAt ? new Date(row.createdAt).toDateString() : new Date().toDateString(),
            sortable: true,
        },

        {
            name: 'Actions',
            cell: row =>
                row.status ? <span>Accepted</span> :
                    <div>
                        <button onClick={() => {
                            setSelectedApplication(row);
                            setModalActive(true)
                            getArea(row.pincode)
                        }} className="btn addModifiBttn mr-2">Accept</button>
                        <button onClick={() => rejectApplication()} className="btn greenBttn mr-2">Reject</button>

                    </div>

        },


    ];


    const handleSubmit = async () => {
        try {
            if (aadhaarNumber != "" && aadhaarImage) {

                let obj = {
                    name: selectedApplication.name,
                    mobile: selectedApplication.mobile,
                    areaId: selectedAreaObj.value,
                    stateId: selectedAreaObj.stateId,
                    cityId: selectedAreaObj.cityId,
                    pinCode: selectedApplication.pincode,
                    vehicleId: selectedVehicleObj.value,
                    rcNumber: rcNumber,
                    chasisNumber: chassisNumber,
                    licenseNumber: licenseNumber,
                    aadhaarNumber: aadhaarNumber,
                    dateOfJoining: new Date(),
                    status: 1,
                    email,
                    password,
                    applicationId: selectedApplication._id,
                    permanentAddressObj: {
                        address: permanentAdress,
                        pinCode: permanentAdressPincode
                    },
                    currentAddressObj: {
                        address: permanentAdress,
                        pinCode: permanentAdressPincode
                    },
                }
                const { data: res, status: statusCode } = await acceptDeliveryBoyApplications(obj);
                console.log(aadhaarImage, licenseImage, rcImage)
                if (statusCode == 200 || statusCode == 304) {
                    let formData = new FormData();
                    if(usableSelectedVehicleObj?.documentRequired){
                        formData.append('file', aadhaarImage)
                        formData.append('file1', licenseImage)
                        formData.append('file2', rcImage)

                    }
                    else{
                        formData.append('file', aadhaarImage)
                        formData.append('file1', aadhaarImage)
                        formData.append('file2', aadhaarImage)
                    }
                    const { data: response, status: status } = await uploadImageDeliveryBoyApplications(formData, res.data._id);
                    if (status == 200 || status == 304) {
                        alert(response.message)
                    }

                }
            }
            else {
                alert("Please fill all the fields")
            }
        } catch (error) {
            console.error(error)
            alert(error.message)
        }
    }

    const handleChange = (val) => {
        console.log(val)
        setSelectedVehicleObj(val)
        console.log(vehicleArr)
        // console.log(vehicleArr.find(el => el._id == val.value))
        setUsableSelectedVehicleObj(mainVehicleArr.find(el => el._id == val.value))
    }
    const handleChangeArea = (val) => {
        setSelectedAreaObj(val)
    }





    useEffect(() => {
        getApplications()
        getVehicles()
    }, [])
    return (
        <main className="main_content">
            <div className="infoOrdertable mb-4">
                <div className="card">
                    <DataTable
                        title="Delivery Boy Applications"
                        columns={columns}
                        data={applicationsArr?.map((el, index) => {
                            let obj = {
                                sno: index + 1,
                                ...el
                            }
                            return obj
                        })}
                        sortIcon={sortIcon}
                        keyField='_id'
                        pagination
                    />
                </div>
            </div>
            <Dialog open={modalActive} onClose={() => setModalActive(false)} fullWidth={true} maxWidth="md">
                <div style={{ padding: 50 }} className='row'>
                    <div className="col-10">
                        <h2 className="text-center">Accept Application</h2>
                        <div className="mb-3">
                            <label htmlFor="formFile" className="form-label">Enter Email</label>
                            <input type="text" className="form-control" onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="formFile" className="form-label">Enter Password</label>
                            <input type="text" className="form-control" maxLength={4} onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <div className="col-12 col-md-6 mt-4">
                            <div className="field-group">
                                <label>Permanent Address</label>
                                <input type="text" onChange={(e) => setPermanentAdress(e.target.value)} className="form-control" placeholder="Enter Your Address" />
                            </div>
                        </div>

                        <div className="col-12 col-md-6 mt-4">
                            <div className="field-group">
                                <label>Permanent Address Pincode</label>
                                <input type="text" onChange={(e) => setPermanentAdressPincode(e.target.value)} className="form-control" placeholder="Enter Your Address" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6 mt-4">
                            <div className="field-group">
                                <label>Current Address</label>
                                <input type="text" onChange={(e) => setCurrentAddress(e.target.value)} className="form-control" placeholder="Enter Your Address" />
                            </div>
                        </div>

                        <div className="col-12 col-md-6 mt-4">
                            <div className="field-group">
                                <label>Current Address Pincode</label>
                                <input type="text" onChange={(e) => setCurrentAddressPincode(e.target.value)} className="form-control" placeholder="Enter Your Address" />
                            </div>
                        </div>
                        <div className="mt-4">
                            <label htmlFor="formFile" className="form-label">Vehicle</label>
                            <Select
                                value={selectedVehicleObj}
                                onChange={handleChange}
                                options={vehicleArr}
                            />
                        </div>
                        {usableSelectedVehicleObj?.documentRequired &&
                            <>

                                <div className="mb-3">
                                    <label htmlFor="formFile" className="form-label">Rc Number</label>
                                    <input type="text" className="form-control" onChange={(e) => setRcNumber(e.target.value)} />
                                </div>
                                <div className="mt-4">
                                    <label htmlFor="formFile" className="form-label">License Number</label>
                                    <input type="text" className="form-control" onChange={(e) => setLicenseNumber(e.target.value)} />
                                </div>
                                <div className="mt-4">
                                    <label htmlFor="formFile" className="form-label">Chassis Number</label>
                                    <input type="text" className="form-control" onChange={(e) => setChassisNumber(e.target.value)} />
                                </div>
                                <div className="mt-4">
                                    <label htmlFor="formFile" className="form-label">Upload Rc Image</label>
                                    <input type="file" className="form-control" onChange={(e) => e.target.files && e.target.files[0] ? setRcImage(e.target.files[0]) : 0} />
                                </div>
                                <div className="mt-4">
                                    <label htmlFor="formFile" className="form-label">Upload License Image</label>
                                    <input type="file" className="form-control" onChange={(e) => e.target.files && e.target.files[0] ? setLicenseImage(e.target.files[0]) : 0} />
                                </div>
                            </>



                        }
                        <div className="mt-4">
                            <label htmlFor="formFile" className="form-label">Aadhaar Number</label>
                            <input type="text" className="form-control" onChange={(e) => setAadhaarNumber(e.target.value)} />
                        </div>
                        <div className="mt-4">
                            <label htmlFor="formFile" className="form-label">Upload Aadhaar Image</label>
                            <input type="file" className="form-control" onChange={(e) => e.target.files && e.target.files[0] ? setAadhaarImage(e.target.files[0]) : 0} />
                        </div>



                        <div className="mt-4">
                            <button className="btn btn-primary btn-block mt-3" onClick={() => handleSubmit()}>
                                Submit
                            </button>
                        </div>
                    </div>
                </div>

            </Dialog>
        </main>
    )
}
