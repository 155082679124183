import axios from "axios";
import React, { useState, useEffect } from "react";
import { Pie, Bar } from "react-chartjs-2";

import { url } from "../../Services/Url";
import { generateYearArr, monthArr } from "../../utils/monthUtils";
import Select from "react-select";

function MyInfo() {
  const [pieChartObj, setPieChartObj] = useState({});
  const [earningGraphObj, setEarningGraphObj] = useState({});
  const [deliveryBoyStatus, setDeliveryBoyStatus] = useState({});
  const [orderStatusObj, setOrderStatusObj] = useState({});
  const [packageOrderObj, setPackageOrderObj] = useState({});

  const [yearArr, setYearArr] = useState([]);

  const getUserPieChartData = async () => {
    try {
      const { data: res, status: statusCode } = await axios.get(`${url}/totalUserPieChart`);
      console.log(res);
      if (statusCode == 200 || statusCode == 304) {
        setPieChartData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getDeliveryBoyStatus = async () => {
    try {
      const { data: res, status: statusCode } = await axios.get(`${url}/getDeliveryBoyStatus`);
      if (statusCode == 200 || statusCode == 304) {
        setDeliveryBoyStatus(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getOrderStatus = async () => {
    try {
      const { data: res, status: statusCode } = await axios.get(`${url}/getOrderStatus`);
      if (statusCode == 200 || statusCode == 304) {
        setOrderStatusObj(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getPackageOrders = async () => {
    try {
      const { data: res, status: statusCode } = await axios.get(`${url}/getPackageSoldDashboard`);
      if (statusCode == 200 || statusCode == 304) {
        setPackageOrderObj(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getEarningsVsExpense = async (year) => {
    try {
      const { data: res, status: statusCode } = await axios.get(`${url}/expenseVsEarning/${year}`);
      if (statusCode == 200 || statusCode == 304) {
        handleBarGraph(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const dashboardEmailStatisticsChart = {
    data: (canvas) => {
      return {
        labels: [1, 2, 3],
        datasets: [
          {
            label: "task",
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: ["#ef8157", "#4acccd", "#fcc468"],
            borderWidth: 0,
            data: [342, 480, 530],
          },
        ],
      };
    },
    options: {
      plugins: {
        legend: { display: false },
        tooltip: { enabled: true },
      },
      maintainAspectRatio: false,
      pieceLabel: {
        render: "percentage",
        fontColor: ["white"],
        precision: 2,
      },
      scales: {
        y: {
          ticks: {
            display: false,
          },
          grid: {
            drawBorder: false,
            display: false,
          },
        },
        x: {
          barPercentage: 1.6,
          grid: {
            drawBorder: false,
            display: false,
          },
          ticks: {
            display: false,
          },
        },
      },
    },
  };
  const setPieChartData = (obj) => {
    let tempObj = {
      labels: ["Business Type user", "normal users"],
      datasets: [
        {
          label: "Users",
          pointRadius: 0,
          pointHoverRadius: 0,
          backgroundColor: ["#4acccd", "#fcc468"],
          borderWidth: 0,
          data: [obj.businessTypeUser, obj.selfUsers],
        },
      ],
    };
    setPieChartObj({ ...tempObj });
  };
  const barGraphConfig = {
    type: "bar",
    data: earningGraphObj,
    options: {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    },
  };

  const handleBarGraph = (arr) => {
    const labels = monthArr;
    const barGraphData = {
      labels: labels,
      datasets: [
        {
          label: "Earning",
          data: arr?.map((el) => el?.earning),
          backgroundColor: ["rgba(255, 159, 64, 0.2)"],
          borderColor: ["rgb(255, 159, 64)"],
          borderWidth: 1,
        },
        {
          label: "Expense",
          data: arr?.map((el) => el?.expense),
          backgroundColor: ["rgba(255, 99, 132, 0.2)"],
          borderColor: ["rgb(255, 99, 132)"],
          borderWidth: 1,
        },
      ],
    };
    setEarningGraphObj({ ...barGraphData });
  };

  const getYearArr = () => {
    let tempArr = generateYearArr();
    let finalArr = tempArr?.map((el) => {
      let obj = {
        label: el,
        value: el,
      };
      return obj;
    });
    setYearArr(finalArr);
  };

  const handleYearChange = (selectedOption) => {
    getEarningsVsExpense(selectedOption.value);
    // console.log(selectedOption)
  };

  useEffect(() => {
    getUserPieChartData();
    getDeliveryBoyStatus();
    getOrderStatus();
    getPackageOrders();
    getEarningsVsExpense(new Date().getFullYear());
    getYearArr();
    return () => setPieChartObj({});
  }, []);

  return (
    <main className="main_content">
      <div className="myInfo_boxes">
        <div className="row">
          <div className="col-xl-3 col-lg-3 col-sm-6 col-12">
            <a href="/delivery-boy-online">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">DELIVERY BOYS </h5>
                </div>
                <div className="card-body">
                  <div className="boysCount">
                    <p>
                      <i className="fa fa-male"></i> {deliveryBoyStatus?.onlineDeliveryBoys}{" "}
                    </p>
                  </div>
                  <div className="boysCase">
                    <p>
                      {" "}
                      <span className="active"></span> Online{" "}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-3 col-lg-3 col-sm-6 col-12">
            <a href="/delivery-boy-offline">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">DELIVERY BOYS </h5>
                </div>
                <div className="card-body">
                  <div className="boysCount">
                    <p>
                      <i className="fa fa-male"></i> {deliveryBoyStatus?.offlineDeliveryBoys}{" "}
                    </p>
                  </div>
                  <div className="boysCase">
                    <p>
                      {" "}
                      <span className="Inactive"></span> Offline{" "}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-3 col-lg-3 col-sm-6 col-12">
            <a href="/new-packages">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">NEW PACKAGES</h5>
                </div>
                <div className="card-body">
                  <div className="boysCount">
                    <p>
                      <i className="fa fa-first-order"></i> 0{" "}
                    </p>
                  </div>
                  <div className="boysCase">
                    <p> Sold Today </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-3 col-lg-3 col-sm-6 col-12">
            <a href="/top-ups">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">TOP UP's</h5>
                </div>
                <div className="card-body">
                  <div className="boysCount">
                    <p>
                      <i className="fa fa-first-order"></i> 0{" "}
                    </p>
                  </div>
                  <div className="boysCase">
                    <p>
                      {" "}
                      <i className="fa fa-celender"></i> Today{" "}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-3 col-lg-3 col-sm-6 col-12">
            <a href="/info/Orders">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title"> Total Orders</h5>
                </div>
                <div className="card-body">
                  <div className="boysCount">
                    <p>
                      <i className="fa fa-male"></i> {orderStatusObj?.deliveredOrders}{" "}
                    </p>
                  </div>
                  <div className="boysCase">
                    <p>
                      {" "}
                      <span className="active"></span> Delivered{" "}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-3 col-lg-3 col-sm-6 col-12">
            <a href="/info/Orders">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title"> Total Orders</h5>
                </div>
                <div className="card-body">
                  <div className="boysCount">
                    <p>
                      <i className="fa fa-male"></i> {orderStatusObj?.activeOrders}{" "}
                    </p>
                  </div>
                  <div className="boysCase">
                    <p>
                      {" "}
                      <span className="active"></span> Active{" "}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>

          <div className="col-xl-3 col-lg-3 col-sm-6 col-12">
            <a href="/info/not-delivered">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Delayed Orders</h5>
                </div>
                <div className="card-body">
                  <div className="boysCount">
                    <p>
                      <i className="fa fa-first-order"></i> 0{" "}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-6 col-lg-6 col-sm-6 col-12">
            <a href="/packages">
              <div className="card dashboardCardd">
                <div className="card-header">
                  <h5 className="card-title"> PACKAGES SOLD </h5>
                  <p>₹ {packageOrderObj?.amount}</p>
                </div>
                <h4>Package Sold in a</h4>
                <div className="card-body">
                  <div className="boysCase">
                    <label>Day</label>
                    <p>{packageOrderObj?.oneDayCount}</p>
                  </div>
                  <div className="boysCase">
                    <label>Week</label>
                    <p>{packageOrderObj?.oneWeekCount}</p>
                  </div>
                  <div className="boysCase">
                    <label>Month</label>
                    <p>{packageOrderObj?.oneMonthCount}</p>
                  </div>
                  <div className="boysCase">
                    <label>Year</label>
                    <p>{packageOrderObj?.oneYearCount}</p>
                  </div>
                </div>
              </div>
            </a>
          </div>
          {/* <div className="col-xl-6 col-lg-6 col-sm-6 col-12">
            <a href="#">
              <div className="card dashboardCardd">
                <div className="card-header">
                  <h5 className="card-title"> Top UP </h5>
                  <p>₹ 120</p>
                </div>
                <h4>Top up’s done in a</h4>
                <div className="card-body">
                  <div className="boysCase">
                    <label>Day</label>
                    <p> 23 </p>
                  </div>
                  <div className="boysCase">
                    <label>Week</label>
                    <p> 3 </p>
                  </div>
                  <div className="boysCase">
                    <label>Month</label>
                    <p> 1 </p>
                  </div>
                  <div className="boysCase">
                    <label>Year</label>
                    <p> 1 </p>
                  </div>
                </div>
              </div>
            </a>
          </div> */}
          <div className="col-xl-6 col-lg-6 col-sm-6 col-12">
            <a href="#">
              <div className="card dashboardCardd">
                <div className="card-header">
                  <h5 className="card-title"> Users </h5>
                </div>
                <div className="card-body">{/* <Pie data={pieChartObj} options={dashboardEmailStatisticsChart.options} /> */}</div>
                <div className="legend">
                  <i className="fa fa-circle text-warning" style={{ color: "#4acccd" }} /> Business Type Users <i className="fa fa-circle text-danger" style={{ color: "#fcc468" }} />
                  Normal users {/* <i className="fa fa-circle text-gray" /> Unopened */}
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-12 col-lg-12 col-sm-12 col-12">
            <span>
              <div className="card dashboardCardd">
                <div className="mt-4 mb-4">
                  <label>Select Year</label>
                  <Select options={yearArr} onChange={handleYearChange} />
                </div>
                <div className="card-header">
                  <h5 className="card-title"> Expense Vs Earnings </h5>
                </div>
                <div className="card-body">{/* <Bar data={earningGraphObj} options={barGraphConfig} /> */}</div>
              </div>
            </span>
          </div>
        </div>
      </div>
    </main>
  );
}

export default MyInfo;

// import React from "react";

// export default function MyInfo() {
//   return <div>MyInfo</div>;
// }
