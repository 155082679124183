import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { url } from '../../Services/Url';
import { ToastContainer, toast } from 'react-toastify';
import MaterialTable from 'material-table';
import { customGeneralFilter, generateImageUrl } from '../../utils/util';
import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { getAreaByCityId } from '../../Services/area';
import Select from 'react-select'

function DeliveryBoyInfo() {
    const sortIcon = <ArrowDownward />;




    const [deliveryBoyArr, setDeliveryBoyArr] = useState();





    const [approvedDeliveryBoyArr, setApprovedDeliveryBoyArr] = useState();
    const [filteredApprovedDeliveryBoyArr, setFilteredApprovedDeliveryBoyArr] = useState();


    const [unapprovedDeliveryBoyArr, setUnapprovedDeliveryBoyArr] = useState();
    const [filteredUnapprovedDeliveryBoyArr, setFilteredUnapprovedDeliveryBoyArr] = useState();


    const [activeDeliverBoyArr, setActiveDeliverBoyArr] = useState();
    const [filteredActiveDeliveryBoyArr, setFilteredActiveDeliveryBoyArr] = useState();


    const [inActiveDeliveryBoyArr, setInActiveDeliveryBoyArr] = useState();
    const [filteredInactiveDeliveryBoyArr, setFilteredInactiveDeliveryBoyArr] = useState();



    const [suspendedDeliveryBoyArr, setSuspendedDeliveryBoyArr] = useState();
    const [filteredSuspendedDeliveryBoyArr, setFilteredSuspendedDeliveryBoyArr] = useState();

    const [stateArr, setStateArr] = useState();
    const [cityArr, setCityArr] = useState();
    const [areaArr, setAreaArr] = useState();
    const [cityFilteredArr, setCityFilteredArr] = useState();
    const [selectedStateId, setSelectedStateId] = useState("");
    const [selectedCityId, setSelectedCityId] = useState("");
    const [pincodeArr, setPincodeArr] = useState([]);
    const [finalPincodeArr, setFinalPincodeArr] = useState([]);




    const [basic, setBasic] = useState(0);
    const [allowance, setAllowance] = useState(0);
    const [fuelAllowance, setFuelAllowance] = useState(0);
    const [other, setOther] = useState(0);
    const [dailyDeliveryLimit, setDailyDeliveryLimit] = useState(0);
    const [amount, setAmount] = useState();

    const [perDeliveryCost, setPerDeliveryCost] = useState(0);

    const [approvalStatus, setApprovalStatus] = useState(1);
    const [SelectedDeliveryboy, setSelectedDeliveryboy] = useState();

    const [approvedBy, setApprovedBy] = useState('');

    const [searchStr, setSearchStr] = useState('');











    const approveDeliveryBoy = async () => {
        try {
            if (SelectedDeliveryboy.DeliveryBoyType == "Full-Time") {

                if (basic != "" && allowance != "" && fuelAllowance != "" && other != "" && finalPincodeArr.length >= 1) {

                    let obj = {
                        salary: {
                            basic,
                            allowance,
                            fuelAllowance,
                            other,
                        },
                        DeliveryBoyType: SelectedDeliveryboy?.DeliveryBoyType,
                        perDeliveryCost,
                        deliveryPincodes: finalPincodeArr.map(el => {
                            let obj = {
                                pincode: el.value
                            }
                            return obj
                        }),
                        approvedBy,
                        status: 0,
                        dailyDeliveryLimit,
                        cityId: selectedCityId,
                        stateId: selectedStateId,
                    }
                    console.log(obj)
                    let res = await axios.patch(`${url}/deliveryBoy/approveDeliveryBoy/${SelectedDeliveryboy._id}`, obj)
                    if (res.data.success) {
                        // console.log(res)
                        getDeliveryBoys()

                    }
                }
                else {
                    alert("Please Fill all the fields")
                }
            }
            else if(SelectedDeliveryboy?.DeliveryBoyType=="Part-Time") {
                if (perDeliveryCost != "" && other != "" && finalPincodeArr.length >= 1) {

                    let obj = {
                        salary: {
                            basic,
                            allowance,
                            fuelAllowance,
                            other,
                        },
                        DeliveryBoyType: SelectedDeliveryboy?.DeliveryBoyType,
                        perDeliveryCost,
                        deliveryPincodes: finalPincodeArr.map(el => {
                            let obj = {
                                pincode: el.value
                            }
                            return obj
                        }),
                        approvedBy,
                        status: 0,
                        dailyDeliveryLimit,
                        cityId: selectedCityId,
                        stateId: selectedStateId,
                    }
                    console.log(obj)
                    let res = await axios.patch(`${url}/deliveryBoy/approveDeliveryBoy/${SelectedDeliveryboy._id}`, obj)
                    if (res.data.success) {
                        // console.log(res)
                        getDeliveryBoys()

                    }
                }
                else {
                    alert("Please Fill all the fields")
                }
            }
            else{
                alert("Please Select Delivery Boy Type")
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                alert(err?.response?.data?.message);
                console.log(err?.response?.data?.message)
            }
            else {
                console.log(err)
                alert(err)
            }
        }
    }



    const updateStatusDeliveryBoy = async (DeliveryBoyObj, val) => {
        try {

            let obj = {
                status: val,
            }
            let res = await axios.patch(`${url}/deliveryBoy/changeStatusDeliveryBoy/${DeliveryBoyObj._id}`, obj);
            console.log(res)
            if (res.data.success) {
                // console.log(res)
                getDeliveryBoys()
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                alert(err?.response?.data?.message);
                console.log(err?.response?.data?.message)
            }
            else {
                console.log(err)
                alert(err)
            }
        }
    }
    const handlePincodeSelect = (val) => {
        setFinalPincodeArr(val)
    }








    const getDeliveryBoys = async () => {
        try {
            let { data: res } = await axios.get(`${url}/deliveryBoy/`)
            if (res.success) {
                console.log(res.data, "delivery Boy Arr")
                setDeliveryBoyArr(res.data);

                let approvedTempArr = res.data.filter(el => el.status == 0)
                if (approvedTempArr) {
                    console.log(approvedTempArr)
                    setApprovedDeliveryBoyArr(approvedTempArr)
                    setFilteredApprovedDeliveryBoyArr(approvedTempArr)
                }

                let unApprovedTempArr = res.data.filter(el => el.status == 1)
                if (unApprovedTempArr) {
                    setUnapprovedDeliveryBoyArr(unApprovedTempArr);
                    setFilteredUnapprovedDeliveryBoyArr(unApprovedTempArr)
                }

                let suspendedTempArr = res.data.filter(el => el.status == 2)
                // console.log(suspendedTempArr)
                if (suspendedTempArr) {
                    setSuspendedDeliveryBoyArr(suspendedTempArr);
                    setFilteredSuspendedDeliveryBoyArr(suspendedTempArr);
                }


                let activeTempArr = res.data.filter(el => el.online == true)
                if (activeTempArr) {
                    setActiveDeliverBoyArr(activeTempArr)
                    setFilteredActiveDeliveryBoyArr(activeTempArr)

                }

                let inactiveTempArr = res.data.filter(el => el.online == false)
                if (inactiveTempArr) {
                    setInActiveDeliveryBoyArr(inactiveTempArr)
                    setFilteredInactiveDeliveryBoyArr(inactiveTempArr)
                }

            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }
    const getCity = async () => {
        try {
            let { data: res } = await axios.get(`${url}/city/`)
            if (res.success) {
                // toast.success(res.message)
                setCityArr(res.data);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }


    const getState = async () => {
        try {
            let { data: res } = await axios.get(`${url}/state/`)
            if (res.success) {
                // toast.success(res.message)
                setStateArr(res.data);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }
    const handleCitySelect = async (cityId) => {
        setSelectedCityId(cityId)
        try {
            let finalArr = []
            const { data: res, status: statusCode } = await getAreaByCityId(cityId);
            if (statusCode == 200 || statusCode == 304) {
                let tempArr = res.data;
                finalArr = tempArr.filter((el, i, arr) => i == arr.findIndex(ele => ele.pincode == el.pincode))
                setPincodeArr([...finalArr.map(el => ({ label: `${el.pincode}`, value: `${el.pincode}` }))])
                console.log([...finalArr.map(el => [{ label: `${el.pincode}`, value: `${el.pincode}` }])])
            }
        } catch (error) {
            console.error(error)
        }
    }


    useEffect(() => {
        // generateYearArr()
        getDeliveryBoys();
        getState()
        getCity()
    }, [])



    const filterApproved = () => {

    }



    const ApprovedColumns = [
        {
            name: 'Date Of Joining',
            width: '200px',
            selector: row => new Date(row?.dateOfJoining).toDateString(),
            cell: row => new Date(row?.dateOfJoining).toDateString(),
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => <a href={`/delivery-boy-Details/${row._id}`}>{row.name}</a>,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Current Status',
            selector: row => <>{row.outForDeliveryBool ? "Out for delivery" : "No orders assigned"}</>,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Email',
            selector: row => <>{row.email ? row?.email : "NA"}</>,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Address',
            selector: row => row?.address,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Phone Number',
            selector: row => row?.mobile,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Pin Code',
            selector: row => row?.pinCode,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Vehicle Type',
            selector: row => row?.vehicleName,
            sortable: true,
            width: '200px',

        },
        {
            name: 'RC Number',
            selector: row => <>{row.rcNumber ? row?.rcNumber : "NA"}</>,
            sortable: true,
            width: '200px',

        },
        {
            name: 'RC Image',
            selector: row => <>{row.rcNumber == "" ? <a target="_blank" href={`${generateImageUrl(row?.rcImageName)}`}><img src={`${generateImageUrl(row?.rcImageName)}`} style={{ height: 40, width: 40 }} alt="" /></a> : "NA"}</>,
            sortable: true,
            width: '200px',

        },

        {
            name: 'Chasis Number',
            selector: row => <>{row?.chasisNumber ? row?.chasisNumber : "NA"}</>,
            sortable: true,
            width: '200px',

        },

        {
            name: 'License Number',
            selector: row => <>{row?.licenseNumber ? row?.licenseNumber : "NA"}</>,
            sortable: true,
            width: '200px',

        },
        {
            name: 'License Image',
            selector: row => <>
                {row?.licenseNumber ?

                    <a target="_blank" href={`${generateImageUrl(row?.licenseImageName)}`}><img src={`${generateImageUrl(row?.licenseImageName)}`} style={{ height: 40, width: 40 }} alt="" /></a>
                    : "NA"}
            </>,

            sortable: true,
            width: '200px',

        },
        {
            name: 'Aadhaar Number',
            selector: row => row?.aadhaarNumber,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Aadhaar Image',
            selector: row => <a target="_blank" href={`${generateImageUrl(row?.aadharImageName)}`}><img src={`${generateImageUrl(row?.aadharImageName)}`} style={{ height: 40, width: 40 }} alt="" /></a>,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Permanent Address',
            selector: row => row?.permanentAddressObj?.address,
            cell: row => `${row?.permanentAddressObj?.address},${row?.permanentAddressObj?.pinCode}`,

            sortable: true,
            width: '200px',

        },
        // {
        //     name: 'Current Address',
        //     selector: row => row?.cityName,
        //     sortable: true,
        //     width: '200px',

        // },
        {
            name: 'Delivery Boy Type',
            selector: row => row?.DeliveryBoyType,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Basic Salary',
            selector: row => row?.salary?.basic,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Allowance',
            selector: row => row?.salary?.allowance,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Fuel Allowance',
            selector: row => row?.salary?.fuelAllowance,
            sortable: true,
            width: '200px',


        },
        {
            name: 'Other Allowance',
            selector: row => row?.salary?.other,
            sortable: true,
            width: '200px',


        },
        {
            name: 'Per Delivery cost',
            selector: row => row?.perDeliveryCost,
            sortable: true,
            width: '200px',


        },
        {
            name: 'Suspend',
            selector: row => <button type="button" class="btn btn-primary" onClick={() => updateStatusDeliveryBoy(row, 2)}>Suspend</button>,
            sortable: true,
            width: '200px',


        },
    ];

    const UnapprovedColumns = [
        {
            name: 'Date Of Joining',
            width: '200px',
            selector: row => new Date(row?.dateOfJoining).toDateString(),
            cell: row => new Date(row?.dateOfJoining).toDateString(),
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => <a href={`/delivery-boy-Details/${row._id}`}>{row.name}</a>,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Email',
            selector: row => <>{row.email ? row?.email : "NA"}</>,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Address',
            selector: row => row?.address,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Phone Number',
            selector: row => row?.mobile,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Pin Code',
            selector: row => row?.pinCode,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Vehicle Type',
            selector: row => row?.vehicleName,
            sortable: true,
            width: '200px',

        },
        {
            name: 'RC Number',
            selector: row => <>{row.rcNumber ? row?.rcNumber : "NA"}</>,
            sortable: true,
            width: '200px',

        },
        {
            name: 'RC Image',
            selector: row => <>{row.rcNumber == "" ? <a target="_blank" href={`${generateImageUrl(row?.rcImageName)}`}><img src={`${generateImageUrl(row?.rcImageName)}`} style={{ height: 40, width: 40 }} alt="" /></a> : "NA"}</>,
            sortable: true,
            width: '200px',

        },

        {
            name: 'Chasis Number',
            selector: row => <>{row?.chasisNumber ? row?.chasisNumber : "NA"}</>,
            sortable: true,
            width: '200px',

        },

        {
            name: 'License Number',
            selector: row => <>{row?.licenseNumber ? row?.licenseNumber : "NA"}</>,
            sortable: true,
            width: '200px',

        },
        {
            name: 'License Image',
            selector: row => <>
                {row?.licenseNumber ?

                    <a target="_blank" href={`${generateImageUrl(row?.licenseImageName)}`}><img src={`${generateImageUrl(row?.licenseImageName)}`} style={{ height: 40, width: 40 }} alt="" /></a>
                    : "NA"}
            </>,

            sortable: true,
            width: '200px',

        },
        {
            name: 'Aadhaar Number',
            selector: row => row?.aadhaarNumber,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Aadhaar Image',
            selector: row => <a target="_blank" href={`${generateImageUrl(row?.aadharImageName)}`}><img src={`${generateImageUrl(row?.aadharImageName)}`} style={{ height: 40, width: 40 }} alt="" /></a>,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Permanent Address',
            selector: row => row?.permanentAddressObj?.address,
            cell: row => `${row?.permanentAddressObj?.address},${row?.permanentAddressObj?.pinCode}`,

            sortable: true,
            width: '200px',

        },
        // {
        //     name: 'City',
        //     selector: row => row?.cityName,
        //     sortable: true,
        //     width: '200px',

        // },
        // {
        //     name: 'Area',
        //     selector: row => row?.areaName,
        //     sortable: true,
        //     width: '200px',

        // },
        {
            name: 'Basic Salary',
            selector: row => row?.salary?.basic,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Allowance',
            selector: row => row?.salary?.allowance,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Fuel Allowance',
            selector: row => row?.salary?.fuelAllowance,
            sortable: true,
            width: '200px',


        },
        {
            name: 'Other Allowance',
            selector: row => row?.salary?.other,
            sortable: true,
            width: '200px',


        },
        {
            name: 'Approve',
            selector: row => <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalLong" onClick={() => { console.log(row); setSelectedDeliveryboy(row) }}>Approve</button>,
            sortable: true

        },
    ];




    const SuspendedColumns = [
        {
            name: 'Date Of Joining',
            width: '200px',
            selector: row => new Date(row?.dateOfJoining).toDateString(),
            cell: row => new Date(row?.dateOfJoining).toDateString(),
            sortable: true,
        },
        {
            name: 'Suspended Date',
            width: '200px',
            selector: row => new Date(row?.suspendedDateArr[row?.suspendedDateArr.length - 1].suspendedDate).toDateString(),
            cell: row => new Date(row?.dateOfJoining).toDateString(),
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => <a href={`/delivery-boy-Details/${row._id}`}>{row.name}</a>,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Email',
            selector: row => <>{row.email ? row?.email : "NA"}</>,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Address',
            selector: row => row?.address,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Phone Number',
            selector: row => row?.mobile,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Pin Code',
            selector: row => row?.pinCode,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Vehicle Type',
            selector: row => row?.vehicleName,
            sortable: true,
            width: '200px',

        },
        {
            name: 'RC Number',
            selector: row => <>{row.rcNumber ? row?.rcNumber : "NA"}</>,
            sortable: true,
            width: '200px',

        },
        {
            name: 'RC Image',
            selector: row => <>{row.rcNumber == "" ? <a target="_blank" href={`${generateImageUrl(row?.rcImageName)}`}><img src={`${generateImageUrl(row?.rcImageName)}`} style={{ height: 40, width: 40 }} alt="" /></a> : "NA"}</>,
            sortable: true,
            width: '200px',

        },

        {
            name: 'Chasis Number',
            selector: row => <>{row?.chasisNumber ? row?.chasisNumber : "NA"}</>,
            sortable: true,
            width: '200px',

        },

        {
            name: 'License Number',
            selector: row => <>{row?.licenseNumber ? row?.licenseNumber : "NA"}</>,
            sortable: true,
            width: '200px',

        },
        {
            name: 'License Image',
            selector: row => <>
                {row?.licenseNumber ?

                    <a target="_blank" href={`${generateImageUrl(row?.licenseImageName)}`}><img src={`${generateImageUrl(row?.licenseImageName)}`} style={{ height: 40, width: 40 }} alt="" /></a>
                    : "NA"}
            </>,

            sortable: true,
            width: '200px',

        },
        {
            name: 'Aadhaar Number',
            selector: row => row?.aadhaarNumber,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Aadhaar Image',
            selector: row => <a target="_blank" href={`${generateImageUrl(row?.aadharImageName)}`}><img src={`${generateImageUrl(row?.aadharImageName)}`} style={{ height: 40, width: 40 }} alt="" /></a>,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Permanent Address',
            selector: row => row?.permanentAddressObj?.address,
            cell: row => `${row?.permanentAddressObj?.address},${row?.permanentAddressObj?.pinCode}`,

            sortable: true,
            width: '200px',

        },
        // {
        //     name: 'City',
        //     selector: row => row?.cityName,
        //     sortable: true,
        //     width: '200px',

        // },
        // {
        //     name: 'Area',
        //     selector: row => row?.areaName,
        //     sortable: true,
        //     width: '200px',

        // },
        {
            name: 'Basic Salary',
            selector: row => row?.salary?.basic,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Allowance',
            selector: row => row?.salary?.allowance,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Fuel Allowance',
            selector: row => row?.salary?.fuelAllowance,
            sortable: true,
            width: '200px',


        },
        {
            name: 'Other Allowance',
            selector: row => row?.salary?.other,
            sortable: true,
            width: '200px',


        },
        {
            name: 'Approve',
            selector: row => <button type="button" class="btn btn-primary" onClick={() => updateStatusDeliveryBoy(row, 0)}>Approve</button>,
            sortable: true

        },
    ];



    const ActiveColumns = [
        {
            name: 'Date Of Joining',
            width: '200px',
            selector: row => new Date(row?.dateOfJoining).toDateString(),
            cell: row => new Date(row?.dateOfJoining).toDateString(),
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => <a href={`/delivery-boy-Details/${row._id}`}>{row.name}</a>,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Email',
            selector: row => <>{row.email ? row?.email : "NA"}</>,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Address',
            selector: row => row?.address,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Phone Number',
            selector: row => row?.mobile,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Pin Code',
            selector: row => row?.pinCode,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Vehicle Type',
            selector: row => row?.vehicleName,
            sortable: true,
            width: '200px',

        },
        {
            name: 'RC Number',
            selector: row => <>{row.rcNumber ? row?.rcNumber : "NA"}</>,
            sortable: true,
            width: '200px',

        },
        {
            name: 'RC Image',
            selector: row => <>{row.rcNumber == "" ? <a target="_blank" href={`${generateImageUrl(row?.rcImageName)}`}><img src={`${generateImageUrl(row?.rcImageName)}`} style={{ height: 40, width: 40 }} alt="" /></a> : "NA"}</>,
            sortable: true,
            width: '200px',

        },

        {
            name: 'Chasis Number',
            selector: row => <>{row?.chasisNumber ? row?.chasisNumber : "NA"}</>,
            sortable: true,
            width: '200px',

        },

        {
            name: 'License Number',
            selector: row => <>{row?.licenseNumber ? row?.licenseNumber : "NA"}</>,
            sortable: true,
            width: '200px',

        },
        {
            name: 'License Image',
            selector: row => <>
                {row?.licenseNumber ?

                    <a target="_blank" href={`${generateImageUrl(row?.licenseImageName)}`}><img src={`${generateImageUrl(row?.licenseImageName)}`} style={{ height: 40, width: 40 }} alt="" /></a>
                    : "NA"}
            </>,

            sortable: true,
            width: '200px',

        },
        {
            name: 'Aadhaar Number',
            selector: row => row?.aadhaarNumber,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Aadhaar Image',
            selector: row => <a target="_blank" href={`${generateImageUrl(row?.aadharImageName)}`}><img src={`${generateImageUrl(row?.aadharImageName)}`} style={{ height: 40, width: 40 }} alt="" /></a>,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Permanent Address',
            selector: row => row?.permanentAddressObj?.address,
            cell: row => `${row?.permanentAddressObj?.address},${row?.permanentAddressObj?.pincode}`,

            sortable: true,
            width: '200px',

        },
        // {
        //     name: 'City',
        //     selector: row => row?.cityName,
        //     sortable: true,
        //     width: '200px',

        // },
        // {
        //     name: 'Area',
        //     selector: row => row?.areaName,
        //     sortable: true,
        //     width: '200px',

        // },
        {
            name: 'Basic Salary',
            selector: row => row?.salary?.basic,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Allowance',
            selector: row => row?.salary?.allowance,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Fuel Allowance',
            selector: row => row?.salary?.fuelAllowance,
            sortable: true,
            width: '200px',


        },
        {
            name: 'Other Allowance',
            selector: row => row?.salary?.other,
            sortable: true,
            width: '200px',


        },
    ];
    const inactiveColumns = [
        {
            name: 'Date Of Joining',
            width: '200px',
            selector: row => new Date(row?.dateOfJoining).toDateString(),
            cell: row => new Date(row?.dateOfJoining).toDateString(),
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => <a href={`/delivery-boy-Details/${row._id}`}>{row.name}</a>,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Email',
            selector: row => <>{row.email ? row?.email : "NA"}</>,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Address',
            selector: row => row?.address,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Phone Number',
            selector: row => row?.mobile,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Pin Code',
            selector: row => row?.pinCode,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Vehicle Type',
            selector: row => row?.vehicleName,
            sortable: true,
            width: '200px',

        },
        {
            name: 'RC Number',
            selector: row => <>{row.rcNumber ? row?.rcNumber : "NA"}</>,
            sortable: true,
            width: '200px',

        },
        {
            name: 'RC Image',
            selector: row => <>{row.rcNumber == "" ? <a target="_blank" href={`${generateImageUrl(row?.rcImageName)}`}><img src={`${generateImageUrl(row?.rcImageName)}`} style={{ height: 40, width: 40 }} alt="" /></a> : "NA"}</>,
            sortable: true,
            width: '200px',

        },

        {
            name: 'Chasis Number',
            selector: row => <>{row?.chasisNumber ? row?.chasisNumber : "NA"}</>,
            sortable: true,
            width: '200px',

        },

        {
            name: 'License Number',
            selector: row => <>{row?.licenseNumber ? row?.licenseNumber : "NA"}</>,
            sortable: true,
            width: '200px',

        },
        {
            name: 'License Image',
            selector: row => <>
                {row?.licenseNumber ?

                    <a target="_blank" href={`${generateImageUrl(row?.licenseImageName)}`}><img src={`${generateImageUrl(row?.licenseImageName)}`} style={{ height: 40, width: 40 }} alt="" /></a>
                    : "NA"}
            </>,

            sortable: true,
            width: '200px',

        },
        {
            name: 'Aadhaar Number',
            selector: row => row?.aadhaarNumber,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Aadhaar Image',
            selector: row => <a target="_blank" href={`${generateImageUrl(row?.aadharImageName)}`}><img src={`${generateImageUrl(row?.aadharImageName)}`} style={{ height: 40, width: 40 }} alt="" /></a>,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Permanent Address',
            selector: row => row?.permanentAddressObj?.address,
            cell: row => `${row?.permanentAddressObj?.address},${row?.permanentAddressObj?.pincode}`,

            sortable: true,
            width: '200px',

        },
        // {
        //     name: 'City',
        //     selector: row => row?.cityName,
        //     sortable: true,
        //     width: '200px',

        // },
        // {
        //     name: 'Area',
        //     selector: row => row?.areaName,
        //     sortable: true,
        //     width: '200px',

        // },
        {
            name: 'Basic Salary',
            selector: row => row?.salary?.basic,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Allowance',
            selector: row => row?.salary?.allowance,
            sortable: true,
            width: '200px',

        },
        {
            name: 'Fuel Allowance',
            selector: row => row?.salary?.fuelAllowance,
            sortable: true,
            width: '200px',


        },
        {
            name: 'Other Allowance',
            selector: row => row?.salary?.other,
            sortable: true,
            width: '200px',


        },
    ];

    const setCityArrFromState = (value) => {
        setSelectedStateId(value)
        let cityfilteredArr = cityArr.filter(el => el.stateId == value);
        setCityFilteredArr(cityfilteredArr)
    }

    const handleDeliveryBoyTypeSelect = (value) => {
        if (value != "")
            setSelectedDeliveryboy(prevState => ({ ...prevState, DeliveryBoyType: value }))
    }


    return (
        <main className="main_content">
            <div className="userTabsDesign">
                <div className="card-body">
                    <div className="custom-tab-2">
                        <div className="tab-content tab-content-default">
                            {/* <div className="searchBar mb-4">
                                <input type="text" className="form-control" placeholder="Search : name : Area : Pincode : Type of Business ; Contact Person  ( default ALL ) " />
                                <i className="fa fa-search"></i>
                            </div> */}
                            <ul className="nav nav-tabs">
                                <li className="nav-item active"><a className="nav-link" data-toggle="tab" href="#approved">Approved</a>
                                </li>
                                <li className="nav-item"><a className="nav-link show" data-toggle="tab" href="#unapproved">Unapproved {filteredUnapprovedDeliveryBoyArr?.length > 0 && `(${filteredUnapprovedDeliveryBoyArr?.length})`}</a>
                                </li>
                                <li className="nav-item"><a className="nav-link show" data-toggle="tab" href="#suspended">Suspended</a>
                                </li>
                                <li className="nav-item"><a className="nav-link show" data-toggle="tab" href="#online">Online</a>
                                </li>
                                <li className="nav-item"><a className="nav-link show" data-toggle="tab" href="#offline">Offline</a>
                                </li>

                            </ul>
                            <div className="tab-pane fade in active" id="approved" role="tabpanel">
                                <div className="searchBar mt-4 mb-4">
                                    <input type="text" className="form-control" placeholder="Search" value={searchStr} onChange={(e) => { setSearchStr(e.target.value) }} />
                                    <i className="fa fa-search"></i>
                                </div>
                                <div className="card">
                                    <div className="row">
                                        <div className="col-12 ">
                                            <DataTable
                                                title=""
                                                columns={ApprovedColumns}
                                                data={customGeneralFilter(filteredApprovedDeliveryBoyArr,ApprovedColumns,searchStr)}
                                                sortIcon={sortIcon}
                                                keyField='_id'
                                                pagination
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade in active" id="suspended" >
                                <div className="card">
                                    <div className="row">
                                        <div className="col-12 ">
                                            <DataTable
                                                title=""
                                                columns={SuspendedColumns}
                                                data={filteredSuspendedDeliveryBoyArr}
                                                sortIcon={sortIcon}
                                                keyField='_id'
                                                pagination
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="unapproved">
                                <div className="card">
                                    <div className="row">

                                        <div className="col-12 ">
                                            <DataTable
                                                title=""
                                                columns={UnapprovedColumns}
                                                data={filteredUnapprovedDeliveryBoyArr}
                                                sortIcon={sortIcon}
                                                keyField='_id'
                                                pagination
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="online">
                                <div className="card">
                                    <div className="row">

                                        <div className="col-12 ">
                                            <DataTable
                                                title=""
                                                columns={ActiveColumns}
                                                data={filteredActiveDeliveryBoyArr}
                                                sortIcon={sortIcon}
                                                keyField='_id'
                                                pagination
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="offline">
                                <div className="card">
                                    <div className="row">
                                        <div className="col-12">
                                            <DataTable
                                                title=""
                                                columns={inactiveColumns}
                                                data={filteredInactiveDeliveryBoyArr}
                                                sortIcon={sortIcon}
                                                keyField='_id'
                                                pagination
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Modal --> */}
            <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title" id="exampleModalLongTitle">Approve Delivery Boy</h3>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body" style={{ paddingBottom: 70 }}>
                            <div className="row" style={{ marginTop: 20 }}>
                                <div className="col-12 col-md-12 mt-3">
                                    <div className="field-group">
                                        <label>Daily Delivery Limit</label>
                                        <input type="number" onChange={(e) => setDailyDeliveryLimit(e.target.value)} className="form-control" placeholder="Enter Daily Delivery Limit" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-12">
                                <div className="field-group">
                                    <label>Delivery Boy Type</label>
                                    <select value={SelectedDeliveryboy?.DeliveryBoyType} className="form-control" onChange={(event) => { handleDeliveryBoyTypeSelect(event.target.value) }}>
                                        <option value="">Please option</option>
                                        <option value="Full-Time">Full-Time</option>
                                        <option value="Part-Time">Part-Time</option>


                                    </select>

                                </div>
                            </div>
                            {SelectedDeliveryboy?.DeliveryBoyType == "Full-Time" ?
                                <>
                                    <div className="row" style={{ marginTop: 20 }}>
                                        <div className="col-12 col-md-12 mt-3">
                                            <div className="field-group">
                                                <label>Monthly Basic Salary</label>
                                                <input type="number" onChange={(e) => setBasic(e.target.value)} className="form-control" placeholder="Enter Basic Salary" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: 20 }}>
                                        <div className="col-12 col-md-12 mt-3">
                                            <div className="field-group">
                                                <label>Monthly Basic Allowance</label>

                                                <input type="number" onChange={(e) => setAllowance(e.target.value)} className="form-control" placeholder="Enter Allowance" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: 20 }}>
                                        <div className="col-12 col-md-12 mt-3">
                                            <div className="field-group">
                                                <label>Monthly Fuel Allowance</label>

                                                <input type="number" onChange={(e) => setFuelAllowance(e.target.value)} className="form-control" placeholder="Enter Fuel Allowance" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: 20 }}>
                                        <div className="col-12 col-md-12 mt-3">
                                            <div className="field-group">
                                                <label>Monthly Other Allowance</label>

                                                <input type="number" onChange={(e) => setOther(e.target.value)} className="form-control" placeholder="Enter Other" />
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="row" style={{ marginTop: 20 }}>
                                        <div className="col-12 col-md-12 mt-3">
                                            <div className="field-group">
                                                <label>Per Delivery Payable</label>

                                                <input type="number" onChange={(e) => setPerDeliveryCost(e.target.value)} className="form-control" placeholder="Enter per delivery cost" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: 20 }}>
                                        <div className="col-12 col-md-12 mt-3">
                                            <div className="field-group">
                                                <label>Referal Amount</label>

                                                <input type="number" onChange={(e) => setOther(e.target.value)} className="form-control" placeholder="Enter Referal amount" />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }

                            <div className="col-12 col-md-6">
                                <div className="field-group">
                                    <label>Select State</label>
                                    <select name="" id="" className="form-control" onChange={(event) => { setCityArrFromState(event.target.value) }}>
                                        <option value="">Please Select a State</option>
                                        {
                                            stateArr && stateArr.length > 0 && stateArr.map(el => {
                                                return (
                                                    <option key={el._id} value={el._id}>{el.name}</option>
                                                )
                                            })
                                        }
                                    </select>

                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="field-group">
                                    <label>Select City</label>
                                    <select name="" id="" className="form-control" onChange={(event) => { handleCitySelect(event.target.value) }}>
                                        <option value="">Please Select a City</option>
                                        {
                                            cityFilteredArr && cityFilteredArr.length > 0 && cityFilteredArr.map(el => {
                                                return (
                                                    <option key={el._id} value={el._id}>{el.name}</option>
                                                )
                                            })
                                        }
                                    </select>

                                </div>
                            </div>
                            <div className="col-12 col-md-12 mt-4">
                                <div className="field-group">
                                    <label>Select Pincode</label>
                                    <Select options={pincodeArr} onChange={handlePincodeSelect} isMulti />

                                </div>
                            </div>
                            <div className="row" style={{ marginTop: 20 }}>
                                <div className="col-12 col-md-12 mt-3">
                                    <div className="field-group">
                                        <label>Approved By</label>
                                        <input type="text" onChange={(e) => setApprovedBy(e.target.value)} className="form-control" placeholder="Approved By" />
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-12 col-md-12" style={{ marginTop: 20 }}>
                                <label>Status</label>
                                <div className="field-group">
                                    <button onClick={() => setApprovalStatus(0)} type="button" className={`${"btn"} ${approvalStatus == 1 ? "addModifiBttn" : "greenBttn"} `}> {approvalStatus == 1 ? "Approve" : "Approved"} </button>
                                    <button onClick={() => setApprovalStatus(1)} type="button" className="btn addModifiBttn"> Pending Approve </button>
                                </div>
                            </div> */}
                        </div>
                        <div class="modal-footer mt-4" style={{ marginTop: 50 }}>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" onClick={() => approveDeliveryBoy()}>Approve & Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </main >
    )
}

export default DeliveryBoyInfo;
