import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { url } from '../../Services/Url'
import { toast } from 'react-toastify';

function AddOrder() {
    const [area, setArea] = useState("");
    // const [pickupAddress, setPickupAddress] = useState();
    // const [deliveryAddress, setDeliveryAddress] = useState();
    // const [cashCollection, setCashCollection] = useState();
    // const [amount, setAmount] = useState();
    // const [deliverToNumber, setDeliverToNumber] = useState();
    // const [deliverToName, setDeliverToName] = useState();
    // const [cashCollectionBool, setCashCollectionBool] = useState(false);
    // const [happyCode, setHappyCode] = useState();


    // const [kmRange, setKmRange] = useState();
    const [numberOfPackets, setNumberOfPackets] = useState(); ////////this has been changed to number of orders
    const [pickupTime, setPickupTime] = useState();
    // const [deliveryTime, setDeliveryTime] = useState();
    // const [totalWeight, setTotalWeight] = useState();
    const [deliveredUsersArr, setDeliveredUsersArr] = useState();


    const [numberOfOrdersArr, setNumberOfOrdersArr] = useState([]);






    const [stateArr, setStateArr] = useState();
    const [cityArr, setCityArr] = useState();
    const [areaArr, setAreaArr] = useState();
    const [userArr, setUserArr] = useState();
    const [cityFilteredArr, setCityFilteredArr] = useState();
    const [areaFilteredArr, setAreaFilteredArr] = useState();
    const [userFilteredArr, setUserFilteredArr] = useState();
    const [selectedStateId, setSelectedStateId] = useState("");
    const [selectedCityId, setSelectedCityId] = useState("");
    const [selectedAreaId, setSelectedAreaId] = useState();
    const [selectedUser, setSelectedUser] = useState();





    const handleSubmit = async () => {
        try {
            if (selectedStateId == "") {
                alert("state must be selected")
            }
            else if (selectedCityId == "") {
                alert("city must be selected")
            }
            else if (selectedAreaId == "") {
                alert("area must be selected")
            }
            else {
                let obj = {

                    userId: selectedUser,
                    // kmRange: kmRange,
                    // totalWeight: totalWeight,
                    numberOfPackets: numberOfPackets,
                    pickupTime: pickupTime,
                    areaId: selectedAreaId,
                    cityId: selectedCityId,
                    stateId: selectedStateId,
                    numberOfOrdersArr: numberOfOrdersArr,
                    // pickupAddress: {}
                }
                console.log(obj)
                let { data: res } = await axios.post(`${url}/order/`, obj)
                if (res.success) {
                    toast.success(res.message)
                    // alert(res.message)
                }
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }

    }


    const generateOrders = () => {
        // setNumberOfOrdersArr([])
        let tempArr = [];
        // console.log(numberOfPackets, "sdasd")
        for (let i = 1; i <= numberOfPackets; i++) {
            tempArr.push({
                sno: i,
                deliveryAddress: "",
                orderStatus: 0,
                cashCollection: "",
                amount: "",
                weight: "",
                deliverToNumber: "",
                maxKm: "",
                deliverToName: "",
                cashCollectionBool: false
            });
        }
        // console.log(tempArr)
        setNumberOfOrdersArr(tempArr)
    }


    const getCity = async () => {
        try {
            let { data: res } = await axios.get(`${url}/city/`)
            if (res.success) {
                let tempCityArr = res.data.filter(el => el.active == true && el.stateActive == true);
                setCityArr(tempCityArr);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }


    const getState = async () => {
        try {
            let { data: res } = await axios.get(`${url}/state/`)
            if (res.success) {
                let tempStateArr = res.data.filter(el => el.active == true);
                setStateArr(tempStateArr);
            }
        } catch (error) {
            if (error.response) {
                toast.error(error?.response?.data?.message)
            }
            console.log(error, "errors")
        }
    }

    const setCityArrFromState = (value) => {
        setSelectedStateId(value)
        let cityfilteredArr = cityArr.filter(el => el.stateId == value);
        setCityFilteredArr(cityfilteredArr)
    }

    const setAreaArrFromCity = (value) => {
        setSelectedCityId(value)
        let areafilteredArr = areaArr.filter(el => el.cityId == value);
        setAreaFilteredArr(areafilteredArr)
    }



    const setUsersArrfromArea = (value) => {
        setSelectedAreaId(value)
        let userfilteredArr = userArr.filter(el => el.areaId == value && el.stateId == selectedStateId && el.cityId == selectedCityId);
        // console.log(userfilteredArr, "user Arra Filtered")
        setUserFilteredArr(userfilteredArr)
    }



    const getDeliveredUsersByUserId = (value) => {
        setSelectedUser(value)

        getDeliveredToUsers(value)

    }





    const getArea = async () => {
        try {
            let { data: res } = await axios.get(`${url}/area/`)
            if (res.success) {
                let tempAreaArr = res.data.filter(el => el.active == true && el.stateActive == true && el.cityActive == true);
                setAreaArr(tempAreaArr);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }


    const getDeliveredToUsers = async (value) => {
        try {
            let { data: res } = await axios.get(`${url}/deliveredUsers/getByUserId/${value}`)
            if (res.success) {
                setDeliveredUsersArr(res.data)
                console.log(res.data, "deliveredUsers");
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }



    const getUsers = async () => {
        try {
            let { data: res } = await axios.get(`${url}/user/`)
            if (res.success) {
                // let tempCityArr = res.data.filter(el => el.active == true && el.stateActive == true);
                setUserArr(res.data);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }







    useEffect(() => {
        getState();
        getCity();
        getArea();
        getUsers();
    }, [])




    ////////////////////

    const setDeliveryRecipientName = (index, value) => {
        setNumberOfOrdersArr(previousState => {
            previousState[index].deliverToName = value;
            return [...previousState]
        })
    }

    const setDeliveryRecipientNumber = (index, value) => {
        setNumberOfOrdersArr(previousState => {
            previousState[index].deliverToNumber = value;
            return [...previousState]
        })

    }

    const setRecipientAddress = (index, value) => {
        setNumberOfOrdersArr(previousState => {
            previousState[index].deliveryAddress = value;
            return [...previousState]
        })
    }
    const setTotalWeight = (index, value) => {
        setNumberOfOrdersArr(previousState => {
            previousState[index].weight = value;
            return [...previousState]
        })
    }
    const setTotalKm = (index, value) => {
        setNumberOfOrdersArr(previousState => {
            previousState[index].maxKm = value;
            return [...previousState]
        })
    }


    const setDeliveryAmount = (index, value) => {
        setNumberOfOrdersArr(previousState => {
            previousState[index].amount = value;
            return [...previousState]
        })
    }

    const setDeliveryCashCollection = (index, value) => {
        setNumberOfOrdersArr(previousState => {
            previousState[index].cashCollection = value;
            return [...previousState]
        })
    }

    const setDeliveryCashCollectionBool = (index, value) => {
        setNumberOfOrdersArr(previousState => {
            previousState[index].cashCollectionBool = value;
            return [...previousState]
        })
    }























    return (
        <main className="main_content">
            <div className="userTabsDesign mb-4">
                <>
                    <div className="headingTitle" style={{ marginBottom: 20 }}><h3> Add Order </h3> </div>
                    <div className="card mt-3">
                        <div className="card-body">
                            <div className="AddModiForm w-100">
                                <form>
                                    <label>Select User</label>
                                    <div className="row bg-dark">
                                        <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <select name="" id="" className="form-control" onChange={(event) => { setCityArrFromState(event.target.value) }}>
                                                    <option value="">Please Select a State</option>
                                                    {
                                                        stateArr && stateArr.length > 0 && stateArr.map(el => {
                                                            return (
                                                                <option key={el._id} value={el._id}>{el.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <select name="" id="" className="form-control" onChange={(event) => { setAreaArrFromCity(event.target.value) }}>
                                                    <option value="">Please Select a City</option>
                                                    {
                                                        cityFilteredArr && cityFilteredArr.length > 0 && cityFilteredArr.map(el => {
                                                            return (
                                                                <option key={el._id} value={el._id}>{el.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <select name="" id="" className="form-control" onChange={(event) => { setUsersArrfromArea(event.target.value) }}>
                                                    <option value="">Please Select a Area</option>
                                                    {
                                                        areaFilteredArr && areaFilteredArr.length > 0 && areaFilteredArr.map(el => {
                                                            return (
                                                                <option key={el._id} value={el._id}>{el.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <select name="" id="" className="form-control" onChange={(event) => { getDeliveredUsersByUserId(event.target.value) }}>
                                                    <option value="">Please Select a User</option>
                                                    {
                                                        userFilteredArr && userFilteredArr.length > 0 && userFilteredArr.map(el => {
                                                            return (
                                                                <option key={el._id} value={el._id}>{el.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <label className="mt-4">Create Order</label>
                                    <div className="row">

                                        {/* <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <input type="text" className="form-control" onChange={(e) => setTotalWeight(e.target.value)} placeholder="Total Weight" />
                                            </div>
                                        </div> */}
                                        <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <input type="text" className="form-control" onChange={(e) => setNumberOfPackets(e.target.value)} placeholder="Number Of Orders" />
                                            </div>
                                        </div>

                                        {/* <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <input type="text" className="form-control" onChange={(e) => setKmRange(e.target.value)} placeholder="Enter Maximum (KM) " />
                                            </div>
                                        </div> */}


                                        <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <select className="form-control" onChange={(e) => setPickupTime(e.target.value)}>
                                                    <option value="">Please Select pickup time</option>
                                                    <option value="09:00 - 11:00">09:00 - 11:00</option>
                                                    <option value="11:00 - 02:00">11:00 - 02:00</option>
                                                    <option value="02:00 - 04:00">02:00 - 04:00</option>
                                                    <option value="04:00 - 06:00">04:00 - 06:00</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-4 d-flex justify-content-center">
                                        <div className="col-12 col-md-3">
                                            <div className="field-group">
                                                <button type="button" onClick={() => generateOrders()} className="btn searchBttn"> Generate Orders </button>
                                            </div>
                                        </div>
                                    </div>
                                    <label className="mt-4">Delivery Details</label>

                                    {
                                        numberOfOrdersArr && numberOfOrdersArr.length > 0 && numberOfOrdersArr.map((el, index) => {

                                            return (
                                                <div className="row" key={el.sno}>
                                                    <div className="col-12 col-md-12">
                                                        <div className="field-group">
                                                            <div className="form-control" style={{ border: "none", boxShadow: "none", paddingLeft: 0, margin: 0 }}>Order No {el.sno}.)</div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-6">
                                                        <div className="field-group">
                                                            <input type="text" className="form-control" list="data" value={el.deliverToName} onChange={(e) => setDeliveryRecipientName(index, e.target.value)} placeholder="Enter Recipient Name" />
                                                            <datalist id="data">
                                                                {deliveredUsersArr && deliveredUsersArr.map((item, key) =>
                                                                    <option key={key} value={item.name}>{item.name}</option>
                                                                )}
                                                            </datalist>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="field-group">
                                                            <input type="text" className="form-control" list="mobile" value={el.deliverToNumber} onChange={(e) => setDeliveryRecipientNumber(index, e.target.value)} placeholder="Enter Recipient Mobile Number" />
                                                            <datalist id="mobile">
                                                                {deliveredUsersArr && deliveredUsersArr.map((item, key) =>
                                                                    <option key={key} value={item.mobile}>{item.mobile}</option>
                                                                )}
                                                            </datalist>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="field-group">
                                                            <input type="text" className="form-control" list="address" value={el.deliveryAddress} onChange={(e) => setRecipientAddress(index, e.target.value)} placeholder="Enter Delivery Address" />
                                                            <datalist id="address">
                                                                {deliveredUsersArr && deliveredUsersArr.map((item, key) =>
                                                                    <option key={key} value={item.address}>{item.address}</option>
                                                                )}
                                                            </datalist>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="field-group">
                                                            <input type="text" className="form-control" value={el.weight} onChange={(e) => setTotalWeight(index, e.target.value)} placeholder="Weight" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="field-group">
                                                            <input type="text" className="form-control" value={el.maxKm} onChange={(e) => setTotalKm(index, e.target.value)} placeholder="max Km" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="field-group">
                                                            <input type="text" className="form-control" value={el.amount} onChange={(e) => setDeliveryAmount(index, e.target.value)} placeholder="Enter Amount" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="field-group">
                                                            <div className="form-control d-flex align-item-center" style={{ alignItems: "center" }}>
                                                                <input type="checkbox" checked={el.cashCollectionBool} onChange={() => { setDeliveryCashCollectionBool(index, !el.cashCollectionBool) }} />
                                                                <span style={{ marginTop: 5, marginLeft: 8 }}> Cash Collection (Does this order includes cash collection)</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="field-group">
                                                            <input type="text" className="form-control" value={el.cashCollection} onChange={(e) => setDeliveryCashCollection(index, e.target.value)} placeholder="Enter Cash Collection" />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }


                                    <div className="row mt-4 d-flex justify-content-center">
                                        <div className="col-12 col-md-3">
                                            <div className="field-group">
                                                <button type="button" onClick={() => handleSubmit()} className="btn searchBttn"> Save </button>
                                            </div>
                                            {/* handleSubmit() */}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>


                </>

            </div>
        </main>
    )
}

export default AddOrder;
