import axios from 'axios';
import { url } from "./Url"


import jwt_decode from 'jwt-decode'
const serverUrl = `${url}/users`

export const globalLogin=async(formData)=>{
    try {
        const res=await axios.post(`${serverUrl}/globalLogin`,formData);
        return res
    } catch (error) {
        console.error(error)
        throw(error)
    }
}

export const decodeJwt=async()=>{
    let token=localStorage.getItem('AUTH_TOKEN');
    if(token)
        return await jwt_decode(token)
    else
        return false
}