import React, { useState, useEffect } from 'react';
import Select from 'react-select'
import { getAllDeliveryBoys, getMonthlyProductivityById } from '../../Services/DeliveryBoy';
import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import axios from 'axios'
import { url } from '../../Services/Url';
import { toast } from 'react-toastify';

function MonthlyProductivity() {
    const sortIcon = <ArrowDownward />;

    const [deliveryBoyArr, setDeliveryBoyArr] = useState([]);
    const [selectedDeliveryBoy, setSelectedDeliveryBoy] = useState({});

    const [deliveryBoyProductivityArr, setDeliveryBoyProductivityArr] = useState([]);

    const [loading, setLoading] = useState(false);
    const getDeliveryBoys = async () => {
        try {
            setLoading(true)
            const { data: res, status: statusCode } = await getAllDeliveryBoys();
            if (statusCode == 200 || statusCode == 304) {
                let tempArr = [...res.data.map(el => {
                    let obj = {
                        value: el._id,
                        label: el.name,
                        ...el
                    }
                    return obj
                })]
                setDeliveryBoyArr(tempArr)
                setSelectedDeliveryBoy(tempArr[0])
                getSpecificBoyProductivityData(tempArr[0].value)
                setLoading(false)

            }
        } catch (error) {
            console.error(error)
        }
    }

    const handleOnChange = (obj) => {
        setSelectedDeliveryBoy(obj)
        getSpecificBoyProductivityData(obj.value)
    }




    const getSpecificBoyProductivityData = async (id) => {

        let tempArr = new Array(12).fill().map(el => ({}));
        const year = new Date().getFullYear();
        for (let i = 0; i < 12; i++) {
            try {
                let { data: res, status: statusCode } = await axios.get(`${url}/effectiveSalary/getDeliveryBoySalarayDataForMonth/${id}/${year}/${i}`)
                if (statusCode == 200 || statusCode == 304) {
                    console.log(res.data)
                    let { data: response, status: responseStatus } = await axios.get(`${url}/effectiveSalary/getDeliveryBoySalarayDataWeekly/${id}/${year}/${i}`)
                    if (responseStatus == 200 || responseStatus == 304) {
                        res.data = { ...res.data, weeklyArr: response.data }
                    }
                    tempArr[i] = res.data;
                }
            } catch (error) {
                let obj = {
                    hours: 0,
                    km: 0,
                    targetAchStr: "NA",
                    // noOfIncentiveTargetsCompleted: 0,

                    noOfdeliveries: 0
                }
                tempArr[i] = obj


                console.error(error)
                if (error?.response?.data?.message)
                    toast.error(error?.response?.data?.message)
            }
            console.log([...tempArr])
            setDeliveryBoyProductivityArr([...tempArr])
        }


    }
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];


    const Columns = [
        {
            name: 'Months',
            selector: (row, index) => monthNames[index],
        },
        {
            name: 'Deliveries Done',
            selector: row => row.noOfdeliveries,
        },
        {
            name: 'No of hours',
            selector: row => row?.hours?.toFixed(2),
        },
        {
            name: 'Targets',
            selector: row => row.targetAchStr ? row.targetAchStr : "NA",
        },
        {
            name: 'Km',
            selector: row => row.km,
        },

    ];

    useEffect(() => {
        getDeliveryBoys()
    }, [])

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]
    return (
        <main className="main_content">
            <div className="userTabsDesign mb-4">
                <div className="filterSearchBar">
                    <form>

                        <div className="row mb-4">
                            <div className="col-12 col-md-12">
                                <div className="searchBar mb-4">
                                    <label>Select Delivery Boy</label>
                                    <Select options={deliveryBoyArr} onChange={handleOnChange} />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <br />
                <div className="perboyProductivity mb-4">
                    <div className="headingTitle"><h3> <b> Monthly Productivity</b></h3></div>
                    <div className="card">
                        <DataTable
                            title={`Boy Name - ${selectedDeliveryBoy.name}`}
                            columns={Columns}
                            data={deliveryBoyProductivityArr}
                            sortIcon={sortIcon}
                            progressPending={loading}

                            // keyField='_id'
                            pagination
                            expandableRows
                            expandableRowsComponent={({ data }) => {
                                return (
                                    <div style={{ padding: 30 }}>

                                        <DataTable

                                            columns={[
                                                {
                                                    name: 'Week Number',
                                                    selector: (row, index) => index+1,
                                                    sortable: true
                                                },

                                                {
                                                    name: 'Deliveries Done',
                                                    selector: row => row.noOfdeliveries,
                                                },
                                                {
                                                    name: 'No of hours',
                                                    selector: row => row.hours.toFixed(2),
                                                },

                                                {
                                                    name: 'Km',
                                                    selector: row => row.km,
                                                },

                                            ]}
                                            data={data?.weeklyArr}
                                        />
                                    </div>

                                )
                            }}
                        />
                    </div>
                </div>
                <br />

            </div>
        </main>
    )
}

export default MonthlyProductivity;