import axios from 'axios';
import { url } from "./Url"


const serverUrl = `${url}/packageOrder`


export const getAllPackageOrders = async() => {
    try {
        const res = await axios.get(`${serverUrl}/getAllPackageOrdersForAdmin`);
        return res
    } catch (error) {
        console.error(error)
    }
}
export const getAllPackageOrdersEarnings = async() => {
    try {
        const res = await axios.get(`${serverUrl}/getAllSoldPackagesForAdmin`);
        return res
    } catch (error) {
        console.error(error)
    }
}
export const getAllTopUpEarnings = async() => {
    try {
        const res = await axios.get(`${serverUrl}/getAllSoldTopups`);
        return res
    } catch (error) {
        console.error(error)
    }
}

