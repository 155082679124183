import axios from 'axios';
import { url } from "./Url"


const serverUrl = `${url}/subAdmin`


export const subAdminRegister = async(formData) => {
    try {
        const res = await axios.post(`${serverUrl}/subAdminRegister`, formData);
        return res
    } catch (error) {
        console.error(error)
        throw(error)
    }
}
export const subAdminStatusToggle = async(id,obj) => {
    try {
        const res = await axios.patch(`${serverUrl}/subAdminStatusToggle/${id}`, obj);
        return res
    } catch (error) {
        console.error(error)
    }
}

export const removeSubAdmin = async(id) => {
    try {
        const res = await axios.delete(`${serverUrl}/removeSubadmin/${id}`);
        return res
    } catch (error) {
        console.error(error)
    }
}

export const getAllSubAdmins = async () => {
    try {
        const res=await axios.get(`${serverUrl}/getAllSubAdmins`);
        return res
    } catch (error) {
        console.error(error)
    }
}

export const subAdminUpdate=async(id,obj)=>{
    try {
        const res = await axios.patch(`${serverUrl}/updateSubAdmin/${id}`,obj);
        return res
    } catch (error) {
        console.error(error)
    }
}