import React,{useState,useEffect,useContext} from 'react';
import { toast } from 'react-toastify';
import { loginAdmin } from '../../Services/admin';
import {AuthContext} from '../../App'
import { globalLogin } from '../../Services/users';
function LoginPage (){

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [isAuthorized, setIsAuthorized] = useContext(AuthContext);
    const handleLogin=async()=>{
        try {
            let obj={
                email,
                password
            }
            const {data:res,status:statusCode}=await globalLogin(obj);
            if(statusCode==200 || statusCode==304){
                localStorage.setItem('AUTH_TOKEN',res.token)
                setIsAuthorized(true)
            }
        } catch (error) {
            console.error(error)
            toast.error(error?.response?.data?.message)
            
        }
    }

    return(
        <main className="main_content">
            <div className="DashboardUserLogin">
                <div className="db_Login_section">
                    <div className="db_LR_Main_sec">
                        <div className="left_db_sec">
                                <div className="login_register_content">
                                    <div className="Form_heading">
                                        <h1>Welcome to Delivery App</h1>
                                        <p>Enter your email to login your account</p>
                                    </div>
                                    <div className="user_form">
                                        <form>
                                            <div className="field-group animatedLabel mb-4">
                                              <input onChange={(e)=>setEmail(e.target.value)} type="text" placeholder="Email id" className="form-control" />
                                             </div>
                                            <div className="field-group animatedLabel mb-1">
                                                <input type="text" onChange={(e)=>setPassword(e.target.value)} placeholder="Password" className="form-control" />
                                            </div>
                                            <div className="field-group theme_Clr_Links mb-4">
                                                <p className=" justify-content-end"><a href="/forgot-password">Forgot Password?</a></p>
                                            </div>
                                            <div className="field-group mt-5 mb-3">
                                                <button type="button" className="btn bigBttn searchBttn w-100" onClick={()=>handleLogin()}> LOGIN </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default LoginPage;