import React from 'react';


function ModifyTarget() {
    return (
        <main className="main_content">
            <div className="userTabsDesign mb-4">
                <div className="headingTitle"><h3> Modify Target </h3></div>
                <div className="card">
                    <div className="card-body">
                        <div className="AddModiForm w-100">
                            <form>
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="field-group">
                                            <input type="text" className="form-control" placeholder="Enter Month" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="field-group">
                                            <input type="text" className="form-control" placeholder="Enter Hour's Online" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="field-group">
                                            <input type="text" className="form-control" placeholder="Enter Deliveries" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="field-group">
                                            <input type="text" className="form-control" placeholder="Enter Km" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4 d-flex justify-content-center">
                                    <div className="col-12 col-md-3">
                                        <div className="field-group">
                                            <button type="button" className="btn searchBttn"> Save </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default ModifyTarget;