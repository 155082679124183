import React, { useEffect, useState, createContext, useMemo } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import LoginPage from "./Components/Login/LoginPage";
import Forgotpassword from "./Components/Login/Forgotpassword";
import Sidebar from "./Components/Common/Sidebar";
import MyInfo from "./Components/Info/MyInfo";
import Topbar from "./Components/Common/Topbar";
import User from "./Components/User/User";
import UserDetails from "./Components/User/UserDetails";
import StoreDetails from "./Components/User/StoreDetails";
import ModifyStoreDetails from "./Components/AdditionModification/ModifyStoreDetails";
import InfoOrders from "./Components/MyInfo/InfoOrders";
import ActiveOrder from "./Components/MyInfo/ActiveOrder";
import ActiveorderDetails from "./Components/MyInfo/ActiveorderDetails";
import InfodbOnline from "./Components/MyInfo/InfodbOnline";
import InfodbOffline from "./Components/MyInfo/InfodbOffline";
import InfoNewPackage from "./Components/MyInfo/InfoNewPackage";
import InfoTopUp from "./Components/MyInfo/InfoTopUp";
import InfoHistory from "./Components/MyInfo/InfoHistory";
import Packages from "./Components/CashFlow/Packages";
import PackageDetails from "./Components/CashFlow/PackageDetails";
import BalanceSheet from "./Components/CashFlow/BalanceSheet";
import Earning from "./Components/CashFlow/Earnings";
import Expenses from "./Components/CashFlow/Expenses";
import InfoNotDelivered from "./Components/MyInfo/InfoNotDelivered";
import DeliveryBoyInfo from "./Components/StaffManagement/DeliveryBoyInfo";
import MonthlyProductivity from "./Components/StaffManagement/MonthlyProductivity";
import InfoOrderDeliveries from "./Components/MyInfo/InfoOrderDeliveries";
import DeliveriesDetails from "./Components/MyInfo/DeliveriesDetails";
import VehicleTypes from "./Components/Category/VehicleTypes";
import VehicleTypeDetails from "./Components/Category/VehicleTypeDetails";
import ShopType from "./Components/Category/ShopType";
import SalaryStatus from "./Components/DeliveryBoy/SalaryStatus";
import IncentiveTarget from "./Components/DeliveryBoy/IncentiveTarget";
import Assigntarget from "./Components/DeliveryBoy/Assigntarget";
import Attendence from "./Components/DeliveryBoy/Attendence";
import BoyMapView from "./Components/DeliveryBoy/BoyMapView";
import SalesChart from "./Components/DeliveryBoy/SalesChart";
import UserLocation from "./Components/Map/UserLocation";
import DBLocation from "./Components/Map/DBLocation";
import TrackDeliveryMan from "./Components/Map/TrackDeliveryMan";
import Adduser from "./Components/AdditionModification/Adduser";
import AddOrder from "./Components/AdditionModification/AddOrder";
import AddPackage from "./Components/AdditionModification/AddPackage";
import AddTopUP from "./Components/AdditionModification/AddTopUP";
import AddVehicle from "./Components/AdditionModification/AddVehicle";
import AddShop from "./Components/AdditionModification/AddShop";
import AddTarget from "./Components/AdditionModification/AddTarget";
import ModifyTarget from "./Components/AdditionModification/ModifyTarget";
import AddIncentive from "./Components/AdditionModification/AddIncentive";
import ModifyIncentive from "./Components/AdditionModification/ModifyIncentive";
import ShopTypeDetails from "./Components/Category/ShopTypeDetails";
import AddDeliveryBoy from "./Components/AdditionModification/AddDeliveryBoy";
import ModifyDeliveryBoy from "./Components/AdditionModification/ModifyDeliveryBoy";
import "./App.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddState from "./Components/AdditionModification/AddState";
import AddArea from "./Components/AdditionModification/AddArea";
import AddCity from "./Components/AdditionModification/AddCity";
import SpecificIncentiveTarget from "./Components/DeliveryBoy/SpecificIncentiveTarget";
import AddBusinessProducts from "./Components/User/AddBusinessProducts";
import ViewBusinessProduct from "./Components/User/ViewBusinessProduct";
import { DeliveryBoyDetails } from "./Components/StaffManagement/DeliveryBoyDetails";
import { AttendanceDetails } from "./Components/DeliveryBoy/AttendanceDetails";
import { ProtectedRoute } from "./auth/ProtectedRoute";
import { UnProtectedRoute } from "./auth/UnprotectedRoute";
import DeliveryBoyRequest from "./Components/DeliveryBoy/DeliveryBoyRequest";
import AddExpenseType from "./Components/AdditionModification/AddExpenseType";
import AddExpense from "./Components/AdditionModification/AddExpense";
import SubAdmin from "./Components/AdditionModification/SubAdmin";
import jwt_decode from "jwt-decode";

import axios from "axios";

import { routes } from "./routes";
import privacy from "./Components/Info/privacy";
export const AuthContext = createContext();
export const jwtContext = createContext();

function App() {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [decodedObj, setDecodedObj] = useState({});
  const checkAuthorized = () => {
    let token = localStorage.getItem("AUTH_TOKEN");
    if (token) {
      let tempDecodedObj = jwt_decode(token);
      if (tempDecodedObj) setDecodedObj(tempDecodedObj);

      setIsAuthorized(true);
    } else {
      setIsAuthorized(false);
    }
  };

  useMemo(() => {
    axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("AUTH_TOKEN");
        if (token) {
          config.headers["Authorization"] = "Bearer " + token;
        }
        // config.headers['Content-Type'] = 'application/json';
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
    axios.interceptors.response.use(
      (res) => {
        // Add configurations here
        return res;
      },
      (err) => {
        if (err?.response?.status === 401) {
          // console.log("ONLY IN ERROR")
          // toast.error('401 Unauthorized')
          localStorage.removeItem("AUTH_TOKEN");
          setIsAuthorized(false);
        }
        return Promise.reject(err);
      }
    );
  }, []);

  useEffect(() => {
    checkAuthorized();
  }, []);

  return (
    <AuthContext.Provider value={[isAuthorized, setIsAuthorized]}>
      <jwtContext.Provider vlaue={[decodedObj, setDecodedObj]}>
        <BrowserRouter>
          {isAuthorized && (
            <>
              <Sidebar role={decodedObj?.role} />
              <Topbar />
            </>
          )}

          <Switch>
            {isAuthorized ? (
              <>
                {decodedObj.role == "admin" ? (
                  routes
                    .filter((el) => el.protected && (el?.access == "admin" || el?.access == "both"))
                    .map((el) => {
                      return <ProtectedRoute path={el.path} component={el.component} />;
                    })
                ) : (
                  <>
                    {routes
                      .filter((el) => el.protected && (el.access == "subadmin" || el.access == "both"))
                      .map((el) => {
                        return <ProtectedRoute path={el.path} component={el.component} />;
                      })}
                  </>
                )}
              </>
            ) : (
              <>
                {routes
                  .filter((el) => !el.protected)
                  .map((el) => {
                    return <UnProtectedRoute path={el.path} component={el.component} />;
                  })}
                <Route path="/privacy" component={privacy} />
              </>
            )}
          </Switch>
          {/* <Switch>
            {(isAuthorized && (decodedObj.role == "admin")) &&
              <>
                <ProtectedRoute component={MyInfo} path="/" />
                <ProtectedRoute path="/user" component={User} />
                <ProtectedRoute path="/subAdmins" component={SubAdmin} />
                <ProtectedRoute path="/user-details" component={UserDetails} />
                <ProtectedRoute path="/add-expenseType" component={AddExpenseType} />
                <ProtectedRoute path="/add-expense" component={AddExpense} />
                <ProtectedRoute path="/store-details" component={StoreDetails} />
                <ProtectedRoute path="/deliveryBoyApplications" component={DeliveryBoyRequest} />
                <ProtectedRoute path="/modify-store-details" component={ModifyStoreDetails} />
                <ProtectedRoute path="/info/Orders" component={InfoOrders} />
                <ProtectedRoute path="/info/history" component={InfoHistory} />
                <ProtectedRoute path="/active-orders" component={ActiveOrder} />
                <ProtectedRoute path="/active-order-details" component={ActiveorderDetails} />
                <ProtectedRoute path="/delivery-boy-online" component={InfodbOnline} />
                <ProtectedRoute path="/Attendance-Details/:id" component={AttendanceDetails} />
                <ProtectedRoute path="/delivery-boy-Details/:id" component={DeliveryBoyDetails} />
                <ProtectedRoute path="/delivery-boy-offline" component={InfodbOffline} />
                <ProtectedRoute path="/new-packages" component={InfoNewPackage} />
                <ProtectedRoute path="/top-ups" component={InfoTopUp} />
                <ProtectedRoute path="/delivery-boy-info" component={DeliveryBoyInfo} />
                <ProtectedRoute path="/monthly-productivity" component={MonthlyProductivity} />
                <ProtectedRoute path="/info/not-delivered" component={InfoNotDelivered} />
                <ProtectedRoute path="/info/deliveries" component={InfoOrderDeliveries} />
                <ProtectedRoute path="/order-deliveries" component={DeliveriesDetails} />
                <ProtectedRoute path="/category/vehicle-type" component={VehicleTypes} />
                <ProtectedRoute path="/vehicle-type-details/:id" component={VehicleTypeDetails} />
                <ProtectedRoute path="/category/shop-type" component={ShopType} />
                <ProtectedRoute path="/earnings" component={Earning} />
                <ProtectedRoute path="/expenses" component={Expenses} />
                <ProtectedRoute path="/packages" component={Packages} />
                <ProtectedRoute path="/deliveryboy/salary-status" component={SalaryStatus} />
                <ProtectedRoute path="/incentive-target" component={IncentiveTarget} />
                <ProtectedRoute path="/incentive-targets/:id" component={SpecificIncentiveTarget} />
                <ProtectedRoute path="/attendence" component={Attendence} />
                <ProtectedRoute path="/BoyMapView" component={BoyMapView} />
                <ProtectedRoute path="/assign-target" component={Assigntarget} />
                <ProtectedRoute path="/map/user-location" component={UserLocation} />
                <ProtectedRoute path="/map/delivery-boy-location" component={DBLocation} />
                <ProtectedRoute path="/map/track-delivery-man" component={TrackDeliveryMan} />
                <ProtectedRoute path="/package-details" component={PackageDetails} />
                <ProtectedRoute path="/balance-sheet" component={BalanceSheet} />
                <ProtectedRoute path="/add-user" component={Adduser} />
                <ProtectedRoute path="/add-state" component={AddState} />
                <ProtectedRoute path="/add-shop" component={AddShop} />
                <ProtectedRoute path="/add-city" component={AddCity} />
                <ProtectedRoute path="/add-area" component={AddArea} />
                <ProtectedRoute path="/add-order" component={AddOrder} />
                <ProtectedRoute path="/add-package" component={AddPackage} />
                <ProtectedRoute path="/add-topup" component={AddTopUP} />
                <ProtectedRoute path="/add-vehicle" component={AddVehicle} />
                <ProtectedRoute path="/add-target" component={AddTarget} />
                <ProtectedRoute path="/modify-target" component={ModifyTarget} />
                <ProtectedRoute path="/add-incentive" component={AddIncentive} />
                <ProtectedRoute path="/modify-incentive" component={ModifyIncentive} />
                <ProtectedRoute path="/shop-type-details/:id" component={ShopTypeDetails} />
                <ProtectedRoute path="/add-delivery-boy" component={AddDeliveryBoy} />
                <ProtectedRoute path="/modify-delivery-boy" component={ModifyDeliveryBoy} />
                <ProtectedRoute path="/AddBusinessProducts/:id" component={AddBusinessProducts} />
                <ProtectedRoute path="/ViewBusinessProduct/:id" component={ViewBusinessProduct} />
              </>
            } */}

          {/* {
              (isAuthorized && (decodedObj.role == "subadmin")) &&
              <>
                <ProtectedRoute path="/info/Orders" component={InfoOrders} />

              </>
            }
            {
              !isAuthorized &&
              <>
                <UnProtectedRoute path="/" exact component={LoginPage} />
                <UnProtectedRoute path="/forgot-password" component={Forgotpassword} />
              </>
            } */}

          {/* </Switch> */}
          <ToastContainer />
        </BrowserRouter>
      </jwtContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
