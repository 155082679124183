import axios from 'axios';
import { url } from "./Url"



const serverUrl = `${url}/target`

export const getTargets = async () => {
    try {
        const { data: res } = await axios.get(`${serverUrl}/`);
        return res;
    }
    catch (err) {
        console.log(err);
        return err.message
    }
}



export const UpdateTargets = async (id, obj) => {
    try {
        const { data: res } = await axios.patch(`${serverUrl}/updateTarget/${id}`, obj);
        return res;
    }
    catch (err) {
        console.log(err);
        return err.message
    }
}


