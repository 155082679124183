import React from 'react'
import LoginPage from './Components/Login/LoginPage';
import Forgotpassword from './Components/Login/Forgotpassword';
import Sidebar from './Components/Common/Sidebar';
import MyInfo from './Components/Info/MyInfo';
import Topbar from './Components/Common/Topbar';
import User from './Components/User/User';
import UserDetails from './Components/User/UserDetails';
import StoreDetails from './Components/User/StoreDetails';
import ModifyStoreDetails from './Components/AdditionModification/ModifyStoreDetails';
import InfoOrders from './Components/MyInfo/InfoOrders';
import ActiveOrder from './Components/MyInfo/ActiveOrder';
import ActiveorderDetails from './Components/MyInfo/ActiveorderDetails';
import InfodbOnline from './Components/MyInfo/InfodbOnline';
import InfodbOffline from './Components/MyInfo/InfodbOffline';
import InfoNewPackage from './Components/MyInfo/InfoNewPackage';
import InfoTopUp from './Components/MyInfo/InfoTopUp';
import InfoHistory from './Components/MyInfo/InfoHistory';
import Packages from './Components/CashFlow/Packages';
import PackageDetails from './Components/CashFlow/PackageDetails';
import BalanceSheet from './Components/CashFlow/BalanceSheet';
import Earning from './Components/CashFlow/Earnings';
import Expenses from './Components/CashFlow/Expenses';
import InfoNotDelivered from './Components/MyInfo/InfoNotDelivered';
import DeliveryBoyInfo from './Components/StaffManagement/DeliveryBoyInfo';
import MonthlyProductivity from './Components/StaffManagement/MonthlyProductivity';
import InfoOrderDeliveries from './Components/MyInfo/InfoOrderDeliveries';
import DeliveriesDetails from './Components/MyInfo/DeliveriesDetails';
import VehicleTypes from './Components/Category/VehicleTypes';
import VehicleTypeDetails from './Components/Category/VehicleTypeDetails';
import ShopType from './Components/Category/ShopType';
import SalaryStatus from './Components/DeliveryBoy/SalaryStatus';
import IncentiveTarget from './Components/DeliveryBoy/IncentiveTarget';
import Assigntarget from './Components/DeliveryBoy/Assigntarget';
import Attendence from './Components/DeliveryBoy/Attendence';
import BoyMapView from './Components/DeliveryBoy/BoyMapView';
import SalesChart from './Components/DeliveryBoy/SalesChart';
import UserLocation from './Components/Map/UserLocation';
import DBLocation from './Components/Map/DBLocation';
import TrackDeliveryMan from './Components/Map/TrackDeliveryMan';
import Adduser from './Components/AdditionModification/Adduser';
import AddOrder from './Components/AdditionModification/AddOrder';
import AddPackage from './Components/AdditionModification/AddPackage';
import AddTopUP from './Components/AdditionModification/AddTopUP';
import AddVehicle from './Components/AdditionModification/AddVehicle';
import AddShop from './Components/AdditionModification/AddShop';
import AddTarget from './Components/AdditionModification/AddTarget';
import ModifyTarget from './Components/AdditionModification/ModifyTarget';
import AddIncentive from './Components/AdditionModification/AddIncentive';
import ModifyIncentive from './Components/AdditionModification/ModifyIncentive';
import ShopTypeDetails from './Components/Category/ShopTypeDetails';
import AddDeliveryBoy from './Components/AdditionModification/AddDeliveryBoy';
import ModifyDeliveryBoy from './Components/AdditionModification/ModifyDeliveryBoy';
import AddState from './Components/AdditionModification/AddState';
import AddArea from './Components/AdditionModification/AddArea';
import AddCity from './Components/AdditionModification/AddCity';
import SpecificIncentiveTarget from './Components/DeliveryBoy/SpecificIncentiveTarget';
import AddBusinessProducts from './Components/User/AddBusinessProducts';
import ViewBusinessProduct from './Components/User/ViewBusinessProduct';
import { DeliveryBoyDetails } from './Components/StaffManagement/DeliveryBoyDetails';
import { AttendanceDetails } from './Components/DeliveryBoy/AttendanceDetails';
import DeliveryBoyRequest from './Components/DeliveryBoy/DeliveryBoyRequest';
import AddExpenseType from './Components/AdditionModification/AddExpenseType';
import AddExpense from './Components/AdditionModification/AddExpense';
import SubAdmin from './Components/AdditionModification/SubAdmin';
import Notifcation from './Components/Notifications/Notifcation';


///access ex admin,subadmin,both

export const routes = [
    {
        name: 'login',
        component: LoginPage,
        access: 'both',
        path: '/',
        protected:false
    },
    // {
    //     name: 'login',
    //     component: LoginPage,
    //     access: 'both',
    //     path: '*',
    //     protected:false
    // },
    {
        name: 'dashboard',
        component: MyInfo,
        access: 'both',
        path: '/',
        protected:true

    },
    {
        name: 'subadmins',
        component:SubAdmin,
        access:'admin',
        path:'/subAdmins',
        protected:true
    },
    {
        name: 'user-details',
        component:UserDetails,
        access:'admin',
        path:'/user-details/:id',
        protected:true
    },
    {
        name: 'add-expenseType',
        component:AddExpenseType,
        access:'admin',
        path:'/add-expenseType',
        protected:true
    },
    {
        name: 'add-expense',
        component:AddExpense,
        access:'both',
        path:'/add-expense',
        protected:true
    },
    {
        name: 'store-details',
        component:StoreDetails,
        access:'admin',
        path:'/store-details',
        protected:true
    },
    {
        name: 'deliveryBoyApplications',
        component:DeliveryBoyRequest,
        access:'both',
        path:'/deliveryBoyApplications',
        protected:true
    },
    {
        name: 'modify-store-details',
        component:ModifyStoreDetails,
        access:'admin',
        path:'/modify-store-details',
        protected:true
    },
    {
        name: 'info/Orders',
        component:InfoOrders,
        access:'both',
        path:'/info/Orders',
        protected:true
    },
    {
        name: '/info/history',
        component:InfoHistory,
        access:'admin',
        path:'/info/history',
        protected:true
    },
    {
        name: 'active-orders',
        component:ActiveOrder,
        access:'admin',
        path:'/active-orders',
        protected:true
    },
    {
        name: 'active-order-details',
        component:ActiveorderDetails,
        access:'admin',
        path:'/active-order-details',
        protected:true
    },
    {
        name: 'delivery-boy-online',
        component:InfodbOnline,
        access:'admin',
        path:'/delivery-boy-online',
        protected:true
    },
    {
        name: 'Attendance-Details/:id',
        component:AttendanceDetails,
        access:'admin',
        path:'/Attendance-Details/:id',
        protected:true
    },
    {
        name: 'delivery-boy-Details/:id',
        component:DeliveryBoyDetails,
        access:'admin',
        path:'/delivery-boy-Details/:id',
        protected:true
    },
    {
        name: 'delivery-boy-offline',
        component:InfodbOffline,
        access:'admin',
        path:'/delivery-boy-offline',
        protected:true
    },
    {
        name: 'new-packages',
        component:InfoNewPackage,
        access:'admin',
        path:'/new-packages',
        protected:true
    },
    {
        name: 'top-ups',
        component:InfoTopUp,
        access:'admin',
        path:'/top-ups',
        protected:true
    },
    {
        name: 'delivery-boy-info',
        component:DeliveryBoyInfo,
        access:'both',
        path:'/delivery-boy-info',
        protected:true
    },
    {
        name: 'monthly-productivity',
        component:MonthlyProductivity,
        access:'both',
        path:'/monthly-productivity',
        protected:true
    },
    {
        name: 'info/not-delivered',
        component:InfoNotDelivered,
        access:'admin',
        path:'/info/not-delivered',
        protected:true
    },
    {
        name: 'info/deliveries',
        component:InfoOrderDeliveries,
        access:'admin',
        path:'/info/deliveries',
        protected:true
    },
    {
        name: 'order-deliveries',
        component:DeliveriesDetails,
        access:'admin',
        path:'/order-deliveries',
        protected:true
    },
    {
        name: 'category/vehicle-type',
        component:VehicleTypes,
        access:'admin',
        path:'/category/vehicle-type',
        protected:true
    },
    {
        name: 'vehicle-type-details/:id',
        component:VehicleTypeDetails,
        access:'admin',
        path:'/vehicle-type-details/:id',
        protected:true
    },
    {
        name: 'category/shop-type',
        component:ShopType,
        access:'admin',
        path:'/category/shop-type',
        protected:true
    },
    {
        name: 'earnings',
        component:Earning,
        access:'both',
        path:'/earnings',
        protected:true
    },
    {
        name: 'expenses',
        component:Expenses,
        access:'both',
        path:'/expenses',
        protected:true
    },
    {
        name: 'packages',
        component:Packages,
        access:'both',
        path:'/packages',
        protected:true
    },
    {
        name: 'deliveryboy/salary-status',
        component:SalaryStatus,
        access:'admin',
        path:'/deliveryboy/salary-status',
        protected:true
    },
    {
        name: 'user',
        component:User,
        access:'both',
        path:'/user',
        protected:true
    },
    {
        name: 'deliveryboy/salary-status',
        component:SalaryStatus,
        access:'admin',
        path:'/deliveryboy/salary-status',
        protected:true
    },
    {
        name: 'incentive-target',
        component:IncentiveTarget,
        access:'both',
        path:'/incentive-target',
        protected:true
    },
    {
        name: 'incentive-targets/:id',
        component:SpecificIncentiveTarget,
        access:'both',
        path:'/incentive-targets/:id',
        protected:true
    },
    {
        name: 'attendence',
        component:Attendence,
        access:'both',
        path:'/attendence',
        protected:true
    },
    {
        name: 'BoyMapView',
        component:BoyMapView,
        access:'admin',
        path:'/BoyMapView',
        protected:true
    },
    {
        name: 'assign-target',
        component:Assigntarget,
        access:'both',
        path:'/assign-target',
        protected:true
    },
    {
        name: 'map/user-location',
        component:UserLocation,
        access:'admin',
        path:'/map/user-location',
        protected:true
    },
    {
        name: 'map/delivery-boy-location',
        component:DBLocation,
        access:'admin',
        path:'/map/delivery-boy-location',
        protected:true
    },
    {
        name: 'map/track-delivery-man',
        component:DBLocation,
        access:'admin',
        path:'/map/track-delivery-man',
        protected:true
    },
    {
        name: 'package-details',
        component:PackageDetails,
        access:'admin',
        path:'/package-details',
        protected:true
    },
    {
        name: 'balance-sheet',
        component:BalanceSheet,
        access:'admin',
        path:'/balance-sheet',
        protected:true
    },
    {
        name: 'add-user',
        component:Adduser,
        access:'admin',
        path:'/add-user',
        protected:true
    },
    {
        name: 'add-state',
        component:AddState,
        access:'admin',
        path:'/add-state',
        protected:true
    },
    {
        name: 'add-shop',
        component:AddShop,
        access:'admin',
        path:'/add-shop',
        protected:true
    },
    {
        name: 'add-city',
        component:AddCity,
        access:'admin',
        path:'/add-city',
        protected:true
    },
    {
        name: 'add-area',
        component:AddArea,
        access:'admin',
        path:'/add-area',
        protected:true
    },
    {
        name: 'add-order',
        component:AddOrder,
        access:'admin',
        path:'/add-order',
        protected:true
    },
    {
        name: 'add-package',
        component:AddPackage,
        access:'admin',
        path:'/add-package',
        protected:true
    },
    {
        name: 'add-topup',
        component:AddTopUP,
        access:'admin',
        path:'/add-topup',
        protected:true
    },
    {
        name: 'add-vehicle',
        component:AddVehicle,
        access:'admin',
        path:'/add-vehicle',
        protected:true
    },
    {
        name: 'add-target',
        component:AddTarget,
        access:'both',
        path:'/add-target',
        protected:true
    },
    {
        name: 'modify-target',
        component:ModifyTarget,
        access:'admin',
        path:'/modify-target',
        protected:true
    },
    {
        name: 'add-incentive',
        component:AddIncentive,
        access:'both',
        path:'/add-incentive',
        protected:true
    },
    {
        name: 'modify-incentive',
        component:ModifyIncentive,
        access:'admin',
        path:'/modify-incentive',
        protected:true
    },
    
    {
        name: 'shop-type-details/:id',
        component:ShopTypeDetails,
        access:'admin',
        path:'/shop-type-details/:id',
        protected:true
    },
    {
        name: 'add-delivery-boy',
        component:AddDeliveryBoy,
        access:'both',
        path:'/add-delivery-boy',
        protected:true
    },
    {
        name: 'modify-delivery-boy',
        component:ModifyDeliveryBoy,
        access:'admin',
        path:'/modify-delivery-boy',
        protected:true
    },
    {
        name: 'AddBusinessProducts/:id',
        component:AddBusinessProducts,
        access:'both',
        path:'/AddBusinessProducts/:id',
        protected:true
    },
    {
        name: 'ViewBusinessProduct/:id',
        component:ViewBusinessProduct,
        access:'both',
        path:'/ViewBusinessProduct/:id',
        protected:true
    },
    {
        name: 'Notifications',
        component:Notifcation,
        access:'both',
        path:'/notification',
        protected:true
    },
]