import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { url } from '../../Services/Url'
import { ToastContainer, toast } from 'react-toastify';
import MaterialTable from 'material-table';
import { getAreaByPincode } from '../../Services/area';
import { useHistory } from 'react-router-dom';



function Adduser() {
    const history=useHistory()
    const [name, setName] = useState("");
    const [contactPerson, setContactPerson] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [landMark, setLandMark] = useState("");
    const [pinCode, setPinCode] = useState("");
    const [password, setPassword] = useState("");
    const [phone, setPhone] = useState("");
    const [gst, setGst] = useState("");
    const [area, setArea] = useState("");
    const [email, setEmail] = useState("");
    const [typeOfBusiness, setTypeOfBusiness] = useState("");
    const [packageId, setPackageId] = useState("");
    const [approvalStatus, setApprovalStatus] = useState(0);
    const [userType, setUserType] = useState(0);
    const [toggle, setToggle] = useState(false);


    const [stateArr, setStateArr] = useState();
    const [cityArr, setCityArr] = useState();
    const [areaArr, setAreaArr] = useState();
    const [userArr, setUserArr] = useState();
    const [packageArr, setPackageArr] = useState();
    const [shoptypeArr, setShoptypeArr] = useState();
    const [cityFilteredArr, setCityFilteredArr] = useState();
    const [areaFilteredArr, setAreaFilteredArr] = useState();
    const [selectedStateId, setSelectedStateId] = useState("");
    const [selectedCityId, setSelectedCityId] = useState("");




    const handleSubmit = async () => {
        try {
           
            if(name!="" && addressLine1!="" && addressLine2!="" && landMark!="" && pinCode!="" && phone!="" && password!="" && area!="" && selectedCityId!="" && selectedStateId!="" && typeOfBusiness!="") {
                if (userType == 1) {
                    let obj = {
                        name: name,
                        addressLine1: addressLine1,
                        addressLine2: addressLine2,
                        landMark: landMark,
                        contactPerson: name,
                        pincode: pinCode,
                        mobile: phone,
                        password: password,
                        areaId: area,
                        cityId: selectedCityId,
                        stateId: selectedStateId,
                        email: email,
                        businessType: typeOfBusiness,
                        date: new Date(),
                        approvalStatus: approvalStatus,
                        userType: userType
                    }

                    console.log(obj)
                    let { data: res } = await axios.post(`${url}/user/`, obj)
                    if (res.success) {
                        toast.success(res.message)
                        // alert(res.message)
                        getUsers()
                    }
                }
                else {
                    let obj = {
                        name: name,
                        addressLine1: addressLine1,
                        addressLine2: addressLine2,
                        landMark: landMark,
                        contactPerson: contactPerson,
                        pincode: pinCode,
                        mobile: phone,
                        password: password,
                        gstNumber: gst,
                        areaId: area,
                        cityId: selectedCityId,
                        stateId: selectedStateId,
                        email: email,
                        businessType: typeOfBusiness,
                        date: new Date(),
                        approvalStatus: 0,
                        userType: userType
                    }

                    console.log(obj)
                    let { data: res } = await axios.post(`${url}/user/`, obj)
                    if (res.success) {
                        toast.success(res.message)
                        // alert(res.message)
                    }
                }
            }
            else{
                alert("Please Fill all the fields")
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }

    }


    const getUsers = async () => {
        try {
            let { data: res } = await axios.get(`${url}/user/`)
            if (res.success) {
                // let tempCityArr = res.data.filter(el => el.active == true && el.stateActive == true);
                setUserArr(res.data);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }


    


    // const getArea = async () => {
    //     try {
    //         let { data: res } = await axios.get(`${url}/area/`)
    //         if (res.success) {
    //             console.log(res.data)
    //             let tempAreaArr = res.data.filter(el => el.active == true && el.stateActive == true && el.cityActive == true);
    //             console.log(tempAreaArr)
    //             // toast.success(res.message)
    //             setAreaArr(tempAreaArr);
    //         }
    //     } catch (error) {
    //         toast.error(error?.response?.data?.message)
    //         console.log(error.response, "errors")
    //     }
    // }


    

    const getShopType = async () => {
        try {
            let { data: res } = await axios.get(`${url}/shopType/`)
            if (res.success) {
                console.log(res.data, "shop type")
                setShoptypeArr(res.data);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }

    const handlePincodeSelect = async(val) => {
        try {
            if (`${val}`.length == 6) {
                const { data: res, status: statusCode } = await getAreaByPincode(val);
                if (statusCode == 200 || statusCode == 304) {
                    setAreaArr(res.data)
                }
            }
            setPinCode(val)
        } catch (error) {
            console.log(error)
            alert(error)
        }
    }


    const handleAreaSelect=(val)=>{
        let areaObj=areaArr.find(el=>el._id==val);
        setSelectedStateId(areaObj.stateId);
        setSelectedCityId(areaObj.cityId);
        setArea(val)
    }



    useEffect(() => {
        getShopType()
    }, [])



    return (
        <main className="main_content">
            <div className="userTabsDesign mb-4">

                <>
                    <div className="headingTitle" style={{ marginBottom: 20 }}><h3> Add User </h3> <button onClick={()=>history.push('/user')}  className="btn addModifiBttn">View User</button></div>
                    <div className="card">
                        <div className="card-body">
                            <div className="AddModiForm w-100">
                                <form>
                                    <label htmlFor="">User Type Selection</label>
                                    <div className="row ">
                                        <div className="col-12 mb-2 col-md-12">
                                            <div className="field-group">
                                                <button type="button" className={`btn ${userType == 0 ? "greenBttn" : "addModifiBttn"}`} onClick={() => setUserType(0)}>Business Use</button>
                                                <button type="button" className={`btn ${userType == 1 ? "greenBttn" : "addModifiBttn"}`} onClick={() => setUserType(1)}>Personal Use</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <label>Name</label>
                                                {
                                                    userType == 0
                                                        ?
                                                        <input type="text" onChange={(event) => setName(event.target.value)} className="form-control" placeholder="Enter Shop Name (Establishment Name)" />
                                                        :
                                                        <input type="text" onChange={(event) => setName(event.target.value)} className="form-control" placeholder="Enter Your Name" />
                                                }
                                            </div>
                                        </div>
                                        {
                                            userType == 0 &&
                                            <div className="col-12 col-md-6">

                                                <div className="field-group">
                                                    <label>Contact Person</label>
                                                    <input type="text" onChange={(event) => setContactPerson(event.target.value)} className="form-control" placeholder="Enter Contact Name" />
                                                </div>
                                            </div>
                                        }
                                        <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <label>Address Line 1</label>
                                                <input type="text" onChange={(event) => setAddressLine1(event.target.value)} className="form-control" placeholder="Enter Address Line 1" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <label>Address Line 2</label>
                                                <input type="text" onChange={(event) => setAddressLine2(event.target.value)} className="form-control" placeholder="Enter Address Line 2" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <label>Landmark</label>
                                                <input type="text" onChange={(event) => setLandMark(event.target.value)} className="form-control" placeholder="Enter Landmark" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <label>Pincode</label>
                                                <input type="number" onChange={(event) => handlePincodeSelect(event.target.value)} maxLength={6} className="form-control" placeholder="Enter Pincode" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <label>Phone</label>
                                                <input type="number" onChange={(event) => setPhone(event.target.value)} className="form-control" placeholder="Enter Mobile No." />
                                            </div>
                                        </div>
                                        {
                                            userType == 0 &&
                                            <div className="col-12 col-md-6">
                                                <div className="field-group">
                                                    <label>GST Number</label>
                                                    <input type="text" onChange={(event) => setGst(event.target.value)} className="form-control" placeholder="Enter GST No." />
                                                </div>
                                            </div>
                                        }
                                        <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <label>Email</label>
                                                <input type="text" onChange={(event) => setEmail(event.target.value)} className="form-control" placeholder="Enter Email Address" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <label>Password</label>
                                                <input type="text" onChange={(event) => setPassword(event.target.value)} className="form-control" placeholder="Enter Password" />
                                            </div>
                                        </div>

                                        {/* <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <select name="" id="" className="form-control" onChange={(event) => { setCityArrFromState(event.target.value) }}>
                                                    <option value="">Please Select a State</option>
                                                    {
                                                        stateArr && stateArr.length > 0 && stateArr.map(el => {
                                                            return (
                                                                <option key={el._id} value={el._id}>{el.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <select name="" id="" className="form-control" onChange={(event) => { setAreaArrFromCity(event.target.value) }}>
                                                    <option value="">Please Select a City</option>
                                                    {
                                                        cityFilteredArr && cityFilteredArr.length > 0 && cityFilteredArr.map(el => {
                                                            return (
                                                                <option key={el._id} value={el._id}>{el.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div> */}

                                        <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <label>Select Area</label>
                                                <select name="" id="" className="form-control" onChange={(event) => { handleAreaSelect(event.target.value) }}>
                                                    <option value="">Please Select a Area</option>
                                                    {
                                                        areaArr && areaArr.length > 0 && areaArr.map(el => {
                                                            return (
                                                                <option key={el._id} value={el._id}>{el.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>

                                       { userType == 0 &&
                                       
                                        <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <label>Select Business Type</label>
                                                <select name="" id="" className="form-control" onChange={(event) => { setTypeOfBusiness(event.target.value) }}>
                                                    <option value="">Please Select your Business Type</option>
                                                    {
                                                        shoptypeArr && shoptypeArr.length > 0 && shoptypeArr.map(el => {
                                                            return (
                                                                <option key={el._id} value={el._id}>{el.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                       }


                                        

                                        {/* <div className="col-12 col-md-6">
                                            <label >Approval status</label>
                                            <div className="field-group">
                                                <button type="button" className={`btn ${approvalStatus == 0 ? "greenBttn" : "addModifiBttn"}`} onClick={() => setApprovalStatus(0)}> Approved </button>
                                                <button type="button" className={`btn ${approvalStatus == 1 ? "greenBttn" : "addModifiBttn"}`} onClick={() => setApprovalStatus(1)}> Not Approved </button>


                                            </div>
                                        </div> */}

                                    </div>
                                    <div className="row mt-4 d-flex justify-content-center">
                                        <div className="col-12 col-md-3">
                                            <div className="field-group">
                                                <button type="button" className="btn searchBttn" onClick={() => handleSubmit()}> Save </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </>

            </div>
        </main >
    )
}

export default Adduser;
