import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { url } from '../../Services/Url'
import { ToastContainer, toast } from 'react-toastify';
import MaterialTable from 'material-table';

import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { deletePackageById, updatePackageById } from '../../Services/Packages';


function AddPackage(props) {
    const sortIcon = <ArrowDownward />;

    const [area, setArea] = useState("");

    const [packageType, setPackageType] = useState();
    const [name, setName] = useState("");
    const [value, setValue] = useState("");
    const [maxWeightPerOrder, setMaxWeightPerOrder] = useState("");
    const [deliveriesLeft, setdeliveriesLeft] = useState(0);
    const [maxDistance, setMaxDistance] = useState("");
    const [perDayCap, setPerDayCap] = useState(0);
    const [maxDistancePerOrder, setMaxDistancePerOrder] = useState("");
    const [packageArr, setpackageArr] = useState();
    const [displayPackageArr, setDisplayPackageArr] = useState();

    const [toggle, setToggle] = useState(false);

    const [packageFor, setPackageFor] = useState(0);//0 for business 1 for household

    const [selectedPackageObj, setSelectedPackageObj] = useState({});
    // const [searchName, setSearchName] = useState('')
    const [nameSelected, setNameSelected] = useState('');
    const [packageSelectedType, setPackageSelectedType] = useState('');
    const [packageSelectedFor, setPackageSelectedFor] = useState('');
    const [selectedMaxDistance, setSelectedMaxDistance] = useState('');
    const [selectedPerDay, setSelectedPerDay] = useState('')
    const getPackage = async () => {
        try {
            let { data: res } = await axios.get(`${url}/packages/getForAdmin`)
            if (res.success) {
                console.log(res.data, "packages")
                setpackageArr(res.data);
                setDisplayPackageArr(res.data);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }




    const handleSubmit = async () => {
        try {
            // if (selectedStateId == "") {
            //     alert("state must be selected")
            // }
            // else if (selectedCityId == "") {
            //     alert("city must be selected")
            // }
            // else if (area == "") {
            //     alert("area must be selected")
            // }
            console.log(packageType)
            if (name != "" && packageType != "" && value != "" && maxDistancePerOrder != "" && maxWeightPerOrder != "" && deliveriesLeft != "" && perDayCap != "") {

                let obj = {
                    name: name,

                    packageType: packageType,
                    value: value,
                    maxDistancePerOrder,
                    maxWeightPerOrder: maxWeightPerOrder,
                    totalDeliveries: deliveriesLeft,
                    // maxDistance: maxDistance,
                    perDayCap: perDayCap,
                    packageFor
                }
                let { data: res } = await axios.post(`${url}/packages/`, obj)
                if (res.success) {
                    toast.success(res.message)
                    getPackage()
                }
            }
            else {
                alert("Please Fill all the fields")
            }
            // }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }

    }


    const handleUpdatePackageById = async () => {
        try {

            const { data: res, status: statusCode } = await updatePackageById(selectedPackageObj);
            if (statusCode == 200 || statusCode == 304) {
                alert(res.message)
                getPackage()
            }
        } catch (error) {
            console.error(error)
        }
    }

    const handlePackageDelete = async (id) => {
        try {
            let checkConfirm = window.confirm("Do you really want to delete ?")
            console.log(checkConfirm)
            const { data: res, status: statusCode } = await deletePackageById(id);
            if (statusCode == 200 || statusCode == 304) {
                alert(res.message)
                getPackage()
            }
        } catch (error) {
            console.error(error)
        }
    }

    const AddPackageColumns = [
        {
            name: 'S.No',
            selector: (row, index) => <span> {index + 1} </span>,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => <span>{row.name}</span>,
            sortable: true,
        },

        {
            name: 'Package Type',
            selector: rowData => <div>{rowData.packageType == 0 ? "1 month" : rowData.packageType == 1 ? "3 Month" : rowData.packageType == 2 ? "6 Month" : rowData.packageType == 3 ? "1 Year" : rowData.packageType == -1 && "1 Day"}</div>,
            sortable: true,
            width: "200px"

        },
        {
            name: 'Package For',
            selector: rowData => <span>{rowData?.packageFor == 0 ? "Business" : "HouseHold"}</span>,
            sortable: true,
            width: "200px"

        },
        {
            name: 'Max Distance Per Order',
            cell: rowData => <span>{rowData?.maxDistancePerOrder}</span>,
            sortable: true,
            width: "200px"

        },

        {
            name: 'Per Day Cap',
            selector: rowData => <span>{rowData?.perDayCap}</span>,
            sortable: true,
            width: "200px"

        },

        {
            name: 'Total Deliveries',
            selector: rowData => <span>{rowData?.totalDeliveries}</span>,
            sortable: true,
            width: "200px"

        },
        {
            name: 'Value',
            selector: rowData => <span>{rowData?.value}</span>,
            sortable: true,
        },
        {
            name: 'Max Weight Per Order',
            selector: rowData => <span>{rowData?.maxWeightPerOrder}</span>,
            sortable: true,
            width: "200px"
        },
        {
            name: 'Actions',
            selector: rowData => <div>
                <button type="button" className="btn addModifiBttn ml-2" data-toggle="modal" data-target="#ModifyShopType" onClick={() => setSelectedPackageObj(rowData)}> Modify </button>
                <button type="button" className="btn addModifiBttn ml-2" onClick={() => handlePackageDelete(rowData._id)}> Delete </button>
            </div>,
            cell: rowData => <div className="d-flex justify-content-center">
                <button type="button" className="btn addModifiBttn ml-2" data-toggle="modal" data-target="#ModifyShopType" onClick={() => setSelectedPackageObj(rowData)}> Modify </button>
                <button type="button" className="btn addModifiBttn ml-2" onClick={() => handlePackageDelete(rowData._id)}> Delete </button>
            </div>
        }
    ];

    useEffect(() => {

        getPackage();
    }, [])

    const handlePackageChange = (val, field) => {
        try {

            setSelectedPackageObj(prevState => {
                prevState[field] = val;
                console.log(prevState[field])
                return { ...prevState }
            })
        } catch (error) {
            console.error(error)
        }
    }


    // const handleFilterByName = () => {
    //     if (nameSelected == "") {
    //         setDisplayPackageArr(packageArr)
    //     }
    //     else {
    //         let tempArr = packageArr.filter(el => `${el.name}`.toLowerCase() == `${nameSelected}`.toLowerCase());
    //         setDisplayPackageArr([...tempArr]);
    //     }
    // }

    // const handleFilterByPackageType = () => {
    //     if (packageSelectedType == "") {
    //         setDisplayPackageArr(packageArr)
    //     }
    //     else {
    //         let tempArr = packageArr.filter(el => `${el.packageType}`.toLowerCase() == `${packageSelectedType}`.toLowerCase());
    //         setDisplayPackageArr([...tempArr]);
    //     }
    // }

    // const handleFilterByPackageFor = () => {
    //     if (packageSelectedFor == "") {
    //         setDisplayPackageArr(packageArr)
    //     }
    //     else {
    //         let tempArr = packageArr.filter(el => `${el.packageFor}`.toLowerCase() == `${packageSelectedFor}`.toLowerCase());
    //         setDisplayPackageArr([...tempArr]);
    //     }
    // }

    // const handleFilterByMaxDistance = () => {
    //     if (selectedMaxDistance == "") {
    //         setDisplayPackageArr(packageArr)
    //     }
    //     else {
    //         let tempArr = packageArr.filter(el => `${el.maxDistancePerOrder}`.toLowerCase() == `${selectedMaxDistance}`.toLowerCase());
    //         setDisplayPackageArr([...tempArr]);
    //     }
    // }

    // const handleFilterByPerDay = () => {
    //     if (selectedPerDay == "") {
    //         setDisplayPackageArr(packageArr)
    //     }
    //     else {
    //         let tempArr = packageArr.filter(el => `${el.perDayCap}`.toLowerCase() == `${selectedPerDay}`.toLowerCase());
    //         setDisplayPackageArr([...tempArr]);
    //     }
    // }


    const handleFilter = () => {
        let arr = [...packageArr]
        if (nameSelected != "") {
            arr = packageArr.filter(el => `${el.name}`.toLowerCase() == `${nameSelected}`.toLowerCase());
        }
        else if (packageSelectedType != "") {
            arr = packageArr.filter(el => `${el.packageType}`.toLowerCase() == `${packageSelectedType}`.toLowerCase());
        }
        else if (packageSelectedFor != "") {
            arr = packageArr.filter(el => `${el.packageFor}`.toLowerCase() == `${packageSelectedFor}`.toLowerCase());
        }
        else if (selectedMaxDistance != "") {
            arr = packageArr.filter(el => `${el.maxDistancePerOrder}`.toLowerCase() == `${selectedMaxDistance}`.toLowerCase());
        }
        else if (selectedPerDay != "") {
            arr = packageArr.filter(el => `${el.perDayCap}`.toLowerCase() == `${selectedPerDay}`.toLowerCase());
        }
        setDisplayPackageArr([...arr]);

    }
    return (
        <main className="main_content">
            <div className="userTabsDesign mb-4">
                {
                    toggle ?
                        <>
                            <div className="headingTitle" style={{ marginBottom: 20 }}><button onClick={() => setToggle(false)} className="btn addModifiBttn">Add Packages</button></div>
                            <div className="card mt-3">
                                <div className="col-12 col-md-6">
                                    <div className="field-group">

                                        <label>Total Deliveries</label>
                                    </div>
                                </div>

                                <div class='row' style={{ display: 'flex', flex: 1, width: '100%' }}>
                                    {/* NAME */}

                                    <div className="col-md-2">
                                        {/* <label>Name</label> */}
                                        <select onChange={(e) => { setNameSelected(e.target.value); console.log(e.target.value, 'xyz') }} className="form-control" >
                                            <option value="">All ( default )</option>
                                            {
                                                packageArr && packageArr.length > 0 && packageArr.map(el => {
                                                    return (
                                                        <option key={el._id} value={el.name}>{el.name}</option>

                                                    )
                                                })
                                            }
                                        </select>
                                    </div>


                                    {/* TYPE */}
                                    <div className="col-md-2">
                                        <select onChange={(e) => { setPackageSelectedType(e.target.value); console.log(e.target.packageType, 'xyz') }} className="form-control" >
                                            <option value="">All ( default )</option>
                                            <option value={-1}>1 Delivery</option>
                                            <option value={0}>1 Month</option>
                                            <option value={1}>3 Months</option>
                                            <option value={2}>6 Months</option>
                                            <option value={3}>1 Year</option>
                                        </select>
                                    </div>


                                    {/* Package For */}
                                    <div className="col-md-2" >
                                        <select onChange={(e) => { setPackageSelectedFor(e.target.value); }} className="form-control" >
                                            <option value="">All ( default )</option>
                                            <option value={0}>Business</option>
                                            <option value={1}>household</option>
                                        </select>
                                    </div>


                                    {/* Max Distance */}
                                    <div className="col-md-2" >
                                        <select onChange={(e) => { setSelectedMaxDistance(e.target.value); }} className="form-control" >
                                            <option value="">All ( default )</option>
                                            {
                                                packageArr && packageArr.length > 0 && packageArr.map(el => {
                                                    return (
                                                        <option key={el._id} value={el.maxDistancePerOrder}>{el.maxDistancePerOrder}</option>

                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    {/* Per Day */}
                                    <div className="col-md-2" >
                                        <select onChange={(e) => { setSelectedPerDay(e.target.value); console.log(e.target.packageType, 'xyz') }} className="form-control" >
                                            <option value="">All ( default )</option>
                                            {
                                                packageArr && packageArr.length > 0 && packageArr.map(el => {
                                                    return (
                                                        <option key={el._id} value={el.perDayCap}>{el.perDayCap}</option>

                                                    )
                                                })
                                            }
                                        </select>
                                    </div>

                                    {/* button */}
                                    <div className="col-md-2" >
                                        <button type="button" onClick={(e) => handleFilter()} className="btn searchBttn"> Submit </button>
                                    </div>
                                </div>

                                <DataTable
                                    title="Business Packages"
                                    columns={AddPackageColumns}
                                    data={displayPackageArr && displayPackageArr.filter(el => el?.packageFor == 0)}
                                    sortIcon={sortIcon}
                                    keyField='_id'
                                    pagination
                                />
                                <DataTable
                                    title="HouseHold Packages"
                                    columns={AddPackageColumns}
                                    data={displayPackageArr && displayPackageArr.filter(el => el?.packageFor == 1)}
                                    // data={displayPackageArr.length > 0 && displayPackageArr.filter(el => el?.packageFor == 1)}
                                    sortIcon={sortIcon}
                                    keyField='_id'
                                    pagination
                                />
                            </div>
                            <div id="ModifyShopType" className="modal fade" role="dialog">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                                            <h4 className="modal-title text-center"> Modify Package </h4>
                                        </div>
                                        <div className="modal-body">
                                            <div className="AddModiForm">
                                                <form>
                                                    <div className="row">
                                                        <div className="col-12 col-md-6">
                                                            <div className="field-group">
                                                                <label>Package Name</label>
                                                                <input type="text" value={selectedPackageObj?.name} onChange={(e) => handlePackageChange(e.target.value, "name")} className="form-control" placeholder="Enter Package Name" />
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-6">
                                                            <div className="field-group">
                                                                <label>Package Type</label>

                                                                <select className="form-control" value={selectedPackageObj?.packageType} onChange={(e) => {
                                                                    handlePackageChange(e.target.value, "packageType")
                                                                    if (e.target.value == -1) {
                                                                        setPerDayCap(1);
                                                                        setdeliveriesLeft(1)
                                                                    }
                                                                    else {
                                                                        setPerDayCap(0);
                                                                        setdeliveriesLeft(0)
                                                                    }
                                                                }} >
                                                                    <option value="">--Type of Package--</option>
                                                                    <option value={-1}>1 Delivery</option>
                                                                    <option value={0}>1 Month</option>
                                                                    <option value={1}>3 Months</option>
                                                                    <option value={2}>6 Months</option>
                                                                    <option value={3}>1 Year</option>

                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className="field-group">
                                                                <label>Package Amount (in rupees)</label>

                                                                <input type="number" onChange={(e) => handlePackageChange(e.target.value, "value")} value={selectedPackageObj?.value} className="form-control" placeholder="Enter Value" />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className="field-group">
                                                                <label>Max Distance per order (in KM)</label>

                                                                <input type="number" onChange={(e) => handlePackageChange(e.target.value, "maxDistancePerOrder")} value={selectedPackageObj?.maxDistancePerOrder} className="form-control" placeholder="Enter Max Distance Per Order" />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className="field-group">
                                                                <label>Enter Max Weight per order (in kg)</label>

                                                                <input type="number" onChange={(e) => handlePackageChange(e.target.value, "maxWeightPerOrder")} value={selectedPackageObj?.maxWeightPerOrder} className="form-control" placeholder="Enter Max Weight per order" />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className="field-group">
                                                                <label>Total Deliveries</label>
                                                                <input type="number" disabled={packageType == -1} onChange={(e) => handlePackageChange(e.target.value, "value")} value={selectedPackageObj?.totalDeliveries} className="form-control" placeholder="Enter Total Deliveries" />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <label>Package For</label>
                                                            <div style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', display: 'flex' }}>
                                                                <div>
                                                                    <input id="business" type="radio" value={0} checked={packageFor == 0} onChange={(e) => handlePackageChange(e.target.value, "packageFor")} />
                                                                    <label for="business">Business</label>
                                                                </div>
                                                                <div>
                                                                    <input id="household" type="radio" value={0} checked={packageFor == 1} onChange={(e) => handlePackageChange(e.target.value, "packageFor")} />
                                                                    <label for="household">Household</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className="field-group">
                                                                <label>Per Day Cap</label>
                                                                <input type="number" disabled={packageType == -1} onChange={(e) => handlePackageChange(e.target.value, "perDayCap")} value={selectedPackageObj?.perDayCap} className="form-control" placeholder="Enter Per Day Cap" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-4 d-flex justify-content-center">
                                                        <div className="col-12 col-md-3">
                                                            <div className="field-group">
                                                                <button type="button" onClick={() => handleUpdatePackageById()} className="btn searchBttn"> Save </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="headingTitle" style={{ marginBottom: 20 }}><h1> Add Package </h1> <button onClick={() => setToggle(true)} className="btn addModifiBttn">View Packages</button></div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="AddModiForm w-100">
                                        <form>
                                            <div className="row">
                                                <div className="col-12 col-md-6">
                                                    <div className="field-group">
                                                        <label>Package Name</label>
                                                        <input type="text" onChange={(e) => setName(e.target.value)} className="form-control" placeholder="Enter Package Name" />
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-6">
                                                    <div className="field-group">
                                                        <label>Package Type</label>

                                                        <select className="form-control" onChange={(e) => {
                                                            setPackageType(e.target.value);
                                                            if (e.target.value == -1) {
                                                                setPerDayCap(1);
                                                                setdeliveriesLeft(1)
                                                            }
                                                            else {
                                                                setPerDayCap(0);
                                                                setdeliveriesLeft(0)
                                                            }
                                                        }} >
                                                            <option value="">--Type of Package--</option>
                                                            <option value={-1}>1 Delivery</option>
                                                            <option value={0}>1 Month</option>
                                                            <option value={1}>3 Months</option>
                                                            <option value={2}>6 Months</option>
                                                            <option value={3}>1 Year</option>

                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="field-group">
                                                        <label>Package Amount (in rupees)</label>

                                                        <input type="number" onChange={(e) => setValue(e.target.value)} className="form-control" placeholder="Enter Value" />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="field-group">
                                                        <label>Max Distance per order (in KM)</label>

                                                        <input type="number" onChange={(e) => setMaxDistancePerOrder(e.target.value)} className="form-control" placeholder="Enter Max Distance Per Order" />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="field-group">
                                                        <label>Enter Max Weight per order (in kg)</label>

                                                        <input type="number" onChange={(e) => setMaxWeightPerOrder(e.target.value)} className="form-control" placeholder="Enter Max Weight per order" />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="field-group">
                                                        <label>Total Deliveries</label>
                                                        <input type="number" disabled={packageType == -1} onChange={(e) => setdeliveriesLeft(e.target.value)} value={deliveriesLeft} className="form-control" placeholder="Enter Total Deliveries" />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <label>Package For</label>
                                                    <div style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', display: 'flex' }}>
                                                        <div>
                                                            <input id="business" type="radio" value={0} checked={packageFor == 0} onChange={(e) => setPackageFor(0)} />
                                                            <label for="business">Business</label>
                                                        </div>
                                                        <div>
                                                            <input id="household" type="radio" value={0} checked={packageFor == 1} onChange={(e) => setPackageFor(1)} />
                                                            <label for="household">Household</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="field-group">
                                                        <label>Per Day Cap</label>
                                                        <input type="number" disabled={packageType == -1} onChange={(e) => setPerDayCap(e.target.value)} value={perDayCap} className="form-control" placeholder="Enter Per Day Cap" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-4 d-flex justify-content-center">
                                                <div className="col-12 col-md-3">
                                                    <div className="field-group">
                                                        <button type="button" onClick={() => handleSubmit()} className="btn searchBttn"> Save </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </>
                }
            </div>
        </main>
    )
}

export default AddPackage;
