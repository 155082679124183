import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { url } from "../../Services/Url"
import { generateImageUrl } from '../../utils/util';
import { ToastContainer, toast } from 'react-toastify';
import MaterialTable from 'material-table';
import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { filterArrayByDateRange } from '../../utils/util';

function Attendence() {
    const sortIcon = <ArrowDownward />;


    const [deliveryBoyArr, setDeliveryBoyArr] = useState([]);
    const [stateArr, setStateArr] = useState();
    const [cityArr, setCityArr] = useState();
    const [areaArr, setAreaArr] = useState();
    const [cityFilteredArr, setCityFilteredArr] = useState();
    const [areaFilteredArr, setAreaFilteredArr] = useState();
    const [filteredDeliveryBoyArr, setFilteredDeliveryBoyArr] = useState();
    const [selectedStateId, setSelectedStateId] = useState("");
    const [selectedCityId, setSelectedCityId] = useState("");
    const [selectedAreaId, setSelectedAreaId] = useState();

    const [attendanceArr, setAttendanceArr] = useState([]);





    const getCity = async () => {
        try {
            let { data: res } = await axios.get(`${url}/city/`)
            if (res.success) {
                let tempCityArr = res.data.filter(el => el.active == true && el.stateActive == true);
                setCityArr(tempCityArr);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }


    const getState = async () => {
        try {
            let { data: res } = await axios.get(`${url}/state/`)
            if (res.success) {
                let tempStateArr = res.data.filter(el => el.active == true);
                setStateArr(tempStateArr);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }

    const setCityArrFromState = (value) => {
        setSelectedStateId(value)
        let cityfilteredArr = cityArr.filter(el => el.stateId == value);
        setCityFilteredArr(cityfilteredArr)
    }

    const setAreaArrFromCity = (value) => {
        setSelectedCityId(value)
        let areafilteredArr = areaArr.filter(el => el.cityId == value);
        setAreaFilteredArr(areafilteredArr)
    }



    const setselectedArea = (value) => {
        setSelectedAreaId(value)

    }


    const getArea = async () => {
        try {
            let { data: res } = await axios.get(`${url}/area/`)
            if (res.success) {
                let tempAreaArr = res.data.filter(el => el.active == true && el.stateActive == true && el.cityActive == true);
                setAreaArr(tempAreaArr);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }


    const filterDeliveryBoysByLocation = () => {
        let tempFilteredDeliveryBoysArr = deliveryBoyArr.filter(el => el.stateId == selectedStateId && el.cityId == selectedCityId && el.areaId == selectedAreaId)
        setFilteredDeliveryBoyArr(tempFilteredDeliveryBoysArr)
    }
    const filterDeliveryBoysByName = (val) => {
        let tempFilteredDeliveryBoysArr = deliveryBoyArr.filter(el => el.name.includes(val))
        setFilteredDeliveryBoyArr(tempFilteredDeliveryBoysArr)
    }




    const getDeliveryBoys = async () => {
        try {
            let { data: res } = await axios.get(`${url}/deliveryBoy/`)
            if (res.success) {
                setDeliveryBoyArr(res.data);
                setFilteredDeliveryBoyArr(res.data)
                let approvedTempArr = res.data.filter(el => el.status == 0)
                if (approvedTempArr) {
                    setDeliveryBoyArr(approvedTempArr)
                }
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }


    const columns = [
        {
            name: 'Date Of Joining',
            selector: row => row?.dateOfJoining,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => <a style={{ color: "black", textDecoration: "none" }} href={`/Attendance-Details/${row._id}`} >{row.name}</a>,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row?.email,
            sortable: true,
        },
        {
            name: 'Address',
            selector: row => row?.address,
            sortable: true,
        },
        {
            name: 'Phone Number',
            selector: row => row?.mobile,
            sortable: true,
        },
        {
            name: 'Pin Code',
            selector: row => row?.pinCode,
            sortable: true,
        },
        {
            name: 'Vehicle Type',
            selector: row => row?.vehicleName,
            sortable: true,
        },
        {
            name: 'RC Number',
            selector: row => row?.rcNumber,
            sortable: true,
        },
        {
            name: 'RC Image',
            selector: row => <a target="_blank" href={`${generateImageUrl(row?.rcImageName)}`}><img src={`${generateImageUrl(row?.rcImageName)}`} style={{ height: 40, width: 40 }} alt="" /></a>,
            sortable: true,
        },

        {
            name: 'Chasis Number',
            selector: row => row?.chasisNumber,
            sortable: true,
        },

        {
            name: 'License Number',
            selector: row => row?.licenseNumber,
            sortable: true,
        },
        {
            name: 'License Image',
            selector: row => <a target="_blank" href={`${generateImageUrl(row?.licenseImageName)}`}><img src={`${generateImageUrl(row?.licenseImageName)}`} style={{ height: 40, width: 40 }} alt="" /></a>,
            sortable: true,
        },
        {
            name: 'Aadhaar Number',
            selector: row => row?.aadhaarNumber,
            sortable: true,
        },
        {
            name: 'Aadhaar Image',
            selector: row => <a target="_blank" href={`${generateImageUrl(row?.aadharImageName)}`}><img src={`${generateImageUrl(row?.aadharImageName)}`} style={{ height: 40, width: 40 }} alt="" /></a>,
            sortable: true,
        },
        {
            name: 'State',
            selector: row => row?.stateName,
            sortable: true,
        },
        {
            name: 'City',
            selector: row => row?.cityName,
            sortable: true,
        },
        {
            name: 'Area',
            selector: row => row?.areaName,
            sortable: true,
        },
        {
            name: 'Basic Salary',
            selector: row => row?.salary?.basic,
            sortable: true
        },
        {
            name: 'Allowance',
            selector: row => row?.salary?.allowance,
            sortable: true
        },
        {
            name: 'Fuel Allowance',
            selector: row => row?.salary?.fuelAllowance,
            sortable: true

        },
        {
            name: 'Other Allowance',
            selector: row => row?.salary?.other,
            sortable: true

        },
    ];


    useEffect(() => {
        getState();
        getCity();
        getArea();
        getDeliveryBoys()
    }, [])
    return (
        <main className="main_content">
            <div className="filterSearchBar">
                <form>
                    <div className="row">
                        <div className="col-12 col-md-3">
                            <label>Search Delivery Boy Name</label>
                            <input onChange={(e) => filterDeliveryBoysByName(e.target.value)} type="text" className="form-control" placeholder="Search Delivery Boy" />
                        </div>
                        <div className="col-12 col-md-2">
                            <div className="field-group">
                                <label>State</label>

                                <select name="" id="" className="form-control" onChange={(event) => { setCityArrFromState(event.target.value) }}>
                                    <option value="">Please Select a State</option>
                                    {
                                        stateArr && stateArr.length > 0 && stateArr.map(el => {
                                            return (
                                                <option key={el._id} value={el._id}>{el.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-12 col-md-2">
                            <div className="field-group">
                                <label>City</label>

                                <select name="" id="" className="form-control" onChange={(event) => { setAreaArrFromCity(event.target.value) }}>
                                    <option value="">Please Select a City</option>
                                    {
                                        cityFilteredArr && cityFilteredArr.length > 0 && cityFilteredArr.map(el => {
                                            return (
                                                <option key={el._id} value={el._id}>{el.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="col-12 col-md-2">
                            <div className="field-group">
                                <label>Area</label>

                                <select name="" id="" className="form-control" onChange={(event) => { setselectedArea(event.target.value) }}>
                                    <option value="">Please Select a Area</option>
                                    {
                                        areaFilteredArr && areaFilteredArr.length > 0 && areaFilteredArr.map(el => {
                                            return (
                                                <option key={el._id} value={el._id}>{el.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-12 col-md-2">
                            <label>&nbsp;</label>
                            <button onClick={() => filterDeliveryBoysByLocation()} type="button" className="btn searchBttn"> Search </button>
                        </div>

                    </div>
                </form>
            </div>
            <br />
            <br />
            <div className="infoOrdertable mb-4">
                <div className="headingTitle addModifyHeading">

                </div>
                <div className="card">


                    <DataTable
                        title="Delivery Boys"
                        columns={columns}
                        data={filteredDeliveryBoyArr}
                        sortIcon={sortIcon}
                        keyField='_id'
                        pagination
                    />
                </div>
            </div>
        </main>
    )
}

export default Attendence;
