import axios from 'axios';
import { url } from "./Url"


const serverUrl = `${url}/businessProducts`

export const getUserProducts = async (id) => {
    try {
        const { data: res } = await axios.get(`${serverUrl}/getByUserId/${id}`);
        return res;
    }
    catch (err) {
        throw err
    }
}



export const createUserProducts = async (obj) => {
    try {
        const { data: res } = await axios.post(`${serverUrl}/adminAddProducts`, obj);
        return res;
    }
    catch (err) {
        throw err
    }
}


export const deleteUserProducts = async (id) => {
    try {
        const { data: res } = await axios.delete(`${serverUrl}/deleteById/${id}`);
        return res;
    }
    catch (err) {
        throw err
    }
}

export const uploadProductWithExcel=async(formData)=>{
    try {
        const res=await axios.post(`${serverUrl}/productexcelupload`,formData);
        return res
    } catch (error) {
        console.error(error)
        throw(error)
    }
}