import { url } from "../Services/Url";


export const generateImageUrl = (fileName) => {
    if (fileName) {
        return ` ${url}/uploads/${fileName}`
    }
    else {
        return ""
    }
}





export const generateExpiryDatefor1Delivery = (date) => {
    if (date) {

        return new Date(`${new Date(date).getDate() + 1} ${new Date(date).getMonth()} ${new Date(date).getFullYear()}`)
    }
    else {
        return ""
    }
}
export const generateExpiryDatefor1Month = (date) => {
    if (date) {

        return new Date(`${new Date(date).getDate()} ${new Date(date).getMonth() + 1} ${new Date(date).getFullYear()}`)
    }
    else {
        return ""
    }
}
export const generateExpiryDatefor3Months = (date) => {
    if (date) {

        return new Date(`${new Date(date).getDate()} ${new Date(date).getMonth() + 3} ${new Date(date).getFullYear()}`)
    }
    else {
        return ""
    }
}
export const generateExpiryDatefor6Months = (date) => {
    if (date) {

        return new Date(`${new Date(date).getDate()} ${new Date(date).getMonth() + 6} ${new Date(date).getFullYear()}`)
    }
    else {
        return ""
    }
}
export const generateExpiryDatefor1Year = (date) => {
    if (date) {
        return new Date(`${new Date(date).getDate()} ${new Date(date).getMonth()} ${new Date(date).getFullYear() + 1}`)
    }
    else {
        return ""
    }
}



export const filterArrayByDateRange = (startDate, endDate, date) => {//////date is the date which will be checked
    if (date && startDate && endDate) {
        return (new Date(startDate).getTime() <= new Date(date).getTime() && new Date(date).getTime() <= new Date(endDate).getTime())
    }
    else {
        return "NA"
    }
}

export const customGeneralFilter = (data, columns, searchStr) => {
    const searchItems = columns?.filter(el => el.sortable)
    function filter(el) {
      let chk = false
      if (!searchItems?.length)
        chk = true
      searchItems.forEach(elx => {
        let val = elx?.selector(el)
        if (val) {
          if (typeof val == 'string' || typeof val == 'number') {
            if (`${val}`.toLowerCase().includes(searchStr.toLowerCase()))
              chk = true
          }
        }
      })
      return chk
    }
    return data?.filter(el => searchStr ? filter(el) : true)
  }


export const colorsArr = [
    {
      solidColor: 'rgba(255, 99, 132, 1)',
      transparentColor: 'rgba(255, 99, 132, 0.2)'
    },
    {
      solidColor: 'rgba(54, 162, 235, 1)',
      transparentColor: 'rgba(54, 162, 235, 0.2)'
    },
    {
      solidColor: 'rgba(255, 206, 86, 1)',
      transparentColor: 'rgba(255, 206, 86, 0.2)'
    },
    {
      solidColor: 'rgba(75, 192, 192, 1)',
      transparentColor: 'rgba(75, 192, 192, 0.2)'
    },
    {
      solidColor: 'rgba(153, 102, 255, 1)',
      transparentColor: 'rgba(153, 102, 255, 0.2)'
    },
    {
      solidColor: 'rgba(255, 159, 64, 1)',
      transparentColor: 'rgba(255, 159, 64, 0.2)'
    },
  ]