import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from 'react-router';
import { toast } from "react-toastify";
import { url } from "../../Services/Url";


import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { getSpecificDeliveryBoy, updateDeliveryBoyPincode, updateDeliveryBoySalaryById } from "../../Services/DeliveryBoy";
import { getAreaByCityId } from "../../Services/area";
export function DeliveryBoyDetails() {

    const sortIcon = <ArrowDownward />;

    let { id } = useParams();

    // console.log(id)
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

    const [deliveryBoyObj, setDeliveryBoyObj] = useState({});

    const [yearArr, setYearArr] = useState([]);
    const [amount, setAmount] = useState();



    const [monthsArr, setMonthsArr] = useState([
        { name: "JANUARY" },
        { name: "FEBRUARY" },
        { name: "MARCH" },
        { name: "APRIL" },
        { name: "MAY" },
        { name: "JUNE" },
        { name: "JULY" },
        { name: "AUGUST" },
        { name: "SEPTEMBER" },
        { name: "OCTOBER" },
        { name: "NOVEMBER" },
        { name: "DECEMBER" },
    ]);
    const [month, setMonth] = useState("");

    const [hoursOnline, setHoursOnline] = useState(0);
    const [deliveries, setDeliveries] = useState(0);
    const [km, setKm] = useState(0);
    const [year, setYear] = useState();




    const [salaryMonth, setSalaryMonth] = useState("");
    const [salaryYear, setSalaryYear] = useState("");
    const [incentiveMonth, setIncentiveMonth] = useState("");
    const [incentiveYear, setIncentiveYear] = useState("");







    const [value1, setValue1] = useState("");
    const [value2, setValue2] = useState("");
    const [value3, setValue3] = useState("");
    const [value4, setvalue4] = useState("");


    const [value5, setValue5] = useState("");
    const [value6, setValue6] = useState("");
    const [value7, setValue7] = useState("");
    const [value8, setValue8] = useState("");


    const [deliveryBoyPincodeArr, setDeliveryBoyPincodeArr] = useState([]);


    const getDeliveryBoy = async () => {
        try {
            let finalAreaArr = [];
            const { data: res, status: statusCode } = await getSpecificDeliveryBoy(id);
            if (statusCode == 200 || statusCode == 304) {
                let tempDeliveryBoyObj = res.data;
                if (tempDeliveryBoyObj.cityId) {
                    let areaArr = await getAreasByCity(tempDeliveryBoyObj.cityId);

                    console.log(areaArr, finalAreaArr)
                    finalAreaArr = areaArr.filter((el, i, arr) => i == arr.findIndex(ele => ele.pincode == el.pincode))
                    if (tempDeliveryBoyObj?.deliveryPincodes?.length) //tempDeliveryBoyObj?.deliveryPincodes.some(ele => el.pincode == ele.pincode)
                        finalAreaArr = finalAreaArr.map(el => ({ ...el, checked: tempDeliveryBoyObj?.deliveryPincodes.some(ele => el.pincode == ele.pincode) }))
                    else
                        finalAreaArr = finalAreaArr.map(el => ({ ...el, checked: false }))
                    setDeliveryBoyPincodeArr([...finalAreaArr])
                    setDeliveryBoyObj(tempDeliveryBoyObj)
                }
            }
        } catch (error) {
            console.error(error)
        }
    }


    const getAreasByCity = async (cityId) => {
        try {

            let { data: res, status: statusCode } = await getAreaByCityId(cityId);
            if (statusCode == 200 || statusCode == 304) {
                return res.data
            }
        } catch (error) {
            console.error(error)
            throw (error)
        }
    }


    const updateDeliveryArea = async (pincode) => {
        try {
            const { data: res, status: statusCode } = await updateDeliveryBoyPincode(id, { pincode });
            if (statusCode == 200 || statusCode == 304) {
                handleOnInIt()
                alert(res.message);

            }
        } catch (error) {
            console.error(error)
        }
    }


    const submitEffectiveSalary = async (val) => {
        try {

            if (salaryMonth == "") {
                alert("You will have to select month first")
            }
            else {

                if (val == 1 && value1 >= 0 && value1 <= 100) {
                    let obj = {
                        taskCompleted: 1,
                        taskNotCompleted: 2,
                        percentage: value1,
                        year: salaryYear,
                        month: salaryMonth,
                        deliveryBoyId: id
                    }
                    let res = await axios.post(`${url}/effectiveSalary/`, obj)

                    if (res.status == 200) {
                        toast.success(res.data.message)
                    }
                }

                else if (val == 2 && value2 >= 0 && value2 <= 100) {
                    let obj = {
                        taskCompleted: 2,
                        taskNotCompleted: 1,
                        percentage: value2,
                        year: salaryYear,
                        month: salaryMonth,
                        deliveryBoyId: id
                    }

                    let res = await axios.post(`${url}/effectiveSalary/`, obj)
                    if (res.status == 200) {
                        toast.success(res.data.message)
                    }
                }


                else if (val == 3 && value3 >= 0 && value3 <= 100) {
                    let obj = {
                        taskCompleted: 3,
                        taskNotCompleted: 0,
                        percentage: value3,
                        year: salaryYear,
                        month: salaryMonth,
                        deliveryBoyId: id
                    }
                    let res = await axios.post(`${url}/effectiveSalary/`, obj)
                    if (res.status == 200) {
                        toast.success(res.data.message)
                    }
                }



                else if (val == 4 && value4 >= 0 && value4 <= 100) {
                    let obj = {
                        taskCompleted: 0,
                        taskNotCompleted: 3,
                        percentage: value4,
                        year: salaryYear,
                        month: salaryMonth,
                        deliveryBoyId: id
                    }
                    let res = await axios.post(`${url}/effectiveSalary/`, obj)
                    if (res.status == 200) {
                        toast.success(res.data.message)
                    }
                }

                else {
                    toast.error("Invalid Value")
                }

            }

        }

        catch (err) {
            if (err?.response?.data?.message) {
                console.log(err?.response?.data?.message)
                alert(err?.response?.data?.message)
            }
            else {
                console.log(err)
                alert(err)
            }
        }
    }



    const submitEffectiveIncentive = async (val) => {
        try {

            if (incentiveMonth == "") {
                alert("You will have to select month first")
            }
            else {

                if (val == 1 && value5 >= 0 && value5 <= 100) {
                    let obj = {
                        taskCompleted: 1,
                        taskNotCompleted: 2,
                        percentage: value5,
                        year: incentiveYear,
                        month: incentiveMonth,
                        deliveryBoyId: id
                    }
                    let res = await axios.post(`${url}/effectiveIncentive/`, obj)

                    if (res.status == 200) {
                        toast.success(res.data.message)
                    }
                }

                else if (val == 2 && value6 >= 0 && value6 <= 100) {
                    let obj = {
                        taskCompleted: 2,
                        taskNotCompleted: 1,
                        percentage: value6,
                        year: incentiveYear,
                        month: incentiveMonth,
                        deliveryBoyId: id
                    }

                    let res = await axios.post(`${url}/effectiveIncentive/`, obj)
                    if (res.status == 200) {
                        toast.success(res.data.message)
                    }
                }


                else if (val == 3 && value7 >= 0 && value7 <= 100) {
                    let obj = {
                        taskCompleted: 3,
                        taskNotCompleted: 0,
                        percentage: value7,
                        year: incentiveYear,
                        month: incentiveMonth,
                        deliveryBoyId: id
                    }
                    let res = await axios.post(`${url}/effectiveIncentive/`, obj)
                    if (res.status == 200) {
                        toast.success(res.data.message)
                    }
                }



                else if (val == 4 && value8 >= 0 && value8 <= 100) {
                    let obj = {
                        taskCompleted: 0,
                        taskNotCompleted: 3,
                        percentage: value8,
                        year: incentiveYear,
                        month: incentiveMonth,
                        deliveryBoyId: id
                    }
                    let res = await axios.post(`${url}/effectiveIncentive/`, obj)
                    if (res.status == 200) {
                        toast.success(res.data.message)
                    }
                }

                else {
                    toast.error("Invalid Value")
                }

            }

        }

        catch (err) {
            if (err?.response?.data?.message) {
                console.log(err?.response?.data?.message)
                alert(err?.response?.data?.message)
            }
            else {
                console.log(err)
                alert(err)
            }
        }
    }


    const getIncentiveParametersByMonth = async () => {
        try {
            const res = await axios.get(`${url}/effectiveIncentive/getByDeliveryBoyId/${id}/${incentiveYear}/${incentiveMonth}`)
            console.log(res.data.data)
            if (res.status == 200) {
                res.data.data.forEach(el => {
                    if (el.taskCompleted == 0) {
                        setValue8(el.percentage)
                    }
                    else if (el.taskCompleted == 1) {
                        setValue5(el.percentage)
                    }

                    else if (el.taskCompleted == 2) {
                        setValue6(el.percentage)
                    }

                    else if (el.taskCompleted == 3) {
                        setValue7(el.percentage)
                    }

                });


                // }

            }
        }
        catch (err) {
            if (err?.response.data.message) {
                console.log(err?.response.data.message)
                toast.error(err?.response.data.message)
            }
            else {
                console.log(err)
                toast.error(err)
            }
        }
    }






    const getSalaryParametersByMonth = async () => {
        try {
            const res = await axios.get(`${url}/effectiveSalary/getByDeliveryBoyId/${id}/${salaryYear}/${salaryMonth}`)
            console.log(res.data.data)
            if (res.status == 200) {
                res.data.data.forEach(el => {
                    if (el.taskCompleted == 0) {
                        setvalue4(el.percentage)
                    }
                    else if (el.taskCompleted == 1) {
                        setValue1(el.percentage)
                    }

                    else if (el.taskCompleted == 2) {
                        setValue2(el.percentage)
                    }

                    else if (el.taskCompleted == 3) {
                        setValue3(el.percentage)
                    }

                });


                // }

            }
        }
        catch (err) {
            if (err?.response.data.message) {
                console.log(err?.response.data.message)
                toast.error(err?.response.data.message)
            }
            else {
                console.log(err)
                toast.error(err)
            }
        }
    }



    const generateYearArr = () => {
        for (let i = currentYear; i <= currentYear + 10; i++) {
            yearArr.push(i)
        }
    }


    const [finalDateString, setfinalDateString] = useState("");


    const [targetArr, setTargetArr] = useState();
    const [incentiveArr, setIncentiveArr] = useState();

    const handleSubmit = async () => {
        // console.log(SelectedDeliveryboy)
        try {
            // alert(SelectedDeliveryboy._id)
            if (month == "") {
                alert("You will have to select month first")
            }
            else {
                let monthName = ""
                if (month == 0) {
                    monthName = "JANUARY"
                }
                else if (month == 1) {
                    monthName = "FEBRUARY"
                }
                else if (month == 2) {

                    monthName = "MARCH"
                }
                else if (month == 3) {

                    monthName = "APRIL"
                }
                else if (month == 4) {

                    monthName = "MAY"
                }
                else if (month == 5) {
                    monthName = "JUNE"
                }
                else if (month == 6) {

                    monthName = "JULY"
                }
                else if (month == 7) {

                    monthName = "AUGUST"
                }
                else if (month == 8) {
                    monthName = "SEPTEMBER"
                }
                else if (month == 9) {

                    monthName = "OCTOBER"
                } else if (month == 10) {

                    monthName = "NOVEMBER"
                }
                else if (month == 11) {

                    monthName = "DECEMBER"
                }

                let obj = {
                    month,
                    year,
                    monthName,
                    hoursOnline,
                    deliveries,
                    deliveryBoyId: id,
                    km
                }
                let res = await axios.post(`${url}/target/createTargetByBoyId/`, obj)
                if (res.status == 200) {
                    toast.success(res.data.message)
                    setHoursOnline(0)
                    setDeliveries(0)
                    setKm(0)
                }
            }
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message)
                console.log(error?.response?.data?.message, "errors")
            }
            else {
                console.log(error)
            }
        }

    }




    const handleIncentiveSubmit = async () => {
        // console.log(SelectedDeliveryboy)
        try {
            // alert(SelectedDeliveryboy._id)
            if (month == "") {
                alert("You will have to select month first")
            }
            else {
                let monthName = ""
                if (month == 0) {
                    monthName = "JANUARY"
                }
                else if (month == 1) {
                    monthName = "FEBRUARY"
                }
                else if (month == 2) {

                    monthName = "MARCH"
                }
                else if (month == 3) {

                    monthName = "APRIL"
                }
                else if (month == 4) {

                    monthName = "MAY"
                }
                else if (month == 5) {
                    monthName = "JUNE"
                }
                else if (month == 6) {

                    monthName = "JULY"
                }
                else if (month == 7) {

                    monthName = "AUGUST"
                }
                else if (month == 8) {
                    monthName = "SEPTEMBER"
                }
                else if (month == 9) {

                    monthName = "OCTOBER"
                } else if (month == 10) {

                    monthName = "NOVEMBER"
                }
                else if (month == 11) {

                    monthName = "DECEMBER"
                }

                let obj = {
                    month,
                    year,
                    monthName,
                    hoursOnline,
                    deliveries,
                    deliveryBoyId: id,
                    km,
                    amount,
                }
                let res = await axios.post(`${url}/incentive/createIncentiveByBoyId/`, obj)
                if (res.status == 200) {
                    toast.success(res.data.message)
                    // getDeliveryBoys()
                    setHoursOnline(0)
                    setDeliveries(0)
                    setKm(0)
                }
            }
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message)
                console.log(error?.response?.data?.message, "errors")
            }
            else {
                console.log(error)
            }
        }

    }



    const getTargetByDeliveryBoyId = async () => {
        try {

            let res = await axios.get(`${url}/deliveryBoy/findByDeliveryBoyId/${id}`)
            console.log(res)
            if (res.status == 200) {
                setTargetArr(res.data.data.targetArr)
                setIncentiveArr(res.data.data.incentiveArr)
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.log(err?.response?.data?.message)
                alert(err?.response?.data?.message)
            }
            else {
                alert(err)
                console.log(err)
            }
        }
    }





    const handleOnInIt = () => {
        getTargetByDeliveryBoyId()
        generateYearArr()
        getDeliveryBoy()
    }



    useEffect(() => {
        handleOnInIt()
    }, [])

    useEffect(() => {
        console.log(salaryMonth)
        console.log(salaryYear)
        if (salaryYear != "" && salaryMonth != "") {
            getSalaryParametersByMonth()
        }

    }, [salaryMonth, salaryYear])


    useEffect(() => {
        console.log(incentiveYear)
        console.log(incentiveMonth)
        if (incentiveYear != "" && incentiveMonth != "") {
            getIncentiveParametersByMonth()
        }

    }, [incentiveYear, incentiveMonth])





    const Columns = [
        {
            name: 'S.No',
            selector: (row, index) => index,
            sortable: true
        },
        {
            name: 'Pincode',
            selector: row => row.pincode,
            sortable: true

        },
        {
            name: 'Action',
            cell: row => {
                if (row.checked) {
                    return (
                        <button className="btn addModifiBttn mr-2" onClick={() => updateDeliveryArea(row.pincode)}>Set In-Active </button>
                    )
                }
                else {
                    return (
                        <button className="btn btn-primary" onClick={() => updateDeliveryArea(row.pincode)}>Set Active</button>
                    )
                }
            }
        },

    ];


    const updateDeliveryBoySalary = async () => {
        try {
            const {data:res,status:statusCode}=await updateDeliveryBoySalaryById(deliveryBoyObj._id,{salary:deliveryBoyObj.salary});
            if(statusCode==200 || statusCode==304){
                toast.success(`${res.message}`);
                handleOnInIt()
            }
        } catch (error) {
            console.error(error)
        }
    }

    const setDeliveryBoySalaryUpdate = (value, field) => {
        setDeliveryBoyObj(prevState => {
            if (field == "basic")
                prevState.salary.basic = value
            
            if (field == "allowance")
                prevState.salary.allowance = value
            
            if (field == "fuelAllowance")
                prevState.salary.fuelAllowance = value
            
            if (field == "other")
                prevState.salary.other = value
            

            return { ...prevState }
        })
    }

    const handlePercentageSelection=(el)=>{
        setIncentiveMonth(new Date(el.month).getMonth())
        console.log(new Date(el.month).getMonth())
        setIncentiveYear(new Date(el.month).getFullYear())
    }


    return (
        <> <main className="main_content">
            <h1>Delivery Boy Details ({deliveryBoyObj?.name})</h1>
            <div className="userTabsDesign">
                <div className="card-body" style={{ backgroundColor: "white", padding: 15 }}>
                    <div className="container-fluid">
                        <div className="row">
                            <h2 >Target</h2>
                            <div className="col-12">

                                <div className="table-responsive">
                                    <table className="table table-responsive-sm">
                                        <thead>
                                            <tr style={{ fontSize: 14 }}>
                                                <th>Month</th>
                                                <th>Hours Online</th>
                                                <th>Deliveries</th>
                                                <th>KM</th>
                                                <th>View Percentage</th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {
                                                targetArr && targetArr.map(el => {
                                                    return (
                                                        <tr key={el._id} style={{ fontSize: 14, fontWeight: "100" }}>
                                                            <td>{el.monthName}</td>
                                                            <td>{el.hoursOnline}</td>
                                                            <td>{el.deliveries}</td>
                                                            <td>{el.km}</td>
                                                            <td className="text-center">
                                                                <button data-toggle="modal"  data-target="#exampleModal3" type="button" className="btn addModifiBttn mr-2">View Percentages</button>
                                                            </td>
                                                            <td className="text-center">
                                                                <button data-toggle="modal" data-target="#exampleModal" type="button" className="btn addModifiBttn mr-2"> Modify </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <div className="row">
                            <h2>Incentive</h2>

                            <div className="col-12">

                                <div className="table-responsive">
                                    <table className="table table-responsive-sm">
                                        <thead>
                                            <tr style={{ fontSize: 14 }}>
                                                <th>Month</th>
                                                <th>Hours Online</th>
                                                <th>Deliveries</th>
                                                <th>KM</th>
                                                <th>Amount</th>
                                                <th>View Percentages</th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {
                                                incentiveArr && incentiveArr.map(el => {
                                                    return (
                                                        <tr key={el._id} style={{ fontSize: 14, fontWeight: "100" }}>
                                                            <td>{el.monthName}</td>
                                                            <td>{el.hoursOnline}</td>
                                                            <td>{el.deliveries}</td>
                                                            <td>{el.km}</td>
                                                            <td>{el.amount}</td>
                                                            <td className="text-center">
                                                                <button onClick={()=>handlePercentageSelection(el)} data-toggle="modal" data-target="#exampleModal4" type="button" className="btn addModifiBttn mr-2"> View Percentages </button>
                                                            </td>
                                                            <td className="text-center">
                                                                <button onClick={() => { }} data-toggle="modal" data-target="#exampleModal2" type="button" className="btn addModifiBttn mr-2"> Modify </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <div className="row">

                            <DataTable
                                title={`Delivery Pincodes- ${deliveryBoyObj?.stateName}`}
                                columns={Columns}
                                data={deliveryBoyPincodeArr}
                                sortIcon={sortIcon}
                                // keyField='_id'
                                pagination
                            />

                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <div className="row">
                            <h3>Delivery Boy Salary-({deliveryBoyObj?.name})</h3>
                            <form>
                                <div className="row">

                                    <div className="col-12 col-md-6 my-3">
                                        <div className="field-group">
                                            <label>Basic Salary</label>
                                            <input type="number" value={deliveryBoyObj?.salary?.basic} onChange={(e) => setDeliveryBoySalaryUpdate(e.target.value, "basic")} className="form-control" placeholder="Enter Hour's Online" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 my-3">
                                        <div className="field-group">
                                            <label>Allowance</label>
                                            <input type="number" value={deliveryBoyObj?.salary?.allowance} onChange={(e) => setDeliveryBoySalaryUpdate(e.target.value, "allowance")} className="form-control" placeholder="Enter Deliveries" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 my-3">
                                        <div className="field-group">
                                            <label>Fuel Allowance</label>
                                            <input type="number" value={deliveryBoyObj?.salary?.fuelAllowance} onChange={(e) => setDeliveryBoySalaryUpdate(e.target.value, "fuelAllowance")} className="form-control" placeholder="Enter Km" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 my-3">
                                        <div className="field-group">
                                            <label>Other</label>
                                            <input type="number" value={deliveryBoyObj?.salary?.other} onChange={(e) => setDeliveryBoySalaryUpdate(e.target.value, "other")} className="form-control" placeholder="Enter Km" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4 d-flex justify-content-center">
                                    <div className="col-12 col-md-3">
                                        <div className="field-group">
                                            <button type="button" onClick={() => updateDeliveryBoySalary()} className="btn searchBttn"> Update Salary </button>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>






                    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <form>
                                        <div className="row">
                                            <div className="col-12 col-md-6 my-3">
                                                <div className="field-group">
                                                    <select className="form-control" onChange={(e) => { setMonth(e.target.value) }} >
                                                        <option value="">--Please Select a month--</option>
                                                        {
                                                            monthsArr && monthsArr.length > 0 && monthsArr.map((el, index) => {
                                                                return (
                                                                    <option key={el.name} value={index}>{el.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {/* <input type="text" onChange={(e) => setMonth(e.target.value)} className="form-control" placeholder="Enter Month" /> */}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 my-3">
                                                <div className="field-group">
                                                    <select className="form-control"  onChange={(e) => { setYear(e.target.value) }} >
                                                        <option value="">--Please Select a Year--</option>
                                                        {
                                                            yearArr && yearArr.length > 0 && yearArr.map((el, index) => {
                                                                return (
                                                                    <option key={el} value={el}>{el}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {/* <input type="text" onChange={(e) => setMonth(e.target.value)} className="form-control" placeholder="Enter Month" /> */}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 my-3">
                                                <div className="field-group">
                                                    <input type="text" onChange={(e) => setHoursOnline(e.target.value)} className="form-control" placeholder="Enter Hour's Online" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 my-3">
                                                <div className="field-group">
                                                    <input type="text" onChange={(e) => setDeliveries(e.target.value)} className="form-control" placeholder="Enter Deliveries" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 my-3">
                                                <div className="field-group">
                                                    <input type="text" onChange={(e) => setKm(e.target.value)} className="form-control" placeholder="Enter Km" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-4 d-flex justify-content-center">
                                            <div className="col-12 col-md-3">
                                                <div className="field-group">
                                                    <button type="button" data-toggle="modal" onClick={() => handleSubmit()} className="btn searchBttn"> Save </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>




                    <div class="modal fade" id="exampleModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Incentive</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <form>
                                        <div className="row">
                                            <div className="col-12 col-md-6 my-3">
                                                <div className="field-group">
                                                    <select className="form-control" onChange={(e) => { setMonth(e.target.value) }} >
                                                        <option value="">--Please Select a month--</option>
                                                        {
                                                            monthsArr && monthsArr.length > 0 && monthsArr.map((el, index) => {
                                                                return (
                                                                    <option key={el.name} value={index}>{el.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {/* <input type="text" onChange={(e) => setMonth(e.target.value)} className="form-control" placeholder="Enter Month" /> */}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 my-3">
                                                <div className="field-group">
                                                    <select className="form-control" onChange={(e) => { setYear(e.target.value) }} >
                                                        <option value="">--Please Select a Year--</option>
                                                        {
                                                            yearArr && yearArr.length > 0 && yearArr.map((el, index) => {
                                                                return (
                                                                    <option key={el} value={el}>{el}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {/* <input type="text" onChange={(e) => setMonth(e.target.value)} className="form-control" placeholder="Enter Month" /> */}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 my-3">
                                                <div className="field-group">
                                                    <input type="text" onChange={(e) => setHoursOnline(e.target.value)} className="form-control" placeholder="Enter Hour's Online" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 my-3">
                                                <div className="field-group">
                                                    <input type="text" onChange={(e) => setDeliveries(e.target.value)} className="form-control" placeholder="Enter Deliveries" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 my-3">
                                                <div className="field-group">
                                                    <input type="text" onChange={(e) => setKm(e.target.value)} className="form-control" placeholder="Enter Km" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="field-group">
                                                    <input type="text" onChange={(e) => setAmount(e.target.value)} className="form-control" placeholder="Enter Amount" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-4 d-flex justify-content-center">
                                            <div className="col-12 col-md-3">
                                                <div className="field-group">
                                                    <button type="button" onClick={() => handleIncentiveSubmit()} className="btn searchBttn"> Save </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>




                    <div class="modal fade" id="exampleModal3" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Target</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <form>
                                        <div className="row">
                                            <div className="col-12 col-md-6 my-3">
                                                <div className="field-group">
                                                    <select className="form-control" onChange={(e) => { setSalaryMonth(e.target.value) }} >
                                                        <option value="">--Please Select a month--</option>
                                                        {
                                                            monthsArr && monthsArr.length > 0 && monthsArr.map((el, index) => {
                                                                return (
                                                                    <option key={el.name} value={index}>{el.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 my-3">
                                                <div className="field-group">
                                                    <select className="form-control" onChange={(e) => { setSalaryYear(e.target.value) }} >
                                                        <option value="">--Please Select a Year--</option>
                                                        {
                                                            yearArr && yearArr.length > 0 && yearArr.map((el, index) => {
                                                                return (
                                                                    <option key={el} value={el}>{el}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <table className="table table-responsive m-0">
                                                <thead>
                                                    <tr>
                                                        <th>Combinations</th>
                                                        <th>Value in (Percentage) </th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td> No parameters are Achieved</td>
                                                        <td> <input className="form-control" onChange={(e) => setvalue4(e.target.value)} value={value4} type="text" /> </td>
                                                        <td>
                                                            <button type="button" onClick={() => submitEffectiveSalary(4)} className="btn searchBttn"> Save </button>
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td>1 parameter is Achieved</td>
                                                        <td> <input className="form-control" onChange={(e) => setValue1(e.target.value)} value={value1} type="text" /> </td>
                                                        <td>
                                                            <button type="button" onClick={() => submitEffectiveSalary(1)} className="btn searchBttn"> Save </button>
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td> 2 parameter are Achieved </td>
                                                        <td> <input className="form-control" onChange={(e) => setValue2(e.target.value)} value={value2} type="text" /> </td>
                                                        <td>
                                                            <button type="button" onClick={() => submitEffectiveSalary(2)} className="btn searchBttn"> Save </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td> All parameters are Achieved</td>
                                                        <td> <input className="form-control" onChange={(e) => setValue3(e.target.value)} value={value3} type="text" /> </td>
                                                        <td>
                                                            <button type="button" onClick={() => submitEffectiveSalary(3)} className="btn searchBttn"> Save </button>
                                                        </td>

                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>







                    <div class="modal fade" id="exampleModal4" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Incentive</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <form>
                                        <div className="row">
                                            <div className="col-12 col-md-6 my-3">
                                                <div className="field-group">
                                                    <select className="form-control" value={incentiveMonth} onChange={(e) => { setIncentiveMonth(e.target.value) }} >
                                                        <option value="">--Please Select a month--</option>
                                                        {
                                                            monthsArr && monthsArr.length > 0 && monthsArr.map((el, index) => {
                                                                return (
                                                                    <option key={el.name} value={index}>{el.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 my-3">
                                                <div className="field-group">
                                                    <select className="form-control" value={incentiveYear} onChange={(e) => { setIncentiveYear(e.target.value) }} >
                                                        <option value="">--Please Select a Year--</option>
                                                        {
                                                            yearArr && yearArr.length > 0 && yearArr.map((el, index) => {
                                                                return (
                                                                    <option key={el} value={el}>{el}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <table className="table table-responsive m-0">
                                                <thead>
                                                    <tr>
                                                        <th>Combinations</th>
                                                        <th>Value in (Percentage) </th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td> No parameters are Achieved</td>
                                                        <td> <input className="form-control" onChange={(e) => setValue8(e.target.value)} value={value8} type="text" /> </td>
                                                        <td>
                                                            <button type="button" onClick={() => submitEffectiveIncentive(4)} className="btn searchBttn"> Save </button>
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td>1 parameter is Achieved</td>
                                                        <td> <input className="form-control" onChange={(e) => setValue5(e.target.value)} value={value5} type="text" /> </td>
                                                        <td>
                                                            <button type="button" onClick={() => submitEffectiveIncentive(1)} className="btn searchBttn"> Save </button>
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td> 2 parameter are Achieved </td>
                                                        <td> <input className="form-control" onChange={(e) => setValue6(e.target.value)} value={value6} type="text" /> </td>
                                                        <td>
                                                            <button type="button" onClick={() => submitEffectiveIncentive(2)} className="btn searchBttn"> Save </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td> All parameters are Achieved</td>
                                                        <td> <input className="form-control" onChange={(e) => setValue7(e.target.value)} value={value7} type="text" /> </td>
                                                        <td>
                                                            <button type="button" onClick={() => submitEffectiveIncentive(3)} className="btn searchBttn"> Save </button>
                                                        </td>

                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        </>
    );
}