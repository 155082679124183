import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { url } from '../../Services/Url'
import { ToastContainer, toast } from 'react-toastify';
import { getAreaByCityId, uploadAreaData } from '../../Services/area';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Select from 'react-select'
import { getAllSubAdmins, removeSubAdmin, subAdminRegister, subAdminStatusToggle, subAdminUpdate } from '../../Services/SubAdmin';
const MySwal = withReactContent(Swal)

export default function SubAdmin() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [stateArr, setStateArr] = useState();
    const [cityArr, setCityArr] = useState();
    const [areaArr, setAreaArr] = useState();
    const [cityFilteredArr, setCityFilteredArr] = useState();
    const [selectedStateId, setSelectedStateId] = useState("");
    const [selectedCityId, setSelectedCityId] = useState("");
    const [filteredSubAdminArr, setFilteredSubAdminArr] = useState();

    const [subAdminArr, setSubAdminArr] = useState([]);


    const [pincodeArr, setPincodeArr] = useState([]);
    const [finalPincodeArr, setFinalPincodeArr] = useState([]);
    const [searchTxt, setSearchTxt] = useState("");

    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const [uploadFile, setUploadFile] = useState();

    const [updateModal, setUpdateModal] = useState(false);
    const [selectedSubadminObj, setSelectedSubadminObj] = useState({});
    const [subadminPincodeArr, setSubadminPincodeArr] = useState([]);
    const sortIcon = <ArrowDownward />;




    const handleSubmit = async () => {
        try {
            let obj = {
                name,
                email,
                password,
                phone,
                pincodesArr: finalPincodeArr.map(el => {
                    let obj = {
                        pincode: el.value,
                    }
                    return obj
                })
            }
            const { data: res, status: statusCode } = await subAdminRegister(obj);
            if (statusCode == 200 || statusCode == 304) {
                getSubAdmins();
                setName('');
                setEmail('');
                setPassword('');
                setPhone('');
                setSelectedStateId('')
                setSelectedCityId('')
                setPincodeArr([])
                alert(res.message)
            }

        } catch (error) {
            // console.error(JSON.stringify(error.response,null,2))
            if(error?.response?.data?.message){
                alert(error.response.data.message)
            }
            else{
                alert(error.message)
            }
        }
    }
    const handleUpdateSubadmin = async () => {
        try {
            let obj = {
                pincodesArr: finalPincodeArr.map(el => {
                    let obj = {
                        pincode: el.value,
                        active:true
                    }
                    return obj
                })
            }
            const { data: res, status: statusCode } = await subAdminUpdate(selectedSubadminObj._id,obj);
            if (statusCode == 200 || statusCode == 304) {
                getSubAdmins();
                setName('');
                setEmail('');
                setPassword('');
                setPhone('');
                setSelectedStateId('')
                setSelectedCityId('')
                setPincodeArr([])
                alert(res.message)
            }

        } catch (error) {
            console.error(error)
        }
    }


    const getSubAdmins = async () => {
        try {
            const { data: res, status: statusCode } = await getAllSubAdmins();
            if (statusCode == 200 || statusCode == 304) {
                setSubAdminArr(res.data)
                console.log(res.data)
                setFilteredSubAdminArr(res.data)
            }
        } catch (error) {
            console.error(error)
        }
    }


    const getCity = async () => {
        try {
            let { data: res } = await axios.get(`${url}/city/`)
            if (res.success) {
                // toast.success(res.message)
                setCityArr(res.data);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }


    const getState = async () => {
        try {
            let { data: res } = await axios.get(`${url}/state/`)
            if (res.success) {
                // toast.success(res.message)
                setStateArr(res.data);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }

    const handleSubadminDelete = async (id) => {
        try {
            let temp = true
            if (temp == true) {

                let { data: res } = await removeSubAdmin(id)
                if (res.success) {
                    toast.success(res.message)
                    getSubAdmins()
                }
            }

        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }



    const setCityArrFromState = (value) => {
        setSelectedStateId(value)
        let cityfilteredArr = cityArr.filter(el => el.stateId == value);
        setCityFilteredArr(cityfilteredArr)
    }




    const filterAreaArr = () => {
        console.log(selectedCityId)
        console.log(selectedStateId)
        let tempAreaArr = areaArr.filter(el => el.stateId == selectedStateId && el.cityId == selectedCityId)
        console.log(tempAreaArr)
        setFilteredSubAdminArr(tempAreaArr)
    }
    const searchSubAdmin = (val) => {
        if (val == "")
            setFilteredSubAdminArr([...subAdminArr])
        else {
            let tempResult = subAdminArr.filter(el => el.name.toLowerCase().includes(val.toLowerCase() || el.pincodesArr.some(ele => ele.pincode.includes(`${val}`))));
            setFilteredSubAdminArr(tempResult)
        }
    }




    const columns = [
        {
            name: 'S.no',
            selector: row => row.sno,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => `${row.name}`,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => `${row.email}`,
            sortable: true,
        },
        {
            name: 'Phone',
            selector: row => row.phone,
            sortable: true,
        },
        {
            name: 'State',
            selector: row => row.stateName,
            sortable: true,
        },
        {
            name: 'City',
            selector: row => row.cityName,
            sortable: true,
        },
        {
            name: 'Areas',
            cell: row => row.areas,
            sortable: true,
            width: '200px'
        },
        {
            name: 'Allocated Pin Codes',
            cell: row => row?.pincodesArr?.reduce((acc, el) => acc + el.pincode +','+'\n', ''),
            sortable: true,
            width: '200px'

        },
        // {
        //     name: 'Status',
        //     selector: row => row.active ? "Active" : "In-Active",
        //     sortable: true,
        // },

        {
            name: 'Actions',
            width:'300px',
            cell: row => <div> {
                row.active
                    ?
                    <button onClick={() => handleSubadminActive(row._id, false)} className="btn addModifiBttn mr-2">Set In-Active</button>
                    :
                    <button onClick={() => handleSubadminActive(row._id, true)} className="btn greenBttn mr-2">Set Active</button>

            }
                <button onClick={() => handleSubadminDelete(row._id)} className="btn btn-danger mr-2">Delete</button>
                <button  data-toggle="modal" data-target="#modifyAssignTarget" type="button" onClick={() => { setSelectedSubadminObj(row);  handleCitySelectForSubadmin(row?.cityId) }} className="btn greenBttn mr-2">Update</button>

            </div>
        },


    ];


    const handleSubadminActive = async (subAdminId, val) => {
        try {
            const { data: res, status: statusCode } = await subAdminStatusToggle(subAdminId, { active: val });
            if (statusCode == 200 || statusCode == 304) {

                getSubAdmins()
                alert(res.message)
            }
        } catch (error) {
            console.error(error)
        }
    }




    const handleCitySelect = async (cityId) => {
        setSelectedCityId(cityId)
        try {
            let finalArr = []
            const { data: res, status: statusCode } = await getAreaByCityId(cityId);
            if (statusCode == 200 || statusCode == 304) {
                let tempArr = res.data;
                finalArr = tempArr.filter((el, i, arr) => i == arr.findIndex(ele => ele.pincode == el.pincode))
                finalArr = finalArr.filter(el => !filteredSubAdminArr.some(ele => ele.pincodesArr.some(elx => elx.pincode == el.pincode)))
                setPincodeArr([...finalArr.map(el => ({ label: `${el.pincode}`, value: `${el.pincode}` }))])
                console.log([...finalArr.map(el => [{ label: `${el.pincode}`, value: `${el.pincode}` }])])
            }
        } catch (error) {
            console.error(error)
        }
    }

    const handleCitySelectForSubadmin = async (cityId) => {
        try {
            let finalArr = []
            const { data: res, status: statusCode } = await getAreaByCityId(cityId);
            if (statusCode == 200 || statusCode == 304) {
                let tempArr = res.data;
                finalArr = tempArr.filter((el, i, arr) => i == arr.findIndex(ele => ele.pincode == el.pincode))
                // finalArr = finalArr.filter(el => !filteredSubAdminArr.some(ele => ele.pincodesArr.some(elx => elx.pincode == el.pincode)))
                // finalArr = 
                console.log(finalArr)
                setSubadminPincodeArr([...finalArr.map(el => ({ label: `${el.pincode}`, value: `${el.pincode}` }))])
            }
        } catch (error) {
            console.error(error)
        }
    }

    const handlePincodeSelect = (val) => {
        console.log(val)
        setFinalPincodeArr(val)
    }
    
  
    


    useEffect(() => {
        getState();
        getCity();
        getSubAdmins()
    }, [])
    return (
        <main className="main_content">
            <div className="userTabsDesign mb-4">
                <div className="headingTitle"><h3> Sub-Admins</h3></div>
                <div className="card">
                    <div className="card-body">
                        <div className="AddModiForm w-100">
                            <form onSubmit={(e) => { handleSubmit() }}>
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="field-group">
                                            <label>Enter Name</label>
                                            <input type="text" className="form-control" value={name} onChange={(event) => setName(event.target.value)} placeholder="Name" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="field-group">
                                            <label>Enter Email</label>
                                            <input type="text" className="form-control" value={email} onChange={(event) => setEmail(event.target.value)} placeholder="Email" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="field-group">
                                            <label>Enter Phone</label>
                                            <input type="number" className="form-control" value={phone} onChange={(event) => setPhone(event.target.value)} placeholder="Phone" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="field-group">
                                            <label>Enter Password</label>
                                            <input type="text" className="form-control" value={password} onChange={(event) => setPassword(event.target.value)} placeholder="password" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="field-group">
                                            <label>Select State</label>
                                            <select name="" id="" className="form-control" onChange={(event) => { setCityArrFromState(event.target.value) }}>
                                                <option value="">Please Select a State</option>
                                                {
                                                    stateArr && stateArr.length > 0 && stateArr.map(el => {
                                                        return (
                                                            <option key={el._id} value={el._id}>{el.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>

                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="field-group">
                                            <label>Select City</label>
                                            <select name="" id="" className="form-control" onChange={(event) => { handleCitySelect(event.target.value) }}>
                                                <option value="">Please Select a City</option>
                                                {
                                                    cityFilteredArr && cityFilteredArr.length > 0 && cityFilteredArr.map(el => {
                                                        return (
                                                            <option key={el._id} value={el._id}>{el.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>

                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12">
                                        <div className="field-group">
                                            <label>Select Pincode</label>
                                            <Select options={pincodeArr} onChange={handlePincodeSelect} isMulti />

                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4 d-flex justify-content-start">
                                    <div className="col-4 col-md-3">
                                        <div className="field-group">
                                            <button type="button" onClick={() => handleSubmit()} className="btn searchBttn"> Save </button>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="infoOrdertable mb-4">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <label htmlFor="">Search</label>
                        <div className="field-group">
                            <input type="text" className="form-control" onChange={(event) => searchSubAdmin(event.target.value)} placeholder="Search from name, and pincode" />
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col-12 col-md-6 mt-4">
                        <label htmlFor="">State</label>

                        <div className="field-group">
                            <select name="" id="" className="form-control" onChange={(event) => { setCityArrFromState(event.target.value) }}>
                                <option value="">Please Select a State</option>
                                {
                                    stateArr && stateArr.length > 0 && stateArr.map(el => {
                                        return (
                                            <option key={el._id} value={el._id}>{el.name}</option>
                                        )
                                    })
                                }
                            </select>

                        </div>
                    </div>
                    <div className="col-12 col-md-6 mt-4">
                        <label htmlFor="">City</label>

                        <div className="field-group">
                            <select name="" id="" className="form-control" onChange={(event) => { setSelectedCityId(event.target.value) }}>
                                <option value="">Please Select a City</option>
                                {
                                    cityFilteredArr && cityFilteredArr.length > 0 && cityFilteredArr.map(el => {
                                        return (
                                            <option key={el._id} value={el._id}>{el.name}</option>
                                        )
                                    })
                                }
                            </select>

                        </div>
                    </div>
                    <div className="col-4 mt-4 mb-4 col-md-3">
                        <div className="field-group">
                            <button type="button" onClick={() => filterAreaArr()} className="btn searchBttn">Find </button>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <DataTable
                        title="Sub-admins"
                        columns={columns}
                        data={filteredSubAdminArr?.map((el, index) => {
                            let obj = {
                                sno: index + 1,
                                ...el
                            }
                            return obj
                        })}
                        sortIcon={sortIcon}
                        keyField='_id'
                        pagination
                    />

                </div>
            </div>
            <Modal open={open} onClose={onCloseModal} center classNames="col-6">
                <div style={{ padding: 50, overflowX: 'hidden' }} className='row'>
                    <h2 className="text-center">Upload</h2>
                    <div className="col">
                        <div className="mb-3">
                            <label htmlFor="formFile" className="form-label">File Input</label>
                            <input type="file" className="form-control" onChange={(e) => e.target.files && e.target.files[0] ? setUploadFile(e.target.files[0]) : 0} />
                        </div>
                        {/* <div>
                            <button className="btn btn-primary" onClick={() => onSubmitUpload()}>
                                Upload
                            </button>
                        </div> */}
                    </div>
                </div>

            </Modal>
            
            <div id="modifyAssignTarget" className="modal fade" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title text-center"> Update Pincode</h4>
                        </div>
                        <div className="modal-body">
                            <div className="AddModiForm">
                                <form>
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <label> Pincode </label>
                                                <Select options={subadminPincodeArr}  onChange={handlePincodeSelect} isMulti />
                                            </div>
                                        </div>
                                      
                                        
                                    </div>
                                    <div className="row mt-4 d-flex justify-content-center">
                                        <div className="col-12 col-md-3">
                                            <div className="field-group">
                                                <button type="button" data-toggle="modal" data-target="#modifyAssignTarget" onClick={() => handleUpdateSubadmin()} className="btn searchBttn"> Save </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main >
    )
}
