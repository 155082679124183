import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { url } from '../../Services/Url'
import { ToastContainer, toast } from 'react-toastify';
import MaterialTable from 'material-table'


function AddDeliveryBoy() {
    const [toggle, setToggle] = useState(false);





    const [status, setStatus] = useState();
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [address, setAddress] = useState();
    const [mobile, setMobile] = useState();
    const [pinCode, setpinCode] = useState();
    const [rcNumber, setRcNumber] = useState();
    const [chasisNumber, setchasisNumber] = useState();
    const [licenseNumber, setLicenseNumber] = useState();
    const [aadhaarNumber, setAadhaarNumber] = useState();
    const [dateOfJoining, setdateOfJoining] = useState();
    const [vehicleId, setVehicleId] = useState("");
    const [aadhaarImage, setaadhaarImage] = useState();
    const [licenseImage, setLicenseImage] = useState();
    const [rcImage, setRcImage] = useState();
    const [dailyDeliveryLimit, setDailyDeliveryLimit] = useState();
    ///////////////////////////////////salary
    const [basic, setBasic] = useState();
    const [allowance, setAllowance] = useState();
    const [fuelAllowance, setFuelAllowance] = useState();
    const [other, setOther] = useState();


    ////////////Arrays
    const [stateArr, setStateArr] = useState();
    const [cityArr, setCityArr] = useState();
    const [areaArr, setAreaArr] = useState();
    const [cityFilteredArr, setCityFilteredArr] = useState();
    const [areaFilteredArr, setAreaFilteredArr] = useState();
    const [VehiclesArr, setVehiclesArr] = useState();
    const [deliveryBoyArr, setDeliveryBoyArr] = useState();

    //////////location final values
    const [selectedStateId, setSelectedStateId] = useState("");
    const [selectedCityId, setSelectedCityId] = useState("");
    const [area, setArea] = useState("");

    const [selectedVehicleObj, setSelectedVehicleObj] = useState({});

    const [permanentAdress, setPermanentAdress] = useState('');
    const [permanentAdressPincode, setPermanentAdressPincode] = useState('');

    const [currentAddress, setCurrentAddress] = useState('');
    const [currentAddressPincode, setCurrentAddressPincode] = useState('');

    const getCity = async () => {
        try {
            let { data: res } = await axios.get(`${url}/city/`)
            if (res.success) {
                let tempCityArr = res.data.filter(el => el.active == true && el.stateActive == true);
                setCityArr(tempCityArr);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }


    const getState = async () => {
        try {
            let { data: res } = await axios.get(`${url}/state/`)
            if (res.success) {
                // toast.success(res.message)
                let tempStateArr = res.data.filter(el => el.active == true);
                setStateArr(tempStateArr);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }

    const setCityArrFromState = (value) => {
        setSelectedStateId(value)
        let cityfilteredArr = cityArr.filter(el => el.stateId == value);
        setCityFilteredArr(cityfilteredArr)
    }

    const setAreaArrFromCity = (value) => {
        setSelectedCityId(value)
        let areafilteredArr = areaArr.filter(el => el.cityId == value);
        setAreaFilteredArr(areafilteredArr)
    }


    const getArea = async () => {
        try {
            let { data: res } = await axios.get(`${url}/area/`)
            if (res.success) {
                console.log(res.data)
                let tempAreaArr = res.data.filter(el => el.active == true && el.stateActive == true && el.cityActive == true);
                console.log(tempAreaArr)
                // toast.success(res.message)
                setAreaArr(tempAreaArr);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }

    const getVehicles = async () => {
        try {
            let { data: res } = await axios.get(`${url}/vehicle/`)
            if (res.success) {
                setVehiclesArr(res.data);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }




    const handleSubmit = async () => {
        try {
            console.log(name,password,mobile,selectedVehicleObj._id)
            if (name && password && mobile  && selectedVehicleObj._id) {
                if (selectedVehicleObj._id == "") {
                    alert("Vehicle Type must be selected")
                }
                else {
                    let obj = {
                        status: 1,
                        name,
                        email,
                        password,
                        address,
                        mobile,
                        pinCode,
                        vehicleId: selectedVehicleObj._id,
                        rcNumber,
                        chasisNumber,
                        licenseNumber,
                        aadhaarNumber,
                        // dailyDeliveryLimit,
                        dateOfJoining: new Date(),

                        permanentAddressObj: {
                            address: permanentAdress,
                            pinCode: permanentAdressPincode
                        },
                        currentAddressObj: {
                            address: permanentAdress,
                            pinCode: permanentAdressPincode
                        },
                        // salary: {
                        //     basic,
                        //     allowance,
                        //     fuelAllowance,
                        //     other,
                        // }
                    }

                    let formData = new FormData();
                    if (selectedVehicleObj.documentRequired) {

                        formData.append("file", aadhaarImage);
                        formData.append("file1", licenseImage);
                        formData.append("file2", rcImage);
                    }
                    else {
                        formData.append("file", aadhaarImage);
                        formData.append("file1", aadhaarImage);
                        formData.append("file2", aadhaarImage);
                    }
                    // console.log(aadhaarImage)
                    // console.log(licenseImage)
                    // console.log(rcImage)

                    // console.log(obj)
                    let { data: res } = await axios.post(`${url}/deliveryBoy/`, obj)
                    if (res.success) {
                        let resImage = await axios.patch(`${url}/deliveryBoy/uploadImageById/${res.data._id}`, formData)
                        toast.success(res.message);
                        if (resImage.sucess) {
                            toast.success(resImage.message);
                        }
                        else {
                            toast.error(resImage.message);

                        }
                        getDeliveryBoys()
                    }
                }
            }
            else{
                alert("Please fill all the fields")

            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }

    }

    const getDeliveryBoys = async () => {
        try {
            let { data: res } = await axios.get(`${url}/deliveryBoy/`)
            if (res.success) {
                setDeliveryBoyArr(res.data);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }




    useEffect(() => {
        // getState();
        // getCity();
        // getArea();
        getVehicles();
        getDeliveryBoys();
    }, [])


    return (
        <main className="main_content">
            <div className="headingTitle"><h3> Add Delivery Boy </h3></div>
            <div className="card">
                <div className="card-body">
                    <div className="AddModiForm w-100">
                        <form>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="field-group">
                                        <label>Name</label>

                                        <input type="text" onChange={(e) => setName(e.target.value)} className="form-control" placeholder="Enter Delivery Boy Name" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="field-group">
                                        <label>Mobile</label>

                                        <input type="number" onChange={(e) => setMobile(e.target.value)} className="form-control" placeholder="Enter Mobile No." />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="field-group">
                                        <label>Email (optional)</label>

                                        <input type="text" onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="Enter Email Address" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="field-group">
                                        <label>Password</label>

                                        <input type="number" maxLength={4} onChange={(e) => setPassword(e.target.value)} className="form-control" placeholder="Enter Password" />
                                    </div>
                                </div>
                                {/* <div className="col-12 col-md-6">
                                    <div className="field-group">
                                        <label>State</label>

                                        <select name="" id="" className="form-control" onChange={(event) => { setCityArrFromState(event.target.value) }}>
                                            <option value="">Please Select a State</option>
                                            {
                                                stateArr && stateArr.length > 0 && stateArr.map(el => {
                                                    return (
                                                        <option key={el._id} value={el._id}>{el.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="field-group">
                                        <label>City</label>

                                        <select name="" id="" className="form-control" onChange={(event) => { setAreaArrFromCity(event.target.value) }}>
                                            <option value="">Please Select a City</option>
                                            {
                                                cityFilteredArr && cityFilteredArr.length > 0 && cityFilteredArr.map(el => {
                                                    return (
                                                        <option key={el._id} value={el._id}>{el.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="col-12 col-md-6">
                                    <div className="field-group">
                                        <label>Area</label>

                                        <select name="" id="" className="form-control" onChange={(event) => { setArea(event.target.value) }}>
                                            <option value="">Please Select a Area</option>
                                            {
                                                areaFilteredArr && areaFilteredArr.length > 0 && areaFilteredArr.map(el => {
                                                    return (
                                                        <option key={el._id} value={el._id}>{el.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div> */}
                                <div className="col-12 col-md-6">
                                    <div className="field-group">
                                        <label>Permanent Address</label>
                                        <input type="text" onChange={(e) => setPermanentAdress(e.target.value)} className="form-control" placeholder="Enter Your Address" />
                                    </div>
                                </div>

                                <div className="col-12 col-md-6">
                                    <div className="field-group">
                                        <label>Permanent Address Pincode</label>
                                        <input type="text" onChange={(e) => setPermanentAdressPincode(e.target.value)} className="form-control" placeholder="Enter Your Address" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="field-group">
                                        <label>Current Address</label>
                                        <input type="text" onChange={(e) => setCurrentAddress(e.target.value)} className="form-control" placeholder="Enter Your Address" />
                                    </div>
                                </div>

                                <div className="col-12 col-md-6">
                                    <div className="field-group">
                                        <label>Current Address Pincode</label>
                                        <input type="text" onChange={(e) => setCurrentAddressPincode(e.target.value)} className="form-control" placeholder="Enter Your Address" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="field-group">
                                        <label>Prefered Working Pincode</label>

                                        <input type="number" maxLength={6} onChange={(e) => setpinCode(e.target.value)} className="form-control" placeholder="Enter Pincode" />
                                    </div>
                                </div>

                                <div className="col-12 col-md-6">
                                    <div className="field-group">
                                        <label>Vehicle Type</label>
                                        <select name="" id="" className="form-control" onChange={(event) => { console.log(event.target.value); setSelectedVehicleObj(VehiclesArr[event.target.value]) }}>
                                            <option value="">Please Select a Vehicle Type</option>
                                            {
                                                VehiclesArr && VehiclesArr.length > 0 && VehiclesArr.map((el, i) => {
                                                    return (
                                                        <option key={el._id} value={i}>{el.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        {/* <input type="text" className="form-control" placeholder="Enter Vehicle Type" /> */}
                                    </div>
                                </div>
                                {selectedVehicleObj?.documentRequired &&
                                    <>

                                        <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <label>Vehicle RC Number</label>

                                                <input type="text" onChange={(e) => setRcNumber(e.target.value)} className="form-control" placeholder="Enter Vehicle Registration Number (RC No.)" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <label>Vehicle Chassis Number</label>

                                                <input type="text" onChange={(e) => setchasisNumber(e.target.value)} className="form-control" placeholder="Enter Vehicle Chasis Number" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <label>License Number</label>

                                                <input type="text" onChange={(e) => setLicenseNumber(e.target.value)} className="form-control" placeholder="Enter Driving Licence Details (Licence No.)" />
                                            </div>
                                        </div>
                                    </>

                                }
                                <div className="col-12 col-md-6">
                                    <div className="field-group">
                                        <label>Aadhaar Number</label>

                                        <input type="text" onChange={(e) => setAadhaarNumber(e.target.value)} className="form-control" placeholder="Enter Address Veification documents (Aadhar Card No.)" />
                                    </div>
                                </div>
                                {/* <div className="col-12 col-md-6">
                                    <div className="field-group">
                                        <input type="date" onChange={(e) => setdateOfJoining(e.target.value)} className="form-control" placeholder="Enter Date of Joining" />
                                    </div>
                                </div> */}


                                {/* <div className="col-12 col-md-6 mb-4">
                                    <label>Status</label>
                                    <div className="field-group">
                                        <button onClick={() => setStatus(0)} type="button" className="btn addModifiBttn mr-2"> Approve </button>
                                        <button onClick={() => setStatus(1)} type="button" className="btn addModifiBttn"> Pending Approve </button>
                                    </div>
                                </div> */}
                                {selectedVehicleObj?.documentRequired &&
                                    <>
                                        <div className="col-12 col-md-6 mb-4">
                                            <label>Upload Vehicle RC Photo</label>
                                            <div className="field-group inputFileUploadd">
                                                <input onChange={(e) => setRcImage(e.target.files[0])} type="file" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 mb-4">
                                            <label>Upload Driving Licence Photo </label>
                                            <div className="field-group inputFileUploadd">
                                                <input onChange={(e) => setLicenseImage(e.target.files[0])} type="file" />
                                            </div>
                                        </div>

                                    </>}
                                <div className="col-12 col-md-6 mb-4">
                                    <label>Upload Aadhar Card Photo </label>
                                    <div className="field-group inputFileUploadd">
                                        <input onChange={(e) => setaadhaarImage(e.target.files[0])} type="file" />
                                    </div>
                                </div>
                            </div>
                            {/* <label>Salary Details </label>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="field-group">
                                        <input type="text" onChange={(e) => setBasic(e.target.value)} className="form-control" placeholder="Enter Basic Salary" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="field-group">
                                        <input type="text" onChange={(e) => setAllowance(e.target.value)} className="form-control" placeholder="Enter Allowance" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="field-group">
                                        <input type="text" onChange={(e) => setFuelAllowance(e.target.value)} className="form-control" placeholder="Enter Fuel Allowance" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="field-group">
                                        <input type="text" onChange={(e) => setOther(e.target.value)} className="form-control" placeholder="Enter Other" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="field-group">
                                        <input type="text" onChange={(e) => setDailyDeliveryLimit(e.target.value)} className="form-control" placeholder="Enter Daily Delivery Limit" />
                                    </div>
                                </div>
                            </div> */}
                            <div className="row mt-4 d-flex justify-content-center">
                                <div className="col-12 col-md-3">
                                    <div className="field-group">
                                        <button type="button" onClick={() => handleSubmit()} className="btn searchBttn"> Save </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default AddDeliveryBoy;
