import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { url } from '../../Services/Url'
import { ToastContainer, toast } from 'react-toastify';
import { setSelectedAreasActive, setSelectedAreasInActive, uploadAreaData } from '../../Services/area';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
const MySwal = withReactContent(Swal)

export default function AddArea() {
    const [name, setName] = useState();
    const [pincode, setPincode] = useState(0);
    const [stateArr, setStateArr] = useState();
    const [cityArr, setCityArr] = useState();
    const [areaArr, setAreaArr] = useState();
    const [cityFilteredArr, setCityFilteredArr] = useState();
    const [selectedStateId, setSelectedStateId] = useState("");
    const [selectedCityId, setSelectedCityId] = useState("");
    const [filteredAreaArr, setFilteredAreaArr] = useState();
    const [searchTxt, setSearchTxt] = useState("");
    const [open, setOpen] = useState(false);
    const [toggleCleared, setToggleCleared] = useState(false);

    const [selectedRows, setSelectedRows] = useState([]);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const [uploadFile, setUploadFile] = useState();

    const sortIcon = <ArrowDownward />;

    const [loading, setLoading] = useState(false);








    const handleRowSelected = React.useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);

    const contextActions = React.useMemo(() => {
        const handleSelectedActive = async () => {
            try {

                let { data: res } = await setSelectedAreasActive({ areaArr: selectedRows.filter(el => el.cityActive && el.stateActive).map(el => el._id) })
                if (res.success) {
                    getState();
                    getCity();
                    getArea();
                    alert(res.message)

                }
            } catch (error) {
                console.error(error)
                if (error.response.data.message) {

                    alert(error.response.data.message)
                }
                else {
                    alert(error.message)
                }
            }
        };
        const handleSelectedInActive = async () => {
            try {

                let { data: res } = await setSelectedAreasInActive({ areaArr: selectedRows.filter(el => el.cityActive && el.stateActive).map(el => el._id) })
                if (res.success) {
                    getState();
                    getCity();
                    getArea();
                    alert(res.message)

                }
            } catch (error) {
                console.error(error)
                if (error.response.data.message) {

                    alert(error.response.data.message)
                }
                else {
                    alert(error.message)
                }
            }
        };

        return (
            <div>
                {
                    selectedRows.length > 0 &&
                    <div className="d-flex justify-content-center">
                        <button type="button" onClick={() => handleSelectedActive()} className="btn searchBttn mr-2" icon>
                            Set Active
                        </button>
                        <button type="button" onClick={() => handleSelectedInActive()} className="btn addModifiBttn mr-2" icon>
                            Set In-Active
                        </button>
                    </div>
                }
            </div>
        );
    }, [filteredAreaArr, selectedRows, toggleCleared]);



    const onSubmitUpload = async () => {
        if (uploadFile) {
            try {
                MySwal.fire({
                    title: "Processing Please Wait",
                    allowOutsideClick: false
                })

                MySwal.showLoading();
                let formData = new FormData()
                formData.append('file', uploadFile)

                const res = await uploadAreaData(formData)
                MySwal.fire(res.data.message)
                getState()
            }
            catch (err) {
                if (err?.response?.data?.message)
                    MySwal.fire(err?.response?.data?.message)
                else
                    MySwal.fire(err?.message)
            }
        }
        else {
            MySwal.fire('please select a xlxs file')
        }
    }


    const handleSubmit = async () => {
        try {
            if (selectedStateId == "" || selectedCityId == "") {
                alert("state & city must be selected")
            }
            else {

                let obj = {
                    name: name,
                    stateId: selectedStateId,
                    cityId: selectedCityId,
                    pincode
                }
                console.log(obj)
                let { data: res } = await axios.post(`${url}/area/`, obj)
                if (res.success) {
                    toast.success(res.message)
                    // alert(res.message)
                    getArea()
                }
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }




    const getCity = async () => {
        try {
            let { data: res } = await axios.get(`${url}/city/`)
            if (res.success) {
                // toast.success(res.message)
                setCityArr(res.data);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }


    const getState = async () => {
        try {
            let { data: res } = await axios.get(`${url}/state/`)
            if (res.success) {
                // toast.success(res.message)
                setStateArr(res.data);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }

    const setCityArrFromState = (value) => {
        setSelectedStateId(value)
        let cityfilteredArr = cityArr.filter(el => el.stateId == value);
        setCityFilteredArr(cityfilteredArr)
    }

    const getArea = async () => {
        try {
            setLoading(true)
            let { data: res } = await axios.get(`${url}/area/`)
            if (res.success) {
                console.log(res.data)
                // toast.success(res.message)
                setAreaArr(res.data);
                setFilteredAreaArr(res.data)
                setLoading(false)
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }


    const filterAreaArr = () => {
        console.log(selectedCityId)
        console.log(selectedStateId)
        let tempAreaArr = areaArr.filter(el => el.stateId == selectedStateId && el.cityId == selectedCityId)
        console.log(tempAreaArr)
        setFilteredAreaArr(tempAreaArr)
    }
    const searchArea = (val) => {
        let tempResult = areaArr.filter(el => el.name.toLowerCase().includes(val.toLowerCase()) || JSON.stringify(el.pincode).includes(`${val}`));

        setFilteredAreaArr(tempResult)
    }


    const updateArea = async (id, value) => {
        try {
            let tempobj = {
                active: value
            }
            let { data: res } = await axios.patch(`${url}/area/changeStatusById/${id}`, tempobj)
            if (res.success) {
                toast.success(res.message)
                getCity()
                getState()
                getArea()
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }

    }

    const columns = [
        {
            name: 'S.no',
            selector: row => row.sno,
            sortable: true,
        },
        {
            name: 'Area Name',
            selector: row => `${row.name}`,
            sortable: true,
        },
        {
            name: 'City Name',
            selector: row => `${row.cityName}`,
            sortable: true,
        },
        {
            name: 'City Status',
            selector: row => row.cityActive ? "Active" : "In-Active",
            sortable: true,
        },
        {
            name: 'State Name',
            selector: row => `${row.stateName}`,
            sortable: true,
        },
        {
            name: 'Pin Code',
            selector: row => `${row.pincode}`,
            sortable: true,
        },
        {
            name: 'State Status',
            selector: row => row.stateStatus ? "Active" : "In-Active",
            sortable: true,
        },
        {
            name: 'Actions',
            cell: row => <div> {
                row.active
                    ?
                    <button disabled={row.stateActive == false || row.cityActive == false} onClick={() => updateArea(row._id, false)} className="btn addModifiBttn mr-2">Set In-Active</button>
                    :
                    <button disabled={row.stateActive == false || row.cityActive == false} onClick={() => updateArea(row._id, true)} className="btn greenBttn mr-2">Set Active</button>

            }</div>
        },


    ];




    const changeAllStatus = async (state) => {
        console.log(state)
    }


    useEffect(() => {
        getState();
        getCity();
        getArea();
    }, [])
    return (
        <main className="main_content">
            <div className="userTabsDesign mb-4">
                <div className="headingTitle"><h3> Add Area </h3></div>
                <div className="card">
                    <div className="card-body">
                        <div className="AddModiForm w-100">
                            <form onSubmit={(e) => { e.preventDefault(); handleSubmit() }}>
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="field-group">
                                            <input type="text" className="form-control" onChange={(event) => setName(event.target.value)} placeholder="Area Name" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="field-group">
                                            <input type="number" className="form-control" onChange={(event) => setPincode(event.target.value)} placeholder="Pincode" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="field-group">
                                            <select name="" id="" className="form-control" onChange={(event) => { setCityArrFromState(event.target.value) }}>
                                                <option value="">Please Select a State</option>
                                                {
                                                    stateArr && stateArr.length > 0 && stateArr.map(el => {
                                                        return (
                                                            <option key={el._id} value={el._id}>{el.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>

                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="field-group">
                                            <select name="" id="" className="form-control" onChange={(event) => { setSelectedCityId(event.target.value) }}>
                                                <option value="">Please Select a City</option>
                                                {
                                                    cityFilteredArr && cityFilteredArr.length > 0 && cityFilteredArr.map(el => {
                                                        return (
                                                            <option key={el._id} value={el._id}>{el.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>

                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4 d-flex justify-content-start">
                                    <div className="col-4 col-md-3">
                                        <div className="field-group">
                                            <button type="button" onClick={() => handleSubmit()} className="btn searchBttn"> Save </button>
                                        </div>
                                    </div>
                                    <div className="col-4 col-md-3">
                                        <div className="field-group">
                                            <button type="button" onClick={() => onOpenModal()} className="btn searchBttn"> Upload Excel </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="infoOrdertable mb-4">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <label htmlFor="">Search</label>
                        <div className="field-group">
                            <input type="text" className="form-control" onChange={(event) => searchArea(event.target.value)} placeholder="Search from name, and pincode" />
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col-12 col-md-6 mt-4">
                        <label htmlFor="">State</label>

                        <div className="field-group">
                            <select name="" id="" className="form-control" onChange={(event) => { setCityArrFromState(event.target.value) }}>
                                <option value="">Please Select a State</option>
                                {
                                    stateArr && stateArr.length > 0 && stateArr.map(el => {
                                        return (
                                            <option key={el._id} value={el._id}>{el.name}</option>
                                        )
                                    })
                                }
                            </select>

                        </div>
                    </div>
                    <div className="col-12 col-md-6 mt-4">
                        <label htmlFor="">City</label>

                        <div className="field-group">
                            <select name="" id="" className="form-control" onChange={(event) => { setSelectedCityId(event.target.value) }}>
                                <option value="">Please Select a City</option>
                                {
                                    cityFilteredArr && cityFilteredArr.length > 0 && cityFilteredArr.map(el => {
                                        return (
                                            <option key={el._id} value={el._id}>{el.name}</option>
                                        )
                                    })
                                }
                            </select>

                        </div>
                    </div>
                    <div className="col-4 mt-4 mb-4 col-md-3">
                        <div className="field-group">
                            <button type="button" onClick={() => filterAreaArr()} className="btn searchBttn">Find </button>
                        </div>
                    </div>
                </div>
                {/* <div className="headingTitle"><h3>Area</h3></div> */}
                <div className="card">
                    <DataTable
                        title="Area"
                        columns={columns}
                        data={filteredAreaArr?.map((el, index) => {
                            let obj = {
                                sno: index + 1,
                                ...el
                            }
                            return obj
                        })}
                        sortIcon={sortIcon}
                        keyField='_id'
                        pagination
                        progressPending={loading}
                        selectableRows
                        subHeader
                        Clicked

                        contextActions={contextActions}
                        onSelectedRowsChange={handleRowSelected}
                    />
                    {/* <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-responsive-sm">
                                <thead>
                                    <tr>
                                        <th>State</th>
                                        <th>State Status</th>
                                        <th>City</th>
                                        <th>City Status</th>
                                        <th>Area</th>
                                        <th>Area Status</th>
                                        <th>Pincode</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        areaArr && areaArr.length > 0 && areaArr.map(el => {
                                            return (
                                                <tr key={el._id}>
                                                    <td>{el.stateName}</td>
                                                    <td>{el.stateActive ? "Active" : "In-Active"}</td>
                                                    <td>{el.cityName}</td>
                                                    <td>{el.cityActive ? "Active" : "In-Active"}</td>
                                                    <td>{el.name}</td>
                                                    <td>{el.active ? "Active" : "In-Active"}</td>
                                                    <td>
                                                        {el.pincode}
                                                    </td>
                                                    <td>
                                                        {
                                                            el.active
                                                                ?
                                                                <button disabled={el.stateActive == false || el.cityActive == false} onClick={() => updateArea(el._id, false)} className="btn addModifiBttn mr-2">Set In-Active</button>
                                                                :
                                                                <button disabled={el.stateActive == false || el.cityActive == false} onClick={() => updateArea(el._id, true)} className="btn greenBttn mr-2">Set Active</button>

                                                        }
                                                    </td>
                                                </tr>

                                            )
                                        })
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div> */}
                </div>
            </div>
            <Modal open={open} onClose={onCloseModal} center classNames="col-6">
                <div style={{ padding: 50, overflowX: 'hidden' }} className='row'>
                    <h2 className="text-center">Upload</h2>
                    <div className="col">
                        <div className="mb-3">
                            <label htmlFor="formFile" className="form-label">File Input</label>
                            <input type="file" className="form-control" onChange={(e) => e.target.files && e.target.files[0] ? setUploadFile(e.target.files[0]) : 0} />
                        </div>
                        <div>
                            <button className="btn btn-primary" onClick={() => onSubmitUpload()}>
                                Upload
                            </button>
                        </div>
                    </div>
                </div>

            </Modal>
        </main >
    )
}
