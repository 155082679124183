import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { url } from '../../Services/Url'
import { ToastContainer, toast } from 'react-toastify';
import MaterialTable from 'material-table'
import { generateImageUrl } from '../../utils/util';
import { generateYearArr, getMonthByIndex, monthArr } from '../../utils/monthUtils';
import { useParams } from 'react-router';
import { getSpecificDeliveryBoy } from '../../Services/DeliveryBoy';

export default function SpecificIncentiveTarget() {


    let { id } = useParams()
    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(new Date().getMonth());
    const [yearArr, setYearArr] = useState();




    const [monthsArr, setMonthsArr] = useState([]);
    const [effectiveSalaryObj, seteffectiveSalaryObj] = useState();
    const [effectiveSalaryArr, setEffectiveSalaryArr] = useState([]);

    const [deliveryBoyObj, setDeliveryBoyObj] = useState({});


    const getYearArr = () => {
        let yearArr = generateYearArr()
        setYearArr(yearArr)
        getmonthsArr()
    }



    const getmonthsArr = () => {
        setMonthsArr(monthArr);
    }




    const getDeliveryBoySpecific = async () => {
        try {
            const { data: res } = await getSpecificDeliveryBoy(id);
            if (res) {
                console.log(res.data)
                setDeliveryBoyObj(res.data)
            }
        } catch (error) {
            console.error(error)
        }
    }



    const getDeliveryBoys = async () => {
        let tempArr = []
        for (let [i, el] of monthArr.entries()) {

            try {
                // seteffectiveSalaryObj(res.data)
                let { data: res } = await axios.get(`${url}/effectiveSalary/getDeliveryBoySalarayDataForMonth/${id}/${year}/${i}`)
                if (res.success) {
                    console.log(res.data)
                    tempArr.push(res.data)
                }
            } catch (error) {
                toast.error(error?.response?.data?.message)
                console.log(error.response, "errors")
                let obj = {
                    km: 'N/A',
                    hours: 'N/A',
                    noOfdeliveries: 'N/A',
                    targetKm: 'N/A',
                    targetHours: 'N/A',
                    targetNumberOfDeliveries: 'N/A',
                    targetAchStr: '',
                    incentiveKm: 'N/A',
                    incentiveHours: 'N/A',
                    incentiveNumberOfDeliveries: 'N/A',
                    noOfTargetsCompleted: 'N/A',
                    noOfIncentiveTargetsCompleted: 'N/A',
                    baseSalary: 'N/A',
                    salaryPercentage: 'N/A',
                    salarySubTotal: 'N/A',
                    baseIncentive: 'N/A',
                    incentivePercentage: 'N/A',
                    incentiveAmount: 'N/A',
                    baseReferralAmount: 'N/A',
                    numberOfReferrals: 'N/A',
                    subTotalBaseReferralAmount: 'N/A',
                    finalAmount: 'N/A',
                    perDeliveryCost: 'N/A',
                    totalDeliveries: 'N/A',
                    error: true,
                    paid: false
                }
                tempArr.push(obj)
            }
        }
        console.log(tempArr)
        setEffectiveSalaryArr(tempArr)
    }



    const handlePaySalary = async (i) => {
        try {

            let { data: res } = await axios.post(`${url}/effectiveSalary/payDeliveryBoySalarayDataForMonth/${id}/${year}/${i}`)
            if (res.success) {
                let { data: response } = await axios.get(`${url}/effectiveSalary/getDeliveryBoySalarayDataForMonth/${id}/${year}/${i}`)
                if (response.success) {
                    console.log(res.data)
                    setEffectiveSalaryArr(prevState => {
                        prevState[i] = response.data
                        return [...prevState]
                    })
                }
                console.log(res.data)
                alert(res.message)
            }
        } catch (error) {
            console.error(error)
            alert(error)
        }
    }



    useEffect(() => {
        getYearArr()
        getDeliveryBoys()
        getDeliveryBoySpecific()
    }, [])
    return (
        <main className="main_content">
            <div className="filterSearchBar">
                <form>
                    <div className="row">

                        <div className="col-12 col-md-2">
                            <label>Month</label>
                            <select className="form-control" onChange={(e) => setMonth(e.target.value)}>
                                {
                                    monthArr && monthArr.map((el, index) => {
                                        return (
                                            <option key={index} value={index}>{el}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-12 col-md-2">
                            <label>Year</label>
                            <select className="form-control" onChange={(e) => setYear(e.target.value)}>
                                {
                                    yearArr && yearArr.map((el, index) => {
                                        return (
                                            <option key={index} value={index}>{el}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-12 col-md-2">
                            <label>&nbsp;</label>
                            <button type="button" className="btn searchBttn" onClick={() => getDeliveryBoys()}> Get </button>
                        </div>
                    </div>
                </form>
            </div>
            <br />
            <br />

            {
                (deliveryBoyObj?.DeliveryBoyType == "Full-Time" || !deliveryBoyObj?.DeliveryBoyType) ?
                    <div className="infoOrdertable mb-4">

                        <div className="card">


                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-responsive m-0">
                                        <thead>
                                            <tr>
                                                <th colSpan="4" className="borderRight2pxWhite sepratehead"> Target </th>
                                                <th colSpan="3" className="borderRight2pxWhite sepratehead"> Incentive </th>
                                                <th colSpan="4" className="borderRight2pxWhite sepratehead">Done By Delivery Boy </th>
                                                <th colSpan="4" className="borderRight2pxWhite sepratehead">Total Payable</th>
                                                <th colSpan="1" className="borderRight2pxWhite sepratehead ">Salary Status</th>

                                            </tr>
                                        </thead>
                                        <thead>
                                            <tr>
                                                <th> Month </th>

                                                <th> Hour's Online </th>
                                                <th> Km </th>
                                                <th> Deliveries </th>

                                                <th> Hour's Online  </th>
                                                <th> Km  </th>
                                                <th> Deliveries </th>

                                                <th> Hour's Online  </th>
                                                <th> Km  </th>
                                                <th> Referals  </th>

                                                <th> Deliveries </th>

                                                <th>Base Salary</th>
                                                <th>Incentive</th>
                                                <th>Referal Amount</th>

                                                <th> Total Payable </th>
                                                <th> Status </th>


                                            </tr>
                                        </thead>
                                        <tbody>

                                            {effectiveSalaryArr.map((el, i) => {
                                                return (

                                                    <tr key={i} >
                                                        <td> {getMonthByIndex(i)} </td>

                                                        <td> {el?.targetHours} </td>
                                                        <td> {el?.targetKm} </td>
                                                        <td> {el?.targetNumberOfDeliveries} </td>

                                                        <td> {el?.incentiveHours} </td>
                                                        <td> {el?.incentiveKm} </td>
                                                        <td> {el?.incentiveNumberOfDeliveries} </td>

                                                        <td> {el?.hours} </td>
                                                        <td> {el?.km} </td>
                                                        <td> {el?.numberOfReferrals} </td>

                                                        <td> {el?.noOfdeliveries} </td>

                                                        <td><b>₹ {el?.salarySubTotal}</b> </td>
                                                        <td><b>₹ {el?.incentiveAmount}</b> </td>
                                                        <td><b>₹ {el?.subTotalBaseReferralAmount}</b> </td>

                                                        <td><b>₹ {el?.finalAmount}</b> </td>
                                                        <td><b>
                                                            {el?.error ? "Salary not calculated due to missing salary and/or incentive " :

                                                                <>
                                                                    {(el?.paid) ? "Paid" :
                                                                        <>
                                                                            {new Date().getMonth() >= i ?
                                                                                <button type="button" className="btn searchBttn" onClick={() => handlePaySalary(i)}> Pay Now </button>
                                                                                :
                                                                                "Not Paid"
                                                                            }
                                                                        </>

                                                                    }
                                                                </>
                                                            }
                                                        </b> </td>

                                                    </tr>
                                                )
                                            })}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    :

                    <div className="infoOrdertable mb-4">

                        <div className="card">


                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-responsive m-0">
                                        <thead>
                                            <tr>
                                                <th className="borderRight2pxWhite sepratehead"> Month </th>

                                                <th className="borderRight2pxWhite sepratehead"> Total Deliveries </th>
                                                <th className="borderRight2pxWhite sepratehead"> Per Delivery Cost</th>
                                                <th className="borderRight2pxWhite sepratehead"> Referal Amount</th>

                                                <th className="borderRight2pxWhite sepratehead">Total Payable</th>
                                                <th className="borderRight2pxWhite sepratehead ">Salary Status</th>

                                            </tr>
                                        </thead>

                                        <tbody>

                                            {effectiveSalaryArr.map((el, i) => {
                                                return (

                                                    <tr key={i} >
                                                        <td style={{textAlign:'center'}}> {getMonthByIndex(i)} </td>

                                                        <td style={{textAlign:'center'}}>{el?.totalDeliveries}</td>
                                                        <td style={{textAlign:'center'}}>₹ {el?.perDeliveryCost}</td>
                                                        <td style={{textAlign:'center'}}>₹ {el?.subTotalBaseReferralAmount}</td>
                                                        <td style={{textAlign:'center'}}>₹ {el?.finalAmount}</td>
                                                        <td  style={{textAlign:'center'}}><b>
                                                            {el?.error ? "Salary not calculated due to missing Per delivery cost or deliveries " :

                                                                <>
                                                                    {(el?.paid) ? "Paid" :
                                                                        <>
                                                                            {new Date().getMonth() >= i ?
                                                                                <button type="button" className="btn searchBttn" onClick={() => handlePaySalary(i)}> Pay Now </button>
                                                                                :
                                                                                "Not Paid"
                                                                            }
                                                                        </>

                                                                    }
                                                                </>
                                                            }
                                                        </b> </td>

                                                        {/* <td> {el?.targetHours} </td>
                                                        <td> {el?.targetKm} </td>
                                                        <td> {el?.targetNumberOfDeliveries} </td>

                                                        <td> {el?.incentiveHours} </td>
                                                        <td> {el?.incentiveKm} </td>
                                                        <td> {el?.incentiveNumberOfDeliveries} </td>

                                                        <td> {el?.hours} </td>
                                                        <td> {el?.km} </td>
                                                        <td> {el?.numberOfReferrals} </td>

                                                        <td> {el?.noOfdeliveries} </td>

                                                        <td><b>₹ {el?.salarySubTotal}</b> </td>
                                                        <td><b>₹ {el?.incentiveAmount}</b> </td>
                                                        <td><b>₹ {el?.subTotalBaseReferralAmount}</b> </td>

                                                        <td><b>₹ {el?.finalAmount}</b> </td>
                                                        <td><b>
                                                            {el?.error ? "Salary not calculated due to missing salary and/or incentive " :

                                                                <>
                                                                    {(el?.paid) ? "Paid" :
                                                                        <>
                                                                            {new Date().getMonth() >= i ?
                                                                                <button type="button" className="btn searchBttn" onClick={() => handlePaySalary(i)}> Pay Now </button>
                                                                                :
                                                                                "Not Paid"
                                                                            }
                                                                        </>

                                                                    }
                                                                </>
                                                            }
                                                        </b> </td> */}

                                                    </tr>
                                                )
                                            })}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

            }




        </main >
    )
}
