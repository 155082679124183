import React from 'react';


function UserLocation() {
    return (
        <main className="main_content">
            <div className="searchBar">
                <input type="text" className="form-control" placeholder="Search Boy Location" />
                <i className="fa fa-search"></i>
            </div>
            <div className="infoOrdertable mb-4">
                <div className="headingTitle"><h3> User Location </h3></div>
                <div className="card">
                    Map
                </div>
            </div>
        </main>
    )
}

export default UserLocation;