import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { url } from '../../Services/Url'
import { ToastContainer, toast } from 'react-toastify';
import MaterialTable from 'material-table'

import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
function AddIncentive() {
    const sortIcon = <ArrowDownward />;

    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [yearArr, setYearArr] = useState([]);
    const [year, setYear] = useState("");
    const [toggle, setToggle] = useState(false);
    const [monthsArr, setMonthsArr] = useState([
        { name: "JANUARY" },
        { name: "FEBRUARY" },
        { name: "MARCH" },
        { name: "APRIL" },
        { name: "MAY" },
        { name: "JUNE" },
        { name: "JULY" },
        { name: "AUGUST" },
        { name: "SEPTEMBER" },
        { name: "OCTOBER" },
        { name: "NOVEMBER" },
        { name: "DECEMBER" },
    ]);
    const [month, setMonth] = useState("");
    const [hoursOnline, setHoursOnline] = useState("");
    const [deliveries, setDeliveries] = useState("");
    const [incentiveArr, setIncentiveArr] = useState([]);
    const [km, setKm] = useState("");
    const [amount, setAmount] = useState("");


    const [incentiveMonth, setIncentiveMonth] = useState("");
    const [incentiveYear, setIncentiveYear] = useState("");

    const [value1, setValue1] = useState("");
    const [value2, setValue2] = useState("");
    const [value3, setValue3] = useState("");
    const [value4, setvalue4] = useState("");

    const handleSubmit = async () => {
        try {
            if (month != "" && year != "" && hoursOnline != "" && deliveries != "" && km != "" && amount != "") {
                let monthName = ""
                if (month == 0) {
                    monthName = "JANUARY"
                }
                else if (month == 1) {
                    monthName = "FEBRUARY"
                }
                else if (month == 2) {
                    monthName = "MARCH"
                }
                else if (month == 3) {
                    monthName = "APRIL"
                }
                else if (month == 4) {
                    monthName = "MAY"
                }
                else if (month == 5) {
                    monthName = "JUNE"
                }
                else if (month == 6) {
                    monthName = "JULY"
                }
                else if (month == 7) {
                    monthName = "AUGUST"
                }
                else if (month == 8) {
                    monthName = "SEPTEMBER"
                }
                else if (month == 9) {
                    monthName = "OCTOBER"
                } else if (month == 10) {
                    monthName = "NOVEMBER"
                }
                else if (month == 11) {
                    monthName = "DECEMBER"
                }

                let obj = {
                    month,
                    year,
                    monthName,
                    hoursOnline,
                    deliveries,
                    km,
                    amount
                }
                console.log(obj);
                console.log(incentiveMonth)
                console.log(incentiveYear)
                let { data: res } = await axios.post(`${url}/incentive/`, obj)
                if (res.success) {
                    toast.success(res.message)
                    getIncentive()
                    submitEffectiveIncentive(1)
                    submitEffectiveIncentive(2)
                    submitEffectiveIncentive(3)
                    submitEffectiveIncentive(4)

                }
            }
            else {
                alert("Please Fill All the fields")

            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }

    }



    const generateYearArr = () => {
        for (let i = currentYear; i <= currentYear + 10; i++) {
            yearArr.push(i)
        }

        // setYearArr()
    }



    const getIncentive = async () => {
        try {
            let { data: res } = await axios.get(`${url}/incentive/`)
            console.log(res.data, " incentives")
            if (res.success) {
                setIncentiveArr(res.data);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }





    const submitEffectiveIncentive = async (val) => {
        try {

            if (incentiveMonth == "" && incentiveYear == "") {
                alert("You will have to select month first")
            }
            else {

                if (val == 1 && value1 >= 0 && value1 <= 100) {
                    let obj = {
                        taskCompleted: 1,
                        taskNotCompleted: 2,
                        percentage: value1,
                        year: incentiveYear,
                        month: incentiveMonth,
                        deliveryBoyId: ''
                    }
                    let res = await axios.post(`${url}/effectiveIncentive/`, obj)

                    if (res.status == 200) {
                        toast.success(res.data.message)
                    }
                }

                else if (val == 2 && value2 >= 0 && value2 <= 100) {
                    let obj = {
                        taskCompleted: 2,
                        taskNotCompleted: 1,
                        percentage: value2,
                        year: incentiveYear,
                        month: incentiveMonth,
                        deliveryBoyId: ''
                    }

                    let res = await axios.post(`${url}/effectiveIncentive/`, obj)
                    if (res.status == 200) {
                        toast.success(res.data.message)
                    }
                }


                else if (val == 3 && value3 >= 0 && value3 <= 100) {
                    let obj = {
                        taskCompleted: 3,
                        taskNotCompleted: 0,
                        percentage: value3,
                        year: incentiveYear,
                        month: incentiveMonth,
                        deliveryBoyId: ''
                    }
                    let res = await axios.post(`${url}/effectiveIncentive/`, obj)
                    if (res.status == 200) {
                        toast.success(res.data.message)
                    }
                }



                else if (val == 4 && value4 >= 0 && value4 <= 100) {
                    let obj = {
                        taskCompleted: 0,
                        taskNotCompleted: 3,
                        percentage: value4,
                        year: incentiveYear,
                        month: incentiveMonth,
                        deliveryBoyId: ''
                    }
                    let res = await axios.post(`${url}/effectiveIncentive/`, obj)
                    if (res.status == 200) {
                        toast.success(res.data.message)
                    }
                }

                else {
                    toast.error("Invalid Value")
                }

            }

        }

        catch (err) {
            if (err?.response?.data?.message) {
                console.log(err?.response?.data?.message)
                alert(err?.response?.data?.message)
            }
            else {
                console.log(err)
                alert(err)
            }
        }
    }

    const getIncentiveParametersByMonth = async () => {
        try {
            const res = await axios.get(`${url}/effectiveIncentive/getGeneral/${incentiveYear}/${incentiveMonth}`)
            console.log(res.data.data)
            if (res.status == 200) {
                res.data.data.forEach(el => {
                    if (el.taskCompleted == 0) {
                        setvalue4(el.percentage)
                    }
                    else if (el.taskCompleted == 1) {
                        setValue1(el.percentage)
                    }

                    else if (el.taskCompleted == 2) {
                        setValue2(el.percentage)
                    }

                    else if (el.taskCompleted == 3) {
                        setValue3(el.percentage)
                    }

                });


                // }

            }
        }
        catch (err) {
            if (err?.response.data.message) {
                console.log(err?.response.data.message)
                toast.error(err?.response.data.message)
            }
            else {
                console.log(err)
                toast.error(err)
            }
        }
    }


    const getIncentiveByMonthAndYear=async(year,month)=>{
        try {
            const {data:res}=await axios.get(`${url}/incentive/getIncentiveByMonthYear/${year}/${month}`)
            if(res.success){
                console.log(res);
                setHoursOnline(res.data.hoursOnline)
                setKm(res.data.km)
                setDeliveries(res.data.deliveries)
                setAmount(res.data.amount)
            }
        } catch (error) {
            console.error(error)
        }
    }



    useEffect(() => {
        generateYearArr()
        getIncentive();
    }, [])

    const IncentiveColumns = [
        {
            name: 'S.No',
            selector: (row, index) => <span> {index + 1} </span>,
            sortable: true,
        },
        {
            name: 'Month',
            selector: row => <span>{row.monthName}</span>,
            sortable: true,
        },

        {
            name: 'Year',
            selector: row => row?.year,
            sortable: true,
        },
        {
            name: 'Hours Online',
            selector: row => row?.hoursOnline,
            sortable: true,
        },
        {
            name: 'No. Of Deliveries',
            selector: row => row?.deliveries,
            sortable: true,
        },
        {
            name: 'KM',
            selector: row => row?.km,
            sortable: true,
        },
        {
            name: 'Amount',
            selector: row => row?.amount,
            sortable: true,
        },
        // {
        //     name: 'Actions',
        //     selector: rowData =><><a href={`/vehicle-type-details/${rowData._id}`} type="button" className="btn searchBttn width-auto"> Details </a> <button type="button" onClick={() => setVehicleIdToBeUpdated(rowData._id)} className="btn addModifiBttn ml-2" data-toggle="modal" data-target="#ModifyShopType"> Modify </button></>,
        //     sortable: true,
        // }
    ];


    useEffect(() => {
        console.log(incentiveMonth)
        console.log(incentiveYear)
        if (incentiveYear != "" && incentiveMonth != "") {
            getIncentiveParametersByMonth()
            getIncentiveByMonthAndYear(incentiveYear,incentiveMonth)
        }

    }, [incentiveMonth, incentiveYear])




    return (
        <main className="main_content">
            <div className="userTabsDesign mb-4">
                {
                    toggle
                        ?
                        <>
                            <div className="headingTitle" style={{ marginBottom: 20 }}><h1> Incentive </h1> <button onClick={() => setToggle(false)} className="btn addModifiBttn">Add Incentive</button></div>
                            <div className="card mt-3">
                                <DataTable
                                title="Incentives"
                                columns={IncentiveColumns}
                                data={incentiveArr}
                                sortIcon={sortIcon}
                                keyField='_id'
                                pagination
                            />
                                {/* {
                                    incentiveArr && incentiveArr.length > 0 &&
                                    <MaterialTable
                                        columns={[
                                            {
                                                title: 'Month', field: "monthName",
                                                cellStyle: {
                                                    minWidth: 150,
                                                    textAlign: "left",
                                                },
                                            },
                                            {
                                                cellStyle: {
                                                    minWidth: 180,
                                                    textAlign: "left"
                                                }, title: 'Year', field: 'year'
                                            },
                                            {
                                                cellStyle: {
                                                    minWidth: 150,
                                                    textAlign: "left"
                                                }, title: 'Hours Online', field: "hoursOnline"
                                            },
                                            {
                                                cellStyle: {
                                                    minWidth: 150,
                                                    textAlign: "left"
                                                }, title: 'No. Of Deliveries', field: "deliveries"
                                            },
                                            {
                                                cellStyle: {
                                                    minWidth: 180,
                                                    textAlign: "left"
                                                }, title: 'KM', field: "km"
                                            },
                                            {
                                                cellStyle: {
                                                    minWidth: 180,
                                                    textAlign: "left"
                                                }, title: 'Amount', field: "amount"
                                            },
                                        ]}
                                        data={incentiveArr}
                                        title=""
                                        options={{
                                            headerStyle: {
                                                backgroundColor: 'rgba(0,0,0,0.7)',
                                                color: '#FFF',
                                                fontSize: 12,
                                                fontWeight: "bold",
                                            },
                                            actionsColumnIndex: -1
                                        }}

                                    />

                                } */}
                            </div>
                        </>
                        :
                        <>

                            <div className="headingTitle" style={{ marginBottom: 20 }}><h1> Add Incentive </h1> <button onClick={() => setToggle(true)} className="btn addModifiBttn">View Incentive</button></div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="AddModiForm w-100">
                                        <form>
                                            <div className="row">
                                                <div className="col-12 col-md-6">
                                                    <div className="field-group">
                                                        <label>Please Select Month</label>
                                                        <select className="form-control" onChange={(e) => {  setIncentiveMonth(e.target.value);setMonth(e.target.value) }} >
                                                            <option value="">--Please Select a month--</option>
                                                            {
                                                                monthsArr && monthsArr.length > 0 && monthsArr.map((el, index) => {
                                                                    return (
                                                                        <option key={el.name} value={index}>{el.name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="field-group">
                                                        <label>Please Select Year</label>

                                                        <select className="form-control" onChange={(e) => { setIncentiveYear(e.target.value);setYear(e.target.value) }} >
                                                    <option value="">--Please Select a Year--</option>
                                                    {
                                                        yearArr && yearArr.length > 0 && yearArr.map((el, index) => {
                                                            return (
                                                                <option key={el} value={el}>{el}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="field-group">
                                                        <label>Enter Hour's Online</label>

                                                        <input type="number" value={hoursOnline} onChange={(e) => setHoursOnline(e.target.value)} className="form-control" placeholder="Enter Hour's Online" />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="field-group">
                                                        <label>Enter Deliveries</label>

                                                        <input type="number" value={deliveries} onChange={(e) => setDeliveries(e.target.value)} className="form-control" placeholder="Enter Deliveries" />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="field-group">
                                                        <label>Enter KM</label>

                                                        <input type="number" value={km} onChange={(e) => setKm(e.target.value)} className="form-control" placeholder="Enter Km" />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="field-group">
                                                        <label>Enter Amount</label>

                                                        <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} className="form-control" placeholder="Enter Amount" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-responsive" style={{ marginTop: 25 }}>
                                                <h4>Note* (parameters are Hours online, Total KM Travelled , Total Deliveries Done)</h4>
                                                <table className="table table-responsive m-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Combinations</th>
                                                            <th>Value in (Percentage) </th>
                                                            {/* <th>Action</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td> No parameters are Achieved</td>
                                                            <td> <input className="form-control" onChange={(e) => setvalue4(e.target.value)} value={value4} type="number" /> </td>
                                                            {/* <td>
                                                                <button type="button" onClick={() => submitEffectiveIncentive(4)} className="btn searchBttn"> Save </button>
                                                            </td> */}

                                                        </tr>
                                                        <tr>
                                                            <td>1 parameter is Achieved (Hours Online)</td>
                                                            <td> <input className="form-control" onChange={(e) => setValue1(e.target.value)} value={value1} type="number" /> </td>
                                                            {/* <td>
                                                                <button type="button" onClick={() => submitEffectiveIncentive(1)} className="btn searchBttn"> Save </button>
                                                            </td> */}

                                                        </tr>
                                                        <tr>
                                                            <td> 2 parameter are Achieved (Total KM Travelled) </td>
                                                            <td> <input className="form-control" onChange={(e) => setValue2(e.target.value)} value={value2} type="number" /> </td>
                                                            {/* <td>
                                                                <button type="button" onClick={() => submitEffectiveIncentive(2)} className="btn searchBttn"> Save </button>
                                                            </td> */}
                                                        </tr>
                                                        <tr>
                                                            <td> All parameters are Achieved</td>
                                                            <td> <input className="form-control" onChange={(e) => setValue3(e.target.value)} value={value3} type="number" /> </td>
                                                            {/* <td>
                                                                <button type="button" onClick={() => submitEffectiveIncentive(3)} className="btn searchBttn"> Save </button>
                                                            </td> */}

                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="row mt-4 d-flex justify-content-center">
                                                <div className="col-12 col-md-3">
                                                    <div className="field-group">
                                                        <button type="button" onClick={() => handleSubmit()} className="btn searchBttn"> Save </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>




                                        {/* 
                                        <h1 className="mt-4">Effective Incentive</h1>
                                        <div className="col-12 col-md-6" style={{ marginTop: 30 }}>
                                            <div className="field-group">
                                                <label>Please Select Month</label>
                                                <select className="form-control" onChange={(e) => { setIncentiveMonth(e.target.value) }} >
                                                    <option value="">--Please Select a month--</option>
                                                    {
                                                        monthsArr && monthsArr.length > 0 && monthsArr.map((el, index) => {
                                                            return (
                                                                <option key={el.name} value={index}>{el.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                <input type="text" onChange={(e) => setMonth(e.target.value)} className="form-control" placeholder="Enter Month" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6" style={{ marginTop: 30 }}>
                                            <div className="field-group">
                                                <label>Please Select Year</label>

                                                <select className="form-control" onChange={(e) => { setIncentiveYear(e.target.value) }} >
                                                    <option value="">--Please Select a Year--</option>
                                                    {
                                                        yearArr && yearArr.length > 0 && yearArr.map((el, index) => {
                                                            return (
                                                                <option key={el} value={el}>{el}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                <input type="text" onChange={(e) => setMonth(e.target.value)} className="form-control" placeholder="Enter Month" />
                                            </div>
                                        </div>
                                        <div className="table-responsive" style={{ marginTop: 25 }}>
                                            <table className="table table-responsive m-0">
                                                <thead>
                                                    <tr>
                                                        <th>Combinations</th>
                                                        <th>Value in (Percentage) </th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td> No parameters are Achieved</td>
                                                        <td> <input className="form-control" onChange={(e) => setvalue4(e.target.value)} value={value4} type="number" /> </td>
                                                        <td>
                                                            <button type="button" onClick={() => submitEffectiveIncentive(4)} className="btn searchBttn"> Save </button>
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td>1 parameter is Achieved</td>
                                                        <td> <input className="form-control" onChange={(e) => setValue1(e.target.value)} value={value1} type="number" /> </td>
                                                        <td>
                                                            <button type="button" onClick={() => submitEffectiveIncentive(1)} className="btn searchBttn"> Save </button>
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td> 2 parameter are Achieved </td>
                                                        <td> <input className="form-control" onChange={(e) => setValue2(e.target.value)} value={value2} type="number" /> </td>
                                                        <td>
                                                            <button type="button" onClick={() => submitEffectiveIncentive(2)} className="btn searchBttn"> Save </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td> All parameters are Achieved</td>
                                                        <td> <input className="form-control" onChange={(e) => setValue3(e.target.value)} value={value3} type="number" /> </td>
                                                        <td>
                                                            <button type="button" onClick={() => submitEffectiveIncentive(3)} className="btn searchBttn"> Save </button>
                                                        </td>

                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
 */}


                                    </div>
                                </div>
                            </div>
                        </>
                }
            </div>
        </main>
    )
}

export default AddIncentive;
