import React, { useState, useContext, useEffect } from 'react'
import { Route, Redirect } from "react-router-dom"
import { AuthContext } from '../App'

export function UnProtectedRoute({ component: Component, ...rest }) {
    const [isAuthorized, setIsAuthorized] = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (isAuthorized == true || isAuthorized == false)
            setLoading(false)
    }, [isAuthorized])
    return (
        <Route {...rest} exact render={() => {
            if (!loading) {
                if (!isAuthorized) {
                    return <Component />
                }
                else {
                    return <Redirect to={'/'} />
                }
            }
            else {
                return (<div>Loading...</div>)
            }
        }} />
    )
}