import React from 'react';


function InfoHistory() {
    return (
        <main className="main_content">
            <div className="filterSearchBar">
                <form>
                    <div className="row mb-4">
                        <div className="col-12 col-md-3">
                            <label>User</label>
                            <input type="text" className="form-control" placeholder="Search Delivery Name" />
                        </div>
                        <div className="col-12 col-md-3">
                            <label>Status</label>
                            <select className="form-control">
                                <option>All ( default )</option>
                                <option>Active</option>
                                <option>Completed</option>
                                <option>Top UP</option>
                            </select>
                        </div>
                        <div className="col-12 col-md-2">
                            <label>&nbsp;</label>
                            <button type="button" className="btn searchBttn"> Search </button>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12 col-md-3">
                            <label>Order No.</label>
                            <input type="text" className="form-control" placeholder="Search Order No." />
                        </div>
                        <div className="col-12 col-md-3">
                            <label>Start Date</label>
                            <input type="date" className="form-control" />
                        </div>
                        <div className="col-12 col-md-3">
                            <label>End Date</label>
                            <input type="date" className="form-control" />
                        </div>
                        <div className="col-12 col-md-2">
                            <label>&nbsp;</label>
                            <button type="button" className="btn searchBttn"> Search </button>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12 col-md-3">
                            <label>Delivery Boy</label>
                            <input type="text" className="form-control" placeholder="Search Delivery Boy" />
                        </div>
                        <div className="col-12 col-md-2">
                            <label>Type</label>
                            <select className="form-control">
                                <option>All ( default )</option>
                                <option>Active</option>
                                <option>Completed</option>
                                <option>Top UP</option>
                            </select>
                        </div>
                        <div className="col-12 col-md-2">
                            <label>City</label>
                            <input type="text" className="form-control" placeholder="Search City Name" />
                        </div>
                        <div className="col-12 col-md-2">
                            <label>Area</label>
                            <input type="text" className="form-control" placeholder="Search Area Name" />
                        </div>
                        <div className="col-12 col-md-2">
                            <label>&nbsp;</label>
                            <button type="button" className="btn searchBttn"> Search </button>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12 col-md-3">
                            <label>Happy Code</label>
                            <input type="text" className="form-control" placeholder="Search Happy Code" />
                        </div>
                        <div className="col-12 col-md-2">
                            <label>&nbsp;</label>
                            <button type="button" className="btn searchBttn"> Create / Search </button>
                        </div>
                    </div>
                </form>
            </div>
            <br />
            <br />
            <div className="myInfo_boxes">
                <div className="row">
                    <div className="col-xl-3 col-lg-3 col-sm-6 col-12">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title"> TOTAL ORDERS </h5>
                            </div>
                            <div className="card-body">
                                <div className="boysCount">
                                    <p><i className="fa fa-first-order"></i> 500 </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-sm-6 col-12">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title"> DELIVERED </h5>
                            </div>
                            <div className="card-body">
                                <div className="boysCount">
                                    <p><i className="fa fa-first-order"></i> 500 </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-sm-6 col-12">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title"> PENDING </h5>
                            </div>
                            <div className="card-body">
                                <div className="boysCount">
                                    <p><i className="fa fa-first-order"></i> 500 </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className="userTabsDesign mb-4">
                <div className="headingTitle"><h3> Order History </h3></div>
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-responsive-sm">
                                <thead>
                                    <tr>
                                        <th>Order No.</th>
                                        <th>Happy CODE</th>
                                        <th>Pickup Address</th>
                                        <th> Delivery address </th>
                                        <th> Area </th>
                                        <th> KM ( range ) </th>
                                        <th> Order Status </th>
                                        <th> Pickup Time </th>
                                        <th> Delivered time </th>
                                        <th> Cash Collection </th>
                                        <th> Amount </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th> 01 </th>
                                        <td>1010</td>
                                        <td>Gurgaon Phase2</td>
                                        <td> Gurgaon Phase1 </td>
                                        <td> DLF (1) </td>
                                        <td> 4 </td>
                                        <td> Active </td>
                                        <td> 11.10 am </td>
                                        <td> 11.30 am </td>
                                        <td> yes </td>
                                        <td> 500 </td>
                                    </tr>
                                    <tr>
                                        <th> 02 </th>
                                        <td>1010</td>
                                        <td>Gurgaon Phase2</td>
                                        <td> Gurgaon Phase1 </td>
                                        <td> DLF (1) </td>
                                        <td> 4 </td>
                                        <td> Active </td>
                                        <td> 11.10 am </td>
                                        <td> 11.30 am </td>
                                        <td> No </td>
                                        <td>  </td>
                                    </tr>
                                    <tr>
                                        <th> 03 </th>
                                        <td>1010</td>
                                        <td>Gurgaon Phase2</td>
                                        <td> Gurgaon Phase1 </td>
                                        <td> DLF (1) </td>
                                        <td> 4 </td>
                                        <td> Active </td>
                                        <td> 11.10 am </td>
                                        <td> 11.30 am </td>
                                        <td> yes </td>
                                        <td> 500 </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default InfoHistory;