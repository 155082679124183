import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { url } from '../../Services/Url'
import { ToastContainer, toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

import 'react-responsive-modal/styles.css';
import { getNotifications, removeNotifications, sendNotification } from '../../Services/AdminNotifications';

export default function Notifcation() {

    const sortIcon = <ArrowDownward />;

    const [notifyRadio, setNotifyRadio] = useState(0);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    const [notificationsArr, setNotificationsArr] = useState([]);

    const handleSubmit = async () => {
        try {
            if (title != "" && content != "") {

                let obj = {
                    title, content, notificationType: notifyRadio
                }
                const { data: res, status: statusCode } = await sendNotification(obj);
                if (statusCode == 200 || statusCode == 304) {
                    alert(res.message)
                }
            }
            else {
                alert("Please Fill All The Fields")
            }
        } catch (error) {
            console.error(error)
        }
    }

    
    const handleNotificationRemove = async (id) => {
        try {
            let check=window.confirm("Do you realy want to delete this notification ? ")
            if(check){
                try {
                    const {data:response}=await removeNotifications(id);
                    if(response.success){
                        getAllNotifications();
                        alert(response.message)
                    }
                } catch (error) {
                    console.error(error)
                    alert(error)
                }
            }
            
        } catch (error) {
            console.error(error)
        }
    }

    const getAllNotifications = async () => {
        try {


            const { data: res, status: statusCode } = await getNotifications();
            if (statusCode == 200 || statusCode == 304) {
                setNotificationsArr(res.data)
            }

        } catch (error) {
            console.error(error)
            alert(error)
        }
    }

    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => <span> {index + 1} </span>,
            sortable: true,
        },
        {
            name: 'Title',
            selector: row => <span>{row.title}</span>,
            sortable: true,
        },

        {
            name: 'Content',
            selector: row => row?.content,
            sortable: true,
        },
        {
            name: 'Actions',
            selector: rowData => <>
                {/* <button type="button" onClick={() => setSelectedShopTypeForupdate(rowData)} className="btn addModifiBttn ml-2" data-toggle="modal" data-target="#ModifyShopType"> Modify </button> */}
                <button type="button" onClick={() => handleNotificationRemove(rowData._id)} className="btn addModifiBttn ml-2" > Delete </button>
            </>,
            sortable: true,
        }
    ];


    useEffect(()=>{
        getAllNotifications()
    },[])


    return (
        <main className="main_content">
            <div className="userTabsDesign mb-4">
                <div className="headingTitle"><h3> Send Notification </h3></div>
                <div className="card">
                    <div className="card-body">
                        <div className="AddModiForm w-100">
                            <form onSubmit={(e) => { e.preventDefault() }}>
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="field-group">
                                            <label>Notification Title</label>
                                            <input className="form-control" placeholder="Notification Title" onChange={(val) => setTitle(val.target.value)} />

                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="field-group">
                                            <label>Notification Content</label>
                                            <input className="form-control" placeholder="Notification Title" onChange={(val) => setContent(val.target.value)} />

                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <label>Notify :</label>
                                        <div style={{ alignItems: 'end', flexDirection: 'row', display: 'flex', flex: 1, justifyContent: 'space-between' }}>
                                            <div>
                                                <input id="allVendor" value={notifyRadio} checked={notifyRadio == 0} type="radio" onClick={() => setNotifyRadio(0)} />
                                                <label for="allVendor">All Vendors</label>
                                            </div>
                                            <div>

                                                <input id="allHouseHold" type="radio" value={notifyRadio} checked={notifyRadio == 1} onClick={() => setNotifyRadio(1)} />
                                                <label for="allHouseHold">All HouseHold</label>
                                            </div>
                                            <div>

                                                <input id="allDeliveryBoy" type="radio" value={notifyRadio} checked={notifyRadio == 2} onClick={() => setNotifyRadio(2)} />
                                                <label for="allDeliveryBoy">All Delivery Boy</label>
                                            </div>
                                            <div>

                                                <input id="allDeliveryBoy" type="radio" value={notifyRadio} checked={notifyRadio == 3} onClick={() => setNotifyRadio(3)} />
                                                <label for="allDeliveryBoy">Notify All</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4 d-flex justify-content-start">
                                    <div className="col-4 col-md-3">
                                        <div className="field-group">
                                            <button type="button" className="btn searchBttn" onClick={() => handleSubmit()}> Send </button>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="AddModiForm w-100">
                            <DataTable
                                title="Notifications"
                                columns={columns}
                                data={notificationsArr}
                                sortIcon={sortIcon}
                                keyField='_id'
                                pagination
                            />
                        </div>
                    </div>
                </div>
            </div>


        </main>
    )
}
