import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { url } from '../../Services/Url'
import { ToastContainer, toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import withReactContent from 'sweetalert2-react-content'
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import 'react-responsive-modal/styles.css';
import { create, getAll } from '../../Services/ExpenseTypes';
import { createExpense } from '../../Services/Expense';

const sortIcon = <ArrowDownward />;


export default function AddExpense() {


    const [incurredBy, setIncurredBy] = useState("");
    const [approvedBy, setApprovedBy] = useState("");
    const [billNo, setBillNo] = useState('');
    const [name, setName] = useState('');
    const [value, setValue] = useState("");

    const [date, setDate] = useState('');

    const [selectedExpenseType, setSelectedExpenseType] = useState("");

    const [expenseTypesArr, setExpenseTypesArr] = useState([]);

    const handleSubmit = async () => {
        try {
            if(incurredBy!="" && value!="" && approvedBy!="" && expenseTypesArr && date!="" && name!="" && billNo!=""){

                let obj = {
                    incurredBy,
                    amount:value,
                    approvedBy,

                    name,
                    billNo,
                    expenseType: selectedExpenseType,
                    date:new Date(date)
                }
                let res = await createExpense(obj)
                if (res.status == 200 || res.status == 304) {
                    alert(res.data.message)
                }
                else {
                    alert(res.data.message)
                }
            }
            else{
                alert("Please Fill All the fields")
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.log(err?.response?.data?.message)
                alert(err?.response?.data?.message)
            }
            else {
                console.log(err);
                alert(err)
            }
        }
    }



    const getExpense = async () => {
        try {
            let res = await getAll()
            if (res.status == 200 || res.status == 304) {
                setExpenseTypesArr(res.data.data)
            }
            else {
                alert(res.data.message)
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.log(err?.response?.data?.message)
                alert(err?.response?.data?.message)
            }
            else {
                console.log(err);
                alert(err)
            }
        }
    }

    useEffect(() => {
        getExpense()
    }, [])

    const columns = [
        {
            name: 'S.no',
            selector: row => row.sno,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
    ];



    return (
        <>
            <main className="main_content">
                <div className="userTabsDesign mb-4">
                    <div className="headingTitle"><h3> Add Expense </h3></div>
                    <div className="AddModiForm w-100">
                        <form onSubmit={(e) => { e.preventDefault(); handleSubmit() }}>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <label htmlFor="">Expense Incurred By</label>

                                    <div className="field-group">
                                        <input type="text" className="form-control" onChange={(event) => setIncurredBy(event.target.value)} placeholder="Expense Incurred By" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <label htmlFor="">Expense Approved By</label>

                                    <div className="field-group">
                                        <input type="text" className="form-control" onChange={(event) => setApprovedBy(event.target.value)} placeholder="Expense Approved By" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <label htmlFor="">Name</label>

                                    <div className="field-group">
                                        <input type="text" className="form-control" onChange={(event) => setName(event.target.value)} placeholder="Name" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <label htmlFor="">Bill Number</label>

                                    <div className="field-group">
                                        <input type="text" className="form-control" onChange={(event) => setBillNo(event.target.value)} placeholder="Bill Number" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <label htmlFor="">Date</label>

                                    <div className="field-group">
                                        <input type="date" className="form-control" onChange={(event) => setDate(event.target.value)}  />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="field-group">
                                        <label htmlFor="">Expense Type</label>
                                        <div className="field-group">
                                            <select name="" id="" className="form-control" onChange={(event) => setSelectedExpenseType(event.target.value)}>
                                                <option value="">Please Select a Expense Type</option>
                                                {
                                                    expenseTypesArr && expenseTypesArr.length > 0 && expenseTypesArr.map(el => {
                                                        return (
                                                            <option key={el._id} value={el._id}>{el.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <label htmlFor="">Expense Value</label>
                                    <div className="field-group">
                                        <input type="number" className="form-control" onChange={(event) => setValue(event.target.value)} placeholder="Expense Value" />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4 mb-4 d-flex justify-content-start">
                                <div className="col-4 col-md-3">
                                    <div className="field-group">
                                        <button type="button" onClick={() => handleSubmit()} className="btn searchBttn"> Save </button>
                                    </div>
                                </div>
                                <div className="col-4 col-md-3">
                                    <div className="field-group">
                                        {/* <button type="button" onClick={() => handleSubmit()} className="btn searchBttn"> Save </button> */}
                                        <a href="/expenses" className=" btn searchBttn">View </a>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </main>
        </>
    )
}
