import axios from 'axios';
import { url } from "./Url"


const serverUrl = `${url}/state`


export const uploadStateData=async(formData)=>{
    try {
        const res=await axios.post(`${serverUrl}/stateCheck`,formData);
        return res
    } catch (error) {
        console.error(error)
        throw(error)
    }
}