import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { url } from '../../Services/Url'
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import MaterialTable from 'material-table';

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { uploadStateData } from '../../Services/state';

import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
const MySwal = withReactContent(Swal)
export default function AddState(props) {
    const [name, setName] = useState();
    const [stateArr, setStateArr] = useState([]);
    const [filteredStateArr, setFilteredStateArr] = useState([]);

    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const [uploadFile, setUploadFile] = useState();
    const sortIcon = <ArrowDownward />;

    const onSubmitUpload = async () => {
        if (uploadFile) {
            try {
                MySwal.fire({
                    title: "Processing Please Wait",
                    allowOutsideClick: false
                })

                MySwal.showLoading();
                let formData = new FormData()
                formData.append('file', uploadFile)

                const res = await uploadStateData(formData)
                MySwal.fire(res.data.message)
                getState()
            }
            catch (err) {
                if (err?.response?.data?.message)
                    MySwal.fire(err?.response?.data?.message)
                else
                    MySwal.fire(err?.message)
            }
        }
        else {
            MySwal.fire('please select a csv file')
        }
    }



    const handleSubmit = async () => {
        try {

            let obj = {
                name: name,
            }
            console.log(obj)
            let { data: res } = await axios.post(`${url}/state/`, obj)
            if (res.success) {
                toast.success(res.message)
                // alert(res.message)
                getState()
            }
            else {
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }

    const setAllCityAreaActive = async (id) => {
        try {
            const { data: res } = await axios.patch(`${url}/state/setAllCityAreaActive/${id}`)
            if(res.success){
                getState()
                alert(res.message)
            }
        } catch (error) {
            console.error(error)
            if (error.response.data.message) {
                alert(error.response.data.message)
            }
            else {
                alert(error.message)
            }
        }
    }
    const setAllCityAreaInActive = async (id) => {
        try {
            const { data: res } = await axios.patch(`${url}/state/setAllCityAreaInActive/${id}`)
            if(res.success){
                getState()
                alert(res.message)
            }
        } catch (error) {
            console.error(error)
            if (error.response.data.message) {
                alert(error.response.data.message)
            }
            else {
                alert(error.message)
            }
        }
    }

    


    const getState = async () => {
        try {
            let { data: res } = await axios.get(`${url}/state/`)
            if (res.success) {
                // toast.success(res.message)
                setStateArr(res.data);
                setFilteredStateArr(res.data)
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }



    const updateState = async (id, value) => {
        try {
            let tempobj = {
                active: value
            }
            let { data: res } = await axios.patch(`${url}/state/changeStatusById/${id}`, tempobj)
            if (res.success) {
                toast.success(res.message)
                getState()
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }

    }

    const columns = [
        {
            name: 'S.no',
            selector: row => row.sno,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => `${row.name}`,
            sortable: true,
        },
        {
            name: 'Actions',
            cell: row => <div className="d-flex justify-content-center"> {
                row.active
                    ?
                    <button onClick={() => updateState(row._id, false)} className="btn addModifiBttn mr-2">Set In-Active</button>
                    :
                    <button onClick={() => updateState(row._id, true)} className="btn greenBttn mr-2">Set Active</button>

            }
                <button onClick={() => setAllCityAreaActive(row._id)} className="btn greenBttn mr-2">Set All Cities & All Areas InActive</button>
                <button onClick={() => setAllCityAreaInActive(row._id)} className="btn addModifiBttn mr-2">Set All Cities & All Areas InActive</button>

            </div>
        },


    ];

    const handleFilterByName = (val) => {
        setFilteredStateArr([...stateArr.filter(el => el.name.toLowerCase().includes(val.toLowerCase()))])
    }

    useEffect(() => {
        getState()
    }, [])
    return (
        <main className="main_content">
            <div className="userTabsDesign mb-4">
                <div className="headingTitle"><h3> Add State </h3></div>
                <div className="card">
                    <div className="card-body">
                        <div className="AddModiForm w-100">
                            <form onSubmit={(e) => { e.preventDefault(); handleSubmit() }}>
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="field-group">
                                            <input type="text" className="form-control" onChange={(event) => setName(event.target.value)} placeholder="State Name" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4 d-flex justify-content-start">
                                    <div className="col-lg-4 col-md-3">
                                        <div className="field-group">
                                            <button type="button" onClick={() => handleSubmit()} className="btn searchBttn"> Save </button>
                                        </div>

                                    </div>
                                    <div className="col-lg-4 col-md-3">
                                        <div className="field-group">
                                            <button type="button" onClick={() => onOpenModal()} className="btn searchBttn"> Upload Excel </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="AddModiForm w-100">
                            <form onSubmit={(e) => { e.preventDefault(); handleSubmit() }}>
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="field-group">
                                            <label>Search</label>
                                            <input type="text" className="form-control" onChange={(event) => handleFilterByName(event.target.value)} placeholder="Search By State Name" />
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="infoOrdertable mb-4">
                {/* <div className="headingTitle"><h3>States </h3></div> */}
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive">

                            <DataTable
                                title="States"
                                columns={columns}
                                data={filteredStateArr?.map((el, index) => {
                                    let obj = {
                                        sno: index + 1,
                                        ...el
                                    }
                                    return obj
                                })}
                                sortIcon={sortIcon}
                                keyField='_id'
                                pagination
                            />


                        </div>
                    </div>
                </div>
            </div>
            <Modal open={open} onClose={onCloseModal} center classNames="col-6">
                <div style={{ padding: 50, overflowX: 'hidden' }} className='row'>
                    <h2 className="text-center">Upload</h2>
                    <div className="col">
                        <div className="mb-3">
                            <label htmlFor="formFile" className="form-label">File Input</label>
                            <input type="file" className="form-control" onChange={(e) => e.target.files && e.target.files[0] ? setUploadFile(e.target.files[0]) : 0} />
                        </div>
                        <div>
                            <button className="btn btn-primary" onClick={() => onSubmitUpload()}>
                                Upload
                            </button>
                        </div>
                    </div>
                </div>

            </Modal>
        </main>
    )
}
