import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { getTargets, UpdateTargets } from '../../Services/Target';

import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
function Assigntarget() {

    const sortIcon = <ArrowDownward />;

    const [targetsArr, setTargetsArr] = useState([]);
    const [filteredTargetArr, setFilteredTargetArr] = useState();
    const [selectedTarget, setSelectedTarget] = useState();

    const [hoursOnline, setHoursOnline] = useState("");
    const [deliveries, setDeliveries] = useState("");
    const [km, setKm] = useState("");
    const getallTargets = async () => {
        try {
            const res = await getTargets()
            if (res.success) {
                setTargetsArr(res.data);
                setFilteredTargetArr(res.data)
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.log(err?.response?.data?.message)
                alert(err?.response?.data?.message)
            }
            else {
                alert(err)
                console.log(err)
            }
        }
    }



    const setTargetToUpdate = (item) => {
        setSelectedTarget(item)
        setHoursOnline(item.hoursOnline)
        setDeliveries(item.deliveries)
        setKm(item.km)
    }


    const updateTarget = async () => {
        try {
            if (hoursOnline != "" && km != "" && deliveries != "") {
                let obj = {
                    hoursOnline,
                    deliveries,
                    km,
                }
                const res = await UpdateTargets(selectedTarget._id, obj)
                if (res.success) {
                    getallTargets()
                }
            }
            else {
                alert("Please Enter All Values")
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.log(err?.response?.data?.message)
                alert(err?.response?.data?.message)
            }
            else {
                alert(err)
                console.log(err)
            }
        }

    }

    const columns = [
        {
            name: 'Date Of Joining',
            selector: row => row?.monthName,
            sortable: true,
        },

        {
            name: 'Hours Online',
            selector: row => row?.hoursOnline,
            sortable: true,
        },
        {
            name: 'Deliveries',
            selector: row => row?.deliveries,
            sortable: true,
        },
        {
            name: 'Distance (in KM)',
            selector: row => row?.km,
            sortable: true,
        },
        {
            name: 'Action',
            selector: row => <button type="button" className="btn searchBttn width-auto" onClick={() => setTargetToUpdate(row)} data-toggle="modal" data-target="#modifyAssignTarget"> Modify </button>,
            sortable: true,
        },

    ];
    useEffect(() => {
        getallTargets()
    }, [])


    return (
        <main className="main_content">
            <div className="infoOrdertable mb-4">
                <div className="headingTitle"><h3> Set Target for All Delivery Man </h3></div>
                <div className="card">

                    <DataTable
                        title=""
                        columns={columns}
                        data={filteredTargetArr}
                        sortIcon={sortIcon}
                        keyField='_id'
                        pagination
                    />
                </div>
            </div>

            <div id="modifyAssignTarget" className="modal fade" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title text-center"> Modify Assign Target </h4>
                        </div>
                        <div className="modal-body">
                            <div className="AddModiForm">
                                <form>
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <label> Month Name </label>
                                                <input disabled value={selectedTarget?.monthName} type="text" className="form-control" placeholder="Enter Month name" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <label> Hour's Online </label>
                                                <input type="text" value={hoursOnline} onChange={(e) => setHoursOnline(e.target.value)} className="form-control" placeholder="Enter Hour's Online" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <label> Deliveries </label>
                                                <input type="text" value={deliveries} onChange={(e) => setDeliveries(e.target.value)} className="form-control" placeholder="Enter Deliveries" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <label> Km </label>
                                                <input type="text" value={km} onChange={(e) => setKm(e.target.value)} className="form-control" placeholder="Enter Km" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-4 d-flex justify-content-center">
                                        <div className="col-12 col-md-3">
                                            <div className="field-group">
                                                <button type="button" data-toggle="modal" data-target="#modifyAssignTarget" onClick={() => updateTarget()} className="btn searchBttn"> Save </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </main >
    )
}

export default Assigntarget;