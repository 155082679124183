import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { createUserProducts, uploadProductWithExcel } from '../../Services/UserBusinessProduct';
import { ToastContainer, toast } from 'react-toastify';
import { nanoid } from 'nanoid/non-secure'

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

function AddBusinessProducts() {
    let id = useParams()
    const history = useHistory()
    const [name, setName] = useState("");
    const [price, setPrice] = useState('');
    const [productArr, setProductArr] = useState([{ name: '', price: '', id: nanoid(), weight: '' }]);

    const [uploadFile, setUploadFile] = useState();
    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const AddProducts = async () => {
        try {
            if (productArr.every(el => el.name != "" && el.price != "" && el.weight != "")) {
                let obj = {
                    productArr,
                    userId: id.id,
                }
                let res = await createUserProducts(obj)
                if (res.success) {
                    toast.success(res.message)
                }
            }
            else {
                toast.error("Name must be entered")
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                toast.error(err?.response?.data?.message)
                console.log(err?.response?.data?.message)
            }
            else {
                toast.error(err)
                console.log(err)
            }
        }
    }

    const handleProductUpdate = (val, index, field) => {
        setProductArr(prevState => {
            if (field == "name") {
                prevState[index].name = val;
            }
            else if (field == "price") {
                prevState[index].price = val
            }
            else if (field == "weight") {
                prevState[index].weight = val
            }
            return [...prevState]
        })
    }

    const handleProductsAdd = () => {
        setProductArr(prevState => [...prevState, { name: "", price: "", id: nanoid(), weight: '' }])
    }
    const handleProductRemove = (index) => {
        setProductArr(prevState => prevState.filter((el, i) => i != index))
    }


    const onSubmitUpload = async () => {
        if (uploadFile) {
            try {
                MySwal.fire({
                    title: "Processing Please Wait",
                    allowOutsideClick: false
                })

                MySwal.showLoading();
                let formData = new FormData()
                formData.append("userId", id.id)
                formData.append('file', uploadFile)

                const res = await uploadProductWithExcel(formData)
                MySwal.fire(res.data.message)
            }
            catch (err) {
                if (err?.response?.data?.message)
                    MySwal.fire(err?.response?.data?.message)
                else
                    MySwal.fire(err?.message)
            }
        }
        else {
            MySwal.fire('please select a csv file')
        }
    }



    useEffect(() => {
    }, [])
    return (
        <main className="main_content">
            <div className="userTabsDesign mb-4">
                {/* <button onClick={() => history.goBack()} type="button" style={{ marginRight: 15, marginBottom: 35 }} className="btn addModifiBttn width-auto"> Go Back </button> */}
                <a href={`/ViewBusinessProduct/${id.id}`} type="button" style={{ marginRight: 15, marginBottom: 35 }} className="btn searchBttn width-auto"> View Products </a>

                <div className="headingTitle"><h3> Add Product </h3></div>
                <div className="card">
                    <div className="card-body">
                        <div className="AddModiForm w-100">
                            <form>
                                {productArr.map((el, i) => {
                                    return (
                                        <div className="row" key={el.id}>
                                            <div className="col-12 col-md-6">
                                                <div className="field-group">
                                                    <label>Product Name</label>
                                                    <input type="text" value={el.name} className="form-control" onChange={(event) => handleProductUpdate(event.target.value, i, "name")} placeholder="Enter Product Name" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="field-group">
                                                    <label>Product Packing</label>
                                                    <input type="number" value={el.weight} className="form-control" onChange={(event) => handleProductUpdate(event.target.value, i, "weight")} placeholder="Enter Product Packing(weight)" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="field-group">
                                                    <label>Price</label>
                                                    <input type="number" value={el.price} className="form-control" onChange={(event) => handleProductUpdate(event.target.value, i, "price")} placeholder="Enter Product Price" />
                                                </div>
                                            </div>
                                            <div className="row mt-4 d-flex justify-content-center">
                                                <div className="col-12 col-md-3">
                                                    <div className="field-group">
                                                        <button type="button" disabled={productArr.length == 1} onClick={() => handleProductRemove(i)} className="btn  btn-danger btn-block">Remove </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    )
                                })}
                                <div className="row mt-4 d-flex justify-content-start">
                                    <div className="col-12 col-md-3">
                                        <div className="field-group">
                                            <button type="button" onClick={() => handleProductsAdd()} className="btn btn-primary btn-block">AddMore </button>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <div className="field-group">
                                            <button type="button" onClick={() => AddProducts()} className="btn searchBttn"> Save </button>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-3">
                                        <div className="field-group">
                                            <button type="button" onClick={() => onOpenModal()} className="btn searchBttn"> Upload Excel </button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Modal open={open} onClose={onCloseModal} center classNames="col-6">
                <div style={{ padding: 50, overflowX: 'hidden' }} className='row'>
                    <h2 className="text-center">Upload</h2>
                    <div className="col">
                        <div className="mb-3">
                            <label htmlFor="formFile" className="form-label">File Input</label>
                            <input type="file" className="form-control" onChange={(e) => e.target.files && e.target.files[0] ? setUploadFile(e.target.files[0]) : 0} />
                        </div>
                        <div>
                            <button className="btn btn-primary" onClick={() => onSubmitUpload()}>
                                Upload
                            </button>
                        </div>
                    </div>
                </div>

            </Modal>
        </main>
    );
}

export default AddBusinessProducts;