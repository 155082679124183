import React from 'react';


function SalaryStatus() {
    return (
        <main className="main_content">
            <div className="filterSearchBar mb-4">
                <form>
                    <div className="row">
                        <div className="col-12 col-md-3">
                            <label>Delivery Boy Name</label>
                            <input type="text" className="form-control" placeholder="Enter Delivery Boy Name" />
                        </div>
                        <div className="col-12 col-md-2">
                            <label>City</label>
                            <input type="text" className="form-control" placeholder="Enter City Name" />
                        </div>
                        <div className="col-12 col-md-2">
                            <label>Area</label>
                            <input type="text" className="form-control" placeholder="Enter Area Name" />
                        </div>
                        <div className="col-12 col-md-2">
                            <label>Month</label>
                            <input type="text" className="form-control" placeholder="Enter Month Name" />
                        </div>
                        <div className="col-12 col-md-2">
                            <label>&nbsp;</label>
                            <button type="button" className="btn searchBttn"> Search </button>
                        </div>
                    </div>
                </form>
                <br />
            </div>
            <div className="infoOrdertable mb-4">
                <div className="headingTitle"><h3> Salary Payable </h3></div>
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-responsive m-0">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Basic</th>
                                        <th>Fuel Allowance</th>
                                        <th>Others</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th> Vikash </th>
                                        <td>Normal</td>
                                        <td>Yes</td>
                                        <td>nothing</td>
                                        <td>13000</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="infoOrdertable mb-4">
                <div className="headingTitle"><h3> Incentive </h3></div>
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-responsive m-0">
                                <thead>
                                    <tr>
                                        <th>Hours Online</th>
                                        <th>Deliveries</th>
                                        <th>Km</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th> Vikash </th>
                                        <td>Normal</td>
                                        <td>Yes</td>
                                        <td>1200</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className="infoOrdertable mb-4">
                <div className="headingTitle"><h3> Total Payable </h3></div>
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-responsive m-0">
                                <thead>
                                    <tr>
                                        <th>Total Salary</th>
                                        <th>Total Incentive</th>
                                        <th>Total Payable</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td> 13000 </td>
                                        <td>1200</td>
                                        <th>14200</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="infoOrdertable mb-4">
                <div className="headingTitle"><h3> Salary Paid </h3></div>
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-responsive m-0">
                                <thead>
                                    <tr>
                                        <th>Monthly</th>
                                        <th>Name</th>
                                        <th>Basic</th>
                                        <th>Fuel Allowance</th>
                                        <th>Others</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th> January </th>
                                        <td>Rahul Singh</td>
                                        <td>Normal</td>
                                        <td>Yes</td>
                                        <td>nothing</td>
                                        <td>13000</td>
                                    </tr>
                                    <tr>
                                        <th> February </th>
                                        <td>Rahul Singh</td>
                                        <td>Normal</td>
                                        <td>Yes</td>
                                        <td>nothing</td>
                                        <td>13000</td>
                                    </tr>
                                    <tr>
                                        <th> March </th>
                                        <td>Rahul Singh</td>
                                        <td>Normal</td>
                                        <td>Yes</td>
                                        <td>nothing</td>
                                        <td>13000</td>
                                    </tr>
                                    <tr>
                                        <th> April </th>
                                        <td>Rahul Singh</td>
                                        <td>Normal</td>
                                        <td>Yes</td>
                                        <td>nothing</td>
                                        <td>13000</td>
                                    </tr>
                                    <tr>
                                        <th> May </th>
                                        <td>Rahul Singh</td>
                                        <td>Normal</td>
                                        <td>Yes</td>
                                        <td>nothing</td>
                                        <td>13000</td>
                                    </tr>
                                    <tr>
                                        <th> June </th>
                                        <td>Rahul Singh</td>
                                        <td>Normal</td>
                                        <td>Yes</td>
                                        <td>nothing</td>
                                        <td>13000</td>
                                    </tr>
                                    <tr>
                                        <th> July </th>
                                        <td>Rahul Singh</td>
                                        <td>Normal</td>
                                        <td>Yes</td>
                                        <td>nothing</td>
                                        <td>13000</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default SalaryStatus;