import React, { useState, useEffect } from 'react';
import { createUserProducts, deleteUserProducts, getUserProducts } from '../../Services/UserBusinessProduct';
import { useHistory, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import MaterialTable from 'material-table';

import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

function ViewBusinessProduct() {
    const history = useHistory()
    console.log(history)
    const trashImage = require("../../assets/images/trash.png")
    const id = useParams()
    const [productsArr, setProductsArr] = useState();
    const sortIcon = <ArrowDownward />;


    ///export to excel
    function convertArrayOfObjectsToCSV(array) {
        let result;

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(productsArr[0]);
        console.log(keys)
        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;

                result += item[key];

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }

    // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
    function downloadCSV(array) {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;

        const filename = 'export.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }




    const getProducts = async () => {
        try {

            let res = await getUserProducts(id.id)
            console.log(res, "products")
            if (res.success) {
                setProductsArr([...res.data.map(el => {
                    let obj = {
                        price: el.price,
                        packing: el.weight,
                        name: el.name
                    }
                    return obj
                })])
            }
        }
        catch (err) {
            if (err?.response?.message?.data) {
                toast.error(err?.response?.message?.data)
                console.log(err?.response?.message?.data)
            }
            else {
                toast.error(err)
                console.log(err)
            }
        }
    }
    const deleteProduct = async (val) => {
        try {

            let res = await deleteUserProducts(val)
            console.log(res, "products")
            if (res.success) {
                toast.success(res.message);
                getProducts()
            }
        }
        catch (err) {
            if (err?.response?.message?.data) {
                toast.error(err?.response?.message?.data)
                console.log(err?.response?.message?.data)
            }
            else {
                toast.error(err)
                console.log(err)
            }
        }
    }

    const column = [
        {
            name: 'Product Name', selector: row => row?.name, sortable: true,
        },
        {
            name: 'Price', selector: row => row?.price, sortable: true,
        },
        {
            name: 'Packing(weight)', selector: row => row?.packing, sortable: true,
        },
        {
            sortable: true, name: 'Email', selector: rowData => <button className="btn-danger btn" onClick={() => deleteProduct(rowData._id)}><img style={{ height: 20, width: 20 }} src={trashImage} alt="delete" />    </button>
        },



    ]


    useEffect(() => {
        getProducts()
    }, [])



    return (
        <main className="main_content">
            <div className="userTabsDesign mb-4">

                {/* <button onClick={() => history.push(`/user`)} className="btn btn-success">View all Users</button> */}
                <>
                    <div className="headingTitle" style={{ marginBottom: 20 }}><h3> Product </h3>
                        <button onClick={() => history.push(`/AddBusinessProducts/${id.id}`)} className="btn addModifiBttn">Add Product</button>
                    </div>
                    <button onClick={() => downloadCSV(productsArr)} className="btn addModifiBttn">Export </button>

                    <div className="card mt-3">
                        <DataTable
                            title=""
                            columns={column}
                            data={productsArr}
                            sortIcon={sortIcon}
                            keyField='_id'
                            pagination

                        />
                        {/* {
                            productsArr && productsArr.length > 0 ?
                                <MaterialTable
                                    columns={[
                                        {
                                            title: 'Product/Offer Name', field: "name",
                                            cellStyle: {
                                                minWidth: 150,
                                                textAlign: "left",
                                            },
                                        },
                                        {
                                            title: 'Action',
                                            cellStyle: {
                                                minWidth: 150,
                                                textAlign: "left",
                                            },
                                            render: rowData => <button className="btn-danger btn" onClick={() => deleteProduct(rowData._id)}><img style={{ height: 20, width: 20 }} src={trashImage} alt="delete" />    </button>
                                        },

                                    ]}
                                    data={productsArr}
                                    title=""
                                    options={{
                                        headerStyle: {
                                            backgroundColor: 'rgba(0,0,0,0.7)',
                                            color: '#FFF',
                                            fontSize: 12,
                                            fontWeight: "bold",
                                        },
                                        actionsColumnIndex: -1
                                    }}

                                />
                                :

                                <>
                                    <div>
                                        No Products Found
                                    </div>
                                </>
                        } */}
                    </div>
                </>

            </div>
        </main>
    );
}



export default ViewBusinessProduct