import axios from 'axios';
import { url } from "./Url"


const serverUrl = `${url}/area`


export const uploadAreaData=async(formData)=>{
    try {
        const res=await axios.post(`${serverUrl}/locationCheck`,formData);
        return res
    } catch (error) {
        console.error(error)
        throw(error)
    }
}

export const getAreaByPincode=async(pincode)=>{
    try {
        const res=await axios.get(`${serverUrl}/getAreaByPincode/${pincode}`);
        return res
    } catch (error) {
        console.error(error)
        throw(error)
    }
}
export const getAreaByCityId=async(cityId)=>{
    try {
        const res=await axios.get(`${serverUrl}/getAreaByCityId/${cityId}`);
        return res
    } catch (error) {
        console.error(error)
        throw(error)
    }
}

export const setSelectedAreasActive=async(arr)=>{
    try {
        const res=await axios.patch(`${serverUrl}/setSelectedAreaActive/`,arr);
        return res
    } catch (error) {
        console.error(error)
        throw(error)
    }
}

export const setSelectedAreasInActive=async(arr)=>{
    try {
        const res=await axios.patch(`${serverUrl}/setSelectedAreaInActive/`,arr);
        return res
    } catch (error) {
        console.error(error)
        throw(error)
    }
}

