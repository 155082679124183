import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { url } from '../../Services/Url'
import { ToastContainer, toast } from 'react-toastify';
import MaterialTable from 'material-table';


function AddVehicle() {
    // vehicle

    const [toggle, setToggle] = useState();


    const [name, setName] = useState("");

    const [carryingcapacity, setCarryingcapacity] = useState('');
    const [documentRequired, setDocumentRequired] = useState(false);
    const [VehiclesArr, setVehiclesArr] = useState();


    const handleSubmit = async () => {
        try {
            if (name != "" && carryingcapacity!="") {
                let obj = {
                    name: name,
                    carryingcapacity,
                    documentRequired
                }
                console.log(obj)
                let { data: res } = await axios.post(`${url}/vehicle/`, obj)
                if (res.success) {
                    toast.success(res.message)
                    getVehicles()
                    // alert(res.message)
                }
            }
            else {
                alert("Please Fill All the fields")
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }

    }



    const getVehicles = async () => {
        try {
            let { data: res } = await axios.get(`${url}/vehicle/`)
            if (res.success) {
                setVehiclesArr(res.data);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }

    useEffect(() => {
        getVehicles()
    }, [])
    return (
        <main className="main_content">
            {
                toggle ?
                    <>
                        <div className="headingTitle" style={{ marginBottom: 20 }}><h3> Vehicle </h3> <button onClick={() => setToggle(false)} className="btn addModifiBttn">Add Vehicles</button></div>
                        <div className="card mt-3">
                            {
                                VehiclesArr && VehiclesArr.length > 0 &&
                                <MaterialTable
                                    columns={[
                                        {
                                            title: 'Vehicle Type Name', field: "name",
                                            cellStyle: {
                                                minWidth: 150,
                                                textAlign: "left"
                                            },
                                        },
                                        {
                                            title: 'Vehicle Carrying Capacity', field: "carryingcapacity",
                                            cellStyle: {
                                                minWidth: 150,
                                                textAlign: "left"
                                            },
                                        },
                                    ]}
                                    data={VehiclesArr}
                                    title=""

                                    // actions={[
                                    //     {
                                    //         icon: 'delete',
                                    //         tooltip: 'Delete Entry',
                                    //         onClick: (event, rowData) => {
                                    //             // Do save operation
                                    //         }
                                    //     }
                                    // ]}
                                    options={{
                                        headerStyle: {
                                            backgroundColor: 'rgba(0,0,0,0.7)',
                                            color: '#FFF',
                                            fontSize: 12,
                                            fontWeight: "bold",
                                        },
                                        actionsColumnIndex: -1
                                    }}

                                />

                            }
                        </div>
                    </>
                    :
                    <>

                        <div className="headingTitle" style={{ marginBottom: 20 }}><h1>Add Vehicles </h1> <button onClick={() => setToggle(true)} className="btn addModifiBttn">View Vehicle</button></div>
                        <div className="card">
                            <div className="card-body">
                                <div className="AddModiForm w-100">
                                    <form>
                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <div className="field-group">
                                                    <label> Add Vehicle Name </label>
                                                    <input type="text" onChange={(e) => setName(e.target.value)} className="form-control" placeholder="Enter Vehicle Type" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="field-group">
                                                    <label> Add Vehicle Carrying Capacity (in kg)</label>
                                                    <input type="number" onChange={(e) => setCarryingcapacity(e.target.value)} className="form-control" placeholder="Enter Vehicle Carrying Capacity" />
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-4">
                                                    <label>Vehicle Documents Required</label>
                                                    <div style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', display: 'flex' }}>
                                                        <div>
                                                            <input id="business" type="radio" value={true}checked={documentRequired} onChange={(e) => setDocumentRequired(true)} />
                                                            <label for="business">Yes</label>
                                                        </div>
                                                        <div>
                                                            <input id="household" type="radio" value={false} checked={!documentRequired} onChange={(e) => setDocumentRequired(false)} />
                                                            <label for="household">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                        
                                        <div className="row mt-4 d-flex justify-content-center">
                                            <div className="col-12 col-md-3">
                                                <div className="field-group">
                                                    <button type="button" onClick={() => { handleSubmit() }} className="btn searchBttn"> Save </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </>
            }
        </main>
    )
}

export default AddVehicle;
