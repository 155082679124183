import { useHistory, useParams } from 'react-router';
import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { url } from '../../Services/Url'
import { ToastContainer, toast } from 'react-toastify';
import MaterialTable from 'material-table';


function ShopTypeDetails() {


    let { id } = useParams();
    let history = useHistory()

    const [stateArr, setStateArr] = useState();
    const [cityArr, setCityArr] = useState();
    const [areaArr, setAreaArr] = useState();
    const [cityFilteredArr, setCityFilteredArr] = useState();
    const [areaFilteredArr, setAreaFilteredArr] = useState();
    const [selectedStateId, setSelectedStateId] = useState("");
    const [selectedCityId, setSelectedCityId] = useState("");
    const [selectedAreaId, setSelectedAreaId] = useState("");
    const [viewAllBool, setViewAllBool] = useState(true);

    const getCity = async () => {
        try {
            let { data: res } = await axios.get(`${url}/city/`)
            if (res.success) {
                let tempCityArr = res.data.filter(el => el.active == true && el.stateActive == true);
                setCityArr(tempCityArr);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }




    const getState = async () => {
        try {
            let { data: res } = await axios.get(`${url}/state/`)
            if (res.success) {
                // toast.success(res.message)
                let tempStateArr = res.data.filter(el => el.active == true);
                setStateArr(tempStateArr);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }

    const setCityArrFromState = (value) => {
        setSelectedStateId(value)
        let cityfilteredArr = cityArr.filter(el => el.stateId == value);
        setCityFilteredArr(cityfilteredArr)
    }

    const setAreaArrFromCity = (value) => {
        setSelectedCityId(value)
        let areafilteredArr = areaArr.filter(el => el.cityId == value);
        setAreaFilteredArr(areafilteredArr)
    }


    const getArea = async () => {
        try {
            let { data: res } = await axios.get(`${url}/area/`)
            if (res.success) {
                let tempAreaArr = res.data.filter(el => el.active == true && el.stateActive == true && el.cityActive == true);
                // toast.success(res.message)
                setAreaArr(tempAreaArr);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }













    const [userArr, setUserArr] = useState();
    const [filteredUserArr, setFilteredUserArr] = useState();
    const getUsersByShopTypeId = async () => {
        try {
            let { data: res } = await axios.get(`${url}/user/getByShoptype/${id}`)
            console.log(res)
            if (res.success) {
                // console.log(res.data, "user Arr")
                setUserArr(res.data);
                setFilteredUserArr(res.data);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }

    const filterUserArr = (value) => {
        if (value == false) {
            if (selectedStateId != "" && selectedCityId != "" && selectedAreaId != "") {
                setViewAllBool(value)
                let tempFilteredUserArr = userArr.filter(el => el.stateId == selectedStateId && el.cityId == selectedCityId && el.areaId == selectedAreaId)
                if (tempFilteredUserArr) {
                    // console.log(tempFilteredUserArr)
                    setFilteredUserArr(tempFilteredUserArr)
                }
            }
            else {
                alert("You need to select State, City and Area for Searching")
            }
        }
        else {
            setViewAllBool(value)
            getUsersByShopTypeId()
        }
    }

    useEffect(() => {
        getState();
        getCity();
        getArea();

        getUsersByShopTypeId()
    }, [])

    return (
        <main className="main_content">
            <div className="headingTitle" style={{ marginBottom: 20 }}> <button onClick={() => { history.goBack() }} className="btn addModifiBttn">Go Back</button></div>

            <div className="filterSearchBar mb-4">
                <form>
                    <div className="row">
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-2">
                            <label>State</label>
                            <select name="" id="" className="form-control" onChange={(event) => { setCityArrFromState(event.target.value) }}>
                                <option value="">Please Select a State</option>
                                {
                                    stateArr && stateArr.length > 0 && stateArr.map(el => {
                                        return (
                                            <option key={el._id} value={el._id}>{el.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-12 col-md-2">
                            <label>City</label>
                            <select name="" id="" className="form-control" onChange={(event) => { setAreaArrFromCity(event.target.value) }}>
                                <option value="">Please Select a City</option>
                                {
                                    cityFilteredArr && cityFilteredArr.length > 0 && cityFilteredArr.map(el => {
                                        return (
                                            <option key={el._id} value={el._id}>{el.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-12 col-md-2">
                            <label>Area</label>
                            <select name="" id="" className="form-control" onChange={(event) => { setSelectedAreaId(event.target.value) }}>
                                <option value="">Please Select a Area</option>
                                {
                                    areaFilteredArr && areaFilteredArr.length > 0 && areaFilteredArr.map(el => {
                                        return (
                                            <option key={el._id} value={el._id}>{el.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>

                        <div className="col-12 col-md-2">
                            <label>&nbsp;</label>
                            <button onClick={() => filterUserArr(true)} type="button" className={`btn ${viewAllBool ? "searchBttn" : "addModifiBttn"}`}>{viewAllBool ? "Viewing All" : "View All"} </button>
                        </div>
                        <div className="col-12 col-md-2">
                            <label>&nbsp;</label>
                            <button onClick={() => filterUserArr(false)} type="button" className="btn searchBttn"> Search </button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="infoOrdertable mb-4">
                <div className="headingTitle"><h3> Shop Details </h3></div>
                <div className="card">
                    {
                        filteredUserArr && filteredUserArr.length > 0 &&
                        <MaterialTable
                            style={{ boxShadow: "none" }}
                            columns={[
                                {
                                    title: 'Shop Name', field: "name",
                                    cellStyle: {
                                        minWidth: 150,
                                        textAlign: "left"
                                    },
                                },
                                {
                                    title: 'State', field: "stateName",
                                    cellStyle: {
                                        minWidth: 150,
                                        textAlign: "left"
                                    },
                                },
                                {
                                    title: 'City', field: "cityName",
                                    cellStyle: {
                                        minWidth: 150,
                                        textAlign: "left"
                                    },
                                },
                                {
                                    title: 'Area', field: "areaName",
                                    cellStyle: {
                                        minWidth: 150,
                                        textAlign: "left"
                                    },
                                },
                                {
                                    title: 'PinCode', field: "pincode",
                                    cellStyle: {
                                        minWidth: 150,
                                        textAlign: "left"
                                    },
                                },
                            ]}
                            data={filteredUserArr}
                            title=""

                            // actions={[
                            //     {
                            //         icon: 'delete',
                            //         tooltip: 'Delete Entry',
                            //         onClick: (event, rowData) => {
                            //             // Do save operation
                            //         }
                            //     }
                            // ]}
                            options={{
                                headerStyle: {
                                    // backgroundColor: 'rgba(0,0,0,0.7)',
                                    color: '#000',
                                    fontSize: 12,
                                    fontWeight: "bold",
                                },
                                actionsColumnIndex: -1
                            }}

                        />

                    }
                </div>
            </div>
        </main>
    )
}

export default ShopTypeDetails;
