import React, { useState, useEffect } from "react";
import { getAllPackageOrders, getAllTopUpEarnings } from "../../Services/PackageOrders";
import DataTable from "react-data-table-component";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import axios from "axios";
import { url } from "../../Services/Url";
import { ToastContainer, toast } from "react-toastify";
import { colorsArr, filterArrayByDateRange, generateExpiryDatefor1Delivery, generateExpiryDatefor1Month, generateExpiryDatefor1Year, generateExpiryDatefor3Months, generateExpiryDatefor6Months } from "../../utils/util";
import { getAreaByCityId } from "../../Services/area";
import { Pie, Bar } from "react-chartjs-2";

function Packages() {
  const sortIcon = <ArrowDownward />;

  /////arrays
  const [packageOrderArr, setPackageOrderArr] = useState([]);
  const [filteredPackageOrderArr, setFilteredPackageOrderArr] = useState([]);
  const [stateArr, setStateArr] = useState();
  const [cityArr, setCityArr] = useState();
  const [areaArr, setAreaArr] = useState();
  const [cityFilteredArr, setCityFilteredArr] = useState();
  const [areaFilteredArr, setAreaFilteredArr] = useState();
  //////counters
  const [activePackageCount, setActivePackageCount] = useState();
  const [topUpcount, setTopUpcount] = useState();
  const [soldThisMonthCount, setSoldThisMonthCount] = useState();

  //////////variable for filters
  const [selectedPackageType, setSelectedPackageType] = useState();
  const [startDate, setstartDate] = useState();
  const [endDate, setendDate] = useState();
  const [selectedStateId, setSelectedStateId] = useState("");
  const [selectedCityId, setSelectedCityId] = useState("");
  const [area, setArea] = useState("");
  const [selectedStateOfOrders, setselectedStateOfOrders] = useState();

  const [searchStr, setSearchStr] = useState("");

  const [barGraphData, setBarGraphData] = useState({});

  const [barGraphSoldData, setBarGraphSoldData] = useState({});

  const getCity = async () => {
    try {
      let { data: res } = await axios.get(`${url}/city/`);
      if (res.success) {
        let tempCityArr = res.data.filter((el) => el.active == true && el.stateActive == true);
        setCityArr(tempCityArr);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log(error.response, "errors");
    }
  };

  const getState = async () => {
    try {
      let { data: res } = await axios.get(`${url}/state/`);
      if (res.success) {
        // toast.success(res.message)
        let tempStateArr = res.data.filter((el) => el.active == true);
        setStateArr(tempStateArr);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log(error.response, "errors");
    }
  };

  const setCityArrFromState = (value) => {
    setSelectedStateId(value);
    let cityfilteredArr = cityArr.filter((el) => el.stateId == value);
    setCityFilteredArr(cityfilteredArr);
  };

  const setAreaArrFromCity = (value) => {
    setSelectedCityId(value);
    console.log(areaArr);
    getArea(value);
    // let areafilteredArr = areaArr.filter(el => el.cityId == value);
    // setAreaFilteredArr(areafilteredArr)
  };

  const getArea = async (id) => {
    try {
      let { data: res } = await getAreaByCityId(id);
      if (res.success) {
        let tempAreaArr = res.data;
        console.log(tempAreaArr);
        setAreaArr(tempAreaArr);
        setAreaFilteredArr(tempAreaArr);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log(error.response, "errors");
    }
  };

  const handleBarGraph = (arr) => {
    let packageArr = arr.reduce((acc, el) => {
      let orderDate = new Date(el.createdAt);
      let onlyDate = new Date(orderDate.getFullYear(), orderDate.getMonth(), orderDate.getDate());
      let tempIndex = acc.findIndex((el) => el.date.getTime() == onlyDate.getTime());
      if (tempIndex != -1) {
        acc[tempIndex].packageArr.push({ amount: el.paymentAmount });
      } else {
        let obj = {
          date: onlyDate,
          packageArr: [
            {
              amount: el.paymentAmount,
            },
          ],
        };
        acc.push(obj);
      }
      return [...acc];
    }, []);
    const labels = packageArr?.map((el) => new Date(el.date).toDateString());
    const barGraphData = {
      labels: labels,
      datasets: [
        {
          label: "Package Sold Amount",
          data: packageArr?.map((el) => el.packageArr.reduce((acc, ele) => acc + ele.amount, 0)),
          backgroundColor: colorsArr.map((el) => el.transparentColor),
          borderColor: colorsArr.map((el) => el.solidColor),
          borderWidth: 1,
        },
      ],
    };
    setBarGraphData({ ...barGraphData });
  };
  const handleBarGraphForSoldPackages = (arr) => {
    let packageArr = arr.reduce((acc, el) => {
      let orderDate = new Date(el.createdAt);
      let onlyDate = new Date(orderDate.getFullYear(), orderDate.getMonth(), orderDate.getDate());
      let tempIndex = acc.findIndex((el) => el.date.getTime() == onlyDate.getTime());
      if (tempIndex != -1) {
        acc[tempIndex].packageArr.push({ amount: el.paymentAmount });
      } else {
        let obj = {
          date: onlyDate,
          packageArr: [
            {
              amount: el.paymentAmount,
            },
          ],
        };
        acc.push(obj);
      }
      return [...acc];
    }, []);
    const labels = packageArr?.map((el) => new Date(el.date).toDateString());
    const barGraphData = {
      labels: labels,
      datasets: [
        {
          label: "Total packages Sold",
          data: packageArr?.map((el) => el.packageArr.length, 0),
          backgroundColor: colorsArr.map((el) => el.transparentColor),
          borderColor: colorsArr.map((el) => el.solidColor),
          borderWidth: 1,
        },
      ],
    };
    setBarGraphSoldData({ ...barGraphData });
  };
  const barGraphConfig = {
    type: "bar",
    data: barGraphData,
    options: {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    },
  };

  const getSoldTopups = async () => {
    try {
      let res = await getAllTopUpEarnings();
      if (res.status == 200 || res.status == 304) {
        setTopUpcount(res.data.data.length);
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        console.log(err?.response?.data?.message);
        alert(err?.response?.data?.message);
      } else {
        console.log(err);
        alert(err);
      }
    }
  };

  const getPackagesOrder = async () => {
    try {
      const { data: res, status: statusCode } = await getAllPackageOrders();
      if (statusCode == 200 || statusCode == 304) {
        ///////////setting expiry Date
        let ordersArr = res.data;
        ordersArr.map((el) => {
          if (el.packageType == -1) {
            el.expiryDate = generateExpiryDatefor1Delivery(el.createdAt);
          } else if (el.packageType == 0) {
            el.expiryDate = generateExpiryDatefor1Month(el.createdAt);
          } else if (el.packageType == 1) {
            el.expiryDate = generateExpiryDatefor3Months(el.createdAt);
          } else if (el.packageType == 2) {
            el.expiryDate = generateExpiryDatefor6Months(el.createdAt);
          } else if (el.packageType == 3) {
            el.expiryDate = generateExpiryDatefor1Year(el.createdAt);
          }
        });
        let currentDate = new Date().getTime();
        let tempActiveOrdersArr = ordersArr.filter((el) => new Date(el.expiryDate).getTime() >= currentDate);
        if (tempActiveOrdersArr.length > 0) setActivePackageCount(tempActiveOrdersArr.length);
        else setActivePackageCount(0);

        let soldthisMonth = ordersArr.filter((el) => new Date(el.createdAt).getMonth() == new Date(currentDate).getMonth());
        setSoldThisMonthCount(soldthisMonth.length);
        console.log(soldthisMonth);

        ////////setting main array and filtered Array
        setPackageOrderArr(ordersArr);
        setFilteredPackageOrderArr(ordersArr);
        handleBarGraph(ordersArr);
        handleBarGraphForSoldPackages(ordersArr);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const columns = [
    {
      name: "Sold On",
      selector: (row) => new Date(row.createdAt).toLocaleDateString(),
      sortable: true,
    },
    {
      name: "Bought By",
      cell: (row) => row.userName,
      selector: (row) => row.userName,
      width: "200",
      sortable: true,
    },
    {
      name: "Package Type",
      cell: (row) => (
        <div>
          {row.packageType == -1 && "1 Delivery"}
          {row.packageType == 0 && "1 Month"}
          {row.packageType == 1 && "3 Months"}
          {row.packageType == 2 && "6 Months"}
          {row.packageType == 3 && "1 Year"}
        </div>
      ),
      selector: (row) =>
        `               
                    ${row.packageType == -1 && "1 Delivery"}
                    ${row.packageType == 0 && "1 Month"}
                    ${row.packageType == 1 && "3 Months"}
                    ${row.packageType == 2 && "6 Months"}
                    ${row.packageType == 3 && "1 Year"}
                `,
      sortable: true,

      width: "200",
    },
    {
      name: "State",
      cell: (row) => row.stateName,
      selector: (row) => row.stateName,
      sortable: true,
      width: "200",
    },
    {
      name: "City",
      cell: (row) => row.cityName,
      selector: (row) => row.cityName,
      sortable: true,
      width: "200",
    },
    {
      name: "Area",
      cell: (row) => row.areaName,
      selector: (row) => row.areaName,
      sortable: true,
      width: "200",
    },
    {
      name: "Pincode",
      cell: (row) => row.pincode,
      selector: (row) => row.pincode,
      sortable: true,
      width: "200",
    },
    {
      name: "Package Amount",
      cell: (row) => `₹ ${row.value}`,
      selector: (row) => `₹ ${row.value}`,
      sortable: true,
      width: "200",
    },
    {
      name: "Recieved Amount",
      cell: (row) => `₹ ${row.paymentAmount}`,
      selector: (row) => `₹ ${row.paymentAmount}`,
      sortable: true,
      width: "200",
    },
    {
      name: "Payment Id",
      cell: (row) => `${row.paymentId}`,
      selector: (row) => `${row.paymentId}`,
      sortable: true,
      width: "200",
    },
    {
      name: "Order Id",
      cell: (row) => `${row.orderId}`,
      selector: (row) => `${row.orderId}`,
      sortable: true,
      width: "200",
    },
  ];

  const filterByLocation = () => {
    if (selectedStateId != "" && selectedCityId != "" && area != "") {
      let filteredArr = packageOrderArr.filter((el) => el.stateId == selectedStateId && el.cityId == selectedCityId && el.areaId == area);
      setFilteredPackageOrderArr([...filteredArr]);
    } else {
      alert("Please select state,city,area to filter");
    }
  };

  const filterByDate = () => {
    let filteredArr = packageOrderArr.filter((el) => new Date(el.createdAt).getTime() <= new Date(endDate).getTime() && new Date(el.createdAt).getTime() >= new Date(startDate).getTime());
    setFilteredPackageOrderArr(filteredArr);
  };

  const reset = () => {
    setFilteredPackageOrderArr(packageOrderArr);
  };

  const filterBytype = () => {
    let filteredArr = packageOrderArr.filter((el) => parseInt(el.packageType) == parseInt(selectedPackageType));
    setFilteredPackageOrderArr(filteredArr);
  };

  const filterByPackageState = () => {
    if (selectedStateOfOrders == 1) {
      let currentDate = new Date().getTime();

      let tempActiveOrdersArr = packageOrderArr.filter((el) => new Date(el.expiryDate).getTime() >= currentDate);
      if (tempActiveOrdersArr.length > 0) setFilteredPackageOrderArr(tempActiveOrdersArr);
    } else if (selectedStateOfOrders == 2) {
      let currentDate = new Date().getTime();

      let tempActiveOrdersArr = packageOrderArr.filter((el) => new Date(el.expiryDate).getTime() < currentDate);
      if (tempActiveOrdersArr.length > 0) setFilteredPackageOrderArr(tempActiveOrdersArr);
    } else if (selectedStateOfOrders == 0) {
      reset();
    }
  };

  const searchItems = columns?.filter((el) => el.sortable && el.selector);
  function filter(el) {
    let chk = false;
    if (!searchItems?.length) chk = true;
    searchItems.forEach((elx) => {
      let val = elx?.selector(el);
      if (val) {
        if (typeof val == "string" || typeof val == "number") {
          if (`${val}`.toLowerCase().includes(searchStr.toLowerCase())) chk = true;
        }
      }
    });
    return chk;
  }

  useEffect(() => {
    getState();
    getCity();
    getArea();
    getSoldTopups();
    getPackagesOrder();
  }, []);

  return (
    <main className="main_content">
      <div className="myInfo_boxes mb-4">
        <div className="row">
          <div className="col-xl-3 col-lg-3 col-sm-6 col-12">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title">Active Package </h5>
              </div>
              <div className="card-body">
                <div className="boysCount">
                  <p>
                    <i className="fa fa-male"></i> {activePackageCount}{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-sm-6 col-12">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title">TOTAL SOLD TILL NOW</h5>
              </div>
              <div className="card-body">
                <div className="boysCount">
                  <p>
                    <i className="fa fa-male"></i> {packageOrderArr.length}{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-sm-6 col-12">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title"> TOTAL SOLD (This Month)</h5>
              </div>
              <div className="card-body">
                <div className="boysCount">
                  <p>
                    <i className="fa fa-male"></i> {soldThisMonthCount}{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-sm-6 col-12">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title">TOP UP</h5>
              </div>
              <div className="card-body">
                <div className="boysCount">
                  <p>
                    <i className="fa fa-first-order"></i> {topUpcount}{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="filterSearchBar mb-4">
          <form>
            <div className="row mb-4">
              <div className="col-12 col-md-3">
                <label>Start Date</label>
                <input onChange={(e) => setstartDate(e.target.value)} type="date" className="form-control" />
              </div>
              <div className="col-12 col-md-3">
                <label>End Date</label>
                <input onChange={(e) => setendDate(e.target.value)} type="date" className="form-control" />
              </div>
              <div className="col-12 col-md-2">
                <label>&nbsp;</label>
                <button type="button" className="btn searchBttn" onClick={() => filterByDate()}>
                  {" "}
                  Search{" "}
                </button>
              </div>
              <div className="col-12 col-md-2">
                <label>&nbsp;</label>
                <button type="button" className="btn addModifiBttn" onClick={() => reset()}>
                  {" "}
                  Reset{" "}
                </button>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-2">
                <label>Type</label>
                <select onChange={(e) => setSelectedPackageType(e.target.value)} className="form-control">
                  <option value={-1}>1 Delivery</option>
                  <option value={0}>1 Month</option>
                  <option value={1}>3 Months</option>
                  <option value={2}>6 Months</option>
                  <option value={3}>1 Year</option>
                </select>
              </div>
              <div className="col-12 col-md-2">
                <label>&nbsp;</label>
                <button type="button" className="btn searchBttn" onClick={() => filterBytype()}>
                  {" "}
                  Search{" "}
                </button>
              </div>
              <div className="col-12 col-md-2">
                <label>&nbsp;</label>
                <button type="button" className="btn addModifiBttn" onClick={() => reset()}>
                  {" "}
                  Reset{" "}
                </button>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12 col-md-2">
                <label>Select State</label>
                <div className="field-group">
                  <select
                    name=""
                    id=""
                    className="form-control"
                    onChange={(event) => {
                      setCityArrFromState(event.target.value);
                    }}
                  >
                    <option value="">Please Select a State</option>
                    {stateArr &&
                      stateArr.length > 0 &&
                      stateArr.map((el) => {
                        return (
                          <option key={el._id} value={el._id}>
                            {el.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-2">
                <label>Select City</label>

                <div className="field-group">
                  <select
                    name=""
                    id=""
                    className="form-control"
                    onChange={(event) => {
                      setAreaArrFromCity(event.target.value);
                    }}
                  >
                    <option value="">Please Select a City</option>
                    {cityFilteredArr &&
                      cityFilteredArr.length > 0 &&
                      cityFilteredArr.map((el) => {
                        return (
                          <option key={el._id} value={el._id}>
                            {el.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>

              <div className="col-12 col-md-2">
                <label>Select Area</label>

                <div className="field-group">
                  <select
                    name=""
                    id=""
                    className="form-control"
                    onChange={(event) => {
                      setArea(event.target.value);
                    }}
                  >
                    <option value="">Please Select a Area</option>
                    {areaFilteredArr &&
                      areaFilteredArr.length > 0 &&
                      areaFilteredArr.map((el) => {
                        return (
                          <option key={el._id} value={el._id}>
                            {el.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-2">
                <label></label>
                <button type="button" className="btn searchBttn" onClick={() => filterByLocation()}>
                  {" "}
                  Search{" "}
                </button>
              </div>
              <div className="col-12 col-md-2">
                <label>&nbsp;</label>
                <button type="button" className="btn addModifiBttn" onClick={() => reset()}>
                  {" "}
                  Reset{" "}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <br />
      <div className="userTabsDesign mb-4">
        <div className="headingTitle">
          <h3> Total Sold </h3>
        </div>
        <div className="card">
          <label>Search</label>
          <input className="form-control" onChange={(event) => setSearchStr(event.target.value)} placeholder="Search" />
          <div className="row">
            <div className="col-lg-6">{/* <Bar data={barGraphData} options={barGraphConfig} /> */}</div>
            <div className="col-lg-6">{/* <Bar data={barGraphSoldData} options={barGraphConfig} /> */}</div>
          </div>
          <DataTable title="Packages" columns={columns} data={filteredPackageOrderArr?.filter((el) => (searchStr ? filter(el) : true))} sortIcon={sortIcon} keyField="_id" pagination />
        </div>
      </div>
    </main>
  );
}

export default Packages;
