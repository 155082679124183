import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { url } from '../../Services/Url'
import { ToastContainer, toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import withReactContent from 'sweetalert2-react-content'
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import 'react-responsive-modal/styles.css';
import { create, getAll } from '../../Services/ExpenseTypes';

const sortIcon = <ArrowDownward />;


export default function AddExpenseType() {
    const [name, setName] = useState("");


    const [expenseTypesArr, setExpenseTypesArr] = useState();

    const handleSubmit = async () => {
        try {
            if (name != "") {

                let obj = {
                    name,
                }
                let res = await create(obj)
                if (res.status == 200 || res.status == 304) {
                    getExpenseTypes()
                    alert(res.data.message)
                }
                else {
                    alert(res.data.message)
                }
            }
            else{
                alert("Please Fill All the fields")
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.log(err?.response?.data?.message)
                alert(err?.response?.data?.message)
            }
            else {
                console.log(err);
                alert(err)
            }
        }
    }



    const getExpenseTypes = async () => {
        try {
            let res = await getAll()
            if (res.status == 200 || res.status == 304) {
                setExpenseTypesArr(res.data.data)
            }
            else {
                alert(res.data.message)
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.log(err?.response?.data?.message)
                alert(err?.response?.data?.message)
            }
            else {
                console.log(err);
                alert(err)
            }
        }
    }

    useEffect(() => {
        getExpenseTypes()
    }, [])

    const columns = [
        {
            name: 'S.no',
            selector: row => row.sno,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
    ];



    return (
        <>
            <main className="main_content">
                <div className="userTabsDesign mb-4">
                    <div className="headingTitle"><h3> Add Expense type </h3></div>
                    <div className="AddModiForm w-100">
                        <form onSubmit={(e) => { e.preventDefault(); handleSubmit() }}>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="field-group">
                                        <input type="text" className="form-control" onChange={(event) => setName(event.target.value)} placeholder="Expense Type Name" />
                                    </div>
                                </div>

                            </div>
                            <div className="row mt-4 mb-4 d-flex justify-content-start">
                                <div className="col-4 col-md-3">
                                    <div className="field-group">
                                        <button type="button" onClick={() => handleSubmit()} className="btn searchBttn"> Save </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="card">
                        <DataTable
                            title="Expense Type"
                            columns={columns}
                            data={expenseTypesArr?.map((el, index) => {
                                let obj = {
                                    sno: index + 1,
                                    ...el
                                }
                                return obj
                            })}
                            sortIcon={sortIcon}
                            keyField='_id'
                            pagination
                        />

                    </div>
                </div>
            </main>
        </>
    )
}
