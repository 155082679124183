import React from 'react';


function InfoNewPackage() {
    return (
        <main className="main_content">
            <div className="userTabsDesign mb-4">
                <div className="headingTitle">
                    <h3> New Packages Sold Today </h3>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-responsive-sm">
                                <thead>
                                    <tr>
                                        <th> Name </th>
                                        <th> Type </th>
                                        <th> Address </th>
                                        <th> Phone No. </th>
                                        <th> Pincode </th>
                                        <th> Package Type </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th> Vikash Cloth House </th>
                                        <td>ABC traders</td>
                                        <td>Chemist</td>
                                        <td> yes </td>
                                        <td> 989 </td>
                                        <td> 2020 </td>
                                    </tr>
                                    <tr>
                                        <th> Vikash Cloth House </th>
                                        <td>ABC traders</td>
                                        <td>Chemist</td>
                                        <td> yes </td>
                                        <td> 989 </td>
                                        <td> 2020 </td>
                                    </tr>
                                    <tr>
                                        <th> Vikash Cloth House </th>
                                        <td>ABC traders</td>
                                        <td>Chemist</td>
                                        <td> yes </td>
                                        <td> 989 </td>
                                        <td> 2020 </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default InfoNewPackage;