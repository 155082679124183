import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../App'
import {Link} from 'react-router-dom'

import axios from 'axios'
import { url } from '../../Services/Url';
function Sidebar(props) {

  const [isAuthorized, setIsAuthorized] = useContext(AuthContext);
  const [orderArr, setOrderArr] = useState([]);

  const [deliveryBoyArr, setDeliveryBoyArr] = useState([]);
  const [userArr, setUserArr] = useState([]);
  const handleLogout = () => {
    localStorage.removeItem('AUTH_TOKEN');

    setIsAuthorized(false)
    window.location.href = "/"
  }


  const getAllOrders=async()=>{
    try {
      const {data:res}=await axios.get(`${url}/order/`)
      if(res){
        console.log(res.data.filter(el=>el.numberOfOrdersArr.some(el=>el.orderStatus==0)))
        setOrderArr(res.data.filter(el=>el.numberOfOrdersArr.some(el=>el.orderStatus==0)))
      }
    } catch (error) {
      console.error(error)
    }
  }
  const getAllDeliveryBoy=async()=>{
    try {
      const {data:res}=await axios.get(`${url}/deliveryBoy/`)
      if(res){
        console.log(res.data.filter(el=>el.status>0))
        setDeliveryBoyArr(res.data.filter(el=>el.status>0))
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getAllUsers=async()=>{
    try {
      const {data:res}=await axios.get(`${url}/user/`)
      if(res){
        setUserArr(res.data)
      }
    } catch (error) {
      console.error(error)
    }
  }


  const handleOnint=()=>{
    getAllOrders()
    getAllDeliveryBoy()
    getAllUsers()
  }

  useEffect(() => {
    handleOnint()
  }, [])


  


  return (
    <aside className="main-sidebar">
      <div className="nav-header">
        <div className="brand-logo">
          <a href="/">
            <span className="brand-title"><b>Delivery App Admin</b></span>
          </a>
        </div>
      </div>
      <div className="sidebarAccordian">
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <ul className="navbar-nav mr-auto sidenav" id="navAccordion">
            {props.role == "admin" ?
              <>
                <li className="nav-item active">
                  <span
                    className="nav-link nav-link-collapse"
                    id="hasSubItems"
                    style={{color:orderArr.length>=1 ?'red' :"white" ,fontWeight:orderArr.length>=1 ? 'bold' :'400'}}
                    data-toggle="collapse"
                    data-target="#collapseSubItems2"
                    aria-controls="collapseSubItems2"
                    aria-expanded="false"
                  ><i className="fa fa-info-circle" aria-hidden="true"></i> My Info {orderArr?.length >=1 ? `(${orderArr.length})` : "" }</span>
                  <ul className="nav-second-level collapse" id="collapseSubItems2" data-parent="#navAccordion">
                    <li className="nav-item">
                      <Link className="nav-link" to="/">
                        <article className="nav-link-text">Dashboard</article>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/info/Orders">
                        <article style={{color:orderArr.length>=1 ?'red' :"white" ,fontWeight:orderArr.length>=1 ? 'bold' :'400'}} className="nav-link-text"> Orders {orderArr?.length >=1 ? `(${orderArr.length})` : "" }</article>
                      </Link>
                    </li>
                   
                  </ul>
                </li>
                <li className="nav-item">
                  <span
                    className="nav-link nav-link-collapse"
                    id="hasSubItems"
                    data-toggle="collapse"
                    data-target="#collapseSubItems9"
                    aria-controls="collapseSubItems9"
                    aria-expanded="false"
                  ><i className="fa fa-list-alt" aria-hidden="true"></i> Category</span>
                  <ul className="nav-second-level collapse" id="collapseSubItems9" data-parent="#navAccordion">
                    <li className="nav-item">
                      <Link className="nav-link" to="/category/shop-type">
                        <article className="nav-link-text"> Shop Type</article>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/category/vehicle-type">
                        <article className="nav-link-text">Vehicle Types</article>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <span
                    className="nav-link nav-link-collapse"
                    id="hasSubItems"
                    style={{color:deliveryBoyArr.length>=1 ?'red' :"white" ,fontWeight:deliveryBoyArr.length>=1 ? 'bold' :'400'}}
                    data-toggle="collapse"
                    data-target="#collapseSubItems4"
                    aria-controls="collapseSubItems4"
                    aria-expanded="false"
                  ><i className="fa fa-info-circle" aria-hidden="true"></i> Staff Management</span>
                  <ul className="nav-second-level collapse" id="collapseSubItems4" data-parent="#navAccordion">
                    <li className="nav-item">
                      <Link className="nav-link" to="/delivery-boy-info">
                        <article style={{color:deliveryBoyArr.length>=1 ?'red' :"white" ,fontWeight:deliveryBoyArr.length>=1 ? 'bold' :'400'}} className="nav-link-text">  Delivery Boy Info {deliveryBoyArr?.length >=1 ? `(${deliveryBoyArr.length})` : "" }</article>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/monthly-productivity">
                        <article className="nav-link-text"> Monthly Productivity </article>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <span
                    className="nav-link nav-link-collapse"
                    id="hasSubItems"
                    data-toggle="collapse"
                    data-target="#collapseSubItems13"
                    aria-controls="collapseSubItems13"
                    aria-expanded="false"
                  ><i className="fa fa-info-circle" aria-hidden="true"></i> Addition / modification</span>
                  <ul className="nav-second-level collapse" id="collapseSubItems13" data-parent="#navAccordion">
                    <li className="nav-item">
                      <Link className="nav-link" to="/add-state">
                        <article className="nav-link-text">Add State</article>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/add-city">
                        <article className="nav-link-text">Add City</article>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/add-area">
                        <article className="nav-link-text">Add Area</article>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/add-user">
                        <article className="nav-link-text">Add user</article>
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                  <a className="nav-link" href="/add-order">
                    <span className="nav-link-text">Add Order</span>
                  </a>
                </li> */}

                    <li className="nav-item">
                      <Link className="nav-link" to="/add-package">
                        <article className="nav-link-text">Add Package</article>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/add-topup">
                        <article className="nav-link-text">Add Top Up</article>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/add-vehicle">
                        <article className="nav-link-text">Add Vehicle</article>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/add-shop">
                        <article className="nav-link-text">Add Shop types</article>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/add-incentive">
                        <article className="nav-link-text">Add Incentive</article>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/add-delivery-boy">
                        <article className="nav-link-text">Add Delivery Boy</article>
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                  <a className="nav-link" href="/modify-delivery-boy">
                    <span className="nav-link-text">Modify Delivery Boy</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/modify-store-details">
                    <span className="nav-link-text">Modify Store Details</span>
                  </a>
                </li> */}

                    <li className="nav-item">
                      <Link className="nav-link" to="/add-expenseType">
                        <article className="nav-link-text">Add Expense Types</article>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="/add-expense">
                        <article className="nav-link-text">Add Expense </article>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="/add-target">
                        <article className="nav-link-text">Add Target</article>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/user"><i className="fa fa-user" aria-hidden="true"></i> User</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/subadmins"><i className="fa fa-user" aria-hidden="true"></i> Subadmins</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/notification"><i className="fa fa-user" aria-hidden="true"></i> Notification</Link>
                </li>
                <li className="nav-item">
                  <span
                    className="nav-link nav-link-collapse"
                    id="hasSubItems"
                    data-toggle="collapse"
                    data-target="#collapseSubItems12"
                    aria-controls="collapseSubItems12"
                    aria-expanded="false"
                  ><i className="fa fa-male" aria-hidden="true"></i> Delivery boy</span>
                  <ul className="nav-second-level collapse" id="collapseSubItems12" data-parent="#navAccordion">

                    <li className="nav-item">
                      <Link className="nav-link" to="/attendence">
                        <article className="nav-link-text">Attendance</article>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/deliveryBoyApplications">
                        <article className="nav-link-text">Delivery Boy Applications</article>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/incentive-target">
                        <article className="nav-link-text">Incentive Vs Target</article>
                      </Link>
                    </li>

                    {/* <li className="nav-item">
                  <a className="nav-link" href="/deliveryboy/salary-status">
                    <span className="nav-link-text">Salary Status</span>
                  </a>
                </li> */}
                    <li className="nav-item">
                      <Link className="nav-link" to="/assign-target">
                        <article className="nav-link-text">Assign Target</article>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <span
                    className="nav-link nav-link-collapse"
                    id="hasSubItems"
                    data-toggle="collapse"
                    data-target="#collapseSubItems7"
                    aria-controls="collapseSubItems7"
                    aria-expanded="false"
                  ><i className="fa fa-money" aria-hidden="true"></i> Cash Flow</span>
                  <ul className="nav-second-level collapse" id="collapseSubItems7" data-parent="#navAccordion">
                    <li className="nav-item">
                      <Link className="nav-link" to="/packages">
                        <article className="nav-link-text">Packages</article>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/earnings">
                        <article className="nav-link-text">Earnings</article>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/expenses">
                        <article className="nav-link-text">Expenses</article>
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                  <a className="nav-link" href="/balance-sheet">
                    <span className="nav-link-text">Balance sheet</span>
                  </a>
                </li> */}
                  </ul>
                </li>
                <li className="nav-item">
                  <span
                    className="nav-link nav-link-collapse"
                    id="hasSubItems"
                    data-toggle="collapse"
                    data-target="#collapseSubItems5"
                    aria-controls="collapseSubItems5"
                    aria-expanded="false"
                  ><i className="fa fa-map" aria-hidden="true"></i> Map View</span>
                  <ul className="nav-second-level collapse" id="collapseSubItems5" data-parent="#navAccordion">
                    <li className="nav-item">
                      <Link  className="nav-link" to="/map/delivery-boy-location">
                        <article className="nav-link-text">Delivery Boy Location</article>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item" onClick={() => handleLogout()}>
                  <button className="btn btn-danger btn-block mt-4" >Logout
                  </button>
                </li>
              </>
              :
              <>
                <li className="nav-item active">
                  <a
                    className="nav-link nav-link-collapse"
                    id="hasSubItems"
                    data-toggle="collapse"
                    data-target="#collapseSubItems2"
                    aria-controls="collapseSubItems2"
                    aria-expanded="false"
                  ><i className="fa fa-info-circle" aria-hidden="true"></i> My Info</a>
                  <ul className="nav-second-level collapse" id="collapseSubItems2" data-parent="#navAccordion">
                    <li className="nav-item">
                      <a className="nav-link" href="/">
                        <span className="nav-link-text">Dashboard</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/info/Orders">
                        <span className="nav-link-text"> Orders</span>
                      </a>
                    </li>

                  </ul>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link nav-link-collapse"
                    href="#"
                    id="hasSubItems"
                    data-toggle="collapse"
                    data-target="#collapseSubItems4"
                    aria-controls="collapseSubItems4"
                    aria-expanded="false"
                  ><i className="fa fa-info-circle" aria-hidden="true"></i> Staff Management</a>
                  <ul className="nav-second-level collapse" id="collapseSubItems4" data-parent="#navAccordion">
                    <li className="nav-item">
                      <a className="nav-link" href="/delivery-boy-info">
                        <span className="nav-link-text">  Delivery Boy Info</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/monthly-productivity">
                        <span className="nav-link-text"> Monthly Productivity </span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/notification"><i className="fa fa-user" aria-hidden="true"></i> Notification</a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link nav-link-collapse"
                    href="#"
                    id="hasSubItems"
                    data-toggle="collapse"
                    data-target="#collapseSubItems13"
                    aria-controls="collapseSubItems13"
                    aria-expanded="false"
                  ><i className="fa fa-info-circle" aria-hidden="true"></i> Addition / modification</a>
                  <ul className="nav-second-level collapse" id="collapseSubItems13" data-parent="#navAccordion">
                    
                    {/* <li className="nav-item">
                  <a className="nav-link" href="/add-order">
                    <span className="nav-link-text">Add Order</span>
                  </a>
                </li> */}

                   
                    <li className="nav-item">
                      <a className="nav-link" href="/add-incentive">
                        <span className="nav-link-text">Add Incentive</span>
                      </a>
                    </li>
                    {/* <li className="nav-item">
                      <a className="nav-link" href="/add-delivery-boy">
                        <span className="nav-link-text">Add Delivery Boy</span>
                      </a>
                    </li> */}
                    <li className="nav-item">
                      <a className="nav-link" href="/add-expense">
                        <span className="nav-link-text">Add Expense </span>
                      </a>
                    </li>

                    <li className="nav-item">
                      <a className="nav-link" href="/add-target">
                        <span className="nav-link-text">Add Target</span>
                      </a>
                    </li>
                  </ul>
                </li>
                {/* <li className="nav-item">
                  <a className="nav-link" href="/user"><i className="fa fa-user" aria-hidden="true"></i> User</a>
                </li> */}
                <li className="nav-item">
                  <a
                    className="nav-link nav-link-collapse"
                    href="#"
                    id="hasSubItems"
                    data-toggle="collapse"
                    data-target="#collapseSubItems12"
                    aria-controls="collapseSubItems12"
                    aria-expanded="false"
                  ><i className="fa fa-male" aria-hidden="true"></i> Delivery boy</a>
                  <ul className="nav-second-level collapse" id="collapseSubItems12" data-parent="#navAccordion">

                    <li className="nav-item">
                      <a className="nav-link" href="/attendence">
                        <span className="nav-link-text">Attendance</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/deliveryBoyApplications">
                        <span className="nav-link-text">Delivery Boy Applications</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/incentive-target">
                        <span className="nav-link-text">Incentive Vs Target</span>
                      </a>
                    </li>

 
                    <li className="nav-item">
                      <a className="nav-link" href="/assign-target">
                        <span className="nav-link-text">Assign Target</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link nav-link-collapse"
                    href="#"
                    id="hasSubItems"
                    data-toggle="collapse"
                    data-target="#collapseSubItems7"
                    aria-controls="collapseSubItems7"
                    aria-expanded="false"
                  ><i className="fa fa-money" aria-hidden="true"></i> Cash Flow</a>
                  <ul className="nav-second-level collapse" id="collapseSubItems7" data-parent="#navAccordion">
                    <li className="nav-item">
                      <a className="nav-link" href="/packages">
                        <span className="nav-link-text">Packages</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/earnings">
                        <span className="nav-link-text">Earnings</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/expenses">
                        <span className="nav-link-text">Expenses</span>
                      </a>
                    </li>
                    {/* <li className="nav-item">
                  <a className="nav-link" href="/balance-sheet">
                    <span className="nav-link-text">Balance sheet</span>
                  </a>
                </li> */}
                  </ul>
                </li>
                <li className="nav-item" onClick={() => handleLogout()}>
                  <button className="btn btn-danger btn-block mt-4" >Logout
                  </button>
                </li></>
            }

          </ul>
        </div>
      </div>
    </aside>
  )
}


export default Sidebar;