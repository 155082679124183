import React from 'react';


function ModifyDeliveryBoy() {
    return (
        <main className="main_content">
            <div className="headingTitle"><h3> Modify Delivery Boy </h3></div>
            <div className="card">
                <div className="card-body">
                    <div className="AddModiForm w-100">
                        <form>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="field-group">
                                        <input type="text" className="form-control" placeholder="Enter Delivery Boy Name" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="field-group">
                                        <input type="text" className="form-control" placeholder="Enter Email Address" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="field-group">
                                        <input type="text" className="form-control" placeholder="Enter City Name" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="field-group">
                                        <input type="text" className="form-control" placeholder="Enter Area" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="field-group">
                                        <input type="text" className="form-control" placeholder="Enter Pincode" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="field-group">
                                        <input type="text" className="form-control" placeholder="Enter Mobile No." />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="field-group">
                                        <input type="text" className="form-control" placeholder="Enter Vehicle Type" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="field-group">
                                        <input type="text" className="form-control" placeholder="Enter Vehicle Registration Details (RC No.)" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="field-group">
                                        <input type="text" className="form-control" placeholder="Enter Address Veification documents (Aadhar Card No.)" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="field-group">
                                        <input type="date" className="form-control" placeholder="Enter Date of Joining" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="field-group">
                                        <input type="text" className="form-control" placeholder="Enter Salary Fixed ( complete details )" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="field-group">
                                        <input type="text" className="form-control" placeholder="Enter Driving Licence Details (Licence No.) " />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mb-4">
                                    <label>Status</label>
                                    <div className="field-group">
                                        <button href="/modify-delivery-boy" type="button" className="btn addModifiBttn mr-2"> Approve </button>
                                        <button href="#" type="button" className="btn addModifiBttn"> Pending Approve </button>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mb-4">
                                    <label>Modify/Replace Vehicle RC Photo</label>
                                    <div className="field-group inputFileUploadd">
                                        <input type="file" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mb-4">
                                    <label>Modify/Replace Aadhar Card Photo </label>
                                    <div className="field-group inputFileUploadd">
                                        <input type="file" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mb-4">
                                    <label>Modify/Replace Driving Licence Photo </label>
                                    <div className="field-group inputFileUploadd">
                                        <input type="file" />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4 d-flex justify-content-center">
                                <div className="col-12 col-md-3">
                                    <div className="field-group">
                                        <button type="button" className="btn searchBttn"> Save </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default ModifyDeliveryBoy;