import axios from 'axios';
import React, { useState, useEffect } from 'react';

import { url } from '../../Services/Url'

function InfodbOffline() {

    const [deliveryBoyArr, setDeliveryBoyArr] = useState([]);
    const [filteredDeliveryBoyArr, setFilteredDeliveryBoyArr] = useState([]);
    const getAllDeliveryBoy = async () => {
        try {
            const { data: res, status: statusCode } = await axios.get(`${url}/getOnlineDeliveryBoyDetails/false`);
            if (statusCode == 200 || statusCode == 304) {
                setDeliveryBoyArr(res.data)
                setFilteredDeliveryBoyArr(res.data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const handleFilterByName = (val) => {
        setFilteredDeliveryBoyArr([...deliveryBoyArr.filter(el => el.name.toLowerCase().includes(val.toLowerCase()))])
    }

    useEffect(() => {
        getAllDeliveryBoy()
    }, [])


    return (
        <main className="main_content">
            <div className="userTabsDesign mb-4">
                <div className="headingTitle">
                    <h3> Delivery Boy Online </h3>
                </div>
                <div className="card">
                <div className="col-lg-4 col-md-4">
                        <label>Search by name</label>
                        <input className="form-control" onChange={(val)=>handleFilterByName(val.target.value)} placeholder="Search by delivery boy name" />
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-responsive-sm">
                                <thead>
                                    <tr>
                                        <th> Name </th>
                                        <th> Today </th>
                                        <th> This Week </th>
                                        <th> Current Month  </th>
                                        <th> Year </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredDeliveryBoyArr && filteredDeliveryBoyArr.map(el => {
                                        return (
                                            <tr key={el._id}>
                                                <th>{el.name}</th>
                                                <td>{el?.timeDiff?.today}</td>
                                                <td>{el?.timeDiff?.week}</td>
                                                <td>{el?.timeDiff?.month}</td>
                                                <td>{el?.timeDiff?.year}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default InfodbOffline;

