import React, { useState, useEffect } from "react";
import { getAllPackageOrdersEarnings, getAllTopUpEarnings } from "../../Services/PackageOrders";
import DataTable from "react-data-table-component";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { filterArrayByDateRange } from "../../utils/util";
import { Pie, Bar } from "react-chartjs-2";

function Earning() {
  const sortIcon = <ArrowDownward />;

  //////dates
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  ///////array
  const [packagesArr, setPackagesArr] = useState([]);
  const [filteredPackagesArr, setFilteredPackagesArr] = useState([]);
  const [topupArr, setTopupArr] = useState([]);
  const [filteredTopupArr, setFilteredTopupArr] = useState([]);

  ////////search string
  const [searchPackages, setSearchPackages] = useState("");
  const [searchTopUps, setSearchTopUps] = useState();

  const [barGraphData, setBarGraphData] = useState({});

  const [topupGraphData, setTopupGraphData] = useState({});

  const filterPackageByName = () => {};

  const getSoldPackages = async () => {
    try {
      const { data: res, status: statusCode } = await getAllPackageOrdersEarnings();
      if (statusCode == 200 || statusCode == 304) {
        setPackagesArr(res.data);
        setFilteredPackagesArr(res.data);
        handleBarGraph(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getSoldTopups = async () => {
    try {
      const { data: res, status: statusCode } = await getAllTopUpEarnings();
      if (statusCode == 200 || statusCode == 304) {
        setTopupArr(res.data);
        setFilteredTopupArr(res.data);
        handleBarGraphTop(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    {
      name: "Bought At",
      selector: (row) => ` ${new Date(row.createdAt).toDateString()}`,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.packagename,
      sortable: true,
    },
    {
      name: "Value",
      selector: (row) => `₹ ${row.value}`,
      sortable: true,
    },
    {
      name: "Sold (count)",
      selector: (row) => row.soldCount,
      sortable: true,
    },
    {
      name: "Total Earned",
      selector: (row) => `₹ ${row.value * row.soldCount}`,
      sortable: true,
    },
  ];
  const TopUpcolumns = [
    {
      name: "Bought At",
      selector: (row) => ` ${new Date(row.createdAt).toDateString()}`,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Value",
      selector: (row) => `₹ ${row.value}`,
      sortable: true,
    },
    {
      name: "Sold (count)",
      selector: (row) => row.soldTopups,
      sortable: true,
    },
    {
      name: "Total Earned",
      selector: (row) => `₹ ${row.value * row.soldTopups}`,
      sortable: true,
    },
  ];

  const filterBydateRange = () => {
    let tempPackagesOrders = packagesArr.filter((el) => filterArrayByDateRange(startDate, endDate, el.createdAt));
    if (tempPackagesOrders) {
      setFilteredPackagesArr(tempPackagesOrders);
    }

    let tempTopUpArr = topupArr.filter((el) => filterArrayByDateRange(startDate, endDate, el.createdAt));
    if (tempTopUpArr) {
      setFilteredTopupArr(tempTopUpArr);
    }
  };

  const searchPackagesByName = (val) => {
    // console.log(val)
    let filteredArr = packagesArr.filter((el) => el?.name?.includes(val.toLowerCase()));
    setFilteredPackagesArr([...filteredArr]);
  };
  const searchTopUpByName = (val) => {
    let filteredArr = topupArr.filter((el) => el?.name?.includes(val.toLowerCase()));
    setFilteredTopupArr(filteredArr);
  };

  const reset = () => {
    setFilteredPackagesArr(packagesArr);
    setFilteredTopupArr(topupArr);
  };

  const handleBarGraph = (arr) => {
    console.log(arr);
    const labels = arr?.map((el) => new Date(el.createdAt).toDateString());
    const barGraphData = {
      labels: labels,
      datasets: [
        {
          label: "Packages Sold",
          data: arr?.map((el) => el.value),
          backgroundColor: ["rgba(255, 159, 64, 0.2)"],
          borderColor: ["rgb(255, 159, 64)"],
          borderWidth: 1,
        },
      ],
    };
    setBarGraphData({ ...barGraphData });
  };
  const handleBarGraphTop = (arr) => {
    const labels = arr?.map((el) => new Date(el.createdAt).toDateString());
    const barGraphData = {
      labels: labels,
      datasets: [
        {
          label: "Topups Sold",
          data: arr?.map((el) => el.value),
          backgroundColor: ["rgba(255, 259, 64, 0.2)"],
          borderColor: ["rgb(255, 159, 64)"],
          borderWidth: 1,
        },
      ],
    };
    setTopupGraphData({ ...barGraphData });
  };
  const barGraphConfig = {
    type: "bar",
    data: barGraphData,
    options: {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    },
  };

  useEffect(() => {
    getSoldPackages();
    getSoldTopups();
  }, []);

  return (
    <main className="main_content">
      <div className="filterSearchBar mb-4">
        <form>
          <div className="row mb-4">
            <div className="col-12 col-md-3">
              <label>Start Date</label>
              <input onChange={(e) => setStartDate(e.target.value)} type="date" className="form-control" />
            </div>
            <div className="col-12 col-md-3">
              <label>End Date</label>
              <input onChange={(e) => setEndDate(e.target.value)} type="date" className="form-control" />
            </div>
            <div className="col-12 col-md-2">
              <label>&nbsp;</label>
              <button type="button" className="btn searchBttn" onClick={() => filterBydateRange()}>
                {" "}
                Search{" "}
              </button>
            </div>
            <div className="col-12 col-md-2">
              <label>&nbsp;</label>
              <button type="button" className="btn searchBttn" onClick={() => reset()}>
                {" "}
                Reset{" "}
              </button>
            </div>
          </div>
          <div className="row mt-3 mb-4">
            <div className="col-12 col-md-3">
              <label>Search Package</label>
              <input onChange={(e) => searchPackagesByName(e.target.value)} type="text" className="form-control" />
            </div>
          </div>
        </form>
      </div>
      <div className="infoOrdertable mb-4">
        <div className="card">
          <div className="row">
            <div className="col-lg-6">{/* <Bar data={barGraphData} options={barGraphConfig} /> */}</div>
            <div className="col-lg-6">{/* <Bar data={topupGraphData} options={barGraphConfig} /> */}</div>
          </div>
        </div>
      </div>

      <div className="infoOrdertable mb-4">
        <div className="card">
          <DataTable title="Packages Sold" columns={columns} data={filteredPackagesArr} sortIcon={sortIcon} keyField="_id" pagination />
        </div>
      </div>

      <div className="row mt-3 mb-4">
        <div className="col-12 col-md-3">
          <label>Search TopUp</label>
          <input onChange={(e) => searchTopUpByName(e.target.value)} type="text" className="form-control" />
        </div>
        <div className="col-12 col-md-2">
          <label>&nbsp;</label>
          <button type="button" className="btn searchBttn" onClick={() => searchTopUpByName()}>
            {" "}
            Search{" "}
          </button>
        </div>
        <div className="col-12 col-md-2">
          <label>&nbsp;</label>
          <button type="button" className="btn searchBttn" onClick={() => reset()}>
            {" "}
            Reset{" "}
          </button>
        </div>
      </div>
      <div className="infoOrdertable mb-4">
        <div className="card">
          <DataTable title="Topups Sold" columns={TopUpcolumns} data={filteredTopupArr} sortIcon={sortIcon} keyField="_id" pagination />
        </div>
        <br />
      </div>
    </main>
  );
}

export default Earning;
