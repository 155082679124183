import React, { useEffect, useState } from "react";
import axios from "axios";
import { url } from "../../Services/Url";
import { ToastContainer, toast } from "react-toastify";
import DataTable from "react-data-table-component";
import withReactContent from "sweetalert2-react-content";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import "react-responsive-modal/styles.css";
import { createExpense, DeleteExpenseById, getAllExpenses, UpdateExpenseById } from "../../Services/Expense";
import { getAll } from "../../Services/ExpenseTypes";
import { Pie, Bar } from "react-chartjs-2";

const sortIcon = <ArrowDownward />;

function InfoOrders() {
  const [incurredBy, setIncurredBy] = useState();
  const [approvedBy, setApprovedBy] = useState();
  const [expenseValue, setExpenseValue] = useState();
  const [date, setDate] = useState();
  const [selectedExpenseType, setSelectedExpenseType] = useState();
  const [selectedExpense, setSelectedExpense] = useState();
  const [expenseTypesArr, setExpenseTypesArr] = useState();
  const [expensesArr, setExpensesArr] = useState([]);
  const [filteredExpenseArr, setFilteredExpenseArr] = useState([]);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [barGraphData, setBarGraphData] = useState({});

  const handleSubmit = async () => {
    try {
      let obj = {
        incurredBy,
        amount: expenseValue,
        approvedBy,
        date,
        expenseType: selectedExpenseType,
      };
      let res = await createExpense(obj);
      if (res.status == 200 || res.status == 304) {
        alert(res.data.message);
        getExpense();
      } else {
        alert(res.data.message);
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        console.log(err?.response?.data?.message);
        alert(err?.response?.data?.message);
      } else {
        console.log(err);
        alert(err);
      }
    }
  };

  const handleBarGraph = (arr) => {
    console.log(arr);
    const labels = arr?.map((el) => new Date(el.createdAt).toDateString());
    const barGraphData = {
      labels: labels,
      datasets: [
        {
          label: "Expenses",
          data: arr?.map((el) => el.amount),
          backgroundColor: ["rgba(255, 159, 64, 0.2)"],
          borderColor: ["rgb(255, 159, 64)"],
          borderWidth: 1,
        },
      ],
    };
    setBarGraphData({ ...barGraphData });
  };
  const barGraphConfig = {
    type: "bar",
    data: barGraphData,
    options: {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    },
  };

  const getExpense = async () => {
    try {
      let res = await getAllExpenses();
      if (res.status == 200 || res.status == 304) {
        setExpensesArr(res.data.data);
        setFilteredExpenseArr(res.data.data);
        handleBarGraph(res.data.data);
      } else {
        alert(res.data.message);
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        console.log(err?.response?.data?.message);
        alert(err?.response?.data?.message);
      } else {
        console.log(err);
        alert(err);
      }
    }
  };

  const deleteExpenseFromAdmin = async (id) => {
    try {
      let res = await DeleteExpenseById(id);
      if (res.status == 200 || res.status == 304) {
        getExpense();
      }
      alert(res.data.message);
    } catch (err) {
      if (err?.response?.data?.message) {
        console.log(err?.response?.data?.message);
        alert(err?.response?.data?.message);
      } else {
        console.log(err);
        alert(err);
      }
    }
  };

  const getExpenseTypes = async () => {
    try {
      let res = await getAll();
      if (res.status == 200 || res.status == 304) {
        setExpenseTypesArr(res.data.data);
      } else {
        alert(res.data.message);
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        console.log(err?.response?.data?.message);
        alert(err?.response?.data?.message);
      } else {
        console.log(err);
        alert(err);
      }
    }
  };

  const columns = [
    {
      name: "S.no",
      selector: (row) => row.sno,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => <div>{new Date(row.date).toDateString()}</div>,
      sortable: true,
    },
    {
      name: "Type of Expense",
      selector: (row) => row.expenseTypeName,
      sortable: true,
    },
    {
      name: "Incurred By",
      selector: (row) => row.incurredBy,
      sortable: true,
    },
    {
      name: "Approved By",
      selector: (row) => row.approvedBy,
      sortable: true,
    },
    {
      name: "Value",
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div style={{ display: "flex", flexDirection: "row" }}>
          {" "}
          {
            <>
              <button onClick={() => updateExpenseModalOpen(row)} className="btn addModifiBttn mr-2" data-toggle="modal" data-target="#modifyExpenses">
                Update
              </button>
              <button onClick={() => deleteExpenseFromAdmin(row._id)} className="btn bg-danger mr-2">
                Delete
              </button>
            </>
          }
        </div>
      ),
    },
  ];

  const serachByDate = () => {
    let filteredArr = expensesArr.filter((el) => new Date(el.date).getTime() <= new Date(endDate).getTime() && new Date(el.date).getTime() >= new Date(startDate).getTime());
    console.log(filteredArr);
    setFilteredExpenseArr(filteredArr);
  };

  const filter = () => {
    let tempfilteredExpenseArr = expensesArr.filter((el) => el.expenseType == selectedExpenseType);
    setFilteredExpenseArr(tempfilteredExpenseArr);
  };

  const updateExpenseModalOpen = (row) => {
    console.log(row);
    setSelectedExpense(row._id);
    setDate(new Date(row.date).toISOString().substr(0, 10));
    setIncurredBy(row.incurredBy);
    setExpenseValue(row.amount);
    setApprovedBy(row.approvedBy);
    setSelectedExpenseType(row.expenseType);
  };

  const updateExpense = async () => {
    try {
      if (incurredBy != "" && expenseValue != "" && approvedBy != "" && date != "" && selectedExpenseType != "") {
        let obj = {
          incurredBy,
          amount: expenseValue,
          approvedBy,
          date,
          expenseType: selectedExpenseType,
        };
        let res = await UpdateExpenseById(selectedExpense, obj);
        if (res.status == 200 || res.status == 304) {
          getExpense();
        } else {
          alert(res.data.message);
        }
      } else {
        alert("Please Fill all the fields");
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        console.log(err?.response?.data?.message);
        alert(err?.response?.data?.message);
      } else {
        console.log(err);
        alert(err);
      }
    }
  };

  const filterByExpenseType = (val) => {
    if (val != "") {
      setSelectedExpenseType(val);
      let tempArr = expensesArr.filter((el) => el.expenseType == val);
      setFilteredExpenseArr([...tempArr]);
    } else {
      setSelectedExpenseType(val);

      setFilteredExpenseArr([...expensesArr]);
    }
  };

  useEffect(() => {
    getExpense();
    getExpenseTypes();
  }, []);

  return (
    <main className="main_content">
      <div className="filterSearchBar mb-4">
        <form>
          <div className="row mb-4">
            <div className="col-12 col-md-3">
              <label>Start Date</label>
              <input onChange={(event) => setStartDate(event.target.value)} type="date" className="form-control" />
            </div>
            <div className="col-12 col-md-3">
              <label>End Date</label>
              <input onChange={(event) => setEndDate(event.target.value)} type="date" className="form-control" />
            </div>
            <div className="col-12 col-md-2">
              <label>&nbsp;</label>
              <button type="button" className="btn searchBttn" onClick={() => serachByDate()}>
                {" "}
                Search{" "}
              </button>
            </div>
            <div className="col-12 col-md-2">
              <label>&nbsp;</label>
              <button type="button" className="btn searchBttn" onClick={() => setFilteredExpenseArr(expensesArr)}>
                {" "}
                Reset{" "}
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-3">
              <label>Type Of Expense</label>
              <select
                name=""
                id=""
                className="form-control"
                onChange={(event) => {
                  filterByExpenseType(event.target.value);
                }}
              >
                <option value="">Please Select a Expense Type</option>
                {expenseTypesArr &&
                  expenseTypesArr.length > 0 &&
                  expenseTypesArr.map((el) => {
                    return (
                      <option key={el._id} value={el._id}>
                        {el.name}
                      </option>
                    );
                  })}
              </select>
            </div>

            {/* <div className="col-12 col-md-3">
                            <label>Approved By</label>
                            <select className="form-control">
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                            </select>
                        </div> */}
            {/* <div className="col-12 col-md-2">
                            <label>&nbsp;</label>
                            <button type="button" className="btn searchBttn" onClick={() => filter()}> Filter </button>
                        </div> */}
          </div>
        </form>
      </div>
      <div className="infoOrdertable mb-4">
        <div className="headingTitle addModifyHeading">
          <h3> Expenses </h3>
          <div className="addModifyInner">
            <button type="button" className="btn addModifiBttn">
              <a href="/add-expense" style={{ color: "white" }}>
                {" "}
                Add
              </a>{" "}
            </button>
            {/* <button type="button" className="btn addModifiBttn"> Modify </button> */}
          </div>
        </div>
        <div className="card">
          <div className="row">
            <div className="col-lg-6">{/* <Bar data={barGraphData} options={barGraphConfig} /> */}</div>
          </div>
          <DataTable
            title=""
            columns={columns}
            data={filteredExpenseArr?.map((el, index) => {
              let obj = {
                sno: index + 1,
                ...el,
              };
              return obj;
            })}
            sortIcon={sortIcon}
            keyField="_id"
            pagination
          />
        </div>
      </div>

      <div id="addExpenses" className="modal fade" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
              <h4 className="modal-title text-center">Add Expenses</h4>
            </div>
            <div className="modal-body">
              <div className="AddModiForm">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="field-group">
                    <label htmlFor="">Expense Date</label>

                    <input type="date" onChange={(event) => setDate(event.target.value)} className="form-control" placeholder="Enter date" />
                  </div>
                  <div className="field-group">
                    <label htmlFor="">Expense Type</label>
                    <div className="field-group">
                      <select name="" id="" className="form-control" onChange={(event) => setSelectedExpenseType(event.target.value)}>
                        <option value="">Please Select a Expense Type</option>
                        {expenseTypesArr &&
                          expenseTypesArr.length > 0 &&
                          expenseTypesArr.map((el) => {
                            return (
                              <option key={el._id} value={el._id}>
                                {el.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="field-group">
                    <label htmlFor="">Expense Incurred By</label>

                    <input type="text" onChange={(event) => setIncurredBy(event.target.value)} className="form-control" placeholder="Enter Incurred By" />
                  </div>
                  <div className="field-group">
                    <label htmlFor="">Expense Approved By</label>

                    <div className="field-group">
                      <input type="text" className="form-control" onChange={(event) => setApprovedBy(event.target.value)} placeholder="Expense Approved By" />
                    </div>
                  </div>
                  <div className="field-group">
                    <label htmlFor="">Expense Value</label>
                    <div className="field-group">
                      <input type="text" className="form-control" onChange={(event) => setExpenseValue(event.target.value)} placeholder="Expense Value" />
                    </div>
                  </div>
                  <div className="field-group">
                    <button type="text" className="btn searchBttn" data-toggle="modal" data-target="#addExpenses" onClick={() => handleSubmit()}>
                      {" "}
                      Submit{" "}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="modifyExpenses" className="modal fade" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
              <h4 className="modal-title text-center">Modify Expenses</h4>
            </div>
            <div className="modal-body">
              <div className="AddModiForm">
                <form>
                  <div className="field-group">
                    <input type="date" value={date} onChange={(event) => setDate(event.target.value)} className="form-control" placeholder="Enter date" />
                  </div>
                  <div className="field-group">
                    <label htmlFor="">Expense Type</label>
                    <div className="field-group">
                      <select name="" id="" className="form-control" value={selectedExpenseType} onChange={(event) => setSelectedExpenseType(event.target.value)}>
                        <option value="">Please Select a Expense Type</option>
                        {expenseTypesArr &&
                          expenseTypesArr.length > 0 &&
                          expenseTypesArr.map((el) => {
                            return (
                              <option key={el._id} value={el._id}>
                                {el.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="field-group">
                    <label htmlFor="">Expense Incurred By</label>

                    <input type="text" value={incurredBy} onChange={(event) => setIncurredBy(event.target.value)} className="form-control" placeholder="Enter Incurred By" />
                  </div>
                  <div className="field-group">
                    <label htmlFor="">Expense Approved By</label>

                    <div className="field-group">
                      <input type="text" value={approvedBy} className="form-control" onChange={(event) => setApprovedBy(event.target.value)} placeholder="Expense Approved By" />
                    </div>
                  </div>
                  <div className="field-group">
                    <label htmlFor="">Expense Value</label>
                    <div className="field-group">
                      <input type="number" value={expenseValue} className="form-control" onChange={(event) => setExpenseValue(event.target.value)} placeholder="Expense Value" />
                    </div>
                  </div>
                  <div className="field-group">
                    <button type="text" className="btn searchBttn" onClick={() => updateExpense()}>
                      {" "}
                      Submit{" "}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
export default InfoOrders;
