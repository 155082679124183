import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { url } from '../../Services/Url';

import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
function UserDetails() {
    const sortIcon = <ArrowDownward />;


    const paramsObj = useParams();


    const [currentPackageObj, setCurrentPackageObj] = useState({});
    const [packageArr, setPackageArr] = useState([]);
    const [savedAddresses, setsavedAddresses] = useState([]);
    const getUserCurrentPackage = async () => {
        try {

            const { data: res } = await axios.get(`${url}/packageOrder/getValidPackageByUserId/${paramsObj.id}`);
            if (res) {
                console.log(res.data)
                setCurrentPackageObj(res.data)
            }
        } catch (error) {
            console.error(error)
        }
    }
    const userPackages = async () => {
        try {
            const { data: res } = await axios.get(`${url}/packageOrder/getOrderedPackagesByUserId/${paramsObj.id}`);
            console.log(res)
            if (res) {
                console.log(res.data)
                setPackageArr(res.data)
            }

        } catch (error) {
            console.error(error)
        }
    }
    const userSavedAddresses = async () => {
        try {
            const { data: res } = await axios.get(`${url}/savedAddresses/getByUserId/${paramsObj.id}`);
            console.log(res)

            if (res) {
                console.log(res.data)
                setsavedAddresses(res.data)
            }

        } catch (error) {
            console.error(error)
        }
    }

    const columns = [
        {
            sortable: true,
            name: 'S.No',

            selector: (row, index) => `${index + 1}`,
            width: '200px',
        },
        {
            sortable: true,
            name: 'Purchased At',

            selector: row => `${new Date(row?.createdAt).toDateString()}`,
            cell: row => `${new Date(row?.createdAt).toDateString()}`,
            width: '200px',
        },
        {
            name: 'Package Name',
            selector: row => row?.packagename,
            sortable: true,
        },
        {
            name: 'Distance',
            sortable: true,
            selector: row => row?.maxDistancePerOrder,
            // cell: row => <>{row.maxDistancePerOrder ? row.maxDistancePerOrder : "NA"}</>
        },
        {
            sortable: true,
            name: 'weight',
            selector: row => row?.maxWeightPerOrder,
            cell: row => row?.maxWeightPerOrder,
            width: '200px'
        },
        {
            sortable: true,
            name: 'Per Day Cap',
            selector: row => row?.perDayCap,
            cell: row => <>{row?.perDayCap ? row.perDayCap : "NA"}</>,
            width: '200px'

        },
        {
            sortable: true,
            name: 'Total Deliveries',
            selector: row => row?.totalDeliveries,
            // cell: row => <>{row?.totalDeliveries ? row.totalDeliveries : "NA"}</>,
            width: '200px'

        },
        {
            sortable: true,
            name: 'Amount',
            selector: row => row?.value,
            cell: row => `₹${row?.value}`
        },


    ]
    const columnsSavedAddress = [
        {
            sortable: true,
            name: 'S.No',

            selector: (row, index) => `${index + 1}`,
            width: '200px',
        },
        {
            name: 'Name',
            selector: row => row?.name,
            sortable: true,
        },
        {
            name: 'Phone',
            sortable: true,
            selector: row => row?.mobile,
            // cell: row => <>{row.maxDistancePerOrder ? row.maxDistancePerOrder : "NA"}</>
        },
        {
            sortable: true,
            name: 'Address',
            selector: item => `${item?.addressObj?.addressLine1}, ${item?.addressObj?.addressLine2}, ${item?.addressObj?.pincode}`,
            cell: item => `${item?.addressObj?.addressLine1}, ${item?.addressObj?.addressLine2}, ${item?.addressObj?.pincode}`,
            width: '200px'
        },



    ]

    function convertArrayOfObjectsToCSV(array) {
        let result;

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(array[0]);
        console.log(keys)
        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;

                result += item[key];

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }

    // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
    function downloadCSV(array) {
        if (array.length > 0) {

            const link = document.createElement('a');
            let csv = convertArrayOfObjectsToCSV(array);
            if (csv == null) return;

            const filename = 'export.csv';

            if (!csv.match(/^data:text\/csv/i)) {
                csv = `data:text/csv;charset=utf-8,${csv}`;
            }

            link.setAttribute('href', encodeURI(csv));
            link.setAttribute('download', filename);
            link.click();
        }
        else {
            alert("Collection Empty")
        }
    }

    useEffect(() => {
        console.log(paramsObj)
        getUserCurrentPackage()
        userPackages()
        userSavedAddresses()
    }, [])

    return (
        <main className="main_content">
            <div className="userTabsDesign mb-4">
                <div className="headingTitle">
                    <h3> User Details </h3>
                </div>
                <div className="row">


                    <div className="col-xl-3 col-lg-3 col-sm-6 col-12">
                        <div className="card" style={{backgroundColor:"rgba(227, 58, 2,0.12"}}>
                            <div className="card-header">
                                <h5 className="card-title">Package Name</h5>
                            </div>
                            <div className="card-body">
                                <div className="boysCount">
                                    <p><i className="fa fa-first-order"></i> {currentPackageObj?.packageName} </p>
                                </div>
                                <div className="boysCase">
                                    <p>Valid Till : {currentPackageObj?.expiryDateStr}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-sm-6 col-12">
                        <div className="card" style={{backgroundColor:"rgba(227, 5, 182,1"}}>
                            <div className="card-header">
                                <h5  className="card-title"  style={{color:"white", fontSize:20, fontWeight:"bold"}}>Total Deliveries left</h5>
                            </div>
                            <div className="card-body">
                                <div className="boysCount"  style={{color:"white", fontSize:18, fontWeight:"bold"}}>
                                    <p><i className="fa fa-first-order"></i> {currentPackageObj?.totalDeliveriesLeft} </p>
                                </div>
                                {/* <div className="boysCase">
                                    <p> Sold Today </p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-sm-6 col-12">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">Package Cost</h5>
                            </div>
                            <div className="card-body">
                                <div className="boysCount">
                                    <p><i className="fa fa-first-order"></i>₹ {currentPackageObj?.packageCost}</p>
                                </div>
                                {/* <div className="boysCase">
                                    <p> Sold Today </p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-sm-6 col-12">
                        <div className="card" style={{backgroundColor:"rgba(11, 230, 99,0.12"}}>
                            <div className="card-header">
                                <h5 className="card-title">Max Distance</h5>
                            </div>
                            <div className="card-body">
                                <div className="boysCount">
                                    <p><i className="fa fa-first-order"></i>{currentPackageObj?.maxDistancePerOrder}</p>
                                </div>
                                {/* <div className="boysCase">
                                    <p> Sold Today </p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-sm-6 col-12">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">Max Weight</h5>
                            </div>
                            <div className="card-body">
                                <div className="boysCount">
                                    <p><i className="fa fa-first-order"></i>{currentPackageObj?.maxWeightPerOrder}</p>
                                </div>
                                {/* <div className="boysCase">
                                    <p> Sold Today </p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-sm-6 col-12">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">Per Day Cap</h5>
                            </div>
                            <div className="card-body">
                                <div className="boysCount">
                                    <p><i className="fa fa-first-order"></i>{currentPackageObj?.perDayCap}</p>
                                </div>
                                {/* <div className="boysCase">
                                    <p> Sold Today </p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <DataTable
                        title="Packages Purchased"
                        columns={columns}
                        data={packageArr}
                        sortIcon={sortIcon}
                        keyField='_id'
                        pagination
                    />
                </div>
                <div className="card">
                    <div className="col-1">

                    <button onClick={() => downloadCSV(savedAddresses)} className="btn addModifiBttn">Export </button>
                    </div>

                    <DataTable
                        title="Saved Addresses"
                        columns={columnsSavedAddress}
                        data={savedAddresses}
                        sortIcon={sortIcon}
                        keyField='_id'
                        pagination
                    />
                </div>
            </div>
        </main>
    )
}

export default UserDetails;