import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { url } from '../../Services/Url'
import { ToastContainer, toast } from 'react-toastify';

import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
function AddTopUP() {
    const sortIcon = <ArrowDownward />;
    const [topupFor, setTopupFor] = useState(0);
    const [name, setName] = useState("");
    // const [topUpType, setTopUpType] = useState();
    // const [address, setAddress] = useState();
    // const [mobile, setMobile] = useState();
    // const [pinCode, setPinCode] = useState();


    const [totalDeliveries, setTotalDeliveries] = useState("");
    const [maxDistance, setMaxDistance] = useState();
    const [value, setValue] = useState("");
    // const [existingPackage, setexistingPackage] = useState();


    const TopUpColumns = [
        {
            name: 'S.No',
            selector: (row, index) => <span> {index + 1} </span>,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => <span>{row.name}</span>,
            sortable: true,
        },

        {
            name: 'Top-Up For',
            selector: rowData => rowData?.topupFor==0 ? "Business":"Household",
            cell: rowData => rowData?.topupFor==0 ? "Business":"Household",
            sortable: true,
        },

        {
            name: 'Total Deliveries',
            cell: rowData => <span>{rowData?.totalDeliveries}</span>,
            selector: rowData => rowData?.totalDeliveries,
            sortable: true,
        },
        {
            name: 'Value',
            selector: rowData => <span>{rowData?.value}</span>,
            sortable: true,
        },
        {
            name: 'Actions',
            cell: rowData => <button className="btn btn-primary">Modify</button>,
            sortable: true,
        },

    ];



    const [toggle, setToggle] = useState(false);
    const [topUpArr, setTopUpArr] = useState();
    const handleSubmit = async () => {
        try {
            if(name!="" && totalDeliveries!="" && value!=""){

                let obj = {
                    name: name,
                    totalDeliveries,
                    maxDistance,
                    value: value,
                    topupFor
                }
                let { data: res } = await axios.post(`${url}/topUp/`, obj)
                if (res.success) {
                    toast.success(res.message)
                    getTopUps()
                    // alert(res.message)
                }
            }
            else{
                alert("Please Fill All the fields")
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }

    }

    const getTopUps = async () => {
        try {
            let { data: res } = await axios.get(`${url}/topUp/`)
            if (res.success) {
                setTopUpArr(res.data);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }

    useEffect(() => {
        getTopUps()
    }, [])

    return (
        <main className="main_content">
            <div className="userTabsDesign mb-4">
                {
                    toggle ?
                        <>
                            <div className="headingTitle" style={{ marginBottom: 20 }}><button onClick={() => setToggle(false)} className="btn addModifiBttn">Add Top-Ups</button></div>
                            <div className="card mt-3">
                                <DataTable
                                    title="Top Up"
                                    columns={TopUpColumns}
                                    data={topUpArr}
                                    sortIcon={sortIcon}
                                    keyField='_id'
                                    pagination
                                />
                                {/* {
                                    topUpArr && topUpArr.length > 0 &&
                                    <MaterialTable
                                        columns={[
                                            {
                                                title: 'Top-Up Name', field: "name",
                                                cellStyle: {
                                                    minWidth: 150,
                                                    textAlign: "left"
                                                },
                                            },
                                            {
                                                cellStyle: {
                                                    minWidth: 150,
                                                    textAlign: "left"
                                                }, title: 'Total Deliveries', field: "totalDeliveries"
                                            },
                                            {
                                                cellStyle: {
                                                    minWidth: 150,
                                                    textAlign: "left"
                                                }, title: 'Max Distance', field: "maxDistance"
                                            },
                                            {
                                                cellStyle: {
                                                    minWidth: 150,
                                                    textAlign: "left"
                                                }, title: 'Top-Up Value', field: "value"
                                            },

                                        ]}
                                        data={topUpArr}
                                        title=""
                                        options={{
                                            headerStyle: {
                                                backgroundColor: 'rgba(0,0,0,0.7)',
                                                color: '#FFF',
                                                fontSize: 12,
                                                fontWeight: "bold",
                                            },
                                            actionsColumnIndex: -1
                                        }}

                                    />

                                } */}
                            </div>
                        </>
                        :
                        <>

                            <div className="headingTitle" style={{ marginBottom: 20 }}><h1> Add Top-Up </h1> <button onClick={() => setToggle(true)} className="btn addModifiBttn">View Top UP</button></div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="AddModiForm w-100">
                                        <form>
                                            <div className="row">
                                                <div className="col-12 col-md-6">
                                                    <div className="field-group">
                                                        <label>Top Up Name</label>
                                                        <input type="text" onChange={(e) => setName(e.target.value)} className="form-control" placeholder="Enter Name" />
                                                    </div>
                                                </div>
                                                {/* <div className="col-12 col-md-6">
                                                    <div className="field-group">
                                                        <label>Top Up Name</label>

                                                        <input type="text" onChange={(e) => setMaxDistance(e.target.value)} className="form-control" placeholder="Enter Max Distance" />
                                                    </div>
                                                </div> */}
                                                <div className="col-12 col-md-6">
                                                    <div className="field-group">
                                                        <label>Total Deliveries</label>
                                                        <input type="number" onChange={(e) => setTotalDeliveries(e.target.value)} className="form-control" placeholder="Enter Total Deliveries" />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12">
                                                    <div className="field-group">
                                                        <label>Top Up Amount (in rupees)</label>
                                                        <input type="number" onChange={(e) => setValue(e.target.value)} className="form-control" placeholder="Enter Top UP Value" />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <label>Package For</label>
                                                    <div style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', display: 'flex' }}>
                                                        <div>
                                                            <input id="business" type="radio" value={0} checked={topupFor==0} onChange={(e) => setTopupFor(0)} />
                                                            <label for="business">Business</label>
                                                        </div>
                                                        <div>
                                                            <input id="household" type="radio" value={0} checked={topupFor==1} onChange={(e) => setTopupFor(1)} />
                                                            <label for="household">Household</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-4 d-flex justify-content-center">
                                                    <div className="col-12 col-md-3">
                                                        <div className="field-group">
                                                            <button type="button" onClick={() => handleSubmit()} className="btn searchBttn"> Save </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </>
                }
            </div>
        </main>
    )
}

export default AddTopUP;
