import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { url } from '../../Services/Url'
import { ToastContainer, toast } from 'react-toastify';
import MaterialTable from 'material-table'

function InfoOrderDeliveries() {
    const [ordersArr, setOrdersArr] = useState();

    const [stateArr, setStateArr] = useState();
    const [cityArr, setCityArr] = useState();
    const [areaArr, setAreaArr] = useState();
    const [userArr, setUserArr] = useState();
    const [cityFilteredArr, setCityFilteredArr] = useState();
    const [areaFilteredArr, setAreaFilteredArr] = useState();
    const [filteredOrderArr, setFilteredOrderArr] = useState();
    const [selectedStateId, setSelectedStateId] = useState("");
    const [selectedCityId, setSelectedCityId] = useState("");
    const [selectedAreaId, setSelectedAreaId] = useState();



    const getOrders = async () => {
        try {
            let { data: res } = await axios.get(`${url}/order/`)
            if (res.success) {
                console.log(JSON.stringify(res.data.length, null, 2), "orders")
                let tempActiveOrdersArr = res.data.filter(el => el.numberOfOrdersArr.some(ele => ele.orderStatus == 2))
                console.log(tempActiveOrdersArr, "asda");

                setOrdersArr(tempActiveOrdersArr);
                setFilteredOrderArr(tempActiveOrdersArr);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }












    const getCity = async () => {
        try {
            let { data: res } = await axios.get(`${url}/city/`)
            if (res.success) {
                let tempCityArr = res.data.filter(el => el.active == true && el.stateActive == true);
                setCityArr(tempCityArr);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }


    const getState = async () => {
        try {
            let { data: res } = await axios.get(`${url}/state/`)
            if (res.success) {
                let tempStateArr = res.data.filter(el => el.active == true);
                setStateArr(tempStateArr);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }

    const setCityArrFromState = (value) => {
        setSelectedStateId(value)
        let cityfilteredArr = cityArr.filter(el => el.stateId == value);
        setCityFilteredArr(cityfilteredArr)
    }

    const setAreaArrFromCity = (value) => {
        setSelectedCityId(value)
        let areafilteredArr = areaArr.filter(el => el.cityId == value);
        setAreaFilteredArr(areafilteredArr)
    }



    const setselectedArea = (value) => {
        setSelectedAreaId(value)

    }


    const getArea = async () => {
        try {
            let { data: res } = await axios.get(`${url}/area/`)
            if (res.success) {
                let tempAreaArr = res.data.filter(el => el.active == true && el.stateActive == true && el.cityActive == true);
                setAreaArr(tempAreaArr);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }


    const filterOrdersByLocation = () => {
        let tempFilteredOrdersArr = ordersArr.filter(el => el.stateId == selectedStateId && el.cityId == selectedCityId && el.areaId == selectedAreaId)
        setFilteredOrderArr(tempFilteredOrdersArr)
    }






    useEffect(() => {
        getState();
        getCity();
        getArea();
        getOrders()
    }, [])


    return (
        <main className="main_content">
            <div className="filterSearchBar">
                <form>
                    <div className="row mb-4">
                        <div className="col-12 col-md-3">
                            <label>Start Date</label>
                            <input type="date" className="form-control" />
                        </div>
                        <div className="col-12 col-md-3">
                            <label>End Date</label>
                            <input type="date" className="form-control" />
                        </div>
                        <div className="col-12 col-md-2">
                            <label>&nbsp;</label>
                            <button type="button" className="btn searchBttn"> Search </button>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12 col-md-3">
                            <label>User</label>
                            <input type="text" className="form-control" placeholder="Search Delivery Name" />
                        </div>
                        <div className="col-12 col-md-3">
                            <label>Status</label>
                            <select className="form-control">
                                <option>All ( default )</option>
                                <option>Active</option>
                                <option>Completed</option>
                                <option>Top UP</option>
                            </select>
                        </div>
                        <div className="col-12 col-md-2">
                            <label>&nbsp;</label>
                            <button type="button" className="btn searchBttn"> Search </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-3">
                            <label>Delivery Boy</label>
                            <input type="text" className="form-control" placeholder="Search Delivery Boy" />
                        </div>
                        <div className="col-12 col-md-2">
                            <label>Type</label>
                            <select className="form-control">
                                <option>All ( default )</option>
                                <option>Active</option>
                                <option>Completed</option>
                                <option>Top UP</option>
                            </select>
                        </div>
                        <div className="col-12 col-md-2">
                            <div className="field-group">
                                <label>State</label>

                                <select name="" id="" className="form-control" onChange={(event) => { setCityArrFromState(event.target.value) }}>
                                    <option value="">Please Select a State</option>
                                    {
                                        stateArr && stateArr.length > 0 && stateArr.map(el => {
                                            return (
                                                <option key={el._id} value={el._id}>{el.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-12 col-md-2">
                            <div className="field-group">
                                <label>City</label>

                                <select name="" id="" className="form-control" onChange={(event) => { setAreaArrFromCity(event.target.value) }}>
                                    <option value="">Please Select a City</option>
                                    {
                                        cityFilteredArr && cityFilteredArr.length > 0 && cityFilteredArr.map(el => {
                                            return (
                                                <option key={el._id} value={el._id}>{el.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="col-12 col-md-2">
                            <div className="field-group">
                                <label>Area</label>

                                <select name="" id="" className="form-control" onChange={(event) => { setselectedArea(event.target.value) }}>
                                    <option value="">Please Select a Area</option>
                                    {
                                        areaFilteredArr && areaFilteredArr.length > 0 && areaFilteredArr.map(el => {
                                            return (
                                                <option key={el._id} value={el._id}>{el.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-12 col-md-2">
                            <label>&nbsp;</label>
                            <button onClick={() => filterOrdersByLocation()} type="button" className="btn searchBttn"> Search </button>
                        </div>
                    </div>
                </form>
            </div>
            <br />
            <br />
            <div className="userTabsDesign mb-4">
                <div className="headingTitle">
                    <h3> Order Delivered </h3>
                </div>
                <div className="card">
                    <>
                        {
                            filteredOrderArr && filteredOrderArr.length > 0 &&
                            <MaterialTable
                                style={{ boxShadow: "none" }}
                                detailPanel={[
                                    {
                                        tooltip: '',
                                        render: rowData => {
                                            return (
                                                <div class="table-responsive">
                                                    <table class="table">
                                                        <table class="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ fontSize: 13 }} scope="col">#</th>
                                                                    <th style={{ fontSize: 13 }} scope="col">Recipient Name</th>
                                                                    <th style={{ fontSize: 13 }} scope="col">Recipient Phone Number</th>
                                                                    <th style={{ fontSize: 13 }} scope="col">Happy Code</th>
                                                                    <th style={{ fontSize: 13 }} scope="col">Delivery Address</th>
                                                                    <th style={{ fontSize: 13 }} scope="col">Cash Collection</th>
                                                                    <th style={{ fontSize: 13 }} scope="col">Cash Collection Amount</th>
                                                                    <th style={{ fontSize: 13 }} scope="col">Amount</th>
                                                                    <th style={{ fontSize: 13 }} scope="col">Order Status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    rowData?.numberOfOrdersArr && rowData.numberOfOrdersArr?.map((el, index) => {
                                                                        return (

                                                                            <tr>
                                                                                <th style={{ fontSize: 12 }} scope="row">{index + 1}</th>
                                                                                <td style={{ fontSize: 12 }}>{el?.deliverToName}</td>
                                                                                <td style={{ fontSize: 12 }}>{el?.deliverToNumber}</td>
                                                                                <td style={{ fontSize: 12 }}>{el?.happyCode}</td>
                                                                                <td style={{ fontSize: 12 }}>{el?.deliveryAddress}</td>
                                                                                <td style={{ fontSize: 12 }}>{el?.cashCollectionBool ? "Yes" : "No"}</td>
                                                                                <td style={{ fontSize: 12 }}>{el?.cashCollection}</td>
                                                                                <td style={{ fontSize: 12 }}>{el?.amount}</td>
                                                                                <td style={{ fontSize: 12 }}>{el?.orderStatus == 0 ? "Order Placed" : el.orderStatus == 1 ? "Order Picked Up" : ""}</td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </table>
                                                </div>
                                            )
                                        },
                                    },
                                ]}
                                columns={[
                                    {
                                        title: 'Order Number', field: "orderNumber",
                                        cellStyle: {
                                            minWidth: 150,
                                            textAlign: "left"
                                        },
                                    },
                                    {
                                        cellStyle: {
                                            minWidth: 150,
                                            textAlign: "left"
                                        }, title: 'Number Of Orders', field: "numberOfPackets"
                                    },
                                    {
                                        cellStyle: {
                                            minWidth: 150,
                                            textAlign: "left"
                                        }, title: 'Total Weight', field: "totalWeight"
                                    },
                                    {
                                        cellStyle: {
                                            minWidth: 150,
                                            textAlign: "left"
                                        }, title: 'Pickup Address', render: rowData => <div>{rowData?.pickupAddress?.addressLine1},{rowData?.pickupAddress?.addressLine2},{rowData?.pickupAddress?.landMark}-{rowData?.pickupAddress?.pincode}</div>
                                    },
                                    {
                                        cellStyle: {
                                            minWidth: 150,
                                            textAlign: "left"
                                        }, title: 'State', field: "stateName"
                                    },
                                    {
                                        cellStyle: {
                                            minWidth: 150,
                                            textAlign: "left"
                                        }, title: 'City', field: "cityName"
                                    },
                                    {
                                        cellStyle: {
                                            minWidth: 150,
                                            textAlign: "left"
                                        }, title: 'Area', field: "areaName"
                                    },
                                    {
                                        cellStyle: {
                                            minWidth: 190,
                                            textAlign: "right"
                                        }, title: 'KM (Approximation)', field: "kmRange", type: 'numeric'
                                    },

                                    {
                                        cellStyle: {
                                            minWidth: 150,
                                            textAlign: "left"
                                        }, title: 'Pickup Time', field: "pickupTime"
                                    },

                                ]}
                                data={filteredOrderArr}
                                title=""
                                options={{
                                    headerStyle: {
                                        color: '#000',
                                        fontSize: 12,
                                        fontWeight: "bold",
                                    },
                                    actionsColumnIndex: -1
                                }}

                            />

                        }
                    </>
                </div>
            </div>
        </main >
    )
}

export default InfoOrderDeliveries;
