import React from 'react';


function BoyMapView() {
    return (
        <main className="main_content">
            <div className="filterSearchBar">
                <form>
                    <div className="row mb-4">
                        <div className="col-12 col-md-3">
                            <label>Delivery Boy</label>
                            <input type="text" className="form-control" placeholder="Search Delivery Boy" />
                        </div>
                        <div className="col-12 col-md-3">
                            <label>Type</label>
                            <select className="form-control">
                                <option>All ( default )</option>
                                <option>Active</option>
                                <option>Completed</option>
                                <option>Top UP</option>
                            </select>
                        </div>
                        <div className="col-12 col-md-3">
                            <label>Area</label>
                            <input type="text" className="form-control" placeholder="Search Area Name" />
                        </div>
                        <div className="col-12 col-md-2">
                            <label>&nbsp;</label>
                            <button type="button" className="btn searchBttn"> Search </button>
                        </div>
                    </div>
                </form>
            </div>
            <br />
            <div className="infoOrdertable mb-4">
                <div className="headingTitle"><h3> Delivery Boy Map View </h3></div>
                <div className="card">
                    Map
                </div>
            </div>
        </main>
    )
}

export default BoyMapView;