import React, { useEffect, useState } from "react";
import axios from "axios";
import { url } from "../../Services/Url";
import { ToastContainer, toast } from "react-toastify";
import MaterialTable from "material-table";

import DataTable from "react-data-table-component";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { getAreaByCityId } from "../../Services/area";
import Moment from "react-moment";
import moment from "moment";
import { Pie, Bar, Doughnut } from "react-chartjs-2";
import { colorsArr } from "../../utils/util";

function InfoOrders() {
  const sortIcon = <ArrowDownward />;

  const [ordersArr, setOrdersArr] = useState();

  const [stateArr, setStateArr] = useState();
  const [cityArr, setCityArr] = useState();
  const [areaArr, setAreaArr] = useState();
  const [userArr, setUserArr] = useState();
  const [cityFilteredArr, setCityFilteredArr] = useState();
  const [areaFilteredArr, setAreaFilteredArr] = useState();
  const [filteredOrderArr, setFilteredOrderArr] = useState();
  const [selectedStateId, setSelectedStateId] = useState("");
  const [selectedCityId, setSelectedCityId] = useState("");
  const [selectedAreaId, setSelectedAreaId] = useState();

  const [searchStr, setSearchStr] = useState("");

  const [forceAssignOrders, setForceAssignOrders] = useState([]);
  const [filteredForceAssignOrders, setFilteredForceAssignOrders] = useState([]);

  const [selectedDeliveryBoyId, setSelectedDeliveryBoyId] = useState("");

  const [selectedOrderId, setSelectedOrderId] = useState("");

  const [barGraphData, setBarGraphData] = useState({});

  const getOrders = async () => {
    try {
      let { data: res } = await axios.get(`${url}/order/`);
      if (res.success) {
        let tempArr = res.data;
        setOrdersArr([...tempArr]);
        setFilteredOrderArr([...tempArr]);
        handleBarGraph([...tempArr]);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log(error.response, "errors");
    }
  };

  const getForceAssignOrders = async () => {
    try {
      let { data: res } = await axios.get(`${url}/order/getOrderForAdminToAssign`);
      if (res.success) {
        let tempArr = res.data;
        console.log(res.data);
        setForceAssignOrders([...tempArr]);
        setFilteredForceAssignOrders([...tempArr]);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log(error.response, "errors");
    }
  };

  const handleDeliveryBoyAssign = async () => {
    try {
      if (selectedOrderId != "" && selectedDeliveryBoyId != "") {
        let { data: res } = await axios.patch(`${url}/order/adminAssignDeliveryBoy/${selectedOrderId}`, { deliveryBoyId: selectedDeliveryBoyId });
        if (res.success) {
          alert(res.message);
          getForceAssignOrders();
        }
      } else {
        alert("Please select delivery boy");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log(error.response, "errors");
    }
  };

  const getCity = async () => {
    try {
      let { data: res } = await axios.get(`${url}/city/`);
      if (res.success) {
        let tempCityArr = res.data.filter((el) => el.active == true && el.stateActive == true);
        setCityArr(tempCityArr);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log(error.response, "errors");
    }
  };

  const getState = async () => {
    try {
      let { data: res } = await axios.get(`${url}/state/`);
      if (res.success) {
        let tempStateArr = res.data.filter((el) => el.active == true);
        setStateArr(tempStateArr);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log(error.response, "errors");
    }
  };

  const setCityArrFromState = (value) => {
    setSelectedStateId(value);
    let cityfilteredArr = cityArr.filter((el) => el.stateId == value);
    setCityFilteredArr(cityfilteredArr);
  };

  const setAreaArrFromCity = (value) => {
    setSelectedCityId(value);
    console.log(areaArr);
    getArea(value);
    // let areafilteredArr = areaArr.filter(el => el.cityId == value);
    // setAreaFilteredArr(areafilteredArr)
  };

  const setselectedArea = (value) => {
    setSelectedAreaId(value);
  };

  const getArea = async (id) => {
    try {
      let { data: res } = await getAreaByCityId(id);
      if (res.success) {
        let tempAreaArr = res.data;
        console.log(tempAreaArr);
        setAreaArr(tempAreaArr);
        setAreaFilteredArr(tempAreaArr);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log(error.response, "errors");
    }
  };

  const filterOrdersByLocation = () => {
    let tempFilteredOrdersArr = ordersArr.filter((el) => el.stateId == selectedStateId && el.cityId == selectedCityId && el.areaId == selectedAreaId);
    setFilteredOrderArr(tempFilteredOrdersArr);
  };

  const columns = [
    {
      name: "S.No",
      selector: (row, index) => index,
      sortable: true,
      center: true,

      width: "100px",
    },
    {
      name: "Order Number",
      cell: (row) => row.orderNumber,
      selector: (row) => row.orderNumber,
      sortable: true,
      center: true,

      width: "200px",
    },
    {
      name: "Order Count",
      selector: (row) => row.numberOfPackets,
      sortable: true,
      width: "200px",
      center: true,
    },
    {
      name: "State",
      cell: (row) => row.stateName,
      selector: (row) => row.stateName,
      sortable: true,
      center: true,
    },
    {
      name: "City",
      cell: (row) => row.cityName,
      selector: (row) => row.cityName,
      sortable: true,
      center: true,
    },
    {
      name: "Area",
      cell: (row) => row.areaName,
      selector: (row) => row.areaName,
      sortable: true,
      width: "200px",
    },
    {
      name: "Pickup Time",
      cell: (row) => row.pickupTime,
      selector: (row) => row.pickupTime,
      sortable: true,
      width: "200px",
    },
    {
      name: "Pickup Location",
      // selector: row => row.pickupFromAddress,
      sortable: true,
      cell: (row) => row.pickupFromAddress,
      selector: (row) => row.pickupFromAddress,
      width: "200px",
    },
    {
      name: "Name",
      cell: (row) => row.pickupFromName,
      selector: (row) => row.pickupFromName,
      sortable: true,
      width: "200px",
    },
    {
      name: "Number",
      cell: (row) => row.pickupFromNumber,
      selector: (row) => row.pickupFromNumber,
      sortable: true,
      width: "200px",
    },
    {
      name: "Pickup Time",
      cell: (row) => row.pickupTime,
      selector: (row) => row.pickupTime,
      sortable: true,
    },
    {
      name: "Delivery Boy Name",
      cell: (row) => row.deliveryBoyName,
      selector: (row) => row.deliveryBoyName,
      sortable: true,
      width: "200px",
    },
    {
      name: "Delivery Boy Phone",
      cell: (row) => (
        <button className="btn" onClick={() => window.open("tel:9971863733")}>
          {row.deliveryBoyPhone}
        </button>
      ),
      selector: (row) => `${row.deliveryBoyPhone}`,
      sortable: true,
      width: "200px",
    },
  ];
  const Assigncolumns = [
    {
      name: "S.No",
      selector: (row, index) => index,
      sortable: true,
      center: true,

      width: "100px",
    },
    {
      name: "Order Number",
      cell: (row) => row.orderNumber,
      selector: (row) => row.orderNumber,
      sortable: true,
      center: true,

      width: "200px",
    },
    {
      name: "Order Count",
      selector: (row) => row.numberOfPackets,
      sortable: true,
      width: "200px",
      center: true,
    },
    {
      name: "State",
      cell: (row) => row.stateName,
      selector: (row) => row.stateName,
      sortable: true,
      center: true,
    },
    {
      name: "City",
      cell: (row) => row.cityName,
      selector: (row) => row.cityName,
      sortable: true,
      center: true,
    },
    {
      name: "Area",
      cell: (row) => row.areaName,
      selector: (row) => row.areaName,
      sortable: true,
      width: "200px",
    },
    {
      name: "Pickup Time",
      cell: (row) => row.pickupTime,
      selector: (row) => row.pickupTime,
      sortable: true,
      width: "200px",
    },
    {
      name: "Pickup Location",
      // selector: row => row.pickupFromAddress,
      sortable: true,
      cell: (row) => row.pickupFromAddress,
      selector: (row) => row.pickupFromAddress,
      width: "200px",
    },
    {
      name: "Name",
      cell: (row) => row.pickupFromName,
      selector: (row) => row.pickupFromName,
      sortable: true,
      width: "200px",
    },
    {
      name: "Number",
      cell: (row) => row.pickupFromNumber,
      selector: (row) => row.pickupFromNumber,
      sortable: true,
      width: "200px",
    },
    {
      name: "Pickup Time",
      cell: (row) => row.pickupTime,
      selector: (row) => row.pickupTime,
      sortable: true,
    },
    // {
    //     name: 'Delivery Boy Name',
    //     cell: row => row.deliveryBoyName,
    //     selector: row => row.deliveryBoyName,
    //     sortable: true,
    //     width: '200px'

    // },
    {
      name: "Assign",
      cell: (row) => (
        <>
          <select
            className="form-control"
            onChange={(e) => {
              setSelectedDeliveryBoyId(e.target.value);
              setSelectedOrderId(row._id);
            }}
          >
            <option value="">Please Select Delivery Boy</option>
            {row?.assignAbleDeliveryBoys.map((el) => {
              return (
                <option value={el._id}>
                  {el.name} ,{el.mobile}
                </option>
              );
            })}
          </select>
          <button className="btn" onClick={() => handleDeliveryBoyAssign()}>
            Assign
          </button>
        </>
      ),
      width: "400px",
    },
  ];

  const filterByOrderStatus = (val) => {
    console.log(val);
    console.log([...ordersArr.filter((el) => el.numberOfOrdersArr.every((ele) => ele.orderStatus == val))]);
    setFilteredOrderArr([...ordersArr.filter((el) => el.numberOfOrdersArr.every((ele) => ele.orderStatus == val))]);
  };

  const searchItems = columns?.filter((el) => el.sortable && el.selector);
  function filter(el) {
    let chk = false;
    if (!searchItems?.length) chk = true;
    searchItems.forEach((elx) => {
      let val = elx?.selector(el);
      if (val) {
        if (typeof val == "string" || typeof val == "number") {
          if (`${val}`.toLowerCase().includes(searchStr.toLowerCase())) chk = true;
        }
      }
    });
    return chk;
  }

  const orderStatusArr = [
    {
      status: -1,
      text: "Order Cancelled",
    },
    {
      status: 3,
      text: "Order Delivered",
    },
    {
      status: 5,
      text: "Order Completed",
    },
    {
      status: 0,
      text: "Order Placed",
    },
    {
      status: 1,
      text: "Order Accepted",
    },
    {
      status: 1.1,
      text: "Going for pickup",
    },
    {
      status: 1.2,
      text: "reached Pickup location",
    },
    {
      status: 2,
      text: "Order Picked Up",
    },
  ];
  const getStatusTxt = (statusCode, shouldValueUpdateChk) => {
    if (statusCode == -1) {
      return "Order Cancelled";
    } else if (statusCode == 3) {
      return "Order Delivered";
    } else if (statusCode == 5) {
      return "Order Completed";
    } else if (shouldValueUpdateChk == 1) {
      return "Change Requested";
    } else if (statusCode == 0) {
      return "Order Placed";
    } else if (statusCode == 1) {
      return "Order Accepted";
    } else if (statusCode == 1.1) {
      return "Going for pickup";
    } else if (statusCode == 1.2) {
      return "reached Pickup location";
    } else if (statusCode == 2) {
      return "Order Picked Up";
    } else if (statusCode == 4) {
      return "Remarks Added";
    } else {
      return "";
    }
  };

  const handleBarGraph = (arr) => {
    console.log(arr);
    const labels = ["Cancelled Orders", "New Orders", "Orders Assigned to delivery boy", "Going For pickup", "Reached Pickup location", "Order Picked up", "Cash Orders Delivered", "Order Completed"];
    const barGraphData = {
      labels: labels,
      datasets: [
        {
          label: "Assigned Orders",
          data: [
            arr?.filter((el) => el.numberOfOrdersArr.every((ele) => ele.orderStatus == -1)).length,
            arr?.filter((el) => el.numberOfOrdersArr.every((ele) => ele.orderStatus == 0)).length,
            arr?.filter((el) => el.numberOfOrdersArr.every((ele) => ele.orderStatus == 1)).length,
            arr?.filter((el) => el.numberOfOrdersArr.every((ele) => ele.orderStatus == 1.1)).length,
            arr?.filter((el) => el.numberOfOrdersArr.every((ele) => ele.orderStatus == 1.2)).length,
            arr?.filter((el) => el.numberOfOrdersArr.every((ele) => ele.orderStatus == 2)).length,
            arr?.filter((el) => el.numberOfOrdersArr.every((ele) => ele.orderStatus == 3)).length,
            arr?.filter((el) => el.numberOfOrdersArr.every((ele) => ele.orderStatus == 5)).length,
          ],

          backgroundColor: colorsArr.map((el) => el.transparentColor),
          borderColor: colorsArr.map((el) => el.solidColor),
          borderWidth: 1,
        },
        // {
        //     label: 'Pickedup Orders',
        //     data: arr?.filter(el => el.numberOfOrdersArr.every(ele=>ele.orderStatus==2 || (ele.orderStatus>1 && ele.orderStatus<2))),
        //     backgroundColor: [
        //         colorsArr[1].solidColor,
        //     ],
        //     borderColor: [
        //         'rgb(255, 159, 64)',
        //     ],
        //     borderWidth: 1
        // },
        // {
        //     label: 'Delivered Orders',
        //     data: arr?.filter(el => el.numberOfOrdersArr.every(ele=>ele.orderStatus==5 || ele.orderStatus==4)),
        //     backgroundColor: [
        //         colorsArr[2].solidColor,
        //     ],
        //     borderColor: [
        //         'rgb(255, 159, 64)',
        //     ],
        //     borderWidth: 1
        // }
      ],
    };
    setBarGraphData({ ...barGraphData });
  };
  const barGraphConfig = {
    type: "doughnut",
    data: barGraphData,
  };

  useEffect(() => {
    getState();
    getCity();
    getOrders();
    getForceAssignOrders();
  }, []);
  return (
    <main className="main_content">
      <div className="filterSearchBar">
        <form>
          <div className="row mb-4">
            <div className="col-12 col-md-3">
              <label>Start Date</label>
              <input type="date" className="form-control" />
            </div>
            <div className="col-12 col-md-3">
              <label>End Date</label>
              <input type="date" className="form-control" />
            </div>
            <div className="col-12 col-md-2">
              <label>&nbsp;</label>
              <button type="button" className="btn searchBttn">
                {" "}
                Search{" "}
              </button>
            </div>
          </div>
          <div className="row mb-4">
            {/* <div className="col-12 col-md-3">
                            <label>User</label>
                            <input type="text" className="form-control" placeholder="Search Delivery Name" />
                        </div> */}
            <div className="col-12 col-md-3">
              <label>Status</label>
              <select className="form-control" onChange={(val) => filterByOrderStatus(val.target.value)}>
                <option value={0}>All ( default )</option>
                <option value={1}>Assigned</option>
                <option value={2}>Picked Up</option>
                <option value={3}>Delivered</option>
                <option value={4}>Completed</option>
                <option value={-2}>Cancelled</option>
              </select>
            </div>
            {/* <div className="col-12 col-md-2">
                            <label>&nbsp;</label>
                            <button type="button" className="btn searchBttn"> Search </button>
                        </div> */}
          </div>
          <div className="row">
            {/* <div className="col-12 col-md-3">
                            <label>Delivery Boy</label>
                            <input type="text" className="form-control" placeholder="Search Delivery Boy" />
                        </div> */}
            {/* <div className="col-12 col-md-2">
                            <label>Type</label>
                            <select className="form-control">
                                <option>All ( default )</option>
                                <option>Active</option>
                                <option>Completed</option>
                                <option>Top UP</option>
                            </select>
                        </div> */}
            <div className="col-12 col-md-2">
              <div className="field-group">
                <label>State</label>

                <select
                  name=""
                  id=""
                  className="form-control"
                  onChange={(event) => {
                    setCityArrFromState(event.target.value);
                  }}
                >
                  <option value="">Please Select a State</option>
                  {stateArr &&
                    stateArr.length > 0 &&
                    stateArr.map((el) => {
                      return (
                        <option key={el._id} value={el._id}>
                          {el.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="field-group">
                <label>City</label>

                <select
                  name=""
                  id=""
                  className="form-control"
                  onChange={(event) => {
                    setAreaArrFromCity(event.target.value);
                  }}
                >
                  <option value="">Please Select a City</option>
                  {cityFilteredArr &&
                    cityFilteredArr.length > 0 &&
                    cityFilteredArr.map((el) => {
                      return (
                        <option key={el._id} value={el._id}>
                          {el.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>

            <div className="col-12 col-md-2">
              <div className="field-group">
                <label>Area</label>

                <select
                  name=""
                  id=""
                  className="form-control"
                  onChange={(event) => {
                    setselectedArea(event.target.value);
                  }}
                >
                  <option value="">Please Select a Area</option>
                  {areaFilteredArr &&
                    areaFilteredArr.length > 0 &&
                    areaFilteredArr.map((el) => {
                      return (
                        <option key={el._id} value={el._id}>
                          {el.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="col-12 col-md-2">
              <label>&nbsp;</label>
              <button onClick={() => filterOrdersByLocation()} type="button" className="btn searchBttn">
                {" "}
                Search{" "}
              </button>
            </div>
          </div>
        </form>
      </div>
      <br />
      <br />

      <div className="userTabsDesign mb-4">
        <div className="headingTitle">{/* <h3>Orders </h3> */}</div>
        <>
          <div className="card mt-3">
            <div className="row mb-4">
              <div className="col-lg-12">{/* <Doughnut data={barGraphData} options={barGraphConfig} style={{maxHeight:500}} /> */}</div>
            </div>
            <input className="form-control" onChange={(event) => setSearchStr(event.target.value)} placeholder="Search" />
            <DataTable
              title="Orders"
              columns={columns}
              data={filteredOrderArr?.filter((el) => (searchStr ? filter(el) : true))}
              sortIcon={sortIcon}
              keyField="_id"
              pagination
              expandableRows
              expandableRowsComponent={({ data }) => {
                return (
                  <div style={{ padding: 30 }}>
                    <DataTable
                      columns={[
                        {
                          name: "S.no",
                          selector: (row, index) => index,
                          sortable: true,
                        },
                        {
                          name: "Name",
                          cell: (row) => row.deliverToName,
                          selector: (row) => row.deliverToName,
                          sortable: true,
                        },
                        {
                          name: "Phone",
                          selector: (row) => row.deliverToNumber,
                          sortable: true,
                        },
                        {
                          name: "Products",
                          cell: (row) => row.productsArr.reduce((acc, el) => acc + el.product + ",", ""),
                          selector: (row) => row.productsArr.reduce((acc, el) => acc + el.product + ",", ""),
                          width: "200px",
                        },

                        {
                          name: "Delivery Instructions",
                          cell: (row) => row.deliveryInstructions,
                          selector: (row) => row.deliveryInstructions,
                          width: "200px",
                        },
                        {
                          name: "Delivery Address",
                          cell: (row) => row.deliveryAddress,
                          selector: (row) => row.deliveryAddress,
                          sortable: true,
                          width: "200px",
                        },
                        {
                          name: "Delivered At",
                          cell: (row) => (
                            <span>
                              {new Date(row.deliveredAt).getHours()}:{new Date(row.deliveredAt).getMinutes()}
                            </span>
                          ),
                          selector: (row) => `${new Date(row.deliveredAt).getHours()}:${new Date(row.deliveredAt).getMinutes()}`,
                          sortable: true,
                        },
                        {
                          name: "Order Status",
                          cell: (row) => getStatusTxt(row?.orderStatus, row?.shouldValueUpdateChk),
                          selector: (row) => getStatusTxt(row?.orderStatus, row?.shouldValueUpdateChk),
                          sortable: true,
                        },
                        {
                          name: "Time stamps",
                          cell: (row) => (
                            <ul>
                              {row?.orderStatusTimestampsArr.map((el) => {
                                return (
                                  <li>
                                    {getStatusTxt(el.status)},{moment(el.timestamp).format("hh:mm:ss A")}
                                  </li>
                                );
                              })}
                            </ul>
                          ),
                          selector: (row) => row?.timestampstext,
                          sortable: true,
                        },
                      ]}
                      data={data?.numberOfOrdersArr}
                    />
                  </div>
                );
              }}
            />
          </div>
          <div className="card mt-3">
            <label>Search</label>
            <input className="form-control" onChange={(event) => setSearchStr(event.target.value)} placeholder="Search" />
            <DataTable
              title="Denied Orders By Delivery Boy /unaccepted orders"
              columns={Assigncolumns}
              data={filteredForceAssignOrders}
              sortIcon={sortIcon}
              keyField="_id"
              pagination
              expandableRows
              expandableRowsComponent={({ data }) => {
                return (
                  <div style={{ padding: 30 }}>
                    <DataTable
                      columns={[
                        {
                          name: "S.no",
                          selector: (row, index) => index,
                          sortable: true,
                        },
                        {
                          name: "Name",
                          cell: (row) => row.deliverToName,
                          selector: (row) => row.deliverToName,
                          sortable: true,
                        },
                        {
                          name: "Phone",
                          selector: (row) => row.deliverToNumber,
                          sortable: true,
                        },
                        {
                          name: "Products",
                          cell: (row) => row.productsArr.reduce((acc, el) => acc + el.product + ",", ""),
                          selector: (row) => row.productsArr.reduce((acc, el) => acc + el.product + ",", ""),
                          width: "200px",
                        },

                        {
                          name: "Delivery Instructions",
                          cell: (row) => row.deliveryInstructions,
                          selector: (row) => row.deliveryInstructions,
                          width: "200px",
                        },
                        {
                          name: "Delivery Address",
                          cell: (row) => row.deliveryAddress,
                          selector: (row) => row.deliveryAddress,
                          sortable: true,
                          width: "200px",
                        },
                        {
                          name: "Delivered At",
                          cell: (row) => (
                            <span>
                              {new Date(row.deliveredAt).getHours()}:{new Date(row.deliveredAt).getMinutes()}
                            </span>
                          ),
                          selector: (row) => `${new Date(row.deliveredAt).getHours()}:${new Date(row.deliveredAt).getMinutes()}`,
                          sortable: true,
                        },
                        {
                          name: "Order Status",
                          cell: (row) => getStatusTxt(row?.orderStatus, row?.shouldValueUpdateChk),
                          selector: (row) => getStatusTxt(row?.orderStatus, row?.shouldValueUpdateChk),
                          sortable: true,
                        },
                        {
                          name: "Time stamps",
                          cell: (row) => (
                            <ul>
                              {row?.orderStatusTimestampsArr.map((el) => {
                                return (
                                  <li>
                                    {getStatusTxt(el.status)},{moment(el.timestamp).format("hh:mm:ss A")}
                                  </li>
                                );
                              })}
                            </ul>
                          ),
                          selector: (row) => row?.timestampstext,
                          sortable: true,
                        },
                      ]}
                      data={data?.numberOfOrdersArr}
                    />
                  </div>
                );
              }}
            />
          </div>
        </>
      </div>
    </main>
  );
}
export default InfoOrders;
