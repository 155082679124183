import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { url } from '../../Services/Url'
import { ToastContainer, toast } from 'react-toastify';
import MaterialTable from 'material-table';
import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { getAreaByCityId } from '../../Services/area';

function User() {
    const [userArr, setUserArr] = useState();

    const sortIcon = <ArrowDownward />;





    const [approvedUserArr, setApprovedUserArr] = useState();
    const [filteredApprovedUserArr, setFilteredApprovedUserArr] = useState();

    const [unApprovedUserArr, setUnApprovedUserArr] = useState();
    const [filteredUnApprovedUserArr, setFilteredUnApprovedUserArr] = useState();

    const [approvedbusinessTypeUser, setApprovedbusinessTypeUser] = useState();
    const [filteredBusinessTypeUserArr, setFilteredBusinessTypeUserArr] = useState();

    const [unapprovedbusinessTypeUser, setUnapprovedbusinessTypeUser] = useState();
    const [filteredUnapprovedBusinessTypeUserArr, setFilteredUnapprovedBusinessTypeUserArr] = useState();



    const [stateArr, setStateArr] = useState();
    const [cityArr, setCityArr] = useState();
    const [areaArr, setAreaArr] = useState();
    const [cityFilteredArr, setCityFilteredArr] = useState();
    const [areaFilteredArr, setAreaFilteredArr] = useState();
    const [filteredOrderArr, setFilteredOrderArr] = useState();
    const [selectedStateId, setSelectedStateId] = useState("");
    const [selectedCityId, setSelectedCityId] = useState("");
    const [selectedAreaId, setSelectedAreaId] = useState();


    const [approvedUserSearchStr, setApprovedUserSearchStr] = useState('');
    const [approvedVendorSearchStr, setApprovedVendorSearchStr] = useState('');


    const getUsers = async () => {
        try {
            let { data: res } = await axios.get(`${url}/user/`)
            if (res.success) {
                setUserArr(res.data);

                let tempApprovedArr = res.data.filter(el => el.approvalStatus == 0 && el.userType == 1)
                if (tempApprovedArr) {
                    setApprovedUserArr(tempApprovedArr)
                    setFilteredApprovedUserArr(tempApprovedArr)
                }
                let tempUnApprovedArr = res.data.filter(el => el.approvalStatus == 1 && el.userType == 1)
                if (tempUnApprovedArr) {
                    setUnApprovedUserArr(tempUnApprovedArr)
                    setFilteredUnApprovedUserArr(tempUnApprovedArr)
                }
                let tempApprovedBusinessArr = res.data.filter(el => el.approvalStatus == 0 && el.userType == 0)
                if (tempApprovedBusinessArr) {
                    console.log(tempApprovedBusinessArr)
                    setApprovedbusinessTypeUser(tempApprovedBusinessArr)
                    setFilteredBusinessTypeUserArr(tempApprovedBusinessArr)
                }
                let tempUnApprovedBusinessArr = res.data.filter(el => el.approvalStatus == 1 && el.userType == 0)
                if (tempUnApprovedBusinessArr) {
                    setUnapprovedbusinessTypeUser(tempUnApprovedBusinessArr)
                    setFilteredUnapprovedBusinessTypeUserArr(tempUnApprovedBusinessArr)
                }
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }
    const getState = async () => {
        try {
            let { data: res } = await axios.get(`${url}/state/`)
            if (res.success) {
                let tempStateArr = res.data.filter(el => el.active == true);
                setStateArr(tempStateArr);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }
    const getCity = async () => {
        try {
            let { data: res } = await axios.get(`${url}/city/`)
            if (res.success) {
                let tempCityArr = res.data.filter(el => el.active == true && el.stateActive == true);
                setCityArr(tempCityArr);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }

    const setCityArrFromState = (value) => {
        setSelectedStateId(value)
        let cityfilteredArr = cityArr.filter(el => el.stateId == value);
        setCityFilteredArr(cityfilteredArr)
    }

    const setAreaArrFromCity = (value) => {
        setSelectedCityId(value)
        console.log(areaArr)
        getArea(value)
        // let areafilteredArr = areaArr.filter(el => el.cityId == value);
        // setAreaFilteredArr(areafilteredArr)
    }

    const getArea = async (id) => {
        try {
            let { data: res } = await getAreaByCityId(id)
            if (res.success) {
                let tempAreaArr = res.data
                console.log(tempAreaArr)
                setAreaArr(tempAreaArr);
                setAreaFilteredArr(tempAreaArr)
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }
    useEffect(() => {
        getUsers();
        getState();
        getCity()
    }, [])

    ///export to excel
    function convertArrayOfObjectsToCSV(array) {
        let result;

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(array[0]);
        console.log(keys)
        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;

                result += item[key];

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }

    // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
    function downloadCSV(array) {
        if (array.length > 0) {

            const link = document.createElement('a');
            let csv = convertArrayOfObjectsToCSV(array);
            if (csv == null) return;

            const filename = 'export.csv';

            if (!csv.match(/^data:text\/csv/i)) {
                csv = `data:text/csv;charset=utf-8,${csv}`;
            }

            link.setAttribute('href', encodeURI(csv));
            link.setAttribute('download', filename);
            link.click();
        }
        else {
            alert("Collection Empty")
        }
    }



    const removeUser=async(id)=>{
        let check=window.confirm("Do you realy want to delete this user ? ")
        if(check){
            try {
                const {data:response}=await axios.delete(`${url}/user/removeUserById/${id}`);
                if(response.success){
                    getUsers();
                    alert(response.message)
                }
            } catch (error) {
                console.error(error)
                alert(error)
            }
        }
        else{

        }
    }




    const columns = [
        {
            sortable: true,
            name: 'Date of Joining',

            selector: row => `${new Date(row?.date).toDateString()}`,
            cell: row => `${new Date(row?.date).toDateString()}`,
            width: '200px',
        },
        {
            name: 'Name',
            selector:  row => <a href={`/user-details/${row?._id}`}>{row?.name}</a>,
            sortable: true,
        },
        {
            name: 'Email',
            sortable: true,
            selector: row => row?.email,
            cell: row => <>{row.email ? row.email : "NA"}</>
        },
        {
            sortable: true,
            name: 'Address Line 1',
            selector: row => row?.addressLine1,
            cell: row => row?.addressLine1,
            width: '200px'
        },
        {
            sortable: true,
            name: 'Address Line 2',
            selector: row => row?.addressLine2,
            cell: row => <>{row?.addressLine2 ? row.addressLine2 : "NA"}</>,
            width: '200px'

        },
        {
            sortable: true,
            name: 'Landmark',
            selector: row => row?.landMark,
            cell: row => <>{row?.landMark ? row.landMark : "NA"}</>,
            width: '200px'

        },
        {
            sortable: true,
            name: 'Pin Code',
            selector: row => row?.pincode
        },
        {
            name: 'Mobile Number',
            sortable: true,
            selector: row => row?.mobile,
            width: '200px'
        },
        {
            sortable: true,
            name: 'State',
            selector: row => row?.stateName,
            cell: row => row?.stateName,
            width: '200px',
        },
        {
            sortable: true,
            name: 'City',
            selector: row => row?.cityName,
            width: '200px',
            cell: row => row?.cityName,


        },
        {
            sortable: true,
            name: 'Area',
            selector: row => row?.areaName,
            width: '200px',
            cell: row => row?.areaName,


        },
        
        {
            name: 'Actions',
            selector: rowData => <div>
               actions
            </div>,
            cell: rowData => <div className="d-flex justify-content-center">
            {/* <button type="button" className="btn addModifiBttn ml-2" data-toggle="modal" data-target="#ModifyShopType" onClick={() => setSelectedPackageObj(rowData)}> Modify </button> */}
            <button type="button" className="btn addModifiBttn ml-2"  onClick={() => removeUser(rowData._id)}> Delete </button>
        </div>
        }

    ]




    const columnsUnApprovedUsers = [

        {
            sortable: true,
            name: 'Date of Joining',

            selector: row => `${new Date(row?.date).toDateString()}`,
            cell: row => `${new Date(row?.date).toDateString()}`,
            width: '200px',
        },
        {
            name: 'Name',
            selector: row => row?.name,
            sortable: true,
        },
        {
            name: 'Email',
            sortable: true,
            selector: row => row?.email,
            cell: row => <>{row.email ? row.email : "NA"}</>
        },
        {
            sortable: true,
            name: 'Address Line 1',
            selector: row => row?.addressLine1,
            cell: row => row?.addressLine1,
            width: '200px'
        },
        {
            sortable: true,
            name: 'Address Line 2',
            selector: row => row?.addressLine2,
            cell: row => <>{row?.addressLine2 ? row.addressLine2 : "NA"}</>,
            width: '200px'

        },
        {
            sortable: true,
            name: 'Landmark',
            selector: row => row?.landMark,
            cell: row => <>{row?.landMark ? row.landMark : "NA"}</>,
            width: '200px'

        },
        {
            sortable: true,
            name: 'Pin Code',
            selector: row => row?.pincode
        },
        {
            name: 'Mobile Number',
            sortable: true,
            selector: row => row?.mobile
        },
        {
            sortable: true,
            name: 'State',
            selector: row => row?.stateName,
            cell: row => row?.stateName,
            width: '200px',
        },
        {
            sortable: true,
            name: 'City',
            selector: row => row?.cityName,
            width: '200px',
            cell: row => row?.cityName,


        },
        {
            sortable: true,
            name: 'Area',
            selector: row => row?.areaName,
            width: '200px',
            cell: row => row?.areaName,


        },
       
        {
            name: 'Actions',
            selector: rowData => <div>
               actions
            </div>,
            cell: rowData => <div className="d-flex justify-content-center">
            {/* <button type="button" className="btn addModifiBttn ml-2" data-toggle="modal" data-target="#ModifyShopType" onClick={() => setSelectedPackageObj(rowData)}> Modify </button> */}
            <button type="button" className="btn addModifiBttn ml-2"  onClick={() => removeUser(rowData._id)}> Delete </button>
        </div>
        }
    ]




    const columnsBusinessUserApprovedArr = [
        {
            sortable: true, name: 'Date of Joining',
            selector: row => row?.date,
            cell: row => new Date(row?.date).toDateString(),
            width: '200px'
        },
        {
            name: 'Shop Name', selector: row => <a href={`/AddBusinessProducts/${row?._id}`}>{row?.name}/{row?.shopName}</a>, sortable: true,
            width: '200px',
        },
        {
            name: 'Contact Person Name',
            selector: row => row?.contactPerson,
            sortable: true,
            cell: row => <>{row?.contactPerson ? row?.contactPerson : "NA"}</>,
            width: '200px'
        },
        {
            sortable: true, name: 'Email',
            selector: row => <>{row?.email ? row.email : "NA"}</>,
            cell: row => <>{row?.email ? row.email : "NA"}</>,
            width: '200px'
        },
        {
            sortable: true, name: 'Address Line 1',
            selector: row => row.addressLine1,
            cell: row => <>{row?.addressLine1 ? row.addressLine1 : "NA"}</>,
            width: '200px'
        },
        {
            sortable: true, name: 'Address Line 2',
            selector: row => row?.addressLine2,
            cell: row => <>{row?.addressLine2 ? row.addressLine2 : "NA"}</>,
            width: '200px'
        },
        {
            sortable: true, name: 'Landmark',
            selector: row => row?.landMark,
            width: '200px',
            cell: row => <>{row?.landMark ? row.landMark : "NA"}</>,

        },
        {
            sortable: true, name: 'Pin Code',
            selector: row => row?.pincode,
            cell: row => <>{row?.pincode ? row.pincode : "NA"}</>
        },
        {
            sortable: true, name: 'Mobile Number',
            selector: row => row?.mobile,
            cell: row => row?.mobile,
            width: '200px',
        },
        {
            sortable: true, name: 'GST Number',
            selector: row => row?.gstNumber,
            cell: row => <>{row.gstNumber ? row?.gstNumber : "NA"}</>,
            width: '200px'
        },
        {
            sortable: true, name: 'State',
            selector: row => row?.stateName,
            width: '200px',
            cell: row => row.stateName
        },
        {
            sortable: true, name: 'City',
            selector: row => row?.cityName,
            cell: row => row.cityName,
            width: '200px',
        },
        {
            sortable: true, name: 'Area',
            selector: row => row?.areaName,
            width: '200px',
            cell: row => row.areaName
        },

        {
            sortable: true, name: 'Business Type',
            selector: row => row?.shopTypeName,
            cell: row => row.shopTypeName,
            width: '200px'
        },
        

        {
            name: 'Actions',
            selector: rowData => <div>
               actions
            </div>,
            cell: rowData => <div className="d-flex justify-content-center">
            <a href={`/user-details/${rowData?._id}`} className="btn addModifiBttn ml-2"> View</a>
            <button type="button" className="btn addModifiBttn ml-2"  onClick={() => removeUser(rowData._id)}> Delete </button>
        </div>
        }
    ]







    const columnsBusinessUserUnApprovedArr = [
        {
            name: 'Shop Name', selector: row => row?.name, sortable: true,
        },
        {
            name: 'Contact Person Name', selector: row => row?.contactPerson, sortable: true,
        },
        {
            sortable: true, name: 'Email', selector: row => row?.email
        },
        {
            sortable: true, name: 'Address Line 1', selector: row => row?.addressLine1
        },
        {
            sortable: true, name: 'Address Line 2', selector: row => row?.addressLine2
        },
        {
            sortable: true, name: 'Landmark', selector: row => row?.landMark
        },
        {
            sortable: true, name: 'Pin Code', selector: row => row?.pincode
        },
        {
            sortable: true, name: 'Mobile Number', selector: row => row?.mobile
        },
        {
            sortable: true, name: 'GST Number', selector: row => row?.gstNumber
        },
        {
            sortable: true, name: 'State', selector: row => row?.stateName
        },
        {
            sortable: true, name: 'City', selector: row => row?.cityName
        },
        {
            sortable: true, name: 'Area', selector: row => row?.areaName
        },

        {
            sortable: true, name: 'Business Type', selector: row => row?.shopTypeName
        },
        {
            sortable: true, name: 'Package', selector: row => row?.packageName
        },
        {
            sortable: true, name: 'Date of Joining', selector: row => row?.date,
        },

        {
            name: 'Actions',
            selector: rowData => <div>
               actions
            </div>,
            cell: rowData => <div className="d-flex justify-content-center">
            {/* <button type="button" className="btn addModifiBttn ml-2" data-toggle="modal" data-target="#ModifyShopType" onClick={() => setSelectedPackageObj(rowData)}> Modify </button> */}
            <button type="button" className="btn addModifiBttn ml-2"  onClick={() => removeUser(rowData._id)}> Delete </button>
        </div>
        }
    ]





    const filterUsers = () => {
        if(selectedAreaId){

            setFilteredApprovedUserArr([...approvedUserArr.filter(el=>el.areaId==selectedAreaId)])
            setFilteredUnApprovedUserArr([...unApprovedUserArr.filter(el=>el.areaId==selectedAreaId)])
            setFilteredBusinessTypeUserArr([...approvedbusinessTypeUser.filter(el=>el.areaId==selectedAreaId)])
            setFilteredUnapprovedBusinessTypeUserArr([...unapprovedbusinessTypeUser.filter(el=>el.areaId==selectedAreaId)])
        }
        else{
            alert("Please select area")
        }

    }




    const customGeneralFilter = (data, columns, searchStr) => {
        const searchItems = columns?.filter(el => el.sortable)
        function filter(el) {
          let chk = false
          if (!searchItems?.length)
            chk = true
          searchItems.forEach(elx => {
            let val = elx?.selector(el)
            if (val) {
              if (typeof val == 'string' || typeof val == 'number') {
                if (`${val}`.toLowerCase().includes(searchStr.toLowerCase()))
                  chk = true
              }
            }
          })
          return chk
        }
        return data?.filter(el => searchStr ? filter(el) : true)
      }





    return (
        <main className="main_content">

            <div className="headingTitle"><h3> Users Stats </h3></div>
            <div className="row mt-4 mb-4">

                <div className="col-12 col-md-2">
                    <div className="field-group">
                        <label>State</label>

                        <select name="" id="" className="form-control" onChange={(event) => { setCityArrFromState(event.target.value) }}>
                            <option value="">Please Select a State</option>
                            {
                                stateArr && stateArr.length > 0 && stateArr.map(el => {
                                    return (
                                        <option key={el._id} value={el._id}>{el.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="col-12 col-md-2">
                    <div className="field-group">
                        <label>City</label>

                        <select name="" id="" className="form-control" onChange={(event) => { setAreaArrFromCity(event.target.value) }}>
                            <option value="">Please Select a City</option>
                            {
                                cityFilteredArr && cityFilteredArr.length > 0 && cityFilteredArr.map(el => {
                                    return (
                                        <option key={el._id} value={el._id}>{el.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>

                <div className="col-12 col-md-2">
                    <div className="field-group">
                        <label>Area</label>

                        <select name="" id="" className="form-control" onChange={(event) => { setSelectedAreaId(event.target.value) }}>
                            <option value="">Please Select a Area</option>
                            {
                                areaFilteredArr && areaFilteredArr.length > 0 && areaFilteredArr.map(el => {
                                    return (
                                        <option key={el._id} value={el._id}>{el.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="col-12 col-md-2">
                    <label>&nbsp;</label>
                    <button onClick={() => filterUsers()} type="button" className="btn searchBttn"> Search </button>
                </div>
            </div>

            <div className="row">

                <div className="col-xl-3 col-lg-3 col-sm-6 col-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">Total users</h5>
                        </div>
                        <div className="card-body">
                            <div className="boysCount">
                                <p><i className="fa fa-first-order"></i> {filteredApprovedUserArr?.length + filteredBusinessTypeUserArr?.length + filteredUnApprovedUserArr?.length + filteredUnapprovedBusinessTypeUserArr?.length} </p>
                            </div>
                            <div className="boysCase">
                                <p> Count </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-sm-6 col-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">Total Household users</h5>
                        </div>
                        <div className="card-body">
                            <div className="boysCount">
                                <p><i className="fa fa-first-order"></i> {filteredApprovedUserArr?.length + filteredUnApprovedUserArr?.length} </p>
                            </div>
                            <div className="boysCase">
                                <p> Count </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-sm-6 col-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">Total Vendors</h5>
                        </div>
                        <div className="card-body">
                            <div className="boysCount">
                                <p><i className="fa fa-first-order"></i> {filteredBusinessTypeUserArr?.length + filteredUnapprovedBusinessTypeUserArr?.length} </p>
                            </div>
                            <div className="boysCase">
                                <p> Count </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-sm-6 col-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">Approved Household users</h5>
                        </div>
                        <div className="card-body">
                            <div className="boysCount">
                                <p><i className="fa fa-first-order"></i> {filteredApprovedUserArr?.length} </p>
                            </div>
                            <div className="boysCase">
                                <p> Count </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-sm-6 col-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">Approved Vendors</h5>
                        </div>
                        <div className="card-body">
                            <div className="boysCount">
                                <p><i className="fa fa-first-order"></i> {filteredBusinessTypeUserArr?.length} </p>
                            </div>
                            <div className="boysCase">
                                <p> Count </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-sm-6 col-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title"> Unapproved Household users</h5>
                        </div>
                        <div className="card-body">
                            <div className="boysCount">
                                <p><i className="fa fa-first-order"></i> {filteredUnApprovedUserArr?.length} </p>
                            </div>
                            <div className="boysCase">
                                <p> Count </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-sm-6 col-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title"> Unapproved Vendors</h5>
                        </div>
                        <div className="card-body">
                            <div className="boysCount">
                                <p><i className="fa fa-first-order"></i> {filteredUnapprovedBusinessTypeUserArr?.length} </p>
                            </div>
                            <div className="boysCase">
                                <p> Count </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="headingTitle"><h3> User Info </h3></div>

            <div className="userTabsDesign">
                <div className="searchBar">
                    <input type="text" className="form-control" onChange={(e)=>setApprovedUserSearchStr(e.target.value)} placeholder="Search Users" />
                    <i className="fa fa-search"></i>
                </div>
                <div className="card-body">
                    <div className="custom-tab-2">
                        <ul className="nav nav-tabs">
                            <li className="nav-item active"><a className="nav-link" data-toggle="tab" href="#approved">Approved</a>
                            </li>
                            <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#unapproved">Unapproved</a>
                            </li>
                        </ul>
                        <div className="tab-content tab-content-default mt-4">
                            <div className="tab-pane fade in active" id="approved" role="tabpanel">

                                <>
                                    <button onClick={() => downloadCSV(filteredApprovedUserArr)} className="btn addModifiBttn">Export </button>
                                    <div className="card mt-3">
                                        <DataTable
                                            title=""
                                            columns={columns}
                                            data={customGeneralFilter(filteredApprovedUserArr,columns, approvedUserSearchStr)}
                                            sortIcon={sortIcon}
                                            keyField='_id'
                                            pagination
                                        />

                                    </div>
                                </>


                            </div>
                            <div className="tab-pane fade" id="unapproved">
                                {/* columnsUnApprovedUsers */}

                                <>
                                    <button onClick={() => downloadCSV(filteredUnApprovedUserArr)} className="btn addModifiBttn">Export </button>

                                    <div className="card mt-3">
                                        <DataTable
                                            title=""
                                            columns={columnsUnApprovedUsers}
                                            data={customGeneralFilter(filteredUnApprovedUserArr,columnsUnApprovedUsers, approvedUserSearchStr)}
                                            sortIcon={sortIcon}
                                            keyField='_id'
                                            pagination
                                        />
                                    </div>
                                </>


                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className="userTabsDesign mb-4">
                <div className="headingTitle"><h3> Vendors </h3></div>
                <div className="searchBar">
                    <input type="text" className="form-control" placeholder="Search Type of Business" onChange={(e)=>setApprovedVendorSearchStr(e.target.value)} />
                    <i className="fa fa-search"></i>
                </div>
                <div className="card-body">
                    <div className="custom-tab-2">
                        <ul className="nav nav-tabs">
                            <li className="nav-item active"><a className="nav-link" data-toggle="tab" href="#approvedStore">Approved</a>
                            </li>
                            <li className="nav-item"><a className="nav-link show" data-toggle="tab" href="#unapprovedStore">Unapproved</a>
                            </li>

                        </ul>
                        <div className="tab-content tab-content-default">
                            <div className="tab-pane fade in active" id="approvedStore" role="tabpanel">
                                <button onClick={() => downloadCSV(filteredBusinessTypeUserArr)} className="btn addModifiBttn">Export </button>
                                <div className="card mt-3">

                                    <DataTable
                                        title=""
                                        columns={columnsBusinessUserApprovedArr}
                                        data={customGeneralFilter(filteredBusinessTypeUserArr,columnsBusinessUserApprovedArr, approvedVendorSearchStr)}
                                        sortIcon={sortIcon}
                                        keyField='_id'
                                        pagination
                                    />
                                </div>
                            </div>
                            <div className="tab-pane fade" id="unapprovedStore">
                                <div style={{ width: '40%' }}>

                                    <button onClick={() => downloadCSV(filteredUnapprovedBusinessTypeUserArr)} className="btn addModifiBttn">Export </button>
                                </div>
                                <div className="card">

                                    <DataTable
                                        title=""
                                        
                                        columns={columnsBusinessUserUnApprovedArr}
                                        data={customGeneralFilter(filteredUnapprovedBusinessTypeUserArr,columnsBusinessUserUnApprovedArr, approvedVendorSearchStr)}
                                        sortIcon={sortIcon}
                                        keyField='_id'
                                        pagination
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div className="userTabsDesign mb-4">

            </div>

            <div id="modifyuserMain" className="modal fade" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title text-center"> Modify User </h4>
                        </div>
                        <div className="modal-body">
                            <div className="AddModiForm">
                                <form>
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <label> Shop Name </label>
                                                <input type="text" className="form-control" placeholder="Enter Shop Name" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <label> Contact person </label>
                                                <input type="text" className="form-control" placeholder="Enter Contact Person" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <label> Date Of Joining </label>
                                                <input type="date" className="form-control" placeholder="Enter date of joining" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <label> Email Address </label>
                                                <input type="text" className="form-control" placeholder="Enter Email Address" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <label> Phone No. </label>
                                                <input type="text" className="form-control" placeholder="Enter Phone No." />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <label> City </label>
                                                <input type="text" className="form-control" placeholder="Enter City Name" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <label> Area </label>
                                                <input type="text" className="form-control" placeholder="Enter Area" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <label> Type of Business </label>
                                                <input type="text" className="form-control" placeholder="Enter Type of Business" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="field-group">
                                                <label> Status </label>
                                                <select className="form-control">
                                                    <option>-- Select Status --</option>
                                                    <option>Active</option>
                                                    <option>InActive</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-4 d-flex justify-content-center">
                                        <div className="col-12 col-md-3">
                                            <div className="field-group">
                                                <button type="button" className="btn searchBttn"> Save </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </main>
    )
}

export default User;
