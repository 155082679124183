import axios from 'axios';
import { url } from "./Url"


const serverUrl = `${url}/deliveryBoyApplications`


export const getAllApplications = async() => {
    try {
        const res = await axios.get(`${serverUrl}/`);
        return res
    } catch (error) {
        console.error(error)
    }
}

export const acceptDeliveryBoyApplications=async(obj)=>{
    try {
        const res=await axios.post(`${serverUrl}/acceptDeliveryBoyApplication`,obj)
        return res
    } catch (error) {
        console.error(error)
    }
}
export const uploadImageDeliveryBoyApplications=async(obj,id)=>{
    try {
        let config={
            headers:{
                'Content-Type':'multipart/form-data'
            }
        }
        const res=await axios.patch(`${url}/deliveryBoy/uploadImageById/${id}`,obj,config)
        return res
    } catch (error) {
        console.error(error)
    }
}