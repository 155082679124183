import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { url } from '../../Services/Url'
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import MaterialTable from 'material-table';

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { uploadStateData } from '../../Services/state';
import { uploadCityData } from '../../Services/city';

import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
const MySwal = withReactContent(Swal)

export default function AddCity() {
    const [name, setName] = useState();
    const [stateArr, setStateArr] = useState();
    const [cityArr, setCityArr] = useState([]);
    const [filteredCityArr, setFilteredCityArr] = useState([]);
    const [selectedStateId, setSelectedStateId] = useState();


    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const [uploadFile, setUploadFile] = useState();

    const sortIcon = <ArrowDownward />;

    const onSubmitUpload = async () => {
        if (uploadFile) {
            try {
                MySwal.fire({
                    title: "Processing Please Wait",
                    allowOutsideClick: false
                })

                MySwal.showLoading();
                let formData = new FormData()
                formData.append('file', uploadFile)

                const res = await uploadCityData(formData)
                MySwal.fire(res.data.message)
                getState()
            }
            catch (err) {
                if (err?.response?.data?.message)
                    MySwal.fire(err?.response?.data?.message)
                else
                    MySwal.fire(err?.message)
            }
        }
        else {
            MySwal.fire('please select a xlxs file')
        }
    }

    const handleSubmit = async () => {
        try {

            let obj = {
                name: name,
                stateId: selectedStateId
            }
            console.log(obj)
            let { data: res } = await axios.post(`${url}/city/`, obj)
            if (res.success) {
                toast.success(res.message)
                // alert(res.message)
                getCity()
            }
            else {
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }




    const getCity = async () => {
        try {
            let { data: res } = await axios.get(`${url}/city/`)
            if (res.success) {
                console.log(res.data)
                // toast.success(res.message)
                setCityArr(res.data);
                setFilteredCityArr(res.data)
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }


    const getState = async () => {
        try {
            let { data: res } = await axios.get(`${url}/state/`)
            if (res.success) {
                // toast.success(res.message)
                setStateArr(res.data);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }
    }



    const updateCity = async (id, value) => {
        try {
            let tempobj = {
                active: value
            }
            let { data: res } = await axios.patch(`${url}/city/changeStatusById/${id}`, tempobj)
            if (res.success) {
                toast.success(res.message)
                getCity()
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log(error.response, "errors")
        }

    }

    const setAllAreaActive = async (id) => {
        try {
            const { data: res } = await axios.patch(`${url}/city/setAllAreaActive/${id}`)
            if(res.success){
                getState()
        getCity();

                alert(res.message)
            }
        } catch (error) {
            console.error(error)
            if (error.response.data.message) {
                alert(error.response.data.message)
            }
            else {
                alert(error.message)
            }
        }
    }
    const setAllAreaInActive = async (id) => {
        try {
            const { data: res } = await axios.patch(`${url}/city/setAllAreaInActive/${id}`)
            if(res.success){
                getState()
        getCity();

                alert(res.message)
            }
        } catch (error) {
            console.error(error)
            if (error.response.data.message) {
                alert(error.response.data.message)
            }
            else {
                alert(error.message)
            }
        }
    }

    const columns = [
        {
            name: 'S.no',
            selector: row => row.sno,
            sortable: true,
        },
        {
            name: 'City Name',
            selector: row => `${row.name}`,
            sortable: true,
        },
        {
            name: 'State Name',
            selector: row => `${row.stateName}`,
            sortable: true,
        },
        {
            name: 'State Status',
            selector: row => row.stateStatus ? "Active" : "In-Active",
            sortable: true,
        },
        {
            name: 'Actions',
            cell: row => <div className="d-flex justify-content-center"> {
                row.active
                    ?
                    <button disabled={row.stateActive == false} onClick={() => updateCity(row._id, false)} className="btn addModifiBttn mr-2">Set In-Active</button>
                    :
                    <button disabled={row.stateActive == false} onClick={() => updateCity(row._id, true)} className="btn greenBttn mr-2">Set Active</button>
            }
                <button disabled={row.stateActive == false} onClick={() => setAllAreaActive(row._id)} className="btn greenBttn mr-2">Set All Areas Active</button>
                <button onClick={() => setAllAreaInActive(row._id)} className="btn addModifiBttn mr-2">Set All Areas InActive</button>
            </div>
        },


    ];

    const handleFilterByName = (val) => {
        setFilteredCityArr([...cityArr.filter(el => el.name.toLowerCase().includes(val.toLowerCase()) || el.stateName.toLowerCase().includes(val.toLowerCase()))])
    }
    const subHeaderComponentRender = () => {
        return (
            <input placeholder="Search" type="text" />
        )
    }



    useEffect(() => {
        getState();
        getCity();
    }, [])
    return (
        <main className="main_content">
            <div className="userTabsDesign mb-4">
                <div className="headingTitle"><h3> Add City </h3></div>
                <div className="card">
                    <div className="card-body">
                        <div className="AddModiForm w-100">
                            <form onSubmit={(e) => { e.preventDefault(); handleSubmit() }}>
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="field-group">
                                            <input type="text" className="form-control" onChange={(event) => setName(event.target.value)} placeholder="City Name" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="field-group">
                                            <select name="" id="" className="form-control" onChange={(event) => setSelectedStateId(event.target.value)}>
                                                <option value="">Please Select a State</option>
                                                {
                                                    stateArr && stateArr.length > 0 && stateArr.map(el => {
                                                        return (
                                                            <option key={el._id} value={el._id}>{el.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>

                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4 d-flex justify-content-start">
                                    <div className="col-4 col-md-3">
                                        <div className="field-group">
                                            <button type="button" onClick={() => handleSubmit()} className="btn searchBttn"> Save </button>
                                        </div>
                                    </div>
                                    <div className="col-4 col-md-3">
                                        <div className="field-group">
                                            <button type="button" onClick={() => onOpenModal()} className="btn searchBttn"> Upload Excel </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="AddModiForm w-100">
                            <form onSubmit={(e) => { e.preventDefault(); handleSubmit() }}>
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="field-group">
                                            <label>Search</label>
                                            <input type="text" className="form-control" onChange={(event) => handleFilterByName(event.target.value)} placeholder="Search By State, City Name" />
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="infoOrdertable mb-4">
                {/* <div className="headingTitle"><h3>Cities</h3></div> */}
                <div className="card">
                    <DataTable
                        title="Cities"
                        columns={columns}
                        data={filteredCityArr?.map((el, index) => {
                            let obj = {
                                sno: index + 1,
                                ...el
                            }
                            return obj
                        })}
                        sortIcon={sortIcon}
                        keyField='_id'
                        pagination
                    />
                    {/* {cityArr &&

                                <MaterialTable
                                    columns={[
                                        {
                                            title: 'S.no', field: "sno",
                                            cellStyle: {
                                                textAlign: "left"
                                            },
                                            render: rowData => <div>{rowData.tableData.id + 1}</div>
                                        },
                                        {
                                            cellStyle: {
                                                textAlign: "left"
                                            }, title: 'State', field: "stateName",

                                        },
                                        {
                                            cellStyle: {
                                                textAlign: "left"
                                            }, title: 'State Status', field: "stateStatus",
                                            render:rowData=><span>{rowData.stateStatus ? "Active":"In-Active" }</span>

                                        },
                                        {
                                            cellStyle: {
                                                textAlign: "left"
                                            }, title: 'city', field: "name",

                                        },
                                        {
                                            cellStyle: {
                                                textAlign: "left"
                                            }, title: 'Action', field: "maxDistance",
                                            render: rowData => <div> {
                                                rowData.active
                                                    ?
                                                    <button disabled={rowData.stateActive == false} onClick={() => updateCity(rowData._id, false)} className="btn addModifiBttn mr-2">Set In-Active</button>
                                                    :
                                                    <button disabled={rowData.stateActive == false} onClick={() => updateCity(rowData._id, true)} className="btn greenBttn mr-2">Set Active</button>

                                            }</div>
                                        },

                                    ]}
                                    data={cityArr}
                                    title=""

                                    // actions={[
                                    //     {
                                    //         icon: 'delete',
                                    //         tooltip: 'Delete Entry',
                                    //         onClick: (event, rowData) => {
                                    //             // Do save operation
                                    //         }
                                    //     }
                                    // ]}
                                    options={{
                                        headerStyle: {
                                            // backgroundColor: 'rgba(0,0,0,0.7)',
                                            color: '#000',
                                            fontSize: 16,
                                            fontWeight: "bold",
                                        },
                                        actionsColumnIndex: -1
                                    }}

                                />
                            } */}
                    {/* <table className="table table-responsive-sm">
                                <thead>
                                    <tr>
                                        <th>State</th>
                                        <th>State Status</th>
                                        <th>City</th>
                                        <th>City Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        cityArr && cityArr.length > 0 && cityArr.map(el => {
                                            return (
                                                <tr key={el._id}>
                                                    <td>{el.stateName}</td>
                                                    <td>{el.stateActive ? "Active" : "In-Active"}</td>
                                                    <td>{el.name}</td>
                                                    <td>{el.active ? "Active" : "In-Active"}</td>
                                                    <td>
                                                        {
                                                            el.active
                                                                ?
                                                                <button disabled={el.stateActive == false} onClick={() => updateCity(el._id, false)} className="btn addModifiBttn mr-2">Set In-Active</button>
                                                                :
                                                                <button disabled={el.stateActive == false} onClick={() => updateCity(el._id, true)} className="btn greenBttn mr-2">Set Active</button>

                                                        }
                                                    </td>
                                                </tr>

                                            )
                                        })
                                    }

                                </tbody>
                            </table> */}
                </div>
            </div>
            <Modal open={open} onClose={onCloseModal} center classNames="col-6">
                <div style={{ padding: 50, overflowX: 'hidden' }} className='row'>
                    <h2 className="text-center">Upload</h2>
                    <div className="col">
                        <div className="mb-3">
                            <label htmlFor="formFile" className="form-label">File Input</label>
                            <input type="file" className="form-control" onChange={(e) => e.target.files && e.target.files[0] ? setUploadFile(e.target.files[0]) : 0} />
                        </div>
                        <div>
                            <button className="btn btn-primary" onClick={() => onSubmitUpload()}>
                                Upload
                            </button>
                        </div>
                    </div>
                </div>

            </Modal>
        </main>
    )
}
